export const introSteps = {
  'dashboard': [
    {
      'element': '#step1',
      'intro': 'You can have a view of link attachement...!',
      'position': 'bottom'
    },
    {
      'element': '#step2',
      'intro': 'Notification is recieved...!',
      'position': 'bottom'
    },
    {
      'element': '#step3',
      'intro': 'Timeline is been guided...!',
      'position': 'bottom'
    },
    {
      'element': '#step4',
      'intro': 'Orders Requirement...!',
      'position': 'bottom'
    },
    {
      'element': '#step5',
      'intro': 'Current Project Status! '+'<br>'+'<i class="fa fa-dot-circle-o" style="color:#f37021" aria-hidden="true"></i> Project Started'+'<br>'+'<i class="fa fa-dot-circle-o" style="color:#f37021" aria-hidden="true"></i> Request Sent to Supplier'+'<br>'+'<i class="fa fa-dot-circle-o" style="color:#ffdb00" aria-hidden="true"></i> Estimate Created, Under Review'+'<br>'+
      '<i class="fa fa-dot-circle-o" style="color:#ffdb00" aria-hidden="true"></i> Estimate Approved,Ready to Send'+'<br>'+'<i class="fa fa-dot-circle-o" style="color:#ffdb00" aria-hidden="true"></i> Request for Change to Estimate by Customer'+'<br>'+'<i class="fa fa-dot-circle-o" style="color:#ffdb00" aria-hidden="true"></i> Estimate Approved by Customer'+'<br>'+'<i class="fa fa-dot-circle-o" style="color:#ffdb00" aria-hidden="true"></i> Request for Change to Estimate - Internal'+'<br>'+'<i class="fa fa-dot-circle-o" style="color:#ffdb00" aria-hidden="true"></i> Estimate Sent to Customer'+'<br>'+'<i class="fa fa-dot-circle-o" style="color:#ffdb00" aria-hidden="true"></i> Estimate Updated by Supplier'+'<br>',
      'position': 'right'
    },
    {
      'element': '#step6',
      'intro': 'Order Status...!',
      'position': 'bottom'
    }
  ],
  'accountant_dashboard': [
    {
      'element': '#step1',
      'intro': 'You can have a view of link attachement...!',
      'position': 'bottom'
    },
    {
      'element': '#step2',
      'intro': 'Notification is recieved...!',
      'position': 'bottom'
    },
    {
      'element': '#step3',
      'intro': 'Timeline is been guided...!',
      'position': 'bottom'
    },
    {
      'element': '#step5',
      'intro': 'Current Project Status! '+'<br>'+'<i class="fa fa-dot-circle-o" style="color:#f37021" aria-hidden="true"></i> Project Started'+'<br>'+'<i class="fa fa-dot-circle-o" style="color:#f37021" aria-hidden="true"></i> Request Sent to Supplier'+'<br>'+'<i class="fa fa-dot-circle-o" style="color:#ffdb00" aria-hidden="true"></i> Estimate Created, Under Review'+'<br>'+
      '<i class="fa fa-dot-circle-o" style="color:#ffdb00" aria-hidden="true"></i> Estimate Approved,Ready to Send'+'<br>'+'<i class="fa fa-dot-circle-o" style="color:#ffdb00" aria-hidden="true"></i> Request for Change to Estimate by Customer'+'<br>'+'<i class="fa fa-dot-circle-o" style="color:#ffdb00" aria-hidden="true"></i> Estimate Approved by Customer'+'<br>'+'<i class="fa fa-dot-circle-o" style="color:#ffdb00" aria-hidden="true"></i> Request for Change to Estimate - Internal'+'<br>'+'<i class="fa fa-dot-circle-o" style="color:#ffdb00" aria-hidden="true"></i> Estimate Sent to Customer'+'<br>'+'<i class="fa fa-dot-circle-o" style="color:#ffdb00" aria-hidden="true"></i> Estimate Updated by Supplier'+'<br>',
      'position': 'bottom'
    },
  ],
  'supplier_dashboard': [
    {
      'element': '#step1',
      'intro': 'You can have a view of link attachement...!',
      'position': 'bottom'
    },
    {
      'element': '#step2',
      'intro': 'Notification is recieved...!',
      'position': 'bottom'
    }
  ],
  'contacts': [
    {
      'element': '#contact_add',
      'intro': 'Add To Contact...!',
      'position': 'bottom'
    },
    {
      'element': '#contact_export',
      'intro': 'Export the data...!',
      'position': 'bottom'
    },
    {
      'element': '#contact_name',
      'intro': 'First name is declared hereby...!',
      'position': 'bottom'
    },
    {
      'element': '#contact_update',
      'intro': 'Actions Update Contact...!',
      'position': 'bottom'
    }
  ],
  'supplier': [
    {
      'element': '#suplier_creation',
      'intro': 'Create Supplier by clicking these number...!',
      'position': 'bottom'
    },
    {
      'element': '#suplier_action',
      'intro': 'Click Here to Supplier Edit...!',
      'position': 'bottom'
    }
  ],
  'project': [
    {
      'element': '#project_create',
      'intro': 'Create Project...!',
      'position': 'bottom'
    },
    // {
    //   'element': '#project_print',
    //   'intro': 'Printing Product Data...!',
    //   'position': 'bottom'
    // },
    {
      'element': '#project_details',
      'intro': 'Click here to view Project details...!',
      'position': 'bottom'
    },
    {
      'element': '#project_dot',
      'intro': 'Current Project Status! '+'<br>'+'<i class="fa fa-dot-circle-o" style="color:#f37021" aria-hidden="true"></i> Project Started'+'<br>'+'<i class="fa fa-dot-circle-o" style="color:#f37021" aria-hidden="true"></i> Request Sent to Supplier'+'<br>'+'<i class="fa fa-dot-circle-o" style="color:#ffdb00" aria-hidden="true"></i> Estimate Created, Under Review'+'<br>'+
      '<i class="fa fa-dot-circle-o" style="color:#ffdb00" aria-hidden="true"></i> Estimate Approved,Ready to Send'+'<br>'+'<i class="fa fa-dot-circle-o" style="color:#ffdb00" aria-hidden="true"></i> Request for Change to Estimate by Customer'+'<br>'+'<i class="fa fa-dot-circle-o" style="color:#ffdb00" aria-hidden="true"></i> Estimate Approved by Customer'+'<br>'+'<i class="fa fa-dot-circle-o" style="color:#ffdb00" aria-hidden="true"></i> Request for Change to Estimate - Internal'+'<br>'+'<i class="fa fa-dot-circle-o" style="color:#ffdb00" aria-hidden="true"></i> Estimate Sent to Customer'+'<br>'+'<i class="fa fa-dot-circle-o" style="color:#ffdb00" aria-hidden="true"></i> Estimate Updated by Supplier'+'<br>',
      'position': 'bottom'
    }
  ],
  'direct_estimation': [
    {
      'element': '#create_estimate',
      'intro': 'Create Estimate...!',
      'position': 'bottom'
    },
    {
      'element': '#view_estimate',
      'intro': 'View Estimate...!',
      'position': 'bottom'
    },
    {
      'element': '#direct_status',
      'intro': 'status of the user is as represented...!',
      'position': 'bottom'
    },
    {
      'element': '#print_estimate',
      'intro': 'Printing Product Data...!',
      'position': 'bottom'
    }
  ],
  'direct_estimation_details': [
    // {
    //   'element': '#direct_approved',
    //   'intro': 'View Estimate...!',
    //   'position': 'bottom'
    // },
    {
      'element': '#direct_print_estimate',
      'intro': 'Printing Product Data...!',
      'position': 'bottom'
    },
    {
      'element': '#estimate_details',
      'intro': 'Printing Product Data...!',
      'position': 'bottom'
    },
    {
      'element': '#product_details',
      'intro': 'Printing Product Data...!',
      'position': 'bottom'
    },
  ],
  'custom_project_view': [
    {
      'element': '#project_artwork_details',
      'intro': 'Click here to view order...!',
      'position': 'bottom'
    },
    {
      'element': '#contact_details',
      'intro': 'Click here to view product details...!',
      'position': 'left'
    },
    {
      'element': '#requeted_by',
      'intro': 'email information is provided...!',
      'position': 'bottom'
    }
  ],
  'sales_order': [
    {
      'element': '#view_order',
      'intro': 'Click here to view order...!',
      'position': 'bottom'
    },
    {
      'element': '#view_products',
      'intro': 'Click here to view product details...!',
      'position': 'bottom'
    },
    {
      'element': '#order_status',
      'intro': 'Click Here to Change Status...!',
      'position': 'bottom'
    },
    {
      'element': '#update_order',
      'intro': 'Update Order...!',
      'position': 'bottom'
    },
    {
      'element': '#view_invoice',
      'intro': 'View Invoice...!',
      'position': 'bottom'
    }
  ],
  'sales_order_view': [
    {
      'element': '#sales_create',
      'intro': 'click here to create sales...!',
      'position': 'bottom'
    },
    {
      'element': '#sales_purchase',
      'intro': 'Click here to close purchase...!',
      'position': 'bottom'
    },
    {
      'element': '#sales_payment_received',
      'intro': 'Click Here to Add payment of the sales payment recieved...!',
      'position': 'bottom'
    },
    {
      'element': '#sales_payment_bill',
      'intro': 'Click Here to pay sales payment...!',
      'position': 'bottom'
    },
    {
      'element': '#sales_order_invoice',
      'intro': 'Click here to view your Order Invoice...!',
      'position': 'bottom'
    },
    {
      'element': '#sales_po_details',
      'intro': 'Click here to View your PO Details...!',
      'position': 'bottom'
    }
  ],
  'sales_order_repo_view': [
    {
      'element': '#sales_payment_received',
      'intro': 'Click Here to Add payment of the sales payment recieved...!',
      'position': 'bottom'
    },
    {
      'element': '#sales_order_invoice',
      'intro': 'Click here to view your Order Invoice...!',
      'position': 'bottom'
    },
    {
      'element': '#sales_product_details',
      'intro': 'Click here to View your PO Details...!',
      'position': 'bottom'
    }
  ],
  'sales_order_print': [
    {
      'element': '#sales_print',
      'intro': 'Click here to print your view...!',
      'position': 'bottom'
    },
    {
      'element': '#sales_order_print_edit',
      'intro': 'Click here to Edit your Order...!',
      'position': 'bottom'
    },
    {
      'element': '#sales_edit_price',
      'intro': 'Click here to edit your price',
      'position': 'top'
    },
    {
      'element': '#invoice_details',
      'intro': 'Presenting your invoice details',
      'position': 'top'
    }
  ],
  'order_invoice': [
    {
      'element': '#view_invoice',
      'intro': 'View invoice',
      'position': 'bottom'
    },
    {
      'element': '#view_order',
      'intro': 'View order',
      'position': 'bottom'
    },
    {
      'element': '#order_invoice_status',
      'intro': 'You can see your status of invoice...!',
      'position': 'bottom'
    },
    {
      'element': '#send_invoice',
      'intro': 'Send invoice...!',
      'position': 'bottom'
    }
  ],
  'purchase_orders_general': [
    {
      'element': '#view_sales_order',
      'intro': 'View Sales Order...!',
      'position': 'bottom'
    },
    {
      'element': '#view_purchase_order',
      'intro': 'View Purchase Order...!',
      'position': 'bottom'
    },
    {
      'element': '#po_status',
      'intro': 'View Purchase Order Status...!',
      'position': 'bottom'
    }
  ],
  'bills_general': [
    {
      'element': '#view_bill',
      'intro': 'Bill Number Detail...!',
      'position': 'bottom'
    },
    {
      'element': '#view_po',
      'intro': 'Po Number Detail...!',
      'position': 'bottom'
    },
    {
      'element': '#view_sales_order',
      'intro': 'Order Number Detail...!',
      'position': 'bottom'
    }
  ],
  'quotes': [
    {
      'element': '#quote_id',
      'intro': 'Quote id is been shown here...!',
      'position': 'bottom'
    },
    {
      'element': '#quotes_view_details',
      'intro': 'Action view details...!',
      'position': 'bottom'
    },
    {
      'element': '#quotes_print',
      'intro': 'Action print details...!',
      'position': 'bottom'
    }
  ],
  'orders': [
    {
      'element': '#order_status',
      'intro': 'Order Status...!',
      'position': 'bottom'
    },
    {
      'element': '#order_view_details',
      'intro': 'Order view Details...!',
      'position': 'bottom'
    },
    {
      'element': '#order_artwork_details',
      'intro': 'Ordere Artwork Details...!',
      'position': 'bottom'
    }

  ],
  'accounting_orders': [
    {
      'element': '#accounting_order_number',
      'intro': 'Order Number is here...!',
      'position': 'bottom'
    },
    {
      'element': '#accounting_order_status',
      'intro': 'Order Number is here...!',
      'position': 'bottom'
    }
  ],
  'payment_received': [
    {
      'element': '#payment_received_order_number',
      'intro': 'Order Number is here...!',
      'position': 'bottom'
    },
    {
      'element': '#payment_received_invoice_number',
      'intro': 'Order Number is here...!',
      'position': 'bottom'
    },
    {
      'element': '#payment_received_order_status',
      'intro': 'Order Number is here...!',
      'position': 'bottom'
    },
    {
      'element': '#payment_received_action',
      'intro': 'Order Number is here...!',
      'position': 'bottom'
    },
  ],
  'payments_bills': [
    {
      'element': '#payments_order_number',
      'intro': 'Order Number of the payment...!',
      'position': 'bottom'
    },
    {
      'element': '#payments_bill_number',
      'intro': 'Order Number of the payment...!',
      'position': 'bottom'
    },
    {
      'element': '#payments_bills_status',
      'intro': 'Click the Print Data...!',
      'position': 'bottom'
    },
    {
      'element': '#payments_view_payment',
      'intro': 'Click the Print Data...!',
      'position': 'bottom'
    }
  ],
  'accounts_receivables': [
    {
      'element': '#accounts_receivables_order_number',
      'intro': 'Click the Print Data...!',
      'position': 'bottom'
    },
    {
      'element': '#accounts_receivables_invoice_number',
      'intro': 'Click the Print Data...!',
      'position': 'bottom'
    },
    {
      'element': '#accounts_receivables_due',
      'intro': 'Due orders are...!',
      'position': 'bottom'
    }
  ],
  'accounts_paybles': [
    {
      'element': '#accounts_paybles_bill_number',
      'intro': 'Click the Print Data...!',
      'position': 'bottom'
    },
    {
      'element': '#accounts_due',
      'intro': 'Over due accounts are...!',
      'position': 'bottom'
    }
  ],
  'quote': [
    // {
    //   'element': '#quote_link',
    //   'intro': 'Click the Link...!',
    //   'position': 'top'
    // },
    {
      'element': '#quote_print',
      'intro': 'Click the Print Data...!',
      'position': 'bottom'
    },
    {
      'element': '#edit_product_quote',
      'intro': 'Edit product quote...!',
      'position': 'bottom'
    },
    {
      'element': '#remove_product',
      'intro': 'Remove product from cart...!',
      'position': 'bottom'
    }
  ],
  'sage_product': [
    {
      'element': '#price_details',
      'intro': 'Price of different quantities...!',
      'position': 'bottom'
    },
    {
      'element': '#supplier_details',
      'intro': 'Details of supplier...!',
      'position': 'top'
    },
    {
      'element': '#create_quote',
      'intro': 'create quote for this product...!',
      'position': 'left'
    }
  ],
  'users': [
    {
      'element': '#users_button',
      'intro': 'Click to add user field...!',
      'position': 'bottom'
    },
    {
      'element': '#users_action',
      'intro': 'Actions...!',
      'position': 'bottom'
    }
  ],
  'supplier_project': [
    {
      'element': '#project_name',
      'intro': 'Project Name is preseneted here...!',
      'position': 'bottom'
    },
    {
      'element': '#supplier_status',
      'intro': 'Click to add user fields...!',
      'position': 'bottom'
    }
  ],
  'purchase-order': [
    {
      'element': '#purchase_po_number',
      'intro': 'purchase po number...!',
      'position': 'bottom'
    },
    {
      'element': '#purchase_status',
      'intro': 'Click to add user fields...!',
      'position': 'bottom'
    }
  ],
  'purchase-order_view': [
    {
      'element': '#purchase_order_po',
      'intro': 'Print PO Number...!',
      'position': 'bottom'
    },
    {
      'element': '#purchase_bill_details',
      'intro': 'here is the Bill details purchased',
      'position': 'bottom'
    },
    {
      'element': '#purchase_order_details',
      'intro': 'here is the Bill details purchased',
      'position': 'bottom'
    },
  ],
  'contact_view': [
    {
      'element': '#billing_address',
      'intro': 'Billing address is as..!',
      'position': 'bottom'
    },
    {
      'element': '#shipping_address',
      'intro': 'Shipping address is as..!',
      'position': 'bottom'
    }
  ],
  'print_products': [
    {
      'element': '#Search_products',
      'intro': 'Search products as per your requirements..!',
      'position': 'bottom'
    },
    {
      'element': '#Single_products',
      'intro': 'Click here to Select Product Of This Category..!',
      'position': 'bottom'
    }
  ],
  'print_products_search': [
    {
      'element': '#Search_products',
      'intro': 'Search products as per your requirements..!',
      'position': 'bottom'
    },
    {
      'element': '#Search_Selection',
      'intro': 'Click here to select product category..!',
      'position': 'top'
    },
    {
      'element': '#Search_print',
      'intro': 'Click here for the filtering data..!',
      'position': 'top'
    }
  ],
  'search_products': [
    {
      'element': '#basic',
      'intro': 'Click here for the basic search...!',
      'position': 'bottom'
    },
    {
      'element': '#advance',
      'intro': 'Click here for the advance search...!',
      'position': 'top'
    }
  ],
  'filtered_products': [
    {
      'element': '#basic',
      'intro': 'Counts the Adding Quotes...!',
      'position': 'bottom'
    },
    {
      'element': '#checkbox',
      'intro': 'Click here for Adding to Quotes...!',
      'position': 'top'
    },
    {
      'element': '#single_product',
      'intro': 'Click here to select product category ...!',
      'position': 'top'
    }
  ],
  'project_estimation': [
    {
      'element': '#project_id',
      'intro': 'Click here for Adding to Quotes...!',
      'position': 'top'
    },
    {
      'element': '#project_edit',
      'intro': 'Click here to select product category ...!',
      'position': 'top'
    },
    {
      'element': '#project_status',
      'intro': 'Counts the Adding Quotes...!',
      'position': 'bottom'
    },
  ],
  'project_bid': [
    {
      'element': '#req_to_supplier',
      'intro': 'Click here for Adding to Quotes...!',
      'position': 'top'
    },
    {
      'element': '#bid_sending_history',
      'intro': 'Click here to select product category ...!',
      'position': 'top'
    }
  ],
  'create_project' : [
    {
      'element' : '#assign_sales_rep',
      'intro' : 'Select Sales Rep...!',
      'position' : 'bottom'
    },
    {
      'element' : '#custome_detail',
      'intro' : 'Select Customer...!',
      'position' : 'bottom'
    },
    {
      'element' : '#custom_product',
      'intro' : 'Select To Create Custom Project...!',
      'position' : 'bottom'
    },
    {
      'element' : '#print_product',
      'intro' : 'Select To Create Print Project...!',
      'position' : 'bottom'
    },
    {
      'element' : '#direct_mail',
      'intro' : 'Select To Create Direct Project...!',
      'position' : 'bottom'
    },
    {
      'element' : '#digital_service',
      'intro' : 'Select To Create Digital Service Project...!',
      'position' : 'bottom'
    },
    {
      'element' : '#project_name',
      'intro' : 'Enter Project Name...!',
      'position' : 'bottom'
    },
    {
      'element' : '#artwork_detail',
      'intro' : 'Project Artwork Detail...!',
      'position' : 'bottom'
    }
  ],
  'edit_project' : [
    {
      'element' : '#assign_sales_rep',
      'intro' : 'Select Sales Rep...!',
      'position' : 'bottom'
    },
    {
      'element' : '#custome_detail',
      'intro' : 'Select Customer...!',
      'position' : 'bottom'
    },
    {
      'element' : '#project_specification',
      'intro' : 'Project specification',
      'position' : 'bottom'
    },
    // {
    //   'element' : '#custom_product_specifications',
    //   'intro' : 'Custom Product Specification...!',
    //   'position' : 'bottom'
    // },
    // {
    //   'element' : '#print_product_specifications',
    //   'intro' : 'Print Product Specification...!',
    //   'position' : 'bottom'
    // },
    {
      'element' : '#project_name',
      'intro' : 'Enter Project Name...!',
      'position' : 'bottom'
    },
    {
      'element' : '#artwork_detail',
      'intro' : 'Project Artwork Detail...!',
      'position' : 'bottom'
    }
  ],
  'create_project_sales_rep' : [
    {
      'element' : '#custome_detail',
      'intro' : 'Select Customer...!',
      'position' : 'bottom'
    },
    {
      'element' : '#custom_product',
      'intro' : 'Select To Create Custom Project...!',
      'position' : 'bottom'
    },
    {
      'element' : '#print_product',
      'intro' : 'Select To Create Print Project...!',
      'position' : 'bottom'
    },
    {
      'element' : '#direct_mail',
      'intro' : 'Select To Create Direct Project...!',
      'position' : 'bottom'
    },
    {
      'element' : '#digital_service',
      'intro' : 'Select To Create Digital Service Project...!',
      'position' : 'bottom'
    },
    {
      'element' : '#project_name',
      'intro' : 'Enter Project Name...!',
      'position' : 'bottom'
    },
    {
      'element' : '#artwork_detail',
      'intro' : 'Project Artwork Detail...!',
      'position' : 'bottom'
    }
  ],
  'edit_project_sales_rep' : [
    {
      'element' : '#custome_detail',
      'intro' : 'Select Customer...!',
      'position' : 'bottom'
    },
    {
      'element' : '#project_specification',
      'intro' : 'Project specification',
      'position' : 'bottom'
    },
    // {
    //   'element' : '#custom_product_specifications',
    //   'intro' : 'Custom Product Specification...!',
    //   'position' : 'bottom'
    // },
    // {
    //   'element' : '#print_product_specifications',
    //   'intro' : 'Print Product Specification...!',
    //   'position' : 'bottom'
    // },
    {
      'element' : '#project_name',
      'intro' : 'Enter Project Name...!',
      'position' : 'bottom'
    },
    {
      'element' : '#artwork_detail',
      'intro' : 'Project Artwork Detail...!',
      'position' : 'bottom'
    }
  ],
};
