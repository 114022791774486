import { Component, OnInit } from "@angular/core";
import { Utils } from "../../../../utils";
import { CommonService } from "../../../../service/common.service";
import { ActivatedRoute } from "@angular/router";
import { SalesOrderService } from "../../../../service/sales-order.service";
import Swal from "sweetalert2";
import { environment } from "../../../../../environments/environment";

declare const $: any;

@Component({
  templateUrl: "proof-public.component.html",
  styleUrls: ["./proof-public.component.scss"],
})
export class ProofPublicComponent implements OnInit {
  products = [];
  status: any = {
    loading: false,
    success: false,
    error: false,
  };
  // for view and edit quote
  estimationData = {};
  franchiseLogo = "";
  loading = false;
  item: any = "item0";
  api_error = {};
  total: any = 0;
  frenchiseId: any = undefined;
  approveRejectReason: any = "";
  showErrorMsg: any = false;
  showItemErrorMsg: any = false;
  approvedOrRejectedEstimation: any = {};
  estId: any = undefined;
  approvalCheck: any = 1;
  approvalRejectComment: any = undefined;
  approvalText: any = undefined;
  newDisplaySelectedTotal: any = 0;
  project_type = "";
  checkedElements: any = [];
  message = "";
  Path: any = "";
  imagePreviewSource: any = "";
  item_id: any = "";
  proof_status: any = "";
  // end
  constructor(
    private utils: Utils,
    public commonService: CommonService,
    private _activatedRoute: ActivatedRoute,
    private orderService: SalesOrderService
  ) {}

  ngOnInit() {
    this._activatedRoute.params.subscribe((params) => {
      if (params["franchise_id"] && params["order_id"] && params["item_id"]) {
        try { this.frenchiseId = atob(params["franchise_id"]); }
        catch(e) { this.frenchiseId = atob(decodeURIComponent(params["franchise_id"])); }
        
        this.Path = environment.AWS_FILE_PATH + this.frenchiseId + "/apparel_with_logo/";
        
        try { this.estId = atob(params["order_id"]); }
        catch(e) { this.estId = atob(decodeURIComponent(params["order_id"])); }

        try { this.item_id = atob(params["item_id"]); }
        catch(e) { this.item_id = atob(decodeURIComponent(params["item_id"])); }

        this.getEstimation(this.estId, this.frenchiseId, this.item_id);
      }
    });
  }

  checkIsChecked = (event) => {
    if (event.target.checked) {
      this.checkedElements.push(event.target.id);
    } else {
      let test = this.checkedElements.indexOf(event.target.id);
      if (test > -1) {
        this.checkedElements.splice(test, 1);
      }
    }
  };

  returnStringWithBold = (description) => {
    if (+this.project_type == 8) {
      let string = description;
      let final = [];
      var c = string.split(", ");
      c.map((item) => {
        var char = item.split(":");
        if (char[0] && char[1]) {
          final.push(char[0].bold() + " : " + char[1] + "<br>");
        }
      });
      return final.join(" ");
    } else {
      return description;
    }
  };

  getEstimation = (estimationId, franchiseId, itemId) => {
    this.newDisplaySelectedTotal = 0;
    this.status = this.utils.showContainers(this.status, "loading");
    this.orderService.getProofData(estimationId, franchiseId, itemId).subscribe(
      (res) => {
        this.loading = true;
        this.estimationData = res["data"];
        if (this.estimationData["project_of_order"]) {
          this.project_type = this.estimationData["project_of_order"]["project_type"];
        }
        this.estimationData["order_items"].map((item) => {
          if (item["id"] == this.item_id) {
            this.proof_status = item["proof_status"];
          }
        });
        const logo = this.commonService.UPLOAD_FILE_AWS("franchise_images", false, franchiseId);
        this.franchiseLogo = logo + this.estimationData["franchise_logo"];
        this.status = this.utils.showContainers(this.status, "success");
        this.loading = false;
      },
      (error) => {
        this.status = this.utils.showContainers(this.status, "success");
      }
    );
  };

  openComment(btnTitle) {
    if (btnTitle == "Approve") {
      if (this.checkedElements.length == 0) {
        Swal.fire({
          title: "Are you sure you want to approve?",
          html: "<textarea id='text' placeholder='' class='form-control d-none' [(ngModel)]='approveRejectReason'></textarea>",
          // --------------^-- define html element with id
          // html: "true",
          showCancelButton: true,
          confirmButtonText: btnTitle,
          showLoaderOnConfirm: true,
          inputPlaceholder: "Write something",
          inputValidator: (value) => {
            return new Promise((resolve) => {
              if (value !== "") {
                resolve('');
              } else {
                resolve("Please enter Comment");
              }
            });
          },
          preConfirm: (value1) => {
            var val = document.getElementById("text")["value"];
            if (val != "" || btnTitle == "Approve") {
              var btnText;
              if (btnTitle == "Approve") {
                btnText = "approve";
              } else if (btnTitle == "Request For Change") {
                btnText = "reject";
              }
              if (!Object.keys(this.approvedOrRejectedEstimation).length) {
                this.showItemErrorMsg = true;
              } else {
                this.showItemErrorMsg = false;
              }

              if (val == "") {
                this.showErrorMsg = true;
              } else {
                this.showErrorMsg = false;
              }

              var postData = {};
              postData["id"] = this.estId;
              postData["btnText"] = btnText;
              postData["approveRejectReason"] = val;
              postData["item_id"] = this.item_id;

              return this.orderService
              .uploadPublicProof(this.frenchiseId, postData)
              .toPromise()
              .then((res) => {
                if (res["data"]) {
                  return true;
                }
              })
              .catch(() => {
                return true;
              });
            } else {
              Swal.showValidationMessage("Please enter Comment");
            }
          },
        }).then((res) => {
          if (res["value"]) {
            this.ngOnInit();
          }
        });
      } else {
        this.message = "please select terms";
      }
    } else {
      Swal.fire({
        title: "Please Enter Reason!",
        html: "<textarea id='text' placeholder='' class='form-control' [(ngModel)]='approveRejectReason'></textarea>",
        // --------------^-- define html element with id
        // html: "true",
        showCancelButton: true,
        confirmButtonText: btnTitle,
        showLoaderOnConfirm: true,
        inputPlaceholder: "Write something",
        inputValidator: (value) => {
          return new Promise((resolve) => {
            if (value !== "") {
              resolve('');
            } else {
              resolve("Please enter Comment");
            }
          });
        },
        preConfirm: (value1) => {
          var val = document.getElementById("text")["value"];
          if (val != "") {
            var btnText;
            if (btnTitle == "Approve") {
              btnText = "approve";
            } else if (btnTitle == "Request For Change") {
              btnText = "reject";
            }
            if (!Object.keys(this.approvedOrRejectedEstimation).length) {
              this.showItemErrorMsg = true;
            } else {
              this.showItemErrorMsg = false;
            }

            if (val == "") {
              this.showErrorMsg = true;
            } else {
              this.showErrorMsg = false;
            }

            var postData = {};
            postData["id"] = this.estId;
            postData["btnText"] = btnText;
            postData["approveRejectReason"] = val;
            postData["item_id"] = this.item_id;
            return this.orderService
            .uploadPublicProof(this.frenchiseId, postData)
            .toPromise()
            .then((res) => {
              if (res["data"]) {
                return true;
              }
            })
            .catch(() => {
              return true;
            });
          } else {
            Swal.showValidationMessage("Please enter Comment");
          }
        },
      }).then((res) => {
        if (res["value"]) {
          this.ngOnInit();
        }
      });
    }
  }
}