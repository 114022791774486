import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PurchaseOrderService } from '../../../service/purchase-order.service';
import { CommonService } from '../../../service/common.service';
import { Utils } from '../../../utils';
import { ModalDirective } from 'ngx-bootstrap';
import { Subject } from 'rxjs';
import { FranchiseService } from '../../../service/franchise.service';
import Swal from 'sweetalert2';
import { ProjectService } from '../../../service/project.service';
import { DatePipe } from '@angular/common';
import { ToasterService } from 'angular2-toaster';

@Component({
  templateUrl: './purchase-order-view.component.html',
  styleUrls: ['./purchase-order-view.component.scss']
})
export class PurchaseOrderViewComponent implements OnInit {
  @ViewChild('billModal') public modal: ModalDirective;
  changingValue: Subject<boolean> = new Subject();
  poId = '';
  franchiseLogo = '';
  poData: any = [];
  franchiseData: any = [];
  billData: any = [];
  billDetail: any = [];
  canLink = false;
  addressDetails: any = {};
  selectedFranchise = '';
  totalcost = 0;
  shippingcost = 0;
  price = 0;
  sales_order_shipping = 0;
  sales_order_price = 0;
  sales_order_total_order_cost =0;
  project_type: string | number = '';

  status: any = {
    loading: false,
    success: false,
    error: false
  };
  statusModal: any = {
    loading: false,
    success: false,
    error: false
  };
  api_error = {};
  loading = false;

  constructor(private activatedRoute: ActivatedRoute,
    private franchiseService: FranchiseService,
    public commonService: CommonService,
    private utils: Utils,
    private _route: Router,
    private poService: PurchaseOrderService,
    private projectService: ProjectService,
    private datePipe: DatePipe,
    private toasterService: ToasterService) {
  }

  ngOnInit() {
    this.commonService.introType.next('purchase-order_view');
    this.status = this.utils.showContainers(this.status, 'loading');
    this.activatedRoute.params.subscribe(resId => {
      this.poId = resId['id'];
      if (resId['franchiseId']) {
        this.selectedFranchise = resId['franchiseId'];
      }
      this.getPoData();
      this.getBillData();
    });


    if (this.selectedFranchise !== '') {
      const franchiseOptions = this.franchiseService.franchiseOptions.getValue();
      franchiseOptions.map(franchise => {
        if (+franchise['id'] === +this.selectedFranchise) {
          this.franchiseService.getFranchiseData(this.selectedFranchise).subscribe(res => {
            this.franchiseData = res['data'];
          });
          const logo = this.commonService.UPLOAD_FILE_AWS('franchise_images', false, this.selectedFranchise);
          this.franchiseLogo = logo + (franchise['print_logo'] ? franchise['print_logo'] : franchise['logo']);
        }
      });
    } else {
      const franchiseRes = this.franchiseService.franchiseData.getValue();
      this.franchiseData = franchiseRes;
      const logo = this.commonService.UPLOAD_FILE_AWS('franchise_images');
      this.franchiseLogo = logo + (this.franchiseData['print_logo'] ? this.franchiseData['print_logo'] : this.franchiseData['logo']);
    }
  }
  returnStringWithBold = (description) => {
    if(+this.project_type == 8){
    let string =description
    let final = []
    var c = string.split(", ");
    c.map((item) => {
      var char = item.split(":");
      if (char[0] && char[1]) {
        final.push(char[0].bold() +' : '+ char[1] +'<br>')
      }
    })
    return final.join(' ')
  }else{
    return description
  }
}

  getPoData = () => {
    this.poService.getPoDataById(this.poId, this.selectedFranchise).subscribe(res => {
      if (res) {
        this.status = this.utils.showContainers(this.status, 'success');
        this.projectService.getOrderData(res['sales_order']['id']).subscribe(res => {
              
          this.project_type = res['data']['project_of_order'] ?res['data']['project_of_order']['project_type'] : ''
        
        });
        this.poData = res;
        if (this.poData['ship_to'] === 2) {
          this.addressDetails = this.poData['shipping_address'];
          this.addressDetails['phone'] = this.poData['sales_order']['contact_shipping_details']['s_phone'] ? this.poData['sales_order']['contact_shipping_details']['s_phone']:'' ;
          this.addressDetails['email'] = this.poData['sales_order']['contact_shipping_details']['s_email']?this.poData['sales_order']['contact_shipping_details']['s_email']:'';
          this.addressDetails['full_name'] = this.poData['sales_order']['contact_shipping_details']['s_full_name'] ?this.poData['sales_order']['contact_shipping_details']['s_full_name'] :'';
        } else {
          const address = this.poData['sales_order']['contact_shipping_details'];
          this.addressDetails['full_name'] = address['s_full_name'];
          this.addressDetails['address_line1'] = address['s_address1'];
          this.addressDetails['address_line2'] = address['s_address2'];
          this.addressDetails['city'] = address['s_city'];
          this.addressDetails['country'] = address['s_country'];
          this.addressDetails['state_code'] = address['s_state_code'];
          this.addressDetails['zip_code'] = address['s_zip_code'];
          this.addressDetails['phone'] = address['s_phone'] ? address['s_phone'] : '';
          this.addressDetails['email'] = address['s_email'] ? address['s_email'] :'';
        }
      }
      this.getPurchaseAmountInfo();
    },(error) => {
      if(error['error']['data'] == 'invalid_request') {
        this.toasterService.pop('error', error['error']['message']);
        this._route.navigate(['../../../dashboard'], { relativeTo: this.activatedRoute });
      }
    });
  }
  getPurchaseAmountInfo = () => {
    var purchaseInfo = this.poData;
    this.totalcost = 0;
    this.shippingcost = 0;
    this.price = 0;
    this.sales_order_price = 0
    this.sales_order_total_order_cost = 0;
    this.sales_order_shipping = 0;
    if(purchaseInfo){
      purchaseInfo['order_items'].map((item,index)=> {
          // this.shippingcost += Number(item['shipping_cost'])
          // this.price += (Number(item['shipping_cost']) + Number(item['total_cost']))
          // this.totalcost += Number(item['total_cost'])
          this.sales_order_shipping += ((Number(item['shipping_cost'])) + ((Number(item['shipping_markup']) * Number(item['shipping_cost']))/100))
          this.sales_order_price += (Number(item['price']))
      })
      this.sales_order_total_order_cost += (Number(this.sales_order_shipping) + Number(this.sales_order_price))

      purchaseInfo['po_items'].map((item,index)=> {
        this.shippingcost += Number(item['shipping_cost'])
        this.price += (Number(item['shipping_cost']) + Number(item['total_cost']))
        this.totalcost += Number(item['total_cost'])
        // this.sales_order_shipping += ((Number(item['shipping_cost'])) + ((Number(item['shipping_markup']) * Number(item['shipping_cost']))/100))
        // this.sales_order_price += (Number(item['price']))
      })
    }
    
  }

  printPo = () => {
    let printContents, popupWin;
    printContents = document.getElementById('invoice').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Purchase Order</title>
          <link rel="stylesheet" href="'../../../../../../assets/css/invoice.css">
        </head>
    <body onload="window.print();window.close()" style="font-family: 'Karla', sans-serif;">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }

  validateAndDownload = () => {
    const postData = this.poData;
    postData['created_at'] = this.getFormatedDate(postData['created_at']);
    postData['address_details'] = this.addressDetails;
    postData['franchise_logo'] = this.franchiseLogo;
    postData['project_type'] = this.project_type
    this.loading = true;
    this.projectService.downloadPurchasePdf(postData, this.selectedFranchise).subscribe(
      (res: any) => {
        this.api_error = {};
        this.loading = false;
        const blobData = new Blob([res], { type: 'application/octet-stream' });
        const a = document.createElement('a');
        a.href = URL.createObjectURL(blobData);
        a.download = postData['po_uid'] + '_Estimate.pdf';
        document.body.appendChild(a);
        a.click();
      },
      (error) => {
        this.loading = false;
        if (error['error']['data']) {
          this.api_error = error['error']['data'];
        }
      }
    );
  }

  getFormatedDate = (date) => {
    let newDate: any = new Date(date);
    if (isNaN(newDate.getTime())) {
      const dateArr = date.split('-');
      newDate = new Date(dateArr[2], dateArr[1] - 1, dateArr[0]);
    }
    return this.datePipe.transform(newDate, 'MM-dd-yyyy');
  }

  getBillData = () => {
    this.poService.getBillDataById(this.poId, this.selectedFranchise).subscribe(res => {
      if (res) {
        this.billData = res['data']['bills'];
        this.canLink = res['data']['can_link'];
      }
    });
  }

  openBillDetails = (id) => {
    this.modal.show();
    this.statusModal = this.utils.showContainers(this.statusModal, 'loading');
    this.poService.getBillDetails(id, this.selectedFranchise).subscribe(res => {
      this.billDetail = res['data'];
      this.statusModal = this.utils.showContainers(this.statusModal, 'success');
    });
  }
  getCostPerUnit = (total_cost, quantity) => {
    const unit = +total_cost / +quantity;
    return Math.round(unit * 100) / 100;

  }
  linkBill = () => {
    this._route.navigate(['../../link-bill', this.poId], { relativeTo: this.activatedRoute });
  }
  getCommaSeparetedItems = (items1) => {
    const nameArray = [];
    items1.map(product => {
      nameArray.push(product['item_spec']);
    });
    return nameArray.join(', ');
  }

  confirmApproved = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Approve it!',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.poService.approvePo(this.poId, this.selectedFranchise).toPromise()
          .then((res) => {
            if (res['message']) {
              this.poData['po_approved'] = 1;
              return true;
            }
          })
          .catch(() => {
            return true;
          });
      },
    }).then((res) => {
    });
  }
}