import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { CommonService } from './common.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class PurchaseOrderService {
  constructor(private _http: HttpClient, private _cs: CommonService) { }
  createPO = (data, isDirect = false) => {
    let header:any = this._cs.setHeader();
    header['reportProgress'] = true;
    header['observe'] = "events";
    if (isDirect) {
      return this._http.post<any>(environment.API_URL + 'purchase-order/save-direct', data, header);
    } else {
      return this._http.post<any>(environment.API_URL + 'purchase-order/save', data, header);
    }


  }
  getPoData = (id) => {
    return this._http.get(environment.API_URL + 'purchase-order/order-po/' + id, this._cs.setHeader());
  }
  getPurchaseOrderReport = (data) => {
    return this._http.post<any>(environment.API_URL + 'purchase-order/get-po-users', data, this._cs.setHeader());
  }
  getPoDataById = (id, franchiseId = '') => {
    return this._http.get(environment.API_URL + 'purchase-order/get-po/' + id, this._cs.setHeader(franchiseId));
  }

  getPoDataByIdWithSuppliers = (id, franchiseId = '') => {
    return this._http.get(environment.API_URL + 'purchase-order/get-po-with-suppliers/' + id, this._cs.setHeader(franchiseId));
  }

  getPoForBill = (id) => {
    return this._http.get(environment.API_URL + 'purchase-order/po-for-bill/' + id, this._cs.setHeader());
  }
  getPoForLinkBill = (id, franchiseId = '') => {
    return this._http.get(environment.API_URL + 'purchase-order/po-for-link-bill/' + id, this._cs.setHeader(franchiseId));
  }
  sendPo = (data) => {
    return this._http.post(environment.API_URL + 'purchase-order/send-po',data, this._cs.setHeader());
  }
  getBillDataById = (id, franchiseId = '') => {
    return this._http.get(environment.API_URL + 'purchase-order/po-bills/' + id, this._cs.setHeader(franchiseId));
  }
  getBillDetails = (id, franchiseId = '') => {
    return this._http.get(environment.API_URL + 'purchase-order/get-bill/' + id, this._cs.setHeader(franchiseId));
  }
  approvePo = (id, franchiseId = '') => {
    return this._http.post<any>(environment.API_URL + 'purchase-order/approve-po', { id: id }, this._cs.setHeader(franchiseId));
  }

  approvePoAccountant = (id) => {
    return this._http.get(environment.API_URL + 'purchase-order/approve-po-accountant/' + id, this._cs.setHeader());
  }

  deleteBill = (id) => {
    return this._http.get(environment.API_URL + 'purchase-order/bill-delete/' + id, this._cs.setHeader());
  }

  deletePO = (id) => {
    return this._http.get(environment.API_URL + 'purchase-order/delete-po/' + id, this._cs.setHeader());
  }
}
