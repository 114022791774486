import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'amountFilter'
})

export class AmountFormatePipe implements PipeTransform {

  constructor() { }

  transform(number: any): any {
    if (number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    return 0;
  }
}
