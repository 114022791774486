import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, ActivatedRoute, CanActivateChild } from '@angular/router';
import { AuthenticationService } from '../service/authentication.service';
import { FranchiseService } from '../service/franchise.service';
import { RolepermissionService } from '../service/rolepermission.service';
import { DevRolepermissionService } from '../service/DevAdmin/rolepemission.service';

/*
 * @usage Auth Guard check if authenticated or not. If authenticated then
 *        continue otherwise redirect to login.
 */

@Injectable()
export class SupplierAuthGuard implements CanActivate {

  rolePermisionService = null;

  constructor(private _router: Router,
    private authService: AuthenticationService,
    private franchiseService: FranchiseService,
    private userRolePermission: RolepermissionService) {
    this.rolePermisionService = this.userRolePermission;
  }

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
    let userData = this.authService.userData.getValue();
    if (localStorage.getItem('token')) {
      if (Object.keys(userData).length > 0) {
        if (userData['is_supplier']) {
          return true;
        } else {

          // when user is not supplier

          this._router.navigate(['account-selection']);
          return false;
        }
        // this._router.navigate(['/supplier/dashboard']);
        // return false;
      } else {
        return this.authService.refreshUserdata().then(res => {
          userData = this.authService.userData.getValue();
          if (userData['is_supplier']) {
            this.franchiseService.franchiseOptions.next(userData['franchises']);
            return true;
          } else {

            // when user is not supplier

            this._router.navigate(['account-selection']);
            return false;
          }
          // this._router.navigate(['/supplier/dashboard']);
          // return false;
        });
      }
    } else {
      this._router.navigate(['/login']);
      return false;
    }
  }

}
