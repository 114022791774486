import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../service/user.service';
import { ToasterService } from 'angular2-toaster';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap';
import { CommonService } from '../../../service/common.service';
import { states } from '../../../../assets/json-options/usa-states';
import { countryCodes } from '../../../../assets/json-options/country-codes';
import { FranchiseService } from '../../../service/franchise.service';
import { AppConstants } from '../../../constants/app.constants';
import { AuthenticationService } from '../../../service/authentication.service';
import { ProjectService } from '../../../service/project.service';
import { ShippingAddressService } from '../../../service/shipping-address.service';
import Swal from 'sweetalert2';
import { DataTableService } from '../../../service/datatable.service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-user-contact-create',
  templateUrl: './user-contact-create.component.html',
  styleUrls: ['./user-contact-create.component.scss']
})
export class UserContactCreateComponent implements OnInit {
  @Input() isModal = false;
  @Output() isSaved: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('ambiguosAddressModel') addModalRef: ModalDirective;
  @ViewChild('companySaveModal') public companySaveModal: ModalDirective;
  @ViewChild('commissionUpdateModal') public commissionUpdateModal: ModalDirective;
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  public loading = false;

  franchiseData: any = [];
  userContactForm: FormGroup;
  addShippingAddressForm: FormGroup;
  edit_id: any;
  edit_shipping_address_selected_id: any;
  api_error = {};
  api_error_address = {};
  other_details_count: any = 0;

  userContactData: any = [];
  address_count = 0;
  basic_count = 0;
  commissionData: any = [];
  isReadOnly = false;
  title = 'Create Account';
  selected_ambiguous_address_index: any;
  selected_ambiguous_address: any;
  ambiguous_addresses: any = [];
  stateList = states;
  countryList = countryCodes;
  ambiguosType = '';
  isShipping = false;

  user_id: any = null;
  companyList: any = [];
  salesRepList: any = [];
  secondSalesRepList: any = [];
  csrList: any = [];
  currentUser: ''
  isSalesRep: boolean = false
  isAccountant: boolean = false;
  isAdmin: boolean = false;
  isCSR: boolean = false;

  deletedUser: any = null; // On update Distributor or Account Manager

  commissionUpdatedRole: any = null;
  projectsForCommissionUpdate: any = []; // Project list for update commission
  commissionUpdateForSalesRepProjects: any = []; // Project ids in which need to update commission for sales_rep
  commissionUpdateForCSRProjects: any = []; // Project ids in which need to update commission for csr
  franchiseId: any = [''];
  hideFiledsByFranchiseId: boolean = false;
  isAddShippingAddress: boolean = false;
  modalRef: BsModalRef | null = null;
  shippingAddressList: any[] = [];
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  file: File | null = null;
  duplicateAddressList: any[] = [];
  selectAll: boolean = false;

  constructor(private _fb: FormBuilder, private _route: Router,
    public _csService: CommonService,
    private _toast: ToasterService,
    private toasterService: ToasterService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private franchiseService: FranchiseService,
    private authService: AuthenticationService,
    private projectService: ProjectService,
    private modalService: BsModalService,
    private _dtService: DataTableService,
    private shippingAddressService: ShippingAddressService) {
  }

  ngOnInit() {
    this.initContactForm();
    this.initAddContactShipping();
    this.userService.getCommissionType().subscribe(res => {
      this.commissionData = res['data'];
    });

    //this.getContactCompanies();

    if (!this.isModal) {
      this.activatedRoute.params.subscribe((resId) => {
        if (resId.id) {
          this.edit_id = resId.id;
          this.title = 'Edit Account';
          this.getContactDetails(this.edit_id);
        }
      });
    }

    this.authService.userData.subscribe((res) => {
      this.currentUser = res['id'];
    });

    this.franchiseData = this.franchiseService.franchiseData.getValue();
    if (this.franchiseData) {
      if (this.franchiseData['role']['original_name'] === AppConstants['roles']['accountant']) {
        this.isAccountant = true;
      } else if (this.franchiseData['role']['original_name'] === AppConstants['roles']['admin']) {
        this.isAdmin = true;
      } else if (this.franchiseData['role']['original_name'] === AppConstants['roles']['sales_rep']) {
        this.userContactForm.get('sales_rep_id').setValue(this.currentUser);
        this.isSalesRep = true
      } else if (this.franchiseData['role']['original_name'] === AppConstants['roles']['estimator_hod']) {
        this.userContactForm.get('csr_user_id').setValue(this.currentUser);
        this.isCSR = true;
      }
      this.getContactCompanies();

      this.userService.getFranchiseUsersByRole('Sales Rep').subscribe((res) => {
        this.salesRepList = res['data'].filter(item => !item.is_secondary_distributor);
        this.secondSalesRepList = res['data'];
      });

      if (!this.isCSR) {
        this.userService.getFranchiseUsersByRole('Estimator HOD').subscribe((res) => {
          this.csrList = res['data'];
        });
      }
    }
    this.getFranchiseById();
    this.hideFields();
    this.getShippingAddress();
  }


  uploadDoc(event: any): void {
    const uploadedFile = event.target.files[0] || event.dataTransfer.files[0];
    if (uploadedFile) {
      this.file = uploadedFile;
    }
  }

  importAddress(template: TemplateRef<any>) {
    if (this.edit_id != null) {
      this.loading = true;
      this.shippingAddressService.importAddress(this.file, this.edit_id).subscribe(
        res => {
          if (res['data']['error']) {
            this.api_error = {};
            this._toast.pop('warning', res['data'].message, '');
            this.loading = false;
          } else {
            this.loading = false;
            this.rerender();
            this.modalRef.hide();
            this.file = null;
            if (res['data'].length > 0) {
              this.duplicateAddressList = res['data'];
              this.openModal(template);
            }
          }
        }
      )
    }
  }

  toggleSelectAll(event: Event): void {
    this.selectAll = (event.target as HTMLInputElement).checked;
    this.duplicateAddressList.forEach((item) => {
      item.selected = this.selectAll;
    });
  }

  onAddressSelectionChange(): void {
    this.selectAll = this.duplicateAddressList.every((item) => item.selected);
  }

  updateAddress(): void {
    this.loading = true;
    const selectedAddresses = this.duplicateAddressList
      .filter((item) => item.selected)
      .map((item) => ({
        contact_id: item.contact_id,
        addr_sr: item.addr_sr,
        address_line1: item.address_line1,
        address_line2: item.address_line2,
        customer_name: item.customer_name,
        company_name: item.company_name,
        city: item.city,
        state: item.state,
        postal_code: item.postal_code,
        country: item.country,
      }));

    console.log('Selected Addresses:', selectedAddresses);
    const payload = {
      addresses: selectedAddresses,
    };
    this.shippingAddressService.updateShippingAddress(payload).subscribe(
      res => {
        if (res['message']['error']) {
          this.api_error = {};
        } else {
          this.modalRef.hide();
          this.rerender();
          this.loading = false;
        }
      }, error => {
        this.loading = false;
        if (error['error']) {
          this.api_error_address = error['error']['data'];
        }
      });
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }

  rerender = (): void => {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.ajax.reload(null, false);
    });
  }

  getShippingAddress = () => {
    let columns: any = [
      { name: 'addr_sr' },
      { name: 'customer_name' },
      { name: 'company_name' },
      { name: 'address_line1' },
      { name: 'address_line2' },
      { name: 'city' },
      { name: 'state' },
      { name: 'country' },
      { name: 'postal_code' }
    ];

    this.dtOptions = {
      dom: "<'row'<'col-sm-12 col-md-4'l><'col-sm-12 col-md-4 top'i><'col-sm-12 col-md-4 top'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
      pagingType: 'full_numbers',
      pageLength: 10,
      lengthMenu: [5, 10, 25, 50],
      serverSide: true,
      processing: true,
      language: {
        searchPlaceholder: 'Search...',
        search: ''
      },
      ajax: (dataTablesParameters: any, callback) => {
        if (this.edit_id) {
          this._dtService.getTableData(dataTablesParameters, 'shipping-address/' + this.edit_id, []).subscribe(res => {
            this.shippingAddressList = res.data.original.data;

            if (this.shippingAddressList.length > 0) {
              this._csService.introType.next('shipping-address');
            }
            else {
              this._csService.introType.next('');
            }
            callback({
              recordsTotal: res.data.original.recordsTotal,
              recordsFiltered: res.data.original.recordsFiltered,
              data: []
            });
          });
        } else {
          callback({
            recordsTotal: 0,
            recordsFiltered: 0,
            data: []
          });
        }
      },
      order: [],
      columns: columns
    };
  }

  editAddress(id: any, template: TemplateRef<any>): void {
    this.loading = true;
    this.api_error = {};
    if (id) {
      this.shippingAddressService.getShippingAddress(id).subscribe(
        res => {
          this.addShippingAddressForm.get('address_line1').setValue(res['data'][0]['address_line1']);
          this.addShippingAddressForm.get('address_line2').setValue(res['data'][0]['address_line2']);
          this.addShippingAddressForm.get('city').setValue(res['data'][0]['city']);
          this.addShippingAddressForm.get('state').setValue(res['data'][0]['state']);
          this.addShippingAddressForm.get('postal_code').setValue(res['data'][0]['postal_code']);
          this.addShippingAddressForm.get('country').setValue(res['data'][0]['country']);
          this.addShippingAddressForm.get('addr_sr').setValue(res['data'][0]['addr_sr']);
          this.addShippingAddressForm.get('customer_name').setValue(res['data'][0]['customer_name']);
          this.addShippingAddressForm.get('company_name').setValue(res['data'][0]['company_name']);
          this.openModal(template);
          this.edit_shipping_address_selected_id = id;
        }
      )
    }
    this.loading = false;
  }

  getFranchiseById() {
    const url = window.location.pathname;
    const urlArray = url.split('/');
    if (!isNaN(+urlArray[1])) {
      this.franchiseId = urlArray[1];
    }
  }

  hideFields() {
    this.hideFiledsByFranchiseId = (this.franchiseId == 6);
  }

  openModal(template: TemplateRef<any>) {
    if (this.modalRef) {
      this.modalRef.hide();
    }
    this.modalRef = this.modalService.show(template, {
      class: 'modal-lg'
    });
  }

  getContactDetails(contact_id) {
    this.loading = true;
    this.initContactForm();
    this.userService.getUserContactDetailById(contact_id).subscribe(res => {
      this.userContactData = res['data'];
      this.userContactData['sales_rep_commission_type_id'] = this.userContactData['sales_rep_commission_type_id'] || '';
      this.userContactData['second_sales_rep_commission_type_id'] = this.userContactData['second_sales_rep_commission_type_id'] || '';
      this.userContactData['csr_user_commission_type_id'] = (this.userContactData['csr_user_commission_type_id'] == 0) ? 0 : (this.userContactData['csr_user_commission_type_id'] || '');

      this.userContactData.company_credit_limit = (res['data']['contact_company']) ? res['data']['contact_company']['credit_limit'] : 0;
      if (this.userContactData.secondary_emails) {
        let secondary_emails = (this.userContactData.secondary_emails || []);
        const control = <FormArray>this.userContactForm.get('secondary_emails');
        while (control.length) {
          control.removeAt(0);
        }
        secondary_emails.forEach((item) => {
          control.push(this._fb.control(item));
        });
      }

      this.loading = false;
      this.setValues();
      this.setShippingInfo(this.userContactData['contact_shipping_details']['is_same']);
    });
  }

  getContactCompanies() {
    this.userService.getContactCompaniesBySalesRep(this.isSalesRep ? this.currentUser : null)
      .subscribe((res) => {
        const uniqueData = res['data'].filter((value, index, self) =>
          index === self.findIndex((t) => t.id === value.id)
        );
        this.companyList = uniqueData;
      });
  }

  onChangeCompany(company: any = []) {
    this.userContactForm.get('company_credit_limit').setValue(company.credit_limit || 0);
    this.userContactForm.get('accounting_email').setValue(company.accounting_email || '');
    this.userContactForm.get('address_line_1').setValue(company.billing_address_1 || '');
    this.userContactForm.get('address_line_2').setValue(company.billing_address_2 || '');
    this.userContactForm.get('city').setValue(company.billing_city || '');
    this.userContactForm.get('state_code').setValue(company.billing_state || '');
    this.userContactForm.get('zip_code').setValue((company.billing_zipcode || '').toString());
    this.userContactForm.get('country').setValue(company.billing_country || 'US');
    this.userContactForm.get('customer_source').setValue(company.customer_source || '');

    this.userContactForm.get('email').setValue(company.accounting_email || '');

    if (this.franchiseId != "6") {
      const shippingControls = this.userContactForm.get('contact_shipping_details');
      shippingControls.get('s_address1').setValue(company.shipping_address_1 || '');
      shippingControls.get('s_address2').setValue(company.shipping_address_2 || '');
      shippingControls.get('s_city').setValue(company.shipping_city || '');
      shippingControls.get('s_state').setValue(company.shipping_state || '');
      shippingControls.get('s_zip_code').setValue(company.shipping_zipcode || '');
      shippingControls.get('s_country').setValue(company.shipping_country || '');
    }
  }

  setValues = () => {
    Object.keys(this.userContactForm.value).forEach((element) => {
      if (element !== 'contact_shipping_details') {
        try {
          this.userContactForm.get(element).setValue(this.userContactData[element]);
        } catch (error) {
          console.warn(element + ' is not Available in userContactForm');
        }
      } else {
        const shippingControls = this.userContactForm.get('contact_shipping_details');
        const shippingArr = Object.entries(shippingControls['controls']);
        const shippingData = this.userContactData['contact_shipping_details'];
        for (const field of shippingArr) {
          shippingControls.get(field[0]).setValue(shippingData[field[0]]);
        }
      }
    });
  }

  initContactForm = () => {
    this.userContactForm = this._fb.group({
      contact_company_id: [null],
      company_credit_limit: [null],
      terms: [''],
      first_name: [''],
      last_name: [''],
      phone: [''],
      client_company_name: [''],
      account_no: [''],
      ship_to_id: [''],
      additional_notes: [''],
      cost_center: [''],
      contact_email: [''],
      customer_source: [''],
      company_tier: [''],
      opta_portal: [''],
      primary_contact: [''],
      email: [''],
      accounting_email: [''],
      address_line_1: [''],
      address_line_2: [''],
      city: [''],
      state_code: [''],
      zip_code: [''],
      country: ['US'],
      same_address: [false],
      contact_shipping_details: this._fb.group(this.initContactShipping()),
      notes: [''],
      secondary_emails: this._fb.array([['']]),
      sales_rep_id: [null],
      sales_rep_commission_type_id: [''],
      sales_rep_commission_percentage: [''],
      sales_rep_system_cost_percentage: [''],
      second_sales_rep_id: [null],
      second_sales_rep_commission_type_id: [''],
      second_sales_rep_commission_percentage: [''],
      second_sales_rep_system_cost_percentage: [''],
      csr_user_id: [null],
      csr_user_commission_type_id: [''],
      csr_user_commission_percentage: [''],
      csr_user_system_cost_percentage: [''],
      payment_through: ['Bank']
    });
  }

  initAddContactShipping = () => {
    this.addShippingAddressForm = this._fb.group({
      contact_id: [''],
      address_line1: [''],
      address_line2: [''],
      customer_name: [''],
      company_name: [''],
      city: [''],
      state: [''],
      postal_code: [''],
      addr_sr: [''],
      country: ['US'],
    });
  }

  initContactShipping = () => {
    return {
      is_same: [''],
      s_address1: [''],
      s_address2: [''],
      s_city: [''],
      s_state: [''],
      s_zip_code: [''],
      s_country: ['US'],
    };
  }


  back = () => {
    if (!this.isModal) {
      if (this.edit_id) {
        this._route.navigate(['../../'], { relativeTo: this.activatedRoute });
      } else {
        this._route.navigate(['../'], { relativeTo: this.activatedRoute });
      }
    } else {
      this.initContactForm();
      this.isSaved.emit(false);
    }
  }

  clearState = (event) => {
    if (event.target.id == 'country') {
      if (event.target.value != 'US') {
        this.userContactForm.get('state_code').setValue('');
      } else {
        this.userContactForm.get('state_code').setValue('');
      }
    } else if (event.target.id == 's_country') {
      if (event.target.value != 'US') {
        this.userContactForm.get('contact_shipping_details').get('s_state').setValue('');
      } else {
        this.userContactForm.get('contact_shipping_details').get('s_state').setValue('');
      }
    }
  }

  setShippingInfo = (isChecked) => {
    this.isShipping = isChecked;
    this.isReadOnly = false;
    const shippingControls = this.userContactForm.get('contact_shipping_details');
    shippingControls.get('s_state').enable();
    shippingControls.get('s_country').enable();
    if (isChecked) {
      shippingControls.get('is_same').setValue(true);
      this.userContactForm.get('same_address').setValue(true);
      shippingControls.get('s_address1').setValue(this.userContactForm.get('address_line_1').value);
      shippingControls.get('s_address2').setValue(this.userContactForm.get('address_line_2').value);
      shippingControls.get('s_city').setValue(this.userContactForm.get('city').value);
      shippingControls.get('s_state').setValue(this.userContactForm.get('state_code').value);
      shippingControls.get('s_zip_code').setValue(this.userContactForm.get('zip_code').value);
      shippingControls.get('s_country').setValue(this.userContactForm.get('country').value); //added to set country value while checkbox is selected
      shippingControls.get('s_state').disable();
      shippingControls.get('s_country').disable();
      this.isReadOnly = true;
    } else {
      if (this.edit_id) {
        const shippingArr = Object.entries(shippingControls['controls']);
        const shippingData = this.userContactData['contact_shipping_details'];
        for (const field of shippingArr) {
          shippingControls.get(field[0]).setValue(shippingData[field[0]]);
        }
      } else {
        shippingControls.get('s_address1').setValue('');
        shippingControls.get('s_address2').setValue('');
        shippingControls.get('s_city').setValue('');
        shippingControls.get('s_state').setValue('');
        shippingControls.get('s_zip_code').setValue('');
      }
      shippingControls.get('is_same').setValue(false);
      this.userContactForm.get('same_address').setValue(false);
    }
  }

  clearAddressForm = () => {
    this.addShippingAddressForm.reset({
      contact_id: '',
      address_line1: '',
      address_line2: '',
      customer_name: [''],
      company_name: [''],
      city: '',
      state: '',
      postal_code: '',
      addr_sr: '',
      country: '',
    });
    this.addShippingAddressForm.get('country').setValue('US');
    this.api_error_address = {};
  }

  saveAddress = () => {
    let userShippingAddress: any = {};
    userShippingAddress = { ...this.addShippingAddressForm.value };
    userShippingAddress['contact_id'] = this.edit_id;
    if (this.edit_shipping_address_selected_id != null) {
      userShippingAddress['id'] = this.edit_shipping_address_selected_id;
      Swal.fire({
        title: 'Disclaimer',
        text: 'Edits made here will not reflect in the ODP system. Please contact the ODP accounting team to ensure their records are updated as well to avoid any discrepancies.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        showLoaderOnConfirm: true,
        preConfirm: (value) => {
          if (value) {
            return true;
          }
        },
      }).then((res) => {
        if (res['value']) {
          this.loading = true;
          this.shippingAddressService.saveShippingAddress(userShippingAddress).subscribe(
            res => {
              if (res['message']['error']) {
                this.api_error = {};
              } else {
                this.loading = false;
                this.modalRef.hide();
                this.rerender();
                this.initAddContactShipping();
                this.edit_shipping_address_selected_id = null;
              }
            }, error => {
              this.loading = false;
              if (error['error']) {
                this.api_error_address = error['error']['data'];
              }
            });
        }
      });
    } else {
      this.loading = true;
      this.shippingAddressService.saveShippingAddress(userShippingAddress).subscribe(
        res => {
          if (res['message']['error']) {
            this.api_error = {};
          } else {
            this.loading = false;
            this.modalRef.hide();
            this.rerender();
            this.initAddContactShipping();
            this.edit_shipping_address_selected_id = null;
          }
        }, error => {
          this.loading = false;
          if (error['error']) {
            this.api_error_address = error['error']['data'];
          }
        });
    }
  }

  deleteAddress = (id: any) => {

    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you really want to delete this record? This action cannot be undone.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      showLoaderOnConfirm: true,
      preConfirm: (value) => {
        if (value) {
          return true;
        }
      },
    }).then((res) => {
      if (res['value']) {
        this.loading = false;
        this.shippingAddressService.deleteShippingAddress(id).subscribe(
          res => {
            if (res['data']['error']) {
              this.api_error = {};
              this._toast.pop('warning', res['data'].message, '');
              this.loading = false;
            } else {
              this.loading = false;
              this.rerender();
            }
          }
        )
      }
    });

    this.shippingAddressService.deleteShippingAddress(id).subscribe(
      res => {
        if (res['data']['error']) {
          this.api_error = {};
          this._toast.pop('warning', res['data'].message, '');
        } else {
          this.rerender();
        }
      }
    )
  }

  saveContactDetails = () => {
    if (this.userContactForm.valid) {
      if (this.franchiseId === '6' && !this.userContactForm.get('account_no').value) {
        this.api_error = {
          account_no: [
            "The Account No field is required"
          ]
        };
        return;
      }
      if (this.franchiseId === '6' && !this.userContactForm.get('account_no').value) {
        this.api_error = {
          client_company_name: [
            "The client company name field is required"
          ]
        };
        return;
      }
      if (this.franchiseId === '6' && !this.userContactForm.get('ship_to_id').value) {
        this.api_error = {
          ship_to_id: [
            "The Ship to Id field is required"
          ]
        };
        return;
      }
      if (this.franchiseId === '6') {
        this.userContactForm.patchValue({
          terms: '1%, Net 90 Day Terms'
        });
      }

      this.userContactForm.get('contact_shipping_details').get('s_state').enable();
      this.userContactForm.get('contact_shipping_details').get('s_country').enable();
      let userContactData: any = {};
      userContactData = { ...this.userContactForm.value };
      if (userContactData.second_sales_rep_id && userContactData.sales_rep_id == userContactData.second_sales_rep_id) {
        this.toasterService.pop('error', 'Distributor and Secondry distributor can not be same!');
        return false;
      }

      if (this.userContactForm.get('contact_shipping_details').get('is_same').value) {
        this.userContactForm.get('contact_shipping_details').get('s_state').disable();
        this.userContactForm.get('contact_shipping_details').get('s_country').disable();
      }
      if (this.edit_id) {
        userContactData['id'] = this.edit_id;
        userContactData['commission_update_for_sales_rep_projects'] = this.commissionUpdateForSalesRepProjects || [];
        userContactData['commission_update_for_csr_projects'] = this.commissionUpdateForCSRProjects || [];
      }

      let secondary_emails = this.userContactForm.get('secondary_emails').value;
      if (secondary_emails.length) {
        userContactData['secondary_emails'] = secondary_emails.join(",");
      } else {
        userContactData['secondary_emails'] = null;
      }

      this.loading = true;
      this.userService.saveUserContactDetail(userContactData).subscribe(response => {
        if (response['data']) {
          this.address_count = 0;
          this.basic_count = 0;
          this.loading = false;
          if (response['data']['error']) {
            this.api_error = {};
            this._toast.pop('warning', response['data'].message, '');
          } else {
            if (response['data']['ambiguos_address']) {
              if (response['data']['is_shipping_address']) {
                this.ambiguosType = 'Shipping Address';
              } else {
                this.ambiguosType = 'Billing Address';
              }

              this.ambiguous_addresses = response['data']['Candidates'];
              this.ambiguous_addresses.forEach((val, key) => {
                if (typeof val['address'] === 'string') {
                  this.ambiguous_addresses[key]['is_array'] = false;
                } else {
                  this.ambiguous_addresses[key]['is_array'] = true;
                }
              });
              this.addModalRef.show();
            } else {
              if (this.edit_id) {
                this._route.navigate(['../../'], { relativeTo: this.activatedRoute });
              } else {
                if (!this.isModal) {
                  this._route.navigate(['../'], { relativeTo: this.activatedRoute });
                } else {
                  this.initContactForm();
                  this.isSaved.emit(response.data);
                }
              }
            }
          }
        }
      }, error => {
        this.loading = false;
        if (error['error']) {
          this.address_count = 0;
          this.basic_count = 0;
          this.api_error = error['error']['data'];
          this.other_details_count = 0;
          Object.keys(this.api_error).forEach((element) => {

            if (element.startsWith('contact_shipping_details') || element === 'address_line_1' || element === 'city'
              || element === 'state_code' || element === 'zip_code' || element === 'country'
            ) {
              this.address_count = this.address_count + 1;
            } else if (element.startsWith('customer_source')) {
              this.other_details_count = this.other_details_count + 1;
            }
            else {
              this.basic_count = this.basic_count + 1;
            }
          });
        }
      });
    }
  }

  setSelectedAddress = () => {
    this.selected_ambiguous_address = this.ambiguous_addresses[this.selected_ambiguous_address_index];
    this.addModalRef.hide();
    if (this.ambiguosType === 'Shipping Address') {
      const shippingControls = this.userContactForm.get('contact_shipping_details');
      if (this.selected_ambiguous_address['is_array']) {
        shippingControls.get('s_address1').setValue(this.selected_ambiguous_address['address'].join(' '));
      } else {
        shippingControls.get('s_address1').setValue(this.selected_ambiguous_address['address']);
      }
      shippingControls.get('s_address2').setValue('');
      shippingControls.get('s_city').setValue(this.selected_ambiguous_address['city']);
      shippingControls.get('s_state').setValue(this.selected_ambiguous_address['state']);
      shippingControls.get('s_zip_code').setValue(this.selected_ambiguous_address['zipcode']);
      shippingControls.get('s_country').setValue(this.selected_ambiguous_address['country']);
    } else {
      if (this.selected_ambiguous_address['is_array']) {
        this.userContactForm.get('address_line_1').setValue(this.selected_ambiguous_address['address'].join(' '));
      } else {
        this.userContactForm.get('address_line_1').setValue(this.selected_ambiguous_address['address']);
      }
      this.userContactForm.get('address_line_2').setValue('');
      this.userContactForm.get('city').setValue(this.selected_ambiguous_address['city']);
      this.userContactForm.get('state_code').setValue(this.selected_ambiguous_address['state']);
      this.userContactForm.get('zip_code').setValue(this.selected_ambiguous_address['zipcode']);
      this.userContactForm.get('country').setValue(this.selected_ambiguous_address['country']);

      if (this.userContactForm.get('same_address').value) {
        this.setShippingInfo(true);
      }
    }
  }

  addSecondaryEmail() {
    const control = <FormArray>this.userContactForm.get('secondary_emails');
    control.push(this._fb.control(''));
  }

  removeSecondaryEmail(index) {
    const control = <FormArray>this.userContactForm.get('secondary_emails');
    control.removeAt(index);
  }

  openContactCompanyModal() {
    this.companySaveModal.show();
  }

  onCloseContactCompanySaveModal(savedContact: any = false) {
    this.companySaveModal.hide();
    if (savedContact) {
      this.getContactCompanies();
      this.userContactForm.get('contact_company_id').setValue(savedContact.id);
      this.onChangeCompany(savedContact);
    }
  }

  openContactsTransferModal(role) {
    this.deletedUser = {
      contact_id: this.edit_id,
      role: role,
      user_id: (role == 'Sales Rep') ? this.userContactData.sales_rep_id : this.userContactData.csr_user_id
    };
  }

  closeContactsTransferModal(isSaved) {
    if (isSaved) {
      this.getContactDetails(this.edit_id);
    }
  }

  updateAccountManagerCommission() {
    if (this.userContactForm.get('csr_user_commission_type_id').value == 0) {
      this.userContactForm.get('csr_user_commission_percentage').setValue('');
    }
  }

  openCommissionUpdateModal(role) {
    (document.querySelector('#toggle-all-projects') as any).checked = false;
    let user_id = (role == 'Sales Rep') ? this.userContactData.sales_rep_id : this.userContactData.csr_user_id;
    this.commissionUpdatedRole = role;
    this.loading = true;

    this.projectService.getProjectsByUser(user_id, this.edit_id).subscribe((res: any) => {
      if (res.data && res.data[0] && res.data[0].client_projects) {
        this.projectsForCommissionUpdate = (res.data[0].client_projects || [])
          .filter((item) => !item.order_of_project)
          .map(item => {
            if (role == 'Sales Rep') {
              item.commission_type = ((this.commissionData || []).find(commission => commission.id == item.sales_rep_commission_type_id) || { name: 'Gross' })['name'];
              item.commission_percentage = item.sales_rep_commission_percentage;
              item.system_cost_percentage = item.sales_rep_system_cost_percentage;
            } else {
              item.commission_type = ((this.commissionData || []).find(commission => commission.id == item.csr_user_commission_type_id) || { name: 'Gross' })['name'];
              item.commission_percentage = item.csr_user_commission_percentage;
              item.system_cost_percentage = item.csr_user_system_cost_percentage;
            }
            return item;
          });

        // Default select if user has selected it before
        setTimeout(() => {
          if (role == 'Sales Rep') {
            this.commissionUpdateForSalesRepProjects.forEach(project_id => {
              let element = <HTMLFormElement>document.querySelector(".project-for-commission[data-id='" + project_id + "']");
              element.checked = true;
            });
          } else {
            this.commissionUpdateForCSRProjects.forEach(project_id => {
              let element = <HTMLFormElement>document.querySelector(".project-for-commission[data-id='" + project_id + "']");
              element.checked = true;
            });
          }
        }, 0);
      }
      this.loading = false;
      this.commissionUpdateModal.show();
    });
  }

  closeCommissionUpdateModal(isSaved = false) {
    if (isSaved) {
      if (this.commissionUpdatedRole == 'Sales Rep') {
        this.commissionUpdateForSalesRepProjects = [];
        this.userContactForm.controls.sales_rep_commission_percentage.setValue(this.userContactForm.value.sales_rep_commission_percentage);
        let elements = document.querySelectorAll(".project-for-commission:checked");
        elements.forEach((element: any) => {
          this.commissionUpdateForSalesRepProjects.push(element.getAttribute('data-id'));
        });
      } else {
        this.commissionUpdateForCSRProjects = [];
        this.userContactForm.controls.csr_user_commission_percentage.setValue(this.userContactForm.value.csr_user_commission_percentage);
        let elements = document.querySelectorAll(".project-for-commission:checked");
        elements.forEach((element: any) => {
          this.commissionUpdateForCSRProjects.push(element.getAttribute('data-id'));
        });
      }
    }
    this.commissionUpdateModal.hide();
  }

  toggleAllProjectSelection = (event) => {
    let checked = event.target.checked;
    let elements = document.querySelectorAll(".project-for-commission");
    if (checked) {
      elements.forEach((element: any) => { element.checked = true; });
    } else {
      elements.forEach((element: any) => { element.checked = false; });
    }
  }
}