import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { CommonService } from './common.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class CompanyService {
  constructor(private _http: HttpClient, private _cs: CommonService) { }

  deleteCompany = (id) => {
    return this._http.delete(environment.API_URL + 'contact-company/delete/' + id, this._cs.setHeader());
  }
}
