import { Injectable } from '@angular/core';

@Injectable()
export class Utils {

  showContainers = ({ ...status }, container, state = true) => {
    switch (container) {
      case 'success':
        status.success = state;
        status.loading = !state;
        status.error = !state;
        return status;
        break;
      case 'error':
        status.success = !state;
        status.loading = !state;
        status.error = state;
        return status;
        break;
      case 'loading':
        status.success = !state;
        status.loading = state;
        status.error = !state;
        return status;
        break;
    }
  }

}
