import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../service/authentication.service';
import { FranchiseService } from '../service/franchise.service';
import { RolepermissionService } from '../service/rolepermission.service';

/*
 * @usage Auth Guard check if authenticated or not. If authenticated then
 *        continue otherwise redirect to login.
 */

@Injectable()
export class EmailRedirectGuard implements CanActivate {
  constructor(private _router: Router,
    private authService: AuthenticationService,
    private franchiseService: FranchiseService,
    private userRolePermission: RolepermissionService, ) {

  }

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
    if (localStorage.getItem('token')) {
      const queryParams = _route.queryParams;
      if (queryParams['event_type'] && queryParams['franchise_id'] && queryParams['action_id']) {
        let userData = this.authService.userData.getValue();
        if (Object.keys(userData).length === 0) {
          return this.authService.refreshUserdata().then(res => {
            userData = this.authService.userData.getValue();
            this.franchiseService.franchiseOptions.next(userData['franchises']);
            if (userData['is_supplier']) {
              return true;
            } else {
              return this.franchiseService.refreshFranchiseData(queryParams['franchise_id']).then(franchises => {
                if (this.userRolePermission.permissions.getValue().length > 0) {
                  return true;
                } else {
                  return this.userRolePermission.refreshPermissions(queryParams['franchise_id']).then(perm => {
                    return true;
                  });
                }
              });
            }
          });
        }
      }
    } else {
      this._router.navigate(['/login']);
      return false;
    }
  }
}
