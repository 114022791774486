import {NgModule} from '@angular/core';
import {SharedModule} from '../../shared.module';
import {LoginComponent} from './login/login.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';

@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [
        LoginComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent
    ]
})
export class AuthenticationModule {

}
