import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { ProjectService } from '../../../../../service/project.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../../../../../service/common.service';
import { DatePipe } from '@angular/common';
import { ModalDirective, TypeaheadMatch } from 'ngx-bootstrap';
import { ToasterService } from 'angular2-toaster';
import { Utils } from '../../../../../utils';
import { SupplierService } from '../../../../../service/supplier.service';
import { states } from '../../../../../../assets/json-options/usa-states';
import { countryCodes } from '../../../../../../assets/json-options/country-codes';
import { TaxRatesService } from '../../../../../service/tax-rates.service';
import { FranchiseService } from '../../../../../service/franchise.service';
import { AppConstants } from '../../../../../constants/app.constants';
import { AuthenticationService } from '../../../../../service/authentication.service';
import { UserService } from '../../../../../service/user.service';
import { environment } from '../../../../../../environments/environment';

@Component({
  templateUrl: './create-order.component.html',
  styleUrls: ['./create-order.component.scss']
})

export class CreateOrderComponent implements OnInit {
  @ViewChild('sidenav') sidenav;
  @ViewChild('modal') public modal: ModalDirective;
  @ViewChild('categorymodal') categorymodal: ModalDirective;
  @ViewChild('filter') filterLabel;
  @ViewChild('ambiguosAddressModel') addModalRef: ModalDirective;
  isInvoiceEdit = false; //added for invoice detail not editable
  orderForm: FormGroup;
  productDetailForm: FormGroup;
  estimationId = '';
  orderId = '';
  orderData: any = [];
  itemsData: any = [];
  billingData: any = [];
  paymentTypes = [];
  products: any = [];
  isReadOnly = false;
  istaxDisable = false;
  isShipplingDisable = false;
  projectId = '';
  projectName = '';

  api_error: any = [];
  is_same = false;
  filesArr: any = [];
  estimationData: any = [];
  docArr: any = [];
  oldDocArr: any = [];
  search = '';
  viewMore = false;
  viewMoreBillingDetails: boolean = false;
  typeaheadLoading: boolean;
  numbers: Array<any> = [];
  franchiseId = '';

  supplierList: any = [];
  shippingDetails: any = [];
  billingDetails: any = []; // billing
  selected_ambiguous_address_index: any;
  selected_ambiguous_address: any;
  ambiguous_addresses: any = [];
  itemChecked: any = [];
  invoiceData: any = { subTotal: 0, tax: 0, shipping: 0, total: 0 };
  loading = false;
  stateList = states;
  countryList = countryCodes;
  editProduct = {};
  taxTypes = [];
  taxRate = 0;
  selectedTax = '';
  estimatorID: any;
  currentRoleEstimatorId: any = '';
  totalData: any = {
    subtotal: '0',
    discount_amount: '0',
    taxable_subtotal: '0',
    tax_amount: '0',
    shipping_charge: '0',
    total_amount: '0'
  };
  project_type: any = [];
  productorderData: any = [];
  customerInformation = {};
  showBillPaymentDateField: boolean = false;
  estimatorList: any = [];
  currentUser: any = ''
  progress: any = '';
  showProgress: boolean = false;
  isEmail:any = 0;
  previousUrl: any = null;

  artworkFileSizeLimit:number; // enviroment file
  showArtworkSizeWarning:boolean = false;
  showArtworkLinkField:boolean = false;
  showSuggestedArtworkSizeWarnig:boolean = false;
  showSuggestedArtworkLinkField:boolean = false;

  editOrder:boolean = false;

  gross_profit:any = 0;
  sales_rep_commission_percent:any = 0;
  sales_rep_commission_amt:any = 0;
  second_sales_rep_commission_percent:any = 0;
  second_sales_rep_commission_amt:any = 0;
  csr_commission_percent:any = 0;
  csr_commission_amt:any = 0;
  isAdmin = false;
  isAccountant = false;
  isSalesRep = false;
  isHod: boolean = false;
  isEstimator: boolean = false;

  constructor(private _fb: FormBuilder,
    private router: Router,
    private _toast: ToasterService,
    private projectService: ProjectService,
    private datePipe: DatePipe,
    private supplierService: SupplierService,
    public commonService: CommonService,
    private toasterService: ToasterService,
    private utils: Utils,
    private activatedRoute: ActivatedRoute,
    public _cs: CommonService,
    private _taxService: TaxRatesService,
    private _franchiseService: FranchiseService,
    private authService: AuthenticationService,
    private userService: UserService) {
    this.numbers = Array.from({ length: 31 }, (v, k) => k + 1);
  }

  ngOnInit() {
    this.commonService.previousUrl$
    .subscribe((previousUrl: string) => {
        this.previousUrl = previousUrl
    });

    this.artworkFileSizeLimit = environment.ARTWORK_SIZE;
    this.initMainForm();
    this.initProductDetailForm();
    this.getSupplierList();

    this.activatedRoute.params.subscribe((res) => {
      if (res['est_id']) {
        this.estimationId = res['est_id'];
        this.getEstimationById();
      } else {
        this.orderId = res['order_id'];
        this.getOrderData();
      }
    });

    const configStatus = this._cs.configStatus.getValue();
    if (Object.keys(configStatus).length === 0) {
      this.getConfigStatus();
    } else {
      const types = this._cs.getByType('payment_types');
      Object.keys(types).forEach(key => {
        this.paymentTypes.push({ 'value': key, 'name': types[key]['name'] });
      });
    }
    this.authService.userData.subscribe((res) => {
      this.currentUser = res['id'];
    });

    this.franchiseId = this.commonService.currentFranchise();
    const franchiseRes = this._franchiseService.franchiseData.getValue();
    if (franchiseRes) {     
      if (franchiseRes['role']['original_name'] === AppConstants['roles']['estimator_hod']) {
        this.isHod = true;
      } else if (franchiseRes['role']['original_name'] === AppConstants['roles']['admin']) {
        this.isAdmin = true;
      } else if (franchiseRes['role']['original_name'] === AppConstants['roles']['accountant']) {
        this.isAccountant = true;
      } else if (franchiseRes['role']['original_name'] === AppConstants['roles']['sales_rep']) {
        this.isSalesRep = true;
      } else if (franchiseRes['role']['original_name'] === AppConstants['roles']['estimator']) {
        this.isEstimator = true;
        this.orderForm.get('estimator_id').setValue(this.currentUser);
        this.currentRoleEstimatorId = this.currentUser;
      }

      if (!this.isEstimator) {
        this.userService.getFranchiseUsersByRole('Estimator').subscribe((res) => {
          this.estimatorList = res['data'];
        });
      }
    }
    this.viewMoreBillingDetails = true;
    this.viewMore = true;
    this.setFields();
  }

  saveAndSend = (flag) => {
    this.isEmail = flag
  }

  onSelectEstimator = (id) => {
    if (id) {
      this.estimatorID = id
      this.orderForm.get('estimator_id').setValue(id);
    } else{
      this.estimatorID = ''
      this.orderForm.get('estimator_id').setValue(null);
    }
  }

  clearState = (event) => {
    if (event.target.id == 's_country') {
      if (event.target.value != 'US') {
        this.orderForm.get('contact_shipping_details').get('s_state_code').setValue('');
      } else {
        this.orderForm.get('contact_shipping_details').get('s_state_code').setValue('');
      }
    }

    if (event.target.id == 'country') {
      if (event.target.value != 'US') {
        this.orderForm.get('contact_billing_details').get('state_code').setValue('');
      } else {
        this.orderForm.get('contact_billing_details').get('state_code').setValue('');
      }
    }
  }

  getSupplierList = () => {
    if (this.supplierList.length === 0) {
      this.supplierService.getSupplierList().subscribe(res => {
        if (res['data']) {
          this.supplierList = res['data'];
        }
      });
    }
  }

  getConfigStatus = async () => {
    const data = await this._cs.getConfigStatusData().toPromise();
    this._cs.configStatus.next(data);
    const types = this._cs.getByType('payment_types');
    Object.keys(types).forEach(key => {
      this.paymentTypes.push({ 'value': key, 'name': types[key]['name'] });
    });
  }

  getTaxRateById = (taxId) => {
    let taxRate = 0;
    this.taxTypes.map(item => {
      if (item['id'] === +taxId) {
        taxRate = item['rate'];
      }
    });
    return +taxRate;
  }

  getAllTaxTypes = () => {
    this._taxService.getTaxOptions().subscribe((res) => {
      this.taxTypes = res['data'];
      if (this.orderId) {
        // set from order data
        this.orderForm.get('tax_type_id').setValue(this.orderData['tax_type_id']);
      } else {
        // set from estimation details
        this.orderForm.get('tax_type_id').setValue(this.estimationData['estimation_info'][0]['tax_type_id']);
      }

      this.taxTypes.map(item => {
        if (item['id'] === +this.orderForm.get('tax_type_id').value) {
          this.taxRate = item['rate'];
          this.selectedTax = item['tax_name'] + ' - ' + item['rate'] + '%';
        }
      });
    });
  }

  initMainForm = () => {
    this.orderForm = this._fb.group({
      id: [''],
      project_id: [''],
      estimator_id: [null],
      estimation_id: [''],
      contact_id: [''],
      payment_type: [''],
      payment_option: [0],  // Default 3 for both options(Bank Transfer & Card)
      delivery_date: [new Date()],
      payment_term_date: [new Date()],  // new change
      bill_payment_term: [0],           // new change
      instructions: [''],
      artwork: [''],
      artwork_link: [''],
      tax_type_id: [''],
      discount_type: ['1'],
      discount_value: [''],
      contact_shipping_details: this._fb.group(this.initShippingDetails()),
      contact_billing_details: this._fb.group(this.initBillingDetails()),   // billing
      customer_po_number: [''],
      edit_order_reason: ['']
    });
  }

  initShippingDetails = () => {
    return {
      is_same: [''],
      s_full_name: [''],
      s_email: [''],
      s_address1: [''],
      s_address2: [''],
      s_city: [''],
      s_state_code: [''],
      s_zip_code: [''],
      s_country: [''],
      s_phone:['']
    };
  }

  initBillingDetails = () => {  // billing
    return {
      full_name: [''],
      email: [''],
      address_line_1: [''],
      address_line_2: [''],
      city: [''],
      state_code: [''],
      zip_code: [''],
      country: [''],
    };
  }

  getOrderData = () => {
    this.loading = true;
    this.projectService.getOrderData(this.orderId).subscribe(res => {
      this.orderData = res['data'];
      
      if(!this.orderData['order_invoice'] && this.orderData['status'] !== 7) {
        this.editOrder = true;
      }
      if(this.orderData['status'] == 10 && this.isHod){
        this.editOrder = false;
      }

      this.products = res['data']['order_items'];
      this.loading = false;
      this.estimatorID= res['data']['estimator_id'] || null;
      this.billingData = this.orderData['contact_of_order'];
      this.itemsData = this.orderData['order_items'];
      this.projectName = this.orderData['project_of_order'] ? this.orderData['project_of_order']['project_name'] : '';
      this.projectId = this.orderData['project_of_order'] ? this.orderData['project_of_order']['project_uid'] : '';
      this.shippingDetails = this.orderData['contact_shipping_details'];
      this.billingDetails = this.orderData['contact_billing_details'];  // billing
      this.project_type = this.orderData['project_of_order'] ? this.orderData['project_of_order']['project_type'] : ''
      requestAnimationFrame(() => {
        this.filterLabel.nativeElement.style.backgroundColor = '#007AFF';
      });

      if (this.taxTypes.length === 0) {
        this.getAllTaxTypes();
      }

      // for digital product billing and shipping address same
      if(this.project_type == 4) {
        this.setShippingInfo(true);
      }else {
        this.setShippingInfo(this.orderData['contact_shipping_details']['is_same']);
      }
      this.setBillingDetails(); // billing
      if (res['data']['contact_id']) {
        let clientId = res['data']['contact_id'];
        this.projectService.getCustomerInvoiceData(clientId).subscribe((clientInvoices) => {
          if (clientInvoices) {
            this.customerInformation = clientInvoices;
            this.setOtherDetail();
          }
        });
      }

      this.orderForm.get('discount_type').setValue(this.orderData['discount_type']);
      this.orderForm.get('discount_value').setValue(this.orderData['discount_value']);

      Object.keys(this.totalData).map((key) => {
        if (this.orderData[key]) {
          this.totalData[key] = this.orderData[key];
        }
      });

      this.sales_rep_commission_percent = res['data']['project_of_order']['sales_rep_commission_percentage'] || 0;
      this.second_sales_rep_commission_percent = res['data']['project_of_order']['second_sales_rep_commission_percentage'] || 0;
      this.csr_commission_percent = res['data']['project_of_order']['csr_user_commission_percentage'] || 0;

      this.calculateCommission();
    },(error) => {
      if(error['error']['data'] == 'invalid_request') {
        this._toast.pop('error', error['error']['message']);
        this.router.navigate(['../../../dashboard'], { relativeTo: this.activatedRoute });
      }
    });
  }

  setOtherDetail = () => {
    Object.keys(this.orderForm.value).forEach((element) => {
      if (element !== 'contact_shipping_details' && element !== 'artwork') {
        if (element === 'delivery_date') {
          const dateArr = this.orderData[element].split('-');
          this.orderForm.get(element).setValue(new Date(dateArr[2], dateArr[1] - 1, dateArr[0]));
        } else if (element === 'payment_term_date') {
          if (!this.orderData[element]) {
            this.orderForm.get(element).setValue(new Date());
          } else {
            // const dateArr = this.orderData[element].split('-');
            // this.orderForm.get(element).setValue(new Date(dateArr[2], dateArr[1] - 1, dateArr[0]));
            this.orderForm.get(element).setValue(this.orderData[element]);
          }
        } else if (element === 'bill_payment_term') {
          var $radios = $('input:radio[name=billing_information]');
          if(this.orderId && this.isHod && !this.editOrder) {  // Specific condition while CSR Approving Order
            if(Number(this.billingData['terms']) == 0) {
              $radios.filter('[value=' + 2 + ']').prop('checked', true);  // 100% in advance
              this.orderForm.get(element).setValue(2);
            }else if((Number(this.orderData['total_amount']) + Number(this.customerInformation['total_due'])) > Number(this.billingData['credit_limit'])) {
              $radios.filter('[value=' + 1 + ']').prop('checked', true); // 50% in advance
              this.orderForm.get(element).setValue(1);
            }else if(this.billingData['terms'] && (this.billingData['terms'] > 0) && this.billingData['credit_limit'] && (this.billingData['credit_limit'] > 0)) {
              let asPerTermsinterval = setInterval(() => {
                if($('#customRadio3').length != 0) {
                  $radios = $('input:radio[name=billing_information]');
                  $radios.filter('[value=' + 3 + ']').prop('checked', true); // as per terms
                  clearInterval(asPerTermsinterval);
                }
              }, 1);
              this.orderForm.get(element).setValue(3);
            }
            this.setPaymentOption(2);   // default Bank in this Condition
          }else {                       // For all roles order edit & except CSR approving order
            if ($radios.is(':checked') === false) {
              if(this.billingData['terms'] && this.billingData['terms'] != 0) {
                let asPerTermsinterval = setInterval(() => {
                                            if($('#customRadio3').length != 0) {
                                              $radios = $('input:radio[name=billing_information]');
                                              $radios.filter('[value=' + this.orderData[element] + ']').prop('checked', true);
                                              clearInterval(asPerTermsinterval);
                                            }
                                          }, 1);
              } else {
                $radios.filter('[value=' + this.orderData[element] + ']').prop('checked', true);
              }
              this.orderForm.get(element).setValue(this.orderData[element]);
            }
            if (this.orderData[element] == 3) {
              // this.showBillPaymentDateField = true;
            }
          }
        } else if(element === 'payment_option') {
          this.setPaymentOption(this.orderData[element]);
        } else {
          this.orderForm.get(element).setValue(this.orderData[element]);
        }
      }
    });
    this.docArr = this.orderData['artwork'];
    this.oldDocArr = this.docArr ? [...this.docArr] : [];
    this.showArtworkLinkField = this.orderData['artwork_link'] ? true : false; 
    if (this.docArr.length > 0) {
      this.docArr.forEach((value, keyDoc) => {
        this.filesArr[keyDoc] = [];
        const pathValue = this.commonService.checkImageTypeAws(value, 'sales_order_images');
        this.filesArr[keyDoc]['download_url'] = this.commonService.UPLOAD_FILE_AWS('sales_order_images') + value;
        if (!pathValue) {
          this.filesArr[keyDoc]['url'] = this.commonService.UPLOAD_FILE_AWS('sales_order_images') + value;
        } else {
          this.filesArr[keyDoc]['url'] = pathValue;
        }
      });
    }

    const tempItemData = JSON.parse(JSON.stringify(this.itemsData));
    this.invoiceData = { subTotal: 0, tax: 0, shipping: 0, total: 0 };
    for (const data of tempItemData) {
      const itemData = [];
      itemData['price'] = Math.round(data['price']);
      itemData['tax'] = Math.round((data['tax'] * itemData['price']) / 100);
      itemData['shipping_cost'] = Math.round(data['shipping_cost']);
      itemData['shipping_markup_amount'] = Math.round(data['shipping_markup_amount']);
      itemData['total'] = Math.round((itemData['price'] + itemData['tax']
        + itemData['shipping_cost'] + itemData['shipping_markup_amount']));
      this.invoiceData['subTotal'] += itemData['price'];
      this.invoiceData['tax'] += itemData['tax'];
      this.invoiceData['shipping'] += (itemData['shipping_cost'] + itemData['shipping_markup_amount']);
      this.invoiceData['total'] += itemData['total'];
    }
  }

  viewMoreDetail = (data) => {
    if (data == 'shippingInfo') {
      this.viewMore = !this.viewMore;
    }
    if (data == 'billingInfo') {
      this.viewMoreBillingDetails = !this.viewMoreBillingDetails;
    }
  }

  getEstimationById = () => {
    this.loading = true;
    this.projectService.getEstimationOfOrder(this.estimationId).subscribe((res) => {
      if (res['data']) {
        if (res['data']['contact_id']) {
          let clientId = res['data']['contact_id'];
          this.projectService.getCustomerInvoiceData(clientId).subscribe((clientInvoices) => {
            if (clientInvoices) {
              this.customerInformation = clientInvoices;
            }
          });
        }
        this.estimationData = res['data'];
        this.estimatorID = res['data']['estimator_id'] || null; // estimator test
        this.orderForm.get('estimator_id').setValue(this.estimatorID || null)
        this.billingData = res['data']['contact_of_estimation'];
        this.itemsData = res['data']['order_items'];
        this.projectName = res['data']['project_of_estimation'] ? res['data']['project_of_estimation']['project_name'] : '';
        this.projectId = res['data']['project_of_estimation'] ? res['data']['project_of_estimation']['project_uid'] : '';
        this.project_type = res['data']['project_of_estimation'] ? res['data']['project_of_estimation']['project_type'] : '';
        const dateArr = this.estimationData['estimation_info'][0]['order_due_date'].split('-');
        this.orderForm.get('delivery_date').setValue(new Date(dateArr[2], dateArr[1] - 1, dateArr[0]));

        if (this.estimationData['project_of_estimation']) {
          if(this.estimationData['project_of_estimation']['status'] > 3) { // && (this.isHod || this.isSalesRep)
            // CSR & Estimator Can Convert to sales order after approved
          }else if (this.estimationData['project_of_estimation']['status'] > 3 && this.estimationData['project_of_estimation']['status'] != 14 && this.estimationData['project_of_estimation']['status'] != 17 && this.estimationData['project_of_estimation']['status'] != 16) {
            this.router.navigate(['../../' + this.estimationData['project_of_estimation']['id']], { relativeTo: this.activatedRoute });
            this.toasterService.pop('error', 'Not Allowed, Customer Has Requested For Change', '');
          }
        }
        this.loading = false;
        if (!this.estimationData['contact_shipping_details']['is_same']) {
          this.shippingDetails = this.estimationData['contact_shipping_details'];
          this.shippingDetails['s_full_name'] = this.estimationData['contact_shipping_details']['s_name'];
          this.shippingDetails['s_email'] = this.estimationData['contact_shipping_details']['s_email'];
        } else {
          this.shippingDetails = this.estimationData['contact_of_estimation']['contact_shipping_details'];
          this.shippingDetails['s_full_name'] = this.estimationData['contact_of_estimation']['first_name'] + ' ' + this.estimationData['contact_of_estimation']['last_name'];
          this.shippingDetails['s_email'] = this.estimationData['contact_of_estimation']['email'];
        }
        // for digital product billing and shipping address same
        if(this.project_type == 4) {
          this.setShippingInfo(true);
        }else {
          this.setShippingInfo(this.estimationData['contact_shipping_details']['is_same']);
        }
        this.setBillingDetails(); // billing
        this.docArr = res['data']['estimation_info'][0]['supplier_files'] ? res['data']['estimation_info'][0]['supplier_files'] : [];
        this.oldDocArr = this.docArr ? [...this.docArr] : [];
        if (this.docArr.length > 0) {
          this.docArr.forEach((value, keyDoc) => {
            this.filesArr[keyDoc] = [];
            const pathValue = this.commonService.checkImageTypeAws(value, 'estimation_images');
            this.filesArr[keyDoc]['download_url'] = this.commonService.UPLOAD_FILE_AWS('estimation_images') + value;
            if (!pathValue) {
              this.filesArr[keyDoc]['url'] = this.commonService.UPLOAD_FILE_AWS('estimation_images') + value;
            } else {
              this.filesArr[keyDoc]['url'] = pathValue;
            }
          });
        }
        this.orderForm.get('discount_type').setValue(this.estimationData['estimation_info'][0]['discount_type']);
        this.orderForm.get('discount_value').setValue(this.estimationData['estimation_info'][0]['discount_value']);

        this.sales_rep_commission_percent = res['data']['project_of_estimation']['sales_rep_commission_percentage'] || 0;
        this.second_sales_rep_commission_percent = res['data']['project_of_estimation']['second_sales_rep_commission_percentage'] || 0;
        this.csr_commission_percent = res['data']['project_of_estimation']['csr_user_commission_percentage'] || 0;
      }
      this.getAllTaxTypes();

      if(!this.orderId && this.isSalesRep) {
        var $radios = $('input:radio[name=billing_information]');
        if(Number(this.billingData['terms']) > 0) {
          let asPerTermsinterval = setInterval(() => {
            if($('#customRadio3').length != 0) {
              $radios = $('input:radio[name=billing_information]');
              $radios.filter('[value=' + 3 + ']').prop('checked', true); // as per terms
              clearInterval(asPerTermsinterval);
            }
          }, 1);
          this.orderForm.get('bill_payment_term').setValue(3);
        }else {
          $radios.filter('[value=' + 2 + ']').prop('checked', true);  // 100% in advance
          this.orderForm.get('bill_payment_term').setValue(2);
        }
      }
    },(error) => {
      if(error['error']['data'] == 'invalid_request') {
        this._toast.pop('error', error['error']['message']);
        this.router.navigate(['../../../../dashboard'], { relativeTo: this.activatedRoute });
      }
    });
  }

  openNav = (event) => {
    this.sidenav.nativeElement.style.transform = 'translate(0, -50%)';
  }

  closeNav = (event) => {
    event.preventDefault();
    this.sidenav.nativeElement.style.transform = 'translate(506px, -50%)';
  }

  showBillPaymentDateOption = (radio_value) => {
    this.orderForm.get('bill_payment_term').setValue(radio_value);
  }

  setPaymentOption = (value = null) => {
    if(!value) {
      let bank = $('#payment_option_bank').prop('checked');
      let card = $('#payment_option_card').prop('checked');

      if(!bank && card) {                               
        this.orderForm.get('payment_option').setValue(1);   // only card selected
      } else if(bank && !card) {                                   
        this.orderForm.get('payment_option').setValue(2);   // only bank selected
      } else if(bank && card) {                              
        this.orderForm.get('payment_option').setValue(3);   // both selected
      } else {                                               
        this.orderForm.get('payment_option').setValue(0);   // nothing selected
      }
    } else {
      switch(value) {
        case 1: 
          $('#payment_option_card').prop('checked', true);
          break;
        case 2: 
          $('#payment_option_bank').prop('checked', true);
          break;
        case 3: 
          $('#payment_option_card').prop('checked', true);
          $('#payment_option_bank').prop('checked', true);
          break;
        default: 
      }
      this.orderForm.get('payment_option').setValue(value);  
    }
  }

  initProductDetailForm = () => {
    this.productDetailForm = this._fb.group({
      id: [''],
      item_code: [''],
      item_spec: [''],
      item_description: [''],
      turnaround_time: [''],
      shipping_included: [''],
      quantity: [''],
      cost_per_piece: [''],
      total_cost: [''],
      markup: [''],
      markup_amount: [''],
      shipping_cost: [''],
      shipping_markup: [''],
      shipping_markup_amount: [''],
      supplier: [''],
      supplier_name: [''],
      supplier_id: [''],
      price: [''],
      price_per_piece: [''],
      tax_applicable: [''],
      tax: [''],
      qb_tax_id: [''],
      comment: [''],
      discount_allowed: [''],
      shipping_included_markup: [''],
      shipping_amount: [''],
      discount_type: [''],
      discount_value: [''],
      discount_amount: [''],
      tax_type_id: [''],
      tax_value: [''],
      total_price: [''],
      project_estimation_id: [''],
      project_estimation_items_id: [''],
      total_data: this._fb.group({
        discount_amount: [''],
        tax_value: [''],
        total_shipping_charge: [''],
        total_price: ['']
      }),
    })
  }

  editProductDetail = (itemId) => {
    const productItem = [];
    this.editProduct = {};
    let length = Object.keys(this.productorderData).length;
    this.productorderData = (length) ? this.productorderData : this.itemsData;
    const orderData = this.productorderData; //itemsData
    for (const product of orderData) {
      if (product['id'] === +itemId) {
        this.editProduct = product;
        productItem.push(product);
      }
    }
    Object.keys(this.productDetailForm.value).forEach((element) => {
      if (element === 'supplier') {
        if (productItem[0]['supplier_name']) {
          this.productDetailForm.get(element).setValue(productItem[0]['supplier_name']);
        } else {
          this.productDetailForm.get(element).setValue(productItem[0]['estimation_supplier']['display_name']);
        }
      }
      else if (element === 'total_data') {
      } else if (element === 'shipping_included_markup') {
        this.productDetailForm.get(element).setValue(productItem[0]['shipping_included_markup']);
      } else if (element === 'cost_per_piece') {
        let cost_per_piece = 0;
        cost_per_piece = productItem[0]['total_cost'] / productItem[0]['quantity'];
        this.productDetailForm.get(element).setValue(Number(+cost_per_piece).toFixed(5));
      } else if (element == 'project_estimation_id') {
        this.productDetailForm.get(element).setValue(productItem[0]['estimation_id']);
      } else if (element == 'project_estimation_items_id') {
        this.productDetailForm.get(element).setValue(productItem[0]['id']);
      } else if (element == 'shipping_included') {
        this.productDetailForm.get(element).setValue(productItem[0]['shipping_included']);
      } else if (element == 'shipping_amount') {
        this.productDetailForm.get(element).setValue(+productItem[0]['shipping_markup_amount'] + +productItem[0]['shipping_cost']);
      } else if (element == 'tax_applicable') {
        this.productDetailForm.get(element).setValue(productItem[0]['tax_applicable']);
      } else if (element == 'tax_type_id') {
        this.productDetailForm.get(element).setValue(productItem[0]['tax_type_id']);
      } else {
        this.productDetailForm.get(element).setValue(productItem[0][element]);
      }
    });
    this.modal.show();
    this.setFields();
  }

  onCloseModel = () => {
    this.productDetailForm.reset();
    this.api_error = {};
  }

  updateProductDetail = () => {
    if (this.productDetailForm.valid) {
      this.deriveTotalAmounts();
      this.productDetailForm.get('shipping_included').enable();
      const updateProductData = { ...this.productDetailForm.value };
      if (this.isSalesRep) {
        Object.keys(updateProductData).map(key => {
          if (['comment', 'price_per_piece', 'quantity', 'supplier', 'supplier_id', 'supplier_name'].indexOf(key) < 0) {
            updateProductData[key] = this.editProduct[key];
          }
        });
      }

      updateProductData['order_id'] = this.orderId;
      updateProductData['project_order_id'] = this.orderId;
      updateProductData['total_data'] = this.totalData;
      this.projectService.updateOrderItemsValidate(updateProductData).subscribe(res => {
        if (res['data']) {
          this.loading = false;
          let ChangedData = this.productorderData;
          Object.keys(this.productorderData).map((index) => {
            if (this.productorderData[index]['id'] === +this.productDetailForm.value['id']) {
              Object.keys(this.productDetailForm.value).map((keynew, indexnew) => {
                ChangedData[index][keynew] = this.productDetailForm.value[keynew];
              })
            }
          })
          if (Object.keys(ChangedData).length) {
            this.productorderData = ChangedData;
            this.itemsData = this.productorderData;
          }
          let changedProduct = this.products;
          if (Object.keys(this.products).length) {
            this.productorderData.map((data, index) => {
              this.products.map((product, indexproduct) => {
                if (data.id == product.id) {
                  changedProduct[indexproduct] = this.productorderData[index];
                }
              })
            })
          }
          if (Object.keys(changedProduct).length) {
            this.products = changedProduct;
          }
          this.modal.hide();
        }
      }, error => {
        if (error['error']) {
          if (this.isSalesRep) {
            this.productDetailForm.get('shipping_included').disable();
          }
          this.api_error = error['error']['data'];
        }
      });
    }
  }

  disabledFileds = (control: FormGroup) => {
    if (control.get('shipping_included').value) {
      control.get('shipping_cost').setValue('0.00');
      control.get('shipping_markup').setValue('0.00');
      control.get('shipping_markup_amount').setValue('0.00');
    } else {
      control.get('shipping_cost').setValue('');
      control.get('shipping_markup').setValue('');
      control.get('shipping_markup_amount').setValue('');
    }
    this.deriveTotalAmounts(control);
  };

  onTaxApplicable = (controlGroup, isChecked) => {
    if (isChecked == 'on') {
      controlGroup.get('tax_type_id').enable();
    } else {
      controlGroup.get('tax_type_id').setValue(0);
      controlGroup.get('tax_type_id').disable();
    }
    this.deriveTotalAmounts(controlGroup);
  }

  disableTaxFileds = () => {
    if (!+this.productDetailForm.get('tax_applicable').value) {
      this.productDetailForm.get('tax').setValue(0);
      this.istaxDisable = true;
    } else {
      this.istaxDisable = false;
    }
  }

  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  onKeyUp = (value) => {
    this.search = value;
    this.productDetailForm.get('supplier_name').setValue(value);
    this.productDetailForm.get('supplier_id').setValue('0');
  }

  typeaheadOnSelect(e: TypeaheadMatch): void {
    if (e.item) {
      this.productDetailForm.get('supplier_id').setValue(e.item['id']);
    }
    this.productDetailForm.get('supplier_name').setValue('');
  }

  onSelectItem = (id, details, isChecked, event, i) => {
    if ($("#" + i).prop('checked')) {
      event.stopPropagation();
    }
    if (this.isHod || this.isEstimator) {
      this.itemsData = this.itemsData.reduce((acc, current) => {
        const x = acc.find(item => item.id === current.id);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
    }
    let itemData = JSON.parse(JSON.stringify(details));
    if (Object.keys(this.productorderData).length) {
      this.productorderData.map((products, index) => {
        if (products.id == details.id) {
          itemData = products;
        }
      })
    }
    const billData = [];
    if (!isChecked) {
      this.products.forEach((value, key) => {
        if (value['id'] === id) {
          this.products.splice(key, 1);
        }
      });
    } else {
      this.products.push(itemData);
    }
    if (this.isHod || this.isEstimator) {
      this.products = this.products.reduce((acc, current) => {
        const x = acc.find(item => item.id === current.id);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
    }
    this.deriveTotalAmounts();

    billData['price'] = Math.round(itemData['price']);
    billData['tax'] = Math.round((itemData['tax'] * billData['price']) / 100);
    billData['shipping_cost'] = Math.round(itemData['shipping_cost']);
    billData['shipping_markup_amount'] = Math.round(itemData['shipping_markup_amount']);
    billData['total'] = Math.round((billData['price'] + billData['tax'] + billData['shipping_cost'] + billData['shipping_markup_amount']));

    if (isChecked) {
      this.invoiceData['subTotal'] += billData['price'];
      this.invoiceData['tax'] += billData['tax'];
      this.invoiceData['shipping'] += (billData['shipping_cost'] + billData['shipping_markup_amount']);
      this.invoiceData['total'] += billData['total'];
    } else {
      this.invoiceData['subTotal'] -= billData['price'];
      this.invoiceData['tax'] -= billData['tax'];
      this.invoiceData['shipping'] -= (billData['shipping_cost'] + billData['shipping_markup_amount']);
      this.invoiceData['total'] -= billData['total'];
    }

    if (this.products.length > 0) {
      this.filterLabel.nativeElement.style.backgroundColor = '#007AFF';
    } else {
      this.filterLabel.nativeElement.style.backgroundColor = '#ffffff';
    }
  }

  saveOrder = () => {
    if ((this.products || []).length == 0) {
      this._toast.pop('error', 'Select at least one product!');
      return false;
    }

    this.products.sort((a, b) => {
      return a.id - b.id;
    });

    this.orderForm.get('contact_shipping_details').get('s_state_code').enable();
    this.orderForm.get('contact_shipping_details').get('s_country').enable();
    const postData = { ...this.orderForm.value, ...this.totalData };
    postData['isEmailSend'] = this.isEmail
    if (this.estimatorID) {  // estimator test
      postData['estimator_id'] = this.orderForm.get('estimator_id').value ? this.orderForm.get('estimator_id').value : this.estimatorID;//this.estimatorID;
    } else {
      postData['estimator_id'] = this.currentRoleEstimatorId;
    }
    if (this.orderForm.get('contact_shipping_details').get('is_same').value) {
      this.orderForm.get('contact_shipping_details').get('s_state_code').disable();
      this.orderForm.get('contact_shipping_details').get('s_country').disable();
    }
    delete postData['artwork'];
    postData['old_supplier_files'] = this.oldDocArr;
    if (this.estimationId) {
      postData['productData'] = this.products;
      postData['project_id'] = this.estimationData['project_id'];
      postData['estimation_id'] = this.estimationData['id'];
      postData['contact_id'] = this.estimationData['contact_id'];
    } else {
      if (this.isHod || this.isEstimator) {
        postData['productData'] = this.products;
      }
    }
    const fData: FormData = new FormData;
    if (this.filesArr.length > 0) {
      for (const file of this.filesArr) {
        if (file['file']) {
          fData.append('files[]', file['file']);
        }
      }
    }
    if (postData['productData']) {
      postData['productData'].map((item) => {
        this.taxTypes.map(taxitem => {
          if (item.tax_type_id == taxitem['id']) {
            item['qb_tax_id'] = taxitem['qb_tax_id'];
            item['tax'] = taxitem['rate'];
          } else if (item.tax_type_id == '' || item.tax_type_id == null) {
            item.tax_type_id = 0;
          }
        });
        if (item.tax_type_id == null) {
          item.tax_type_id = ''
        }
        if (item.tax_value == null) {
          item.tax_value = 0.00
        }
        if (item.discount_value == null) {
          item.discount_value = 0.00
        }
      })
    }
    if (postData['delivery_date']) {
      postData['delivery_date'] = this.datePipe.transform(postData['delivery_date'], 'y-MM-d');
    }
    if (postData['bill_payment_term'] == 3) {
      if (postData['payment_term_date']) {
        postData['payment_term_date'] = this.datePipe.transform(postData['payment_term_date'], 'y-MM-d');
      }
    } else {
      postData['payment_term_date'] = null;
    }
    if (this.orderId && this.isHod) {
      postData['approve_order'] = 1
    } else {
      postData['approve_order'] = 0
    }
    postData['user_terms_days'] = this.billingData ? this.billingData['terms'] : ''
    postData['editing_order'] = this.editOrder ? 'yes' : null;
    postData['bill_payment_term'] = 2;
    fData.append('postData', JSON.stringify(postData));
    let projecttype = '';
    if (this.estimationData.length > 0 || Object.keys(this.estimationData).length) {
      if (this.estimationData['project_of_estimation']) {
        projecttype = this.estimationData['project_of_estimation']['project_type'];
        this.project_type = this.estimationData['project_of_estimation']['project_type'];
      }
    } else {
      if (Object.keys(this.orderData).length > 0 || this.orderData.length > 0) {
        projecttype = this.orderData['project_of_order']['project_type'];
        this.project_type = this.orderData['project_of_order']['project_type'];
      }
    }

    // Show error while setup charge is selected but its parent item is not selected by user
    try {
      (this.products || []).forEach(element => {
        if (element.is_additional_charge == 1) {
          let isParentItemExists = (this.products).find((item) => item.project_estimation_spec_id == element.parent_item_spec_id);
          if (!isParentItemExists) {
            throw 'You have forgot to selected item of ' + element.item_spec;
          }
        }
      });
    } catch (error) {
      this._toast.pop('error', error);
      return false;
    }

    this.showProgress = true;
    this.projectService.createSalesOrder(fData, this.projectId, projecttype).subscribe((event) => {
      this.progress = this._cs.showProgress(event);
      // this.loading = false;
      if (event['body']) {
        if (event['body']['data']) {
          // this.loading = false;
          this.showProgress = false;
          if (event['body']['data']['error']) {
            this._toast.pop('warning', event['body']['data'].message, '');
          } else {
            if (event['body']['data']['ambiguos_address']) {
              this.ambiguous_addresses = event['body']['data']['Candidates'];
              this.ambiguous_addresses.forEach((val, key) => {
                if (typeof val['address'] === 'string') {
                  this.ambiguous_addresses[key]['is_array'] = false;
                } else {
                  this.ambiguous_addresses[key]['is_array'] = true;
                }
              });
              this.addModalRef.show();
            } else {
              window['storedRoutes'] = {};
              this.back();
            }
          }
        }
      }
    }, error => {
      // this.loading = false;
      this.progress = this._cs.showProgress(event);
      if (error['error']) {
        this.showProgress = false;
        this.api_error = error['error']['data'];
      }
    });
  }

  approveOrder = () => {
    if (this.orderId && this.isHod) {
      this.projectService.approveSalesOrder(this.orderId, 1).subscribe((res) => {
        if (res) {
          return res;
        }
      })
    }
    return null;
  }

  setShippingInfo = (checked) => {
    const shippingControls = this.orderForm.get('contact_shipping_details');
    this.is_same = checked;
    shippingControls.get('is_same').setValue(checked);
    let billingData = [];

    if (checked) {
      this.isReadOnly = true;
      billingData = this.billingData;
      shippingControls.get('s_full_name').setValue(billingData['first_name'] + ' ' + billingData['last_name']);
      shippingControls.get('s_email').setValue(billingData['email']);
      shippingControls.get('s_phone').setValue(billingData['phone']);
      shippingControls.get('s_address1').setValue(billingData['address_line_1']);
      shippingControls.get('s_address2').setValue(billingData['address_line_2']);
      shippingControls.get('s_city').setValue(billingData['city']);
      shippingControls.get('s_state_code').setValue(billingData['state_code']);
      shippingControls.get('s_zip_code').setValue(billingData['zip_code']);
      shippingControls.get('s_country').setValue(billingData['country']);
      shippingControls.get('s_state_code').disable();
      shippingControls.get('s_country').disable();
    } else {
      this.isReadOnly = false;
      shippingControls.get('s_state_code').enable();
      shippingControls.get('s_country').enable();
      if (Object.keys(this.shippingDetails).length > 0) {
        shippingControls.get('s_full_name').setValue(this.shippingDetails['s_full_name']);
        shippingControls.get('s_email').setValue(this.shippingDetails['s_email']);
        shippingControls.get('s_phone').setValue(this.shippingDetails['s_phone']);
        shippingControls.get('s_address1').setValue(this.shippingDetails['s_address1']);
        shippingControls.get('s_address2').setValue(this.shippingDetails['s_address2']);
        shippingControls.get('s_city').setValue(this.shippingDetails['s_city']);
        shippingControls.get('s_state_code').setValue(
          this.shippingDetails['s_state'] ? this.shippingDetails['s_state'] : this.shippingDetails['s_state_code']);
        shippingControls.get('s_zip_code').setValue(this.shippingDetails['s_zip_code']);
        shippingControls.get('s_country').setValue(this.shippingDetails['s_country']);
      } else {
        Object.keys(shippingControls['controls']).forEach(key => {
          if (key !== 's_country') {
            shippingControls.get(key).setValue('');
          }
        });
      }
    }
  }

  setBillingDetails = () => { // billing
    const billingControls = this.orderForm.get('contact_billing_details');
    if (this.billingData) {
      billingControls.get('address_line_1').setValue(this.billingData['address_line_1'] ? this.billingData['address_line_1'] : '');
      billingControls.get('address_line_2').setValue(this.billingData['address_line_2'] ? this.billingData['address_line_2'] : '');
      billingControls.get('city').setValue(this.billingData['city'] ? this.billingData['city'] : '');
      billingControls.get('state_code').setValue(this.billingData['state_code'] ? this.billingData['state_code'] : '');
      billingControls.get('zip_code').setValue(this.billingData['zip_code'] ? this.billingData['zip_code'] : '');
      billingControls.get('country').setValue(this.billingData['country'] ? this.billingData['country'] : '');
      billingControls.get('full_name').setValue(this.billingData['first_name'] + ' ' + this.billingData['last_name']);
      billingControls.get('email').setValue(this.billingData['email'] ? this.billingData['email'] : '');
    }
  }

  removeDoc = (doc, i) => {
    this.filesArr.splice(i, 1);
    this.docArr.forEach((value, key) => {
      if (key === +i) {
        this.docArr.splice(key, 1);
      }
    });
    this.oldDocArr.forEach((value, key) => {
      if (key === +i) {
        this.oldDocArr.splice(key, 1);
      }
    });
  }

  uploadDoc = (event: any) => {
    const files = event.target.files;
    let i = this.filesArr.length;
    for (const file of files) {
      // file size validation 
      let fileSize = file.size/1024/1024; 
      if(fileSize > this.artworkFileSizeLimit) {
        this.showArtworkSizeWarning = true;
        this.showArtworkLinkField = true;
        continue;
      }
      // end file size validation 
      
      const ext = file['name'].substring(file['name'].lastIndexOf('.') + 1).toLowerCase();
      let flag = true;
      if (this.docArr.length > 0) {
        this.docArr.forEach((value) => {
          if (value === file.name) {
            flag = false;
          }
        });
      }
      if (flag) {
        this.docArr.push(file.name);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event1: Event) => {
          this.filesArr[i] = {};
          this.filesArr[i]['file'] = file;
          const pathValue = this.commonService.getLogoUrl(ext);
          if (!pathValue) {
            this.filesArr[i]['url'] = event1.target['result'];
          } else {
            this.filesArr[i]['url'] = pathValue;
          }
          i++;
        };
      }
    }
  }

  back = () => {
    this.router.navigateByUrl(this.previousUrl || '/'+this.franchiseId+'/sales-order');
  }

  onPriceChanges = (control: FormGroup, changePerPiece = true) => {
    if (Number(control.get('price').value) > 0) {
      this.validatePriceTotalCost(control);
      const totalCost = (Number(+control.get('total_cost').value) > 0 ? Number(+control.get('total_cost').value) : 0);
      const markUpAmount = Number(+control.get('price').value) - +totalCost;
      if (+markUpAmount > 0) {
        const markupPer = (+markUpAmount * 100) / (+totalCost > 0 ? +totalCost : 1);
        control.get('markup').setValue(Number(+markupPer).toFixed(2));
        control.get('markup_amount').setValue(Number(+markUpAmount).toFixed(2));
      } else {
        control.get('markup').setValue('');
        control.get('markup_amount').setValue('');
      }
      if (changePerPiece && +control.get('quantity').value > 0) {
        const pricePerPiece = Number(control.get('price').value) / +control.get('quantity').value;
        control.get('price_per_piece').setValue(Number(+pricePerPiece).toFixed(5));
      }
    } else {
      control.get('price_per_piece').setValue('');
      control.get('markup').setValue('');
      control.get('markup_amount').setValue('');
      this.deriveTotalAmounts(control);
    }
  }

  validatePriceTotalCost = (control: FormGroup) => {
    if (Number(control.get('price').value) < Number(control.get('total_cost').value) && !control.get('discount_allowed').value) {
      control.get('price').setErrors({ min: 'price must be greater than or equal to total cost' });
    } else {
      control.get('price').setErrors(null);
    }
    this.deriveTotalAmounts(control);
  }

  onPricePerPieceChange = (control: FormGroup) => {
    if (+control.get('quantity').value > 0) {
      const price = Number(+control.get('price_per_piece').value) * Number(+control.get('quantity').value);
      const total_cost = Number(+control.get('cost_per_piece').value) * Number(+control.get('quantity').value);
      control.get('total_cost').setValue(Number(+total_cost).toFixed(2));
      control.get('price').setValue(Number(+price).toFixed(2));
      this.onPriceChanges(control, false);
    } else {
      control.get('price').setValue('0.00');
      control.get('total_cost').setValue('0.00');
      control.get('markup_amount').setValue('');
      this.deriveTotalAmounts(control);
    }
  }

  onMarkupChange = (control: FormGroup, markupPercentChange = true) => {
    if (!markupPercentChange || Number(+control.get('markup').value) > 0) {
      if (markupPercentChange) {
        const markupAmount = (Number(+control.get('markup').value) * Number(+control.get('total_cost').value) / 100);
        control.get('markup_amount').setValue(Number(+markupAmount).toFixed(2));
      } else {
        if ((control.get('total_cost').value) > 0) {
          const markupPer = ((Number(+control.get('markup_amount').value) * 100) / Number(+control.get('total_cost').value));
          control.get('markup').setValue(Number(+markupPer).toFixed(2));
        }
      }
      if ((control.get('total_cost').value) > 0) {
        const finalPrice = ((+control.get('markup').value) *
          (Number(+control.get('total_cost').value)) / 100) + (Number(+control.get('total_cost').value));
        control.get('price').setValue(Number(+finalPrice).toFixed(2));
      }

    } else {
      control.get('markup_amount').setValue('');
      control.get('price').setValue(Number(+control.get('total_cost').value).toFixed(2));
    }

    if (+control.get('quantity').value !== 0) {
      const price_per_piece = Number(+control.get('price').value) / Number(+control.get('quantity').value);
      control.get('price_per_piece').setValue(Number(+price_per_piece).toFixed(5));
    } else {
      control.get('price_per_piece').setValue('');
    }
    this.deriveTotalAmounts(control);
  }

  onTotalPriceChange = (control: FormGroup) => {
    if (control.get('markup').value > 0) {
      const markupAmount = ((+control.get('markup').value) * (+control.get('total_cost').value) / 100);
      control.get('markup_amount').setValue(Number(+markupAmount).toFixed(2));
      const finalPrice = (markupAmount + (+control.get('total_cost').value));
      control.get('price').setValue(Number(+finalPrice).toFixed(2));
      if (+control.get('quantity').value > 0) {
        // const totalCost = (+control.get('total_cost').value > 0 ? control.get('total_cost').value : 1);
        const pricePerPiece = +control.get('price').value / +control.get('quantity').value;
        control.get('price_per_piece').setValue(Number(+pricePerPiece).toFixed(5));
        const cost_per_piece = +control.get('total_cost').value / +control.get('quantity').value;
        control.get('cost_per_piece').setValue(Number(+cost_per_piece).toFixed(5));
      }
    } else {
      const price_per_piece = control.get('total_cost').value / +control.get('quantity').value;
      control.get('price').setValue(Number((+control.get('total_cost').value)).toFixed(2));
      if (+control.get('quantity').value > 0) {
        control.get('price_per_piece').setValue(Number(+price_per_piece).toFixed(5));
        control.get('cost_per_piece').setValue(Number(+price_per_piece).toFixed(5));
      }
    }

    if (+control.get('quantity').value === 0) {
      if (+control.get('markup').value > 1) {
        const markup = ((+control.get('markup').value) *
          (+control.get('total_cost').value) / 100) + (+control.get('total_cost').value);
        control.get('price').setValue(Number(+markup).toFixed(2));
      } else {
        control.get('price').setValue(Number(+control.get('total_cost').value).toFixed(2));
        control.get('price_per_piece').setValue('');
      }
    }
    if ((+control.get('total_cost').value > 0)) {
      // if(control.get('shipping_cost')){
      //   let total_order_cost = Number(control.get('total_cost').value) + Number(control.get('shipping_cost').value)
      //   control.get('total_order_cost').setValue(Number(+total_order_cost).toFixed(2));
      // }else{
      //   let total_order_cost = Number(control.get('total_cost').value)
      //   control.get('total_order_cost').setValue(Number(+total_order_cost).toFixed(2));
      // }
    }
    if (+control.get('total_cost').value === 0) {
      control.get('price_per_piece').setValue('');
      control.get('price').setValue('');
    }
    if ((+control.get('total_cost').value == 0) || control.get('total_cost').value == '') {
      // if(+control.get('shipping_cost').value > 0){
      //   control.get('total_order_cost').setValue(Number(+control.get('shipping_cost').value).toFixed(2))
      // }else{
      //   control.get('total_order_cost').setValue('');
      // }
    }
    this.validatePriceTotalCost(control);
  }

  onQuantityChanges = (control: FormGroup) => {
    // if (((control.get('markup').value) > 0) || ((control.get('markup_amount').value) > 0)) {
    //   control.get('markup').setValue('');
    //   control.get('markup_amount').setValue('');
    // }
    if ((Number)(control.get('price').value) > 0 && (Number)(control.get('quantity').value) > 0) {
      const price_per_piece = +control.get('price').value / +control.get('quantity').value;
      // control.get('price_per_piece').setValue(Number(price_per_piece).toFixed(2));
    } else {
      control.get('price_per_piece').setValue('');
      //control.get('cost_per_piece').setValue('');
    }
    if ((Number)(control.get('cost_per_piece').value) > 0 && (Number)(control.get('quantity').value) > 0) {
      const total_cost = Number(+control.get('cost_per_piece').value * control.get('quantity').value);
      control.get('total_cost').setValue(Number(+total_cost).toFixed(2));
      // if (Number(control.get('shipping_cost').value) > 0) {
      //   const total_order_cost = Number(control.get('shipping_cost').value) + total_cost
      //   control.get('total_order_cost').setValue(Number(+total_order_cost).toFixed(2));
      // } else {
      //   control.get('total_order_cost').setValue(Number(+total_cost).toFixed(2));
      // }
    } else {
      control.get('total_cost').setValue('');
      //control.get('total_order_cost').setValue('');
    }
    if (control.get('markup').value > 0) {
      const markupAmount = ((+control.get('markup').value) * (+control.get('total_cost').value) / 100);
      control.get('markup_amount').setValue(Number(+markupAmount).toFixed(2));
      const finalPrice = (markupAmount + (+control.get('total_cost').value));
      control.get('price').setValue(Number(+finalPrice).toFixed(2));
      if (+control.get('quantity').value > 0) {
        const pricePerPiece = +control.get('price').value / +control.get('quantity').value;
        control.get('price_per_piece').setValue(Number(+pricePerPiece).toFixed(5));
      }
    }
    this.deriveTotalAmounts(control);
  }

  onShippingMarkup = (control: FormGroup) => {
    if (control.get('shipping_markup').value > 0 && control.get('shipping_cost').value > 0) {
      const amount = (Number(control.get('shipping_cost').value) * Number(control.get('shipping_markup').value)) / 100;
      control.get('shipping_markup_amount').setValue(Number(+amount).toFixed(2));
    } else {
      control.get('shipping_markup_amount').setValue(0.00);
    }
    this.deriveTotalAmounts(control);
  }

  onShippingAmount = (control: FormGroup) => {
    if (control.get('shipping_markup_amount').value > 0 && control.get('shipping_cost').value > 0) {
      const newMarkup = ((Number(control.get('shipping_markup_amount').value) * 100) / Number(control.get('shipping_cost').value));
      control.get('shipping_markup').setValue(Number(+newMarkup).toFixed(2));
    } else {
      control.get('shipping_markup').setValue('0.00');
    }
    this.deriveTotalAmounts(control);
  }

  onShippingCost = (control: FormGroup) => {
    if ((Number)(control.get('shipping_markup').value) > 0 && control.get('shipping_cost').value > 0) {
      const amount = (Number(control.get('shipping_cost').value) * Number(control.get('shipping_markup').value)) / 100;
      control.get('shipping_markup_amount').setValue(Number(+amount).toFixed(2));
    } else {
      control.get('shipping_markup').setValue('0.00');
      control.get('shipping_markup_amount').setValue('0.00');
    }
    // if (Number(control.get('shipping_cost').value > 0 && control.get('total_cost').value > 0)) {
    //   const total_order_cost = Number(control.get('shipping_cost').value) + Number(control.get('total_cost').value);
    //   control.get('total_order_cost').setValue(Number(+total_order_cost).toFixed(2));
    // } else {
    //   if (Number(control.get('total_cost').value > 0)) {
    //     let total_order_cost = Number(control.get('total_cost').value);
    //     control.get('total_order_cost').setValue(Number(+total_order_cost).toFixed(2));
    //   } else {
    //     control.get('total_order_cost').setValue('');
    //   }
    // }
    this.deriveTotalAmounts(control);
  }
  disabledFiledsMarkup = (control: FormGroup) => {
    if (control.get('shipping_included_markup').value) {
      control.get('shipping_markup').setValue('0.00');
      control.get('shipping_markup_amount').setValue('0.00');
    } else {
      control.get('shipping_markup').setValue('');
      control.get('shipping_markup_amount').setValue('');
    }
    this.deriveTotalAmounts(control);
  }
  onSelectTax = (controlGroup, id) => {
    this.taxRate = 0;
    this.taxTypes.map(item => {
      if (item['id'] == +id) {
        this.taxRate = item['rate'];
      }
    });
    this.deriveTotalAmounts(controlGroup);
  }
  getTotalDataByKey = (controlGroup, key) => {
    let value = controlGroup.get('total_data').get(key).value;
    return Number(+value).toFixed(2);
  }
  // ============================================

  deriveTotalAmounts = async (controlGroup: any = {}) => {
    let subTotal = 0;
    let totalOfTaxItems = 0;
    let discountAmount = 0;
    let taxableSubtotal = 0;
    let taxAmount = 0;
    let shippingCharge = 0;
    let tax = 0;
    let totalDiscount = 0;
    let finaltaxamount = 0

    let products = [];
    if (this.productDetailForm.get('id').value) {
      products.push(this.productDetailForm.value);
      this.products.map(product => {
        if (+product['id'] !== this.productDetailForm.get('id').value) {
          products.push(product);
        }
      });
    } else {
      products = JSON.parse(JSON.stringify(this.products));
    }
    if (products.length > 0) {
      // calculate subtotal, shipping charge and subtotal of tax allowed items
      products.map((item) => {
        if (item['tax_applicable']) {
          totalOfTaxItems += +item['price'];
          tax += +item['tax_value'];
          finaltaxamount = tax;
        }
        subTotal += +item['price'];
        if (+item['shipping_included'] === 0) {
          shippingCharge += (+item['shipping_cost'] + +item['shipping_markup_amount']);
        } else {
          shippingCharge += (+item['shipping_markup_amount']);
        }
        //for discount amount
        if (item['discount_amount']) {
          totalDiscount += +item['discount_amount'];
        }
      });
      // end

      // calculate discount and taxable amounts
      this.totalData['subtotal'] = subTotal.toFixed(2);
      this.totalData['shipping_charge'] = shippingCharge.toFixed(2);
      const discountValue = +this.orderForm.get('discount_value').value;
      if (discountValue > 0) {
        if (+this.orderForm.get('discount_type').value === 1) {
          // calculate by percent
          discountAmount = (discountValue * subTotal) / 100;
        } else {
          // calculate by value
          discountAmount = discountValue;
        }
      }

      if (totalOfTaxItems > 0) {
        // taxableSubtotal = totalOfTaxItems - discountAmount;
        const taxable = (totalOfTaxItems * discountAmount) / subTotal;
        taxableSubtotal = totalOfTaxItems - taxable;
      }
      if (tax > 0 && subTotal > 0) {
        tax = tax + subTotal;
      } else {
        tax = +subTotal;
      }

      //taxableSubtotal
      //discountAmount
      this.totalData['discount_amount'] = +totalDiscount > 0 ? Number(+totalDiscount).toFixed(2) : '0.00';
      this.totalData['taxable_subtotal'] = +tax > 0 ? Number(+tax).toFixed(2) : '0.00';
      // end

      // calculate tax amount by rate
      const taxId = this.orderForm.get('tax_type_id').value;
      if (taxId && +this.taxRate === 0) {
        this.taxRate = 0;
        this.taxTypes.map(item => {
          if (item['id'] === +taxId) {
            this.taxRate = item['rate'];
          }
        });
      }

      if (+this.taxRate > 0) {
        taxAmount = (taxableSubtotal * this.taxRate) / 100;
      }
      //taxAmount
      this.totalData['tax_amount'] = finaltaxamount.toFixed(2);
      // end

      // calculate total amount
      //const totalAmount = subTotal - discountAmount + taxAmount + shippingCharge;
      const totalAmount = tax + shippingCharge - +totalDiscount;
      this.totalData['total_amount'] = totalAmount.toFixed(2);
      // end
    }
    //new added
    if (Object.keys(controlGroup).length > 0) {
      let discountAmount = 0;
      let taxAmount = 0;
      let taxableAmount = 0;
      let total = 0;
      let shippingCharge = 0;

      const totalDataGroup = controlGroup.get('total_data');
      const originalPrice = +controlGroup.get('price').value;
      // total += originalPrice;

      const discountValue = +controlGroup.get('discount_value').value;
      if (discountValue > 0) {
        if (+controlGroup.get('discount_type').value === 1) {
          // calculate by percent
          discountAmount += (discountValue * originalPrice) / 100;
        } else {
          // calculate by value
          discountAmount += discountValue;
        }
      }

      taxableAmount = Number(originalPrice) - Number(discountAmount);

      if (+controlGroup.get('tax_applicable').value) {
        if (this.taxTypes.length === 0) {
          const res = await this._taxService.getTaxOptions().toPromise();
          this.taxTypes = res['data'];
        }
        const taxRate = this.getTaxRateById(controlGroup.get('tax_type_id').value);
        taxAmount = (taxRate * taxableAmount) / 100;
        controlGroup.get('tax_value').setValue(Number(+taxAmount).toFixed(2));
      }

      if (!controlGroup.get('shipping_included').value || controlGroup.get('shipping_included').value == 0 || controlGroup.get('shipping_included').value == '' || controlGroup.get('shipping_included').value == 1) {
        if (controlGroup.get('shipping_markup_amount').value) {
          shippingCharge = (+controlGroup.get('shipping_cost').value + +controlGroup.get('shipping_markup_amount').value);
        } else {
          if (controlGroup.get('shipping_markup_amount').value > 0 && !controlGroup.get('shipping_cost').value) {
            shippingCharge = (+controlGroup.get('shipping_markup_amount').value)
          } else {
            shippingCharge = (+controlGroup.get('shipping_cost').value)
          }
        }
      }

      total = originalPrice - discountAmount + taxAmount + shippingCharge;
      //controlGroup.get('discount').setValue(Number(+discountAmount).toFixed(2));
      // controlGroup.get('tax_type').setValue(Number(+taxAmount).toFixed(2));
      controlGroup.get('tax_value').setValue(Number(+taxAmount).toFixed(2));
      controlGroup.get('discount_amount').setValue(Number(+discountAmount).toFixed(2));
      controlGroup.get('total_price').setValue(Number(+total).toFixed(2));
      totalDataGroup.get('discount_amount').setValue(Number(+discountAmount).toFixed(2));
      totalDataGroup.get('tax_value').setValue(Number(+taxAmount).toFixed(2));
      totalDataGroup.get('total_shipping_charge').setValue(Number(+shippingCharge).toFixed(2));
      //controlGroup.get('shipping_price').setValue(Number(+shippingCharge).toFixed(2));
      controlGroup.get('shipping_amount').setValue(Number(+shippingCharge).toFixed(2)); //aaded Number(+controlGroup.get('shipping_markup_amount').value).toFixed(2)
      totalDataGroup.get('total_price').setValue(Number(+total).toFixed(2));
    }
  }

  setSelectedAddress = () => {
    this.selected_ambiguous_address = this.ambiguous_addresses[this.selected_ambiguous_address_index];
    this.addModalRef.hide();
    const contactControls = this.orderForm.get('contact_shipping_details');
    if (this.selected_ambiguous_address['is_array']) {
      contactControls.get('s_address1').setValue(this.selected_ambiguous_address['address'].join(' '));
    } else {
      contactControls.get('s_address1').setValue(this.selected_ambiguous_address['address']);
    }
    contactControls.get('s_address2').setValue('');
    contactControls.get('s_city').setValue(this.selected_ambiguous_address['city']);
    contactControls.get('s_state_code').setValue(this.selected_ambiguous_address['state']);
    contactControls.get('s_zip_code').setValue(this.selected_ambiguous_address['zipcode']);
    contactControls.get('s_country').setValue(this.selected_ambiguous_address['country']);
  }

  setFields = () => {
    $(document).ready(() => {
      var costPerPiece = setInterval(() => {
        let totalCostPerPiece = $("[customFormatAttribute=true]").length;
        let counter = 0; 
          $("[customFormatAttribute=true]").each((index, value)=> {
          value.addEventListener('focusout', () => {
            this.formatFields('price_per_piece');
            if(value.getAttribute('formcontrolname') == 'total_cost') {
              this.formatFields('cost_per_piece');
            }
          });
          counter++;
          if(totalCostPerPiece == counter && totalCostPerPiece != 0) {
            this.formatFields('cost_per_piece');
            this.formatFields('price_per_piece');
            clearInterval(costPerPiece);
          }
        })
      }, 10);
    });
  }

  formatFields = (field) => {
    if(field == 'price_per_piece') {
      var PricePerPiece = setInterval(() => {
        let totalPricePerPiece = $("[formControlName=price_per_piece]").length;
        let counter = 0; 
        $("[formControlName=price_per_piece]").each((index, value)=> {
          value.focus();
          value.blur();
          counter++;
          if(totalPricePerPiece == counter) {
            clearInterval(PricePerPiece);
          }
        })
      }, 10);
    }

    if(field == 'cost_per_piece') {
      var CostPerPiece = setInterval(() => {
        let totalCostPerPiece = $("[formControlName=cost_per_piece]").length;
        let counter = 0; 
        $("[formControlName=cost_per_piece]").each((index, value)=> {
          value.focus();
          value.blur();
          counter++;
          if(totalCostPerPiece == counter) {
            clearInterval(CostPerPiece);
          }
        })
      }, 10);
    }
  }

  chnageArtworkLink = (field) => {
    if(field == 'artwork') {
      this.showArtworkSizeWarning = false;
    }
  }

  calculateCommission = () => {
    let sale_amt = 0;
    let purchase_cost = 0;

    (this.products || []).forEach((item) => {
      // We are calculating this long formula because while Order Create and Order Approve this same method is being called but both way the item has same property name with different calculated amount
      sale_amt += parseFloat(item.price) + parseFloat(item.shipping_cost || 0) + parseFloat(item.shipping_markup_amount || 0) - parseFloat(item.discount_amount || 0);
      purchase_cost += (parseFloat(item.total_cost) + parseFloat(item.shipping_cost));
    });
    this.gross_profit = +((sale_amt - purchase_cost).toFixed(2));

    if (this.sales_rep_commission_percent > 0) {
      this.sales_rep_commission_amt = (this.gross_profit * (this.sales_rep_commission_percent / 100)).toFixed(2);
    }
    if (this.second_sales_rep_commission_percent > 0) {
      this.second_sales_rep_commission_amt = (this.gross_profit * (this.second_sales_rep_commission_percent / 100)).toFixed(2);
    }
    if (this.csr_commission_percent > 0) {
      this.csr_commission_amt = (this.gross_profit * (this.csr_commission_percent / 100)).toFixed(2);
    }
  }
}
