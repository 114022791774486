import { AbstractControl, Validators } from '@angular/forms';


export class ConditionalValidator {
  static conditionallyRequired(calendarForm: AbstractControl) {
    const validator = [
      ['cover_stock', 'Other_cover_Stock_Name'],
      ['text_stock', 'Other_Text_Stock_Name']
    ];

    validator.map(item => {
      if (calendarForm.get(item[0]).value === 'Other') {
        calendarForm.get(item[1]).setValidators(Validators.required);
      } else {
        calendarForm.get(item[1]).setValidators(null);
        calendarForm.get(item[1]).setErrors(null);
      }
    });

    if (calendarForm.get('Text_Coating').value !== '') {
      calendarForm.get('text_coaton').setValidators(Validators.required);
    } else {
      calendarForm.get('text_coaton').setValidators(null);
      calendarForm.get('text_coaton').setErrors(null);
    }

    if (calendarForm.get('Binding').value !== 'saddle stitch') {
      calendarForm.get('number_of_pages').setValidators(Validators.required);
      calendarForm.get('number_of_months').setValidators(null);
      calendarForm.get('number_of_months').setErrors(null);
      calendarForm.get('Flat_size').setValidators(null);
      calendarForm.get('Flat_size').setErrors(null);
    } else {
      calendarForm.get('number_of_months').setValidators(Validators.required);
      calendarForm.get('number_of_pages').setValidators(null);
      calendarForm.get('number_of_pages').setErrors(null);
    }
  }

  static validatorBooklet(quoteForm: AbstractControl) {
    const bookletType = quoteForm.get('booklet_type').value;
    switch (bookletType) {
      case 'self_cover':
        quoteForm.get('stock').setValidators(Validators.required);
        quoteForm.get('sides').setValidators(Validators.required);
        if (quoteForm.get('coating').value) {
          quoteForm.get('coat_on').setValidators(Validators.required);
        } else {
          quoteForm.get('coat_on').setValidators(null);
          quoteForm.get('coat_on').setErrors(null);
        }
        ConditionalValidator.removeValidations(quoteForm, ['cover_stock', 'text_stock', 'text_sides']);
        break;
      case 'hard_cover':
        quoteForm.get('cover_stock').setValidators(Validators.required);
        quoteForm.get('text_stock').setValidators(Validators.required);
        quoteForm.get('text_sides').setValidators(Validators.required);
        if (quoteForm.get('text_sides').value) {
          quoteForm.get('coat_on').setValidators(Validators.required);
        } else {
          quoteForm.get('coat_on').setValidators(null);
          quoteForm.get('coat_on').setErrors(null);
        }
        ConditionalValidator.removeValidations(quoteForm, ['stock', 'sides']);
        break;
    }
  }

  static removeValidations = (quoteForm: AbstractControl, controlArray) => {
    controlArray.map(
      (control) => {
        quoteForm.get(control).setValidators(null);
        quoteForm.get(control).setErrors(null);
      }
    );
  }
}
