import { Directive, ElementRef, OnInit, Input } from '@angular/core';
import { RolepermissionService } from '../service/rolepermission.service';
import { DevRolepermissionService } from '../service/DevAdmin/rolepemission.service';

@Directive({
    selector: '[appCheckPermission]'
})

export class CheckPermissionDirective implements OnInit {
    // tslint:disable-next-line:no-input-rename
    @Input('appCheckPermission') permission: string; // Required permission passed in
    roleService = null;
    constructor(private el: ElementRef, private rolePermissionService: RolepermissionService,
        private devRolePermission: DevRolepermissionService) {
    }

    ngOnInit() {
        if (localStorage.getItem('designation') && localStorage.getItem('designation') === 'da') {
            this.roleService = this.devRolePermission;
        } else {
            this.roleService = this.rolePermissionService;
        }
        if (!this.roleService.hasAnyPermissions(this.permission)) {
            this.el.nativeElement.style.display = 'none';
        }
    }
}
