import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'awi-form-fields',
  templateUrl: './form-fields.component.html',
  styleUrls: ['./../quote-forms.component.scss']
})

export class FormFieldsComponent {
  @Input() formRef: FormGroup;
  @Input() formElem;
  @Input() formSubmitRef;

  @Output() showFields = new EventEmitter();

  ShowFields = (ele, val) => {
    this.showFields.emit([ele, val]);
  }

}
