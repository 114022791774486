import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { DataTableService } from '../../service/datatable.service';
import { CommonService } from '../../service/common.service';
import * as XLSX from 'xlsx';
import { FranchiseService } from '../../service/franchise.service';
import { AuthenticationService } from '../../service/authentication.service';
import { UserService } from '../../service/user.service';
import * as moment from 'moment';
import {RolepermissionService} from '../../service/rolepermission.service';
import {Router} from '@angular/router';

@Component({
  templateUrl: './global-project-report.component.html',
  styleUrls: ['./global-project-report.component.scss'],
  selector: 'app-global-project-report'
})
export class GlobalProjectReportComponent implements OnInit, OnDestroy {
  constructor(
    private _dtService: DataTableService,
    private userService: UserService,
    public commonService: CommonService,
    private franchiseService: FranchiseService,
    private authService: AuthenticationService,
    private rolePermissionService: RolepermissionService,
    private router: Router
  ){}

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  loading = false;

  selectedTab = 'All';
  currentUser: any = null;
  projects = [];
  franchiseList:any = [];
  franchise_id:any = null;
  salesRepList:any = [];
  sales_rep_id:any = null;
  estimatorList:any = [];
  estimator_id: any = null;

  userData:any = {};
  isSalesRep = false;
  isCSR = false;
  isEstimator = false;
  wordExportRecords:any = [];

  projectStatuses: any = {};
  project_status_id:any = [];
  orderStatuses: any = {};
  order_status_id:any = [];

  options: any = {
    locale: { format: 'MM/DD/YYYY' },
    alwaysShowCalendars: false,
    startDate: moment("2019-01-01"),
    endDate: moment(),
    ranges: {
      'Today': [moment(), moment()],
      'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
      'All' : [moment("2019-01-01"), moment()] //moment().subtract(3, 'year').startOf('year')
    }
  };

  date_filter:any = {
    from: moment("2019-01-01").format(),
    to: moment().format(),
    filter_by: 'created'
  };

  async ngOnInit() {
    this.userData = this.franchiseService.franchiseData.getValue();
    await new Promise(resolve => {
      this.authService.userData.subscribe((res) => {
        this.currentUser = res['id'];
        resolve(res);
      });
    });

    this.franchiseService.getAllFranchise().subscribe((res) => {
      this.franchiseList = res['data'];
    });

    this.userService.getUsersOfAllFranchiseByRole({role: 'Sales Rep'}).subscribe((res) => {
      this.salesRepList = res['data'];
    });

    this.userService.getUsersOfAllFranchiseByRole({role: 'Estimator'}).subscribe((res) => {
      this.estimatorList = res['data'];
    });
    
    let configStatus:any = this.commonService.configStatus.getValue();
    if (Object.keys(configStatus).length === 0) {
      await new Promise(resolve => {
        this.commonService.getConfigStatusData().subscribe(res => {
          configStatus = res;
          this.commonService.configStatus.next(res);
          resolve(res);
        });
      });
    }

    this.projectStatuses = configStatus.project_status || {};
    this.project_status_id = Object.keys(this.projectStatuses);

    this.orderStatuses = configStatus.project_order || {};
    this.order_status_id = Object.keys(this.orderStatuses);

    this.getProjects();
    setTimeout(()=> {
      this.dtTrigger.next();
    }, 0);
  }

  getProjects() {
    this.dtOptions = {
      dom: "<'row'<'col-sm-12 col-md-4'l><'col-sm-12 col-md-4 top'i><'col-sm-12 col-md-4 top'f>>" +
      "<'row'<'col-sm-12'tr>>" +
      "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
      pagingType: 'full_numbers',
      pageLength: 10,
      // lengthMenu: [5, 10, 25, 50],
      lengthMenu: [ [10, 25, 50, 100, -1], [10, 25, 50, 100, "All"] ],
      searchDelay: 1000,
      serverSide: true,
      processing: true,
      language: {
        searchPlaceholder: 'Search...',
        search: ''
      },
      ajax: (dataTablesParameters: any, callback) => {
        let data:any = {
          franchise_id: (this.franchise_id || []).map(item => item.id),
          sales_rep_id: this.sales_rep_id,
          estimator_id: this.estimator_id,
          date_filter: this.date_filter,
          project_status_id: [],
          order_status_id: [],
        };

        if (this.selectedTab == 'All') {
          // If we want data for all statuses then no need to send project_status_id OR order_status_id key in request. It will reduce condtion in query at backend which will improve performance
          delete data.project_status_id;
          delete data.order_status_id;
        } else if (this.selectedTab == 'Projects') {
          delete data.project_status_id;
        } else if (this.selectedTab == 'Orders') {
          data.order_status_id = (this.order_status_id || []).filter((status_id) => {
            return status_id != 3 && status_id != 12 && status_id != 13 && status_id != 14;
          });
        } else if (this.selectedTab == 'Completed Orders') {
          // 3 = Order Completed
          // 12 = Pre Press
          // 13 = Proof Approved
          // 14 = In Production
          data.order_status_id = [3, 12, 13, 14];
        } else if (this.selectedTab == 'Deleted Projects') {
          data.deleted_projects_only = true;
          delete data.project_status_id;
          delete data.order_status_id;
        }

        this._dtService.getTableData(dataTablesParameters, 'global-project-report', data).subscribe((response: any = {})=>{
          let currentDate = response.current_time || moment().format("MM/DD/YYYY HH:mm:ss");

          this.projects = (response.data.original.data || []).map((item) => {
            // For project
            if (item['deleted_at']) {
              item['display_deleted_date'] = moment(item['deleted_at']).format("MM-DD-YYYY HH:mm:ss");
            }

            if(item['updated_at']){
              let displaydate = moment(item['updated_at']).format("MM-DD-YYYY HH:mm:ss");
              item['display_update_date'] = displaydate;

              let updatedDate = moment(item['updated_at']).format("MM/DD/YYYY HH:mm:ss");
              let diff = Math.abs(+new Date(updatedDate) - +new Date(currentDate)) / 1000;    
              const hours = Math.floor(+diff / 3600);
              item['hours'] = hours;
              if(hours > 24) {
                item['days'] = Math.floor(hours/24);
              }else {
                item['days'] = 0;
              }
            }
            item['status_name'] = this.commonService.getStatusName(item['status'], 'project_status');

            // For sales order
            if(item['order_of_project'] && item['order_of_project']['updated_at']){
              let displaydate = moment(item['order_of_project']['updated_at']).format("MM-DD-YYYY HH:mm:ss");
              item['order_of_project']['display_update_date'] = displaydate;

              let updatedDate = moment(item['order_of_project']['updated_at']).format("MM/DD/YYYY HH:mm:ss");
              let diff = Math.abs(+new Date(updatedDate) - +new Date(currentDate)) / 1000;    
              const hours = Math.floor(+diff / 3600);
              item['order_of_project']['hours'] = hours;
              if(hours > 24) {
                item['order_of_project']['days'] = Math.floor(hours/24);
              }else {
                item['order_of_project']['days'] = 0;
              }
              
              item['order_of_project']['status_name'] = this.commonService.getStatusName(item['order_of_project']['status'], 'project_order');
            }
            
            return item;
          });

          callback({
            recordsTotal: response['data']['original']['recordsTotal'],
            recordsFiltered: response['data']['original']['recordsFiltered'],
            data: []
          });
        },(error) => {
          callback({
            recordsTotal: 0,
            recordsFiltered: 0,
            data: []
          });
        });
      },
      order: [],
      columns: [
        {
          data: null,
          orderable: false,
          searchable: false
        },
        {
          name: 'project_uid'
        },
        {
          name: 'pod.sales_order_uid'
        },
        {
          name: 'project_name'
        },
        {
          name: 'status',
          orderable: false,
          searchable: false
        },
        {
          name: 'sales_rep.first_name',
          orderable: false
        },
        {
          name: 'estimator.first_name',
          orderable: false
        },
        {
          name: 'contacts.contact_company.name',
          orderable: false,
          searchable: true
        },
        {
          name: 'contacts.email',
          orderable: false,
          searchable: true
        },
        {
          name: 'contacts.phone',
          orderable: false,
          searchable: true
        },
        {
          name: 'updated_at'
        },
        {
          data: null,
          orderable: false,
          searchable: false
        }
      ]
    };
  }

  dropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    placeholder: 'Distributor Network',
    allowSearchFilter: true,
    closeDropDownOnSelection: true
  };

  rerender = (): void => {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    }, (error) => {
      console.error(error);
    });
  }

  selectedDate (value: any) {
    this.date_filter.from = value.start.format();
    this.date_filter.to = value.end.format();
  }

  exportToExcel(type = 'XLSX') {
    this.loading = true;
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      let dataTablesParameters:any = dtInstance.ajax.params();
      delete dataTablesParameters.length;
      delete dataTablesParameters.start;

      let data:any = {
        franchise_id: (this.franchise_id || []).map(item => item.id),
        sales_rep_id: this.sales_rep_id,
        estimator_id: this.estimator_id,
        date_filter: this.date_filter,
        project_status_id: [],
        order_status_id: []
      };

      if (this.selectedTab == 'All') {
        // If we want data for all statuses then no need to send project_status_id OR order_status_id key in request. It will reduce condtion in query at backend which will improve performance
        delete data.project_status_id;
        delete data.order_status_id;
      } else if (this.selectedTab == 'Projects') {
        delete data.project_status_id;
      } else if (this.selectedTab == 'Orders') {
        delete data.order_status_id;
      } else if (this.selectedTab == 'Completed Orders') {
        // 3 = Order Completed
        // 12 = Pre Press
        // 13 = Proof Approved
        // 14 = In Production
        data.order_status_id = [3, 12, 13, 14];
      } else if (this.selectedTab == 'Deleted Projects') {
        data.deleted_projects_only = true;
        delete data.project_status_id;
        delete data.order_status_id;
      }

      this._dtService.getTableData(dataTablesParameters, 'global-project-report', data).subscribe((response: any = {})=>{
        let xlsJSON = [];
        let currentDate = response.current_time || moment().format("MM/DD/YYYY HH:mm:ss");
        (response.data.original.data || []).forEach((item) => {
          // For project
          if (item['deleted_at']) {
            item['display_deleted_date'] = moment(item['deleted_at']).format("MM-DD-YYYY HH:mm:ss");
          }

          if(item['updated_at']){
            let displaydate = moment(item['updated_at']).format("MM-DD-YYYY HH:mm:ss");
            item['display_update_date'] = displaydate;

            let updatedDate = moment(item['updated_at']).format("MM/DD/YYYY HH:mm:ss");
            let diff = Math.abs(+new Date(updatedDate) - +new Date(currentDate)) / 1000;    
            const hours = Math.floor(+diff / 3600);
            item['hours'] = hours;
            if(hours > 24) {
              item['days'] = Math.floor(hours/24);
            }else {
              item['days'] = 0;
            }
          }
          item['status_name'] = this.commonService.getStatusName(item['status'], 'project_status');

          // For sales order
          if(item['order_of_project'] && item['order_of_project']['updated_at']){
            let displaydate = moment(item['order_of_project']['updated_at']).format("MM-DD-YYYY HH:mm:ss");
            item['order_of_project']['display_update_date'] = displaydate;

            let updatedDate = moment(item['order_of_project']['updated_at']).format("MM/DD/YYYY HH:mm:ss");
            let diff = Math.abs(+new Date(updatedDate) - +new Date(currentDate)) / 1000;    
            const hours = Math.floor(+diff / 3600);
            item['order_of_project']['hours'] = hours;
            if(hours > 24) {
              item['order_of_project']['days'] = Math.floor(hours/24);
            }else {
              item['order_of_project']['days'] = 0;
            }
            
            item['order_of_project']['status_name'] = this.commonService.getStatusName(item['order_of_project']['status'], 'project_order');
          }

          if(!item['order_of_project'] && item['rush_date']) {
            item['in_hand_date'] = moment(item['rush_date'],'DD-MM-YYYY').format('MM-DD-YYYY');
          } else if (item['order_of_project'] && item['order_of_project']['delivery_date']) {
            item['in_hand_date'] = moment(item['order_of_project']['delivery_date'],'DD-MM-YYYY').format('MM-DD-YYYY');
          }
          
          let obj = [];
          obj['Distributor Network'] = (item.franchise && item.franchise.name) ? item.franchise.name : '';
          obj['Project No'] = item.project_uid;
          obj['Sales Order No'] = (item.order_of_project && item.order_of_project.sales_order_uid) ? item.order_of_project.sales_order_uid: '';
          obj['Project Name'] = item.project_name;
          obj['Status'] = (item.order_of_project) ? item.order_of_project.status_name : item.status_name;
          obj['In-Hands Date'] = item.in_hand_date || '';
          obj['Distributor'] = item.sales_rep ? ((item.sales_rep.first_name || '') +' '+ (item.sales_rep.last_name || '')) : '';
          obj['Estimator'] = (item.order_of_project && item.order_of_project.estimator) ? ((item.order_of_project.estimator.first_name || '') +' '+(item.order_of_project.estimator.last_name || '')) : (!item.order_of_project && item.estimator) ? ((item.estimator.first_name || '') +' '+(item.estimator.last_name || '')) : '';
          obj['Client'] = (item.contact_info && item.contact_info.contact_company) ? item.contact_info.contact_company.name : '';
          obj['Client Email'] = (item.contact_info && item.contact_info.email) ? item.contact_info.email : '';
          obj['Client Phone'] = (item.contact_info && item.contact_info.phone) ? item.contact_info.phone : '';

          if (this.selectedTab == 'Deleted Projects') {
            obj['Deleted At'] = item.display_deleted_date || '';
            obj['Deleted By'] = item.deleted_by ? item.deleted_by.full_name : '';
          } else {
            obj['Updated At'] = (item.order_of_project) ? (item.order_of_project.display_update_date || '') : (item.display_update_date || '');
            obj['Action At'] = '';
            if (item.order_of_project) {
              obj['Action At'] = (item.order_of_project.hours == 1) ? ' 1 Hour' : (item.order_of_project.hours >= 2 && item.order_of_project.hours < 24) ? item.order_of_project.hours + ' Hours' : item.order_of_project.days == 1 ? '1 Day' : item.order_of_project.days + ' Days';
            } else {
              obj['Action At'] = (item.hours == 1) ? ' 1 Hour' : (item.hours >= 2 && item.hours < 24) ? item.hours + ' Hours' : item.days == 1 ? '1 Day' : item.days + ' Days';
            }
          }

          xlsJSON.push(obj);
        });

        const worksheet1: XLSX.WorkSheet = XLSX.utils.json_to_sheet(xlsJSON);
        const csvOutput: string = XLSX.utils.sheet_to_csv(worksheet1);
        const workbook: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet1, 'Sheet1');

        if (type == 'CSV') {
          XLSX.writeFile(workbook,'Project Report.csv')
        } else {
          XLSX.writeFile(workbook,'Project Report.xlsx', { bookType: 'xlsx', type: 'buffer' } );
        }

        this.loading = false;
      }, (error) => {
        console.error(error);
        this.loading = false;
      });
    }, (error) => {
      console.error(error);
      this.loading = false;
    });
  }

  exportToWord() {
    this.loading = true;
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      let dataTablesParameters:any = dtInstance.ajax.params();
      delete dataTablesParameters.length;
      delete dataTablesParameters.start;

      let data:any = {
        franchise_id: this.franchise_id,
        sales_rep_id: this.sales_rep_id,
        estimator_id: this.estimator_id,
        date_filter: this.date_filter,
        project_status_id: [],
        order_status_id: [],
        withStickyNotes: true
      };

      if (this.selectedTab == 'All') {
        // If we want data for all statuses then no need to send project_status_id OR order_status_id key in request. It will reduce condtion in query at backend which will improve performance
        delete data.project_status_id;
        delete data.order_status_id;
      } else if (this.selectedTab == 'Projects') {
        delete data.project_status_id;
      } else if (this.selectedTab == 'Orders') {
        delete data.order_status_id;
      } else if (this.selectedTab == 'Completed Orders') {
        // 3 = Order Completed
        // 12 = Pre Press
        // 13 = Proof Approved
        // 14 = In Production
        data.order_status_id = [3, 12, 13, 14];
      } else if (this.selectedTab == 'Deleted Projects') {
        data.deleted_projects_only = true;
        delete data.project_status_id;
        delete data.order_status_id;
      }

      this._dtService.getTableData(dataTablesParameters, 'global-project-report', data).subscribe((response: any = {})=>{
        let currentDate = response.current_time || moment().format("MM/DD/YYYY HH:mm:ss");
        let projects = (response.data.original.data || []).map((item) => {
          // For project
          if(item['updated_at']){
            let displaydate = moment(item['updated_at']).format("MM-DD-YYYY HH:mm:ss");
            item['display_update_date'] = displaydate;

            let updatedDate = moment(item['updated_at']).format("MM/DD/YYYY HH:mm:ss");
            let diff = Math.abs(+new Date(updatedDate) - +new Date(currentDate)) / 1000;    
            const hours = Math.floor(+diff / 3600);
            item['hours'] = hours;
            if(hours > 24) {
              item['days'] = Math.floor(hours/24);
            }else {
              item['days'] = 0;
            }
          }
          item['status_name'] = this.commonService.getStatusName(item['status'], 'project_status');

          // For sales order
          if(item['order_of_project'] && item['order_of_project']['updated_at']){
            let displaydate = moment(item['order_of_project']['updated_at']).format("MM-DD-YYYY HH:mm:ss");
            item['order_of_project']['display_update_date'] = displaydate;

            let updatedDate = moment(item['order_of_project']['updated_at']).format("MM/DD/YYYY HH:mm:ss");
            let diff = Math.abs(+new Date(updatedDate) - +new Date(currentDate)) / 1000;    
            const hours = Math.floor(+diff / 3600);
            item['order_of_project']['hours'] = hours;
            if(hours > 24) {
              item['order_of_project']['days'] = Math.floor(hours/24);
            }else {
              item['order_of_project']['days'] = 0;
            }
            
            item['order_of_project']['status_name'] = this.commonService.getStatusName(item['order_of_project']['status'], 'project_order');
          }

          (item.project_sticky_notes || []).forEach((note, index) => {
            item.project_sticky_notes[index]['display_update_date'] = moment(note['updated_at']).format("MM-DD-YYYY HH:mm:ss");
          });
          
          return item;
        });
        this.wordExportRecords = projects;

        setTimeout(() => {
          let header = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML to Word Document with JavaScript</title></head><body>";
          let footer = "</body></html>";
          let html = header+document.getElementById('word-export').innerHTML+footer;
          let blob = new Blob(['\ufeff', html], {
              type: 'application/msword'
          });
          
          // Specify link url
          let url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);
          
          // Specify file name
          let filename = 'projects.doc';
          
          // Create download link element
          let downloadLink = document.createElement("a");
      
          document.body.appendChild(downloadLink);
          
          if(navigator.msSaveOrOpenBlob ){
              navigator.msSaveOrOpenBlob(blob, filename);
          }else{
              // Create a link to the file
              downloadLink.href = url;

              // Setting the file name
              downloadLink.download = filename;
              
              //triggering the function
              downloadLink.click();
          }
          
          document.body.removeChild(downloadLink);
          this.loading = false;
        }, 700);
      }, (error) => {
        console.error(error);
        this.loading = false;
      });
    }, (error) => {
      console.error(error);
      this.loading = false;
    });
  }

  navigateTo(franchiseId, projectOROrderId, page = 'Project') {
    this.franchiseService.refreshFranchiseData(franchiseId).then(() => {});
    if (franchiseId) {
      this.loading = true;
      this.rolePermissionService.getPermissions(franchiseId).subscribe(res => {
        if (res) {
          this.rolePermissionService.permissions.next(res['data']['permissions']);
          this.rolePermissionService.unread.next(res['data']['unread']);
          this.rolePermissionService.notificationData.next(res['data']['notification']);
          this.rolePermissionService.projectsData.next(res['data']['projects']);
        }
        this.loading = false;
        if (page == 'Project') {
          this.router.navigateByUrl('/'+franchiseId+'/project/view/'+projectOROrderId);
        } else if (page == 'Sales Order') {
          this.router.navigateByUrl('/'+franchiseId+'/sales-order/view/'+projectOROrderId);
        }
      });
    }
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }
}