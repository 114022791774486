import {LocationStrategy} from '@angular/common';
import {Injectable} from '@angular/core';
import { RouteReuseStrategy, ActivatedRouteSnapshot, DetachedRouteHandle } from '@angular/router';

@Injectable()
export class CustomRouteReuseStrategy implements RouteReuseStrategy {
  isBackPressed = false;

  constructor(location: LocationStrategy) {
    location.onPopState(() => {
      // If user will click on back or next arrow of the window
      this.isBackPressed = true;
    });
  }

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    if (this.isBackPressed) return false;
    return route.routeConfig && route.routeConfig.data && route.routeConfig.data.reuseRoute;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    if (!this.isBackPressed && route.routeConfig && route.routeConfig.data && route.routeConfig.data.reuseRoute) {
      let url = this.getResolvedUrl(route);
      if (url && handle) {
        if (window['storedRoutes']) window['storedRoutes'][url] = handle;
        else {
          window['storedRoutes'] = {};
          window['storedRoutes'][url] = handle;
        };
      }
    }
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    if (this.isBackPressed) {
      this.isBackPressed = false;
      return false;
    }
    let url = this.getResolvedUrl(route);
    return !!route.routeConfig && !!(window['storedRoutes'] || {})[url];
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    if (this.isBackPressed || !route.routeConfig || route.routeConfig.loadChildren) return null;
    let url = this.getResolvedUrl(route);
    return (window['storedRoutes'] || {})[url];
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, current: ActivatedRouteSnapshot): boolean {
    return future.routeConfig === current.routeConfig;
  }

  getResolvedUrl(route: ActivatedRouteSnapshot): string {
    let url = route.pathFromRoot.map((v) => v.url.map((segment) => segment.toString()).join('/')).join('/');
    const queryParam = route.queryParamMap;
    if (queryParam.keys.length > 0) {
      url += '?' + queryParam.keys.map(key => queryParam.getAll(key).map(value => key + '=' + value).join('&')).join('&');
    }
    return url;
  }
}