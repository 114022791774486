import { Injectable, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, } from 'rxjs';
import { CommonService } from './common.service';


@Injectable({ providedIn: 'root' })
export class RolepermissionService {
  permissions = new BehaviorSubject([]);
  roledata = new BehaviorSubject({});
  unread = new BehaviorSubject({});
  notificationData = new BehaviorSubject({});
  projectsData = new BehaviorSubject([]);
  public permissionsExists = false;
  userArray: any = [];

  constructor(private _http: HttpClient,
    private _cs: CommonService) {
  }

  hasAllPermissions = (checkPermissions) => {
    checkPermissions = checkPermissions.split(',');
    const permissions = this.permissions.getValue();
    const result = checkPermissions.filter(function (checkPermission) {
      return permissions.includes(checkPermission);
    });
    return (result.length === checkPermissions.length);
  };

  hasAnyPermissions = (checkPermissions) => {
    checkPermissions = checkPermissions.split(',');
    const permissions = this.permissions.getValue();
    const result = checkPermissions.filter(function (checkPermission) {
      return permissions.includes(checkPermission);
    });
    return (result.length > 0);
  };

  hasPermission = (checkPermission: string) => {
    const permissions = this.permissions.getValue();
    return permissions.includes(checkPermission);
  };
  getRoleData = () => {
    return this._http.get(environment.API_URL + 'roles', this._cs.setHeader());
  };

  refreshRoleData = () => {
    this.getRoleData().subscribe(res => {
      if (res['data']) {
        this.roledata.next(res['data']);
      }
    });
  };

  getRolePermissionData = () => {
    return this._http.get(environment.API_URL + 'roles/permissions', this._cs.setHeader());
  };

  saveRolePermissionData = (rolePermissiondata) => {
    return this._http.post<any>(environment.API_URL + 'roles/permissions/save',
      { rolePermissiondata: rolePermissiondata }, this._cs.setHeader());
  };

  getPermissions = (franchise_id = '') => {
    return this._http.get(environment.API_URL + 'franchise/permissions',
      franchise_id ? { headers: { 'awi-franchise-id': franchise_id.toString() } } : this._cs.setHeader());
  };

  getDeveloperAdminPermission = () => {
    return this._http.get<any>(environment.API_URL + 'developer-admin/user-permissions');
  };

  refreshPermissions = (franchiseId = '') => {
    return new Promise((resolve, reject) => {
      this.getPermissions(franchiseId).subscribe(res => {
        if (res) {
          this.permissions.next(res['data']['permissions']);
          this.unread.next(res['data']['unread']);
          this.notificationData.next(res['data']['notification']);
          this.projectsData.next(res['data']['projects']);
          resolve(true);
        }
      });
    });
  }
}
