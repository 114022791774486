import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Subject } from 'rxjs';
import { CommonService } from './common.service';


@Injectable({ providedIn: 'root' })
export class DashboardService {

  constructor(private _http: HttpClient, private _cs: CommonService) {

  }
  getAllDashboardList = () => {
    return this._http.get(environment.API_URL + 'dashboard/demo_dashboard', this._cs.setHeader());
  }
  getDashboardViewList = (skip) => {
    return this._http.post(environment.API_URL + "dashboard/view_more", { skip: skip }, this._cs.setHeader());
  }

  // for accountant

  getPoAccountantDashboard = (skip) => {
    return this._http.post(environment.API_URL + 'dashboard/accountant-po', { skip }, this._cs.setHeader());
  }

  getOrdersAccountantDashboard = () => {
    return this._http.get(environment.API_URL + 'dashboard/accountant-orders', this._cs.setHeader());
  }

  getHubSpotDeals = () => {
    return this._http.get(environment.API_URL + 'dashboard/hub-spot-deals', this._cs.setHeader());
  }
  getAllDealsData = () => {
    return this._http.get(environment.API_URL + 'dashboard/hub-spot-deals-data', this._cs.setHeader());
  }
  getInoviceGraph = (graphType,salesRep = 0) => {
    salesRep = salesRep || 0;
    return this._http.get(environment.API_URL + 'dashboard/invoice_graph/' + graphType +'/' +salesRep, this._cs.setHeader());
  }
  getSalesGraph = (graphType,salesRep = 0) => {
    salesRep = salesRep || 0;
    return this._http.get(environment.API_URL + 'dashboard/sales_graph/' + graphType +'/' +salesRep, this._cs.setHeader());
  }
}