import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AccordionModule, BsDatepickerModule, CarouselModule, ModalModule, TooltipModule, TabsModule, TypeaheadModule, ProgressbarModule } from 'ngx-bootstrap';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgSelectModule } from '@ng-select/ng-select';
// import { NgxMaskModule, IConfig } from 'ngx-mask';
// import { TextMaskModule } from 'angular2-text-mask'; // test
import { StoragemanagerService } from './service/storagemanager.service';
import { DataTablesModule } from 'angular-datatables';
import { DatatableComponent } from './components/datatable/datatable.component';
import { ValidatorComponent } from './components/validator-component/validator.component';
import { ValidationService } from './service/validation.service';
import { CheckPermissionDirective } from './directives/check-permission.directive';
import { Utils } from './utils';
import { CardComponent } from './components/card-component/card-component.component';
import { ViewOrderComponent } from './views/custom-project/custom-project-estimates/custom-project-sales-order/view-order/view-order.component';
import { PurchaseOrderViewComponent } from './views/purchase-order/purchase-order-view/purchase-order-view.component';
import { CreateOrderComponent } from './views/custom-project/custom-project-estimates/custom-project-sales-order/create-order/create-order.component';
import { SupplierBillComponent } from './views/supplier/supplier-bill/supplier-bill.component';
import { ChartsModule } from 'ng2-charts';
import { ImagePreviewComponent } from './components/image-previes/image-preview.component';
import { StickyNotesBoardComponent } from './components/sticky-notes-board/sticky-notes-board.component';
import { CreateReceivableComponent } from './views/accounting/receivables/create-receivable/create-receivable.component';
import { CreatePayableComponent } from './views/accounting/payables/create-payable/create-payable.component';
import { NgxLoadingModule } from 'ngx-loading';
import { Daterangepicker } from 'ng2-daterangepicker';
import { InvoiceOrderComponent } from './views/custom-project/custom-project-estimates/custom-project-sales-order/invoice-order/invoice-order.component';
import { PurchaseOrderComponent } from './views/purchase-order/purchase-order.component';
import { PurchaseOrdersComponent } from './views/accounting/purchase-orders/purchase-orders.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
// import { ThousandsSeperatorDirective } from './directives/thousands-seperator.directive';  // test
import { AllBillsComponent } from './views/accounting/all-bills/all-bills.component';
import { ScrollTrackerDirective } from './directives/scroll-tracker.directive';
import { CustomProjectEstimateViewComponent } from './views/custom-project/custom-project-estimates/custom-project-estimate-view/custom-project-estimate-view.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OwlDateTimeFormats } from 'ng-pick-datetime';
import { OwlMomentDateTimeModule } from 'ng-pick-datetime-moment';
import { OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
import { DateFilterPipe } from './pipes/datefilter.pipe';
import { MdyToDmyDateFilterPipe } from './pipes/dmy-to-mdy-datefilter.pipe';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { QuoteFormsComponent } from './views/quote-forms/quote-forms.component';
import { FormFieldsComponent } from './views/quote-forms/form-fields/form-fields.component';
import { CalendarComponent } from './views/quote-forms/calendar/calendar.component';
import { BookletComponent } from './views/quote-forms/booklet/booklet.component';
import { EddmFormComponent } from './views/quote-forms/eddm-form/eddm-form.component';
import { AmountFormatePipe } from './pipes/amount-format.pipe';
import { PayMultipleComponent } from './views/accounting/receivables/pay-multiple/pay-multiple.component';
import { PayMultipleBillsComponent } from './views/accounting/payables/pay-multiple-bills/pay-multiple-bills.component';
import { NgxCurrencyInputModule } from 'ngx-currency-input';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { MyCurrencyFormatterDirective } from "./directives/my-currency-formatter.directive";
import { MyCurrencyPipe } from "./directives/my-currency.pipe";
import { PhoneMaskDirective } from './directives/phone-mask.directive'; 
import { PhonePipe } from './pipes/phone.pipe';
import { ProjectHistoryComponent } from './views/project-history/project-history.component';
import { UserContactCreateComponent } from './views/user-contacts/user-contact-create/user-contact-create.component';
import { ContactCompanyCreateComponent } from './views/user-contacts/contact-company-create/contact-company-create.component';
import { ContactTransferComponent } from './views/user-contacts/contact-transfer/contact-transfer.component';
import { PaymentHistoryComponent } from './views/payments/payment-history/payment-history.component';

export const MY_MOMENT_DATE_TIME_FORMATS: OwlDateTimeFormats = {
  parseInput: 'MM/DD/YYYY',
  fullPickerInput: 'l LT',
  datePickerInput: 'MM/DD/YYYY',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    HttpClientModule,
    DataTablesModule,
    NgxCurrencyInputModule,
    NgCircleProgressModule,
    ModalModule.forRoot(),
    // NgxMaskModule.forRoot(),
    // TextMaskModule,
    ProgressbarModule.forRoot(),
    AccordionModule.forRoot(),
    CarouselModule.forRoot(),
    TooltipModule.forRoot(),
    BsDatepickerModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    NgMultiSelectDropDownModule.forRoot(),
    NgSelectModule,
    TabsModule.forRoot(),
    TypeaheadModule.forRoot(),
    ChartsModule,
    Daterangepicker,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    OwlMomentDateTimeModule,
    AutocompleteLibModule
  ],
  exports: [
    CommonModule,
    NgxLoadingModule,
    ProgressbarModule,
    FormsModule,
    Daterangepicker,
    ReactiveFormsModule,
    HttpClientModule,
    BsDatepickerModule,
    ModalModule,
    TooltipModule,
    DataTablesModule,
    AccordionModule,
    RouterModule,
    DatatableComponent,
    ValidatorComponent,
    CardComponent,
    ViewOrderComponent,
    CreateOrderComponent,
    // ThousandsSeperatorDirective, // test
    PurchaseOrdersComponent,
    AllBillsComponent,
    SupplierBillComponent,
    PurchaseOrderViewComponent,
    CheckPermissionDirective,
    ScrollTrackerDirective,
    ClickOutsideDirective,
    NgMultiSelectDropDownModule,
    NgSelectModule,
    TabsModule,
    TypeaheadModule,
    CreateReceivableComponent,
    CreatePayableComponent,
    PayMultipleComponent,
    PayMultipleBillsComponent,
    ImagePreviewComponent,
    StickyNotesBoardComponent,
    CustomProjectEstimateViewComponent,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    OwlMomentDateTimeModule,
    DateFilterPipe,
    MdyToDmyDateFilterPipe,
    AutocompleteLibModule,
    QuoteFormsComponent,
    FormFieldsComponent,
    CalendarComponent,
    BookletComponent,
    EddmFormComponent,
    AmountFormatePipe,
    MyCurrencyFormatterDirective,
    MyCurrencyPipe,
    PhoneMaskDirective,
    PhonePipe,
    ProjectHistoryComponent,
    UserContactCreateComponent,
    ContactCompanyCreateComponent,
    ContactTransferComponent,
    PaymentHistoryComponent
  ],
  declarations: [
    DatatableComponent,
    ValidatorComponent,
    CardComponent,
    // ThousandsSeperatorDirective, // test
    CheckPermissionDirective,
    ScrollTrackerDirective,
    ClickOutsideDirective,
    ViewOrderComponent,
    InvoiceOrderComponent,
    AllBillsComponent,
    CreateOrderComponent,
    SupplierBillComponent,
    PurchaseOrdersComponent,
    PurchaseOrderViewComponent,
    ImagePreviewComponent,
    StickyNotesBoardComponent,
    CreateReceivableComponent,
    CreatePayableComponent,
    PayMultipleComponent,
    PayMultipleBillsComponent,
    CustomProjectEstimateViewComponent,
    QuoteFormsComponent,
    FormFieldsComponent,
    CalendarComponent,
    BookletComponent,
    DateFilterPipe,
    MdyToDmyDateFilterPipe,
    AmountFormatePipe,
    EddmFormComponent,
    MyCurrencyFormatterDirective,
    MyCurrencyPipe,
    PhoneMaskDirective,
    PhonePipe,
    ProjectHistoryComponent,
    UserContactCreateComponent,
    ContactCompanyCreateComponent,
    ContactTransferComponent,
    PaymentHistoryComponent
  ],
  providers: [
    StoragemanagerService,
    ValidationService,
    Utils,
    MyCurrencyPipe,
    PhonePipe,
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_DATE_TIME_FORMATS },
  ]
})
export class SharedModule { }