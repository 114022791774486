import { Component, OnInit } from '@angular/core';
import { Utils } from '../../../../utils';
import { CommonService } from '../../../../service/common.service';
import { ActivatedRoute } from '@angular/router';
import { ProjectService } from '../../../../service/project.service';
import { FormGroup, FormBuilder, FormArray, FormControl} from '@angular/forms';
import { SupplierService } from '../../../../service/supplier.service';
import { DigitalService } from '../../../../service/digital.service';

@Component({
  templateUrl: 'supplier-digital-public-estimates.component.html',
  styleUrls: ['./supplier-digital-public-estimates.component.scss']
})

export class SupplierDigitalPublicEstimatesComponent implements OnInit {
  products = [];
  status: any = {
    loading: false,
    success: false,
    error: false
  };

  // for view and edit quote
  projectData = {};
  franchiseLogo = '';
  loading = false;
  item: any = 'item0'
  api_error = {};
  total: any = 0;
  frenchiseId: any = undefined;
  printQuote = {};
  iisa_quote = {};
  additionalQuantities = [];
  iisa_additionalQuantities = [];

  projectId: any = undefined
  digitalProductForm: FormGroup;
  filesArr: any = [];
  docArr: any = [];
  oldDocArr: any = [];
  checkExist: boolean = false;
  supplierId: any = undefined
  supplierEditData: any = undefined
  edit: boolean = false;
  supplierBidEditId: any = undefined
  services:any = []
  selectedProducts : any = []
  selectedService:any = []

  // end
  constructor(
    private utils: Utils,
    private supplierService: SupplierService,
    public commonService: CommonService,
    private _activatedRoute: ActivatedRoute,
    private projectService: ProjectService,
    private _fb: FormBuilder,
    private digitalService : DigitalService) {}

  ngOnInit() {
    this.status = this.utils.showContainers(this.status, 'loading');
    this.digitalService.getDigitalServices().subscribe(res => {
      this.services = res['data']['original']['data']
      this.status = this.utils.showContainers(this.status, 'success');
      if(this.services.length > 0){
        this._activatedRoute.params.subscribe(
          (params) => {
            if (params['franchise_id'] && params['project_id']) {
              try { this.frenchiseId = atob(params["franchise_id"]); }
              catch (e) { this.frenchiseId = atob( decodeURIComponent(params["franchise_id"]) ); }

              try { this.projectId = atob(params["project_id"]); }
              catch (e) { this.projectId = atob( decodeURIComponent(params["project_id"]) ); }

              try { this.supplierId = atob(params["supplier_id"]); }
              catch (e) { this.supplierId = atob( decodeURIComponent(params["supplier_id"]) ); }
              
              this.filesArr = []
              this.docArr = []
              this.oldDocArr = []
              
              this.getProjectDetails(this.projectId, this.frenchiseId);
              
              this.initProductForm()
            }
          }
        );
      }
    })
  }

  initProductForm = () => {
    this.digitalProductForm = this._fb.group({
      id:[''],
      notes: [''],
      bid_doc: [''],
      theme_type : [''],
      description:[''],
      digital_services_category_id: [''],
      digital_services_name:[''],
      project_type:['4'],
      client_audit: [''],
      problem: [''],
      project_name:[''],
      website_domain:[''],
      suggestedartwork:[''],
      contact_id:[''],
      sales_rep_id:[''],
      opportunity: [''],
      what_can_optamark_do: [''],
      specification: this._fb.array([]),
      estimation_info: this._fb.group(this.initBuyoutInfo()),
      remove_specs: [],
      remove_items: [],
      project_id: "",
      old_supplier_files: [],
      old_suggested_artwork: []
    });
  }

  initBuyoutInfo = () => {
    return {
      id: [''],
      project_name: [''],
      quote_received_date: [new Date()],
      order_due_date: [new Date()],
      tax_type_id: [''],
      discount_type: ['1'],
      discount_value: [''],
      supplier_files: [''],
      suggestedartwork:[''],
    };
  }

  initSpecification = (data = []) => {
    return this._fb.group({
      id: [data['id']],
      item_spec: [data['item_spec']],
      item_description: [data['item_description']],
      supplier: [data['supplier']],
      supplier_name: [data['supplier_name']],
      supplier_id: [data['supplier_id'] ? data['supplier_id'] : ''],
      // item_detail: this._fb.array([this.initSpecificationDetail()])
      item_detail: this._fb.array([]),
      digital_service_category_id  : [''],
      digital_category_id:[data['digital_category_id'] ? data['digital_category_id']:''],
    });
  }

  initSpecificationDetail = (data = []) => {
    return this._fb.group({
      id: [data['id']],
      digital_service_name  : [data['digital_service_name'] ? data['digital_service_name'] : ''],
      item_description : [data['item_description'] ? data['item_description']:''],
      item_spec:[data['item_spec']?data['item_spec']:''],
      digital_service_id : [data['digital_service_id'] ? data['digital_service_id'] : ''],
      digital_category_id:[data['digital_category_id']? data['digital_category_id']:''],
      turnaround_time: [data['turnaround_time'] ? data['turnaround_time'] : ''],
      shipping_included: [data['shipping_included'] !== undefined ? data['shipping_included'] : ''],
      quantity: [data['quantity'] > 0 ? data['quantity'] : '1'],
      total_cost: [(data['total_cost'] > 0) ? Number(data['total_cost']).toFixed(2) : '0.00'],
      markup: [(data['markup']) ? Number(data['markup']).toFixed(2) : ''],
      markup_amount: [(data['markup_amount'] || data['markup_amount'] == 0) ? Number(data['markup_amount']).toFixed(2) : '0.00'],
      shipping_cost: [(data['shipping_cost']) ? Number(data['shipping_cost']).toFixed(2) : '0.00'],
      shipping_markup: [(data['shipping_markup']) ? Number(data['shipping_markup']).toFixed(2) : '0.00'],
      shipping_markup_amount: [(data['shipping_amount'] || data['shipping_amount'] == 0) ? Number(data['shipping_amount']).toFixed(2) : '0.00'], //shipping_markup_amount
      // shipping_price: [data['shipping_price']],
      price: [(data['price']) ? Number(data['price']).toFixed(2) : '0.00'],
      price_per_piece: [((data['price_per_piece'])) ? Number(data['price_per_piece']).toFixed(2) : '0.00'],
      tax_applicable: [data['tax_applicable'] !== undefined ? data['tax_applicable'] : ''],
      tax: [data['tax']],
      discount_allowed: [data['discount_allowed']],
      discount_type: [data['discount_type'] ? data['discount_type'] : '1'],
      discount_value: [(data['discount_value']) ? Number(data['discount_value']).toFixed(2) : '0.00'],
      tax_type_id: [{
        value: data['tax_type_id'] ? data['tax_type_id'] : '',
        disabled: data['tax_applicable'] ? false : true
      }],
      tax_value: [(data['tax_value']) ? Number(data['tax_value']).toFixed(2) : '0.00'],
      total_data: this._fb.group({
        discount_amount: ['0.00'],
        tax_value: ['0.00'],
        total_shipping_charge: ['0.00'],
        total_price: ['0.00']
      }),
      discount: [(data['discount_amount']) ? Number(data['discount_amount']).toFixed(2) : '0.00'],
      shipping_price: [Number(+data['total_shipping_charge']).toFixed(2)],
      total_order_cost: [((data['total_cost'] && data['shipping_cost']) ? (((Number(data['shipping_cost']) + Number(data['total_cost'])).toFixed(2))) : (data['total_cost']))],
      cost_per_piece: [((data['total_cost'] && data['quantity']) ? ((Number(data['total_cost']) / Number(data['quantity'])).toFixed(2)) : '')],
      tax_type: [(data['tax_value']) ? Number(data['tax_value']).toFixed(2) : ''],
      shipping_included_markup: [((data['shipping_markup_amount'] !== undefined) ? (data['shipping_markup_amount'] !== '' && data['shipping_markup_amount'] > 0) ? (0) : (1) : (''))],
      est_quantity: [data['quantity']],
      shipping_amount: [Number(data['shipping_amount']).toFixed(2)]
    });
  }

  addItem = () => {
    const control = <FormArray>this.digitalProductForm.get('specification');
    control.push(this.initSpecification());
    const pushed = control.length - 1;
    const detailControl = <FormArray>this.digitalProductForm.get('specification')['controls'][pushed].get('item_detail');
    detailControl.push(this.initSpecificationDetail());
  }

  addItemDetails = (i) => {
    const control = <FormArray>this.digitalProductForm.get('specification')['controls'][i].get('item_detail');
    control.push(this.initSpecificationDetail());
  }
  
  initSpecName = () => {
    const controlGroup = this.digitalProductForm.get('specification');
    let checkFlag = false;
    controlGroup['controls'].map((spec,index) => {
      let itemControls = spec.get('item_detail')
    })
  }

  validatePriceTotalCost = (control: FormControl) => {
    if (+control.get('price').value < +control.get('total_cost').value
      && !control.get('discount_allowed').value) {
      control.get('price').setErrors({ min: 'price must be greater than or equal to total cost' });
    } else {
      control.get('price').setErrors(null);
    }
    this.deriveTotalAmounts(control);
  }

  onPriceChanges = (control: FormControl, changePerPiece = true) => {
    if (Number(control.get('price').value) > 0) {
      this.validatePriceTotalCost(control);
      const totalCost = (Number(control.get('total_cost').value) > 0 ? Number(control.get('total_cost').value) : 0);
      const markUpAmount = Number(control.get('price').value) - totalCost;
      if (markUpAmount > 0) {
        const markupPer = (markUpAmount * 100) / (totalCost > 0 ? totalCost : 1);
        control.get('markup').setValue(Number(+markupPer).toFixed(2));
        control.get('markup_amount').setValue(Number(+markUpAmount).toFixed(2));
      } else {
        control.get('markup').setValue('');
        control.get('markup_amount').setValue('');
      }
      if (changePerPiece && +control.get('quantity').value > 0) {
        const pricePerPiece = Number(control.get('price').value) / +control.get('quantity').value;
        control.get('price_per_piece').setValue(Number(+pricePerPiece).toFixed(2));
      }
    } else {
      control.get('markup').setValue('');
      control.get('markup_amount').setValue('');
      this.deriveTotalAmounts(control);
    }
  }

  onPricePerPieceChange = (control: FormControl) => {
    if (+control.get('quantity').value > 0) {
      const price = Number(+control.get('price_per_piece').value) * Number(+control.get('quantity').value);
      const total_cost = Number(+control.get('cost_per_piece').value) * Number(+control.get('quantity').value);
      control.get('total_cost').setValue(Number(+total_cost).toFixed(2));
      if (control.get('shipping_cost').value > 0) {
        let total_order_cost = total_cost + Number(+control.get('shipping_cost').value);
        control.get('total_order_cost').setValue(Number(+total_order_cost).toFixed(2));
      } else {
        control.get('total_order_cost').setValue(Number(+total_cost).toFixed(2));
      }
      control.get('price').setValue(Number(+price).toFixed(2));
      this.onPriceChanges(control, false);
    } else {
      control.get('price').setValue('0.00');
      control.get('total_cost').setValue('0.00');
      control.get('markup_amount').setValue('');
      control.get('total_order_cost').setValue('0.00');
      this.deriveTotalAmounts(control);
    }
  }

  onMarkupChange = (control: FormControl, markupPercentChange = true) => {
    if (!markupPercentChange || Number(+control.get('markup').value) > 0) {
      if (markupPercentChange) {
        const markupAmount = (Number(+control.get('markup').value) * Number(+control.get('total_cost').value) / 100);
        control.get('markup_amount').setValue(Number(+markupAmount).toFixed(2));
      } else {
        if ((control.get('total_cost').value) > 0) {
          const markupPer = ((Number(+control.get('markup_amount').value) * 100) / Number(+control.get('total_cost').value));
          control.get('markup').setValue(Number(+markupPer).toFixed(2));
        }
      }
      if ((control.get('total_cost').value) > 0) {
        const finalPrice = ((+control.get('markup').value) * (Number(+control.get('total_cost').value)) / 100) + (Number(+control.get('total_cost').value));
        control.get('price').setValue(Number(+finalPrice).toFixed(2));
      }
    } else {
      control.get('markup_amount').setValue('0.00');
      control.get('price').setValue(Number(+control.get('total_cost').value).toFixed(2));
    }

    if (+control.get('quantity').value !== 0) {
      const price_per_piece = Number(+control.get('price').value) / Number(+control.get('quantity').value);
      control.get('price_per_piece').setValue(Number(+price_per_piece).toFixed(2));
    } else {
      control.get('price_per_piece').setValue('');
    }
    this.deriveTotalAmounts(control);
  }

  getTotalDataByKey = (controlGroup, key) => {
    let value = controlGroup.get('total_data').get(key).value;
    return Number(+value).toFixed(2);
  }

  deriveTotalAmounts = async (controlGroup: any = {}) => {
    if (Object.keys(controlGroup).length > 0) {
      let discountAmount = 0;
      let total = 0;

      const totalDataGroup = controlGroup.get('total_data');
      const originalPrice = +controlGroup.get('price').value;

      const discountValue = +controlGroup.get('discount_value').value;
      if (discountValue > 0) {
        if (+controlGroup.get('discount_type').value === 1) {
          // calculate by percent
          discountAmount += (discountValue * originalPrice) / 100;
        } else {
          // calculate by value
          discountAmount += discountValue;
        }
      }

      total = originalPrice - discountAmount 
      controlGroup.get('discount').setValue(Number(+discountAmount).toFixed(2));
      totalDataGroup.get('discount_amount').setValue(Number(+discountAmount).toFixed(2));
      totalDataGroup.get('total_price').setValue(Number(+total).toFixed(2));
    }
  }
  SaveData = () => {
    let pData = { ...this.digitalProductForm.value };
    pData['digital_services_name'] = this.selectedProducts
    pData['digital_services_category_id']= this.selectedService
    this.status = this.utils.showContainers(this.status, 'loading');
    pData['bid_doc'] = this.oldDocArr;
    pData['project_id'] = this.projectData['id'];
    pData['project_supplier_id'] = this.supplierId;
    pData['sales_rep_id'] = this.projectData['sales_rep']['id'];
    pData['edit'] = this.edit;
    if (this.edit) {
      pData['editbid'] = this.supplierBidEditId;
    }
    const fData: FormData = new FormData;
    if (this.filesArr.length > 0) {
      for (const file of this.filesArr) {
        if (file['file']) {
          fData.append('files[]', file['file']);
        }
      }
    }
    else if (this.filesArr.length == 0) {
      fData.append('files[]', '');
    }
    fData.append('pData', JSON.stringify(pData));
    
    this.status = this.utils.showContainers(this.status, 'loading');
    this.loading = true;
    this.supplierService.saveEstimation(fData, this.frenchiseId).subscribe(res => {
     
     if(res){
      this.checkExist = true;
     }
      this.loading = false;
      this.status = this.utils.showContainers(this.status, 'success');
    }, error => {
      this.loading = false;
      this.status = this.utils.showContainers(this.status, 'success');
      if (error['error']) {
        this.api_error = error['error']['data'];
      }
    });
  }
 
  getProjectDetails = (projectId, franchiseId) => {
    this.status = this.utils.showContainers(this.status, 'loading');
    this.projectService.getProDetailsForSupplier(projectId, franchiseId).subscribe(res => {
      this.loading = true
      this.projectData = res['data'];
      if(this.projectData['status'] != 12){
        this.checkExist = true;
      }
      const data = res['data']
      var arrayKeys= data['digital_quote']['digital_services_category_id'].split(',');
      arrayKeys.map((key)=> {
        if(this.services.length > 0){
          this.services.map((ser) => {
            if(key == ser['id']){
              this.addItem();
              const control = <FormArray>this.digitalProductForm.get('specification');
              const pushed = control.length - 1;
              const itemControl = this.digitalProductForm.get('specification')['controls'][pushed];
              const itemControlDetail = this.digitalProductForm.get('specification')['controls'][pushed].get('item_detail')['controls'][0];
              itemControlDetail.get('item_spec').setValue('custom')
              itemControlDetail.get('item_description').setValue('custom')
              itemControlDetail.get('digital_category_id').setValue(ser['id'])
              itemControlDetail.get('digital_service_name').setValue('Custom')
            
              itemControl.get('item_description').setValue(ser['name'])
              itemControl.get('digital_service_category_id').setValue(ser['id'])
              itemControl.get('item_spec').setValue(ser['name'])
              itemControl.get('digital_category_id').setValue(ser['id'])
              this.selectedProducts.push(ser['name'])
              this.selectedService.push(ser['id'])
            }
          })
        }
      })
          
      if (this.projectData['bids'].length) {
        for (let i = 0; i < this.projectData['bids'].length; i++) {
          if (this.projectData['bids'][i]['project_supplier_id'] == this.supplierId && this.projectData['bids'][i]['status'] != 1  || this.projectData['status'] == 12) {
            this.edit = true;
            this.supplierBidEditId = this.projectData['bids'][i]['id'];
            this.supplierEditData = this.projectData['bids'][i];
            // this.supplierBidForm.get('notes').setValue(this.supplierEditData['notes'])
            this.docArr = this.supplierEditData['bid_doc'] ? this.supplierEditData['bid_doc'] : [];
            this.oldDocArr = this.supplierEditData['bid_doc'] ? [...this.supplierEditData['bid_doc']] : [];

            this.docArr.forEach((value, keyDoc) => {
              this.filesArr[keyDoc] = [];
              
              const pathValue = this.commonService.checkImageTypeAws(value, 'bid_images', false, false, this.frenchiseId);
              if (!pathValue) {
                this.filesArr[keyDoc]['url'] = pathValue + value;
              } else {
                this.filesArr[keyDoc]['url'] = pathValue;
              }
            });
          }
          else if (this.projectData['bids'][i]['project_supplier_id'] == this.supplierId && this.projectData['bids'][i]['status'] == 1) {
            // this.checkExist = true;
          }
        }
      }
      const logo = this.commonService.UPLOAD_FILE_AWS('franchise_images', false, franchiseId);
      this.franchiseLogo = logo + this.projectData['franchise_logo'];
      this.status = this.utils.showContainers(this.status, 'success');
      this.loading = false
    }, (error) => {
      this.status = this.utils.showContainers(this.status, 'success');
    })
  }

  uploadDoc = (event: any) => {
    const files = event.target.files;
    let i = this.filesArr.length;
    for (const file of files) {
      const ext = file['name'].substring(file['name'].lastIndexOf('.') + 1).toLowerCase();
      let flag = true;
      this.docArr.forEach((value) => {
        if (value === file.name) {
          flag = false;
        }
      });
      if (flag) {
        this.docArr.push(file.name);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event1: Event) => {
          this.filesArr[i] = {};
          this.filesArr[i]['file'] = file;
          const pathValue = this.commonService.getLogoUrl(ext);
          if (!pathValue) {
            this.filesArr[i]['url'] = event1.target['result'];
          } else {
            this.filesArr[i]['url'] = pathValue;
          }
          i++;
        };
      }
    }
  }
  
  removeDoc = (doc, i) => {
    this.filesArr.splice(i, 1);
    this.docArr.forEach((value, key) => {
      if (key === +i) {
        this.docArr.splice(key, 1);
      }
    });
    this.oldDocArr.forEach((value, key) => {
      if (key === +i) {
        this.oldDocArr.splice(key, 1);
      }
    });
  }
}
