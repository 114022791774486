import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../service/authentication.service';
import { StoragemanagerService } from '../../../service/storagemanager.service';
import { FranchiseService } from '../../../service/franchise.service';
import { CommonService } from '../../../service/common.service';
import { navItems } from '../../../_nav';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;

  ngOnInit() {
    this.initLoginForm();
    this.redirectToDashboard();
  }

  constructor(
    private _fb: FormBuilder,
    private router: Router,
    private authService: AuthenticationService,
    private franchiseService: FranchiseService,
    public _cs: CommonService
  ) {
  }

  initLoginForm = () => {
    this.loginForm = this._fb.group({
      email_or_username: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });
  }

  onLogin = () => {
    if (this.loginForm.valid) {
      const credential = this.loginForm.value;
      this.loading = true;
      this.authService.login(credential).subscribe((res: any) => {
        if (res.data) {
          this.loading = false;
          StoragemanagerService.storeToken(res.data.access_token);
          this.authService.userData.next(res.data.user);
          this.redirectAfterLogin();
        }
      }, error => {
        this.loading = false;
      });
    }
  }

  redirectAfterLogin = () => {
    const userData = this.authService.userData.getValue();
    if (userData['roles'] && userData['roles'].length > 0) {
      if (userData['roles'][0]['id'] === 1) {
        StoragemanagerService.setStorage('designation', 'da');
        this.router.navigate(['/dashboard']);
      } else {
        if (userData['is_supplier']) {
          this.franchiseService.franchiseOptions.next(userData['franchises']);
          this.router.navigate(['supplier', 'dashboard']);
        } else {
          if (userData['franchises']) {
            const franchiseArray = [];
            for (const franchise of userData['franchises']) {
              franchiseArray.push(franchise['id']);
            }
            localStorage.setItem('franchise_ids', JSON.stringify(franchiseArray));
            if (Object.keys(userData['franchises']).length > 1) {
              this.router.navigate(['account-selection']); //franchise-selection
            } else {
              this.franchiseService.franchiseData.next(userData['franchises'][0]);
              this.router.navigate([userData['franchises'][0]['id'], 'dashboard']);
            }
          }
        }
      }
    }
  }

  redirectToDashboard = () => {
    if (localStorage.getItem('token')) {
      if (localStorage.getItem('designation')) {
        this.router.navigate(['dashboard']);
      } else {
        const franchises = JSON.parse(localStorage.getItem('franchise_ids'));
        if (franchises) {
          this.router.navigate(['account-selection']); //franchise-selection
        } else {
          this.router.navigate(['supplier', 'dashboard']);
        }
      }
    }
  }

}
