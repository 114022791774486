import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { ProjectService } from '../../service/project.service';
import { CommonService } from '../../service/common.service';
import { NgSelectModule, NgOption, NgSelectComponent } from '@ng-select/ng-select';
import { FranchiseService } from '../../service/franchise.service';
import { UserService } from '../../service/user.service';
import { AuthenticationService } from '../../service/authentication.service';
import { SalesOrderService } from '../../service/sales-order.service';
import { TaxRatesService } from '../../service/tax-rates.service';
import { AppConstants } from '../../constants/app.constants';
import { ModalDirective } from 'ngx-bootstrap';
import { DatePipe } from '@angular/common';
import { ViewEncapsulation } from '@angular/core';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import { ToasterService } from 'angular2-toaster';
declare var $;

@Component({
  templateUrl: './project-history.component.html',
  styleUrls: ['./project-history.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProjectHistoryComponent implements OnInit {
  constructor(
    private projectService: ProjectService,
    public commonService: CommonService,
    private franchiseService: FranchiseService,
    private userService: UserService,
    private authService: AuthenticationService,
    private salesOrderService: SalesOrderService,
    private _taxService: TaxRatesService,
    private _fb: FormBuilder,
    private route: Router,
    private datePipe: DatePipe,
    private _toast: ToasterService
  ) { }

  loading = false;
  franchiseId = '';
  currentURL = '';
  component = 'Project History'; // It can be Project, Archive Project or Sales Order
  pageSize = [10, 25, 50, 100]
  tableData: any = {
    length: 25,
    start: 0,
    search: {
      value: '',
      regex: false
    },
    order: []
  };

  currentUserId: any = null;
  project_history = [];
  salesRepList: any = [];
  sales_rep_id: any = null;
  estimatorList: any = [];
  estimator_id: any = null;
  csrList: any = [];
  csr_user_id: any = null;
  contactList: any = [];
  contact_id: any = null;
  selectedTab = 'Orders';
  projectStatuses: any = {};
  project_status_id: any = [];
  orderStatuses: any = {};
  order_status_id: any = [];
  //select_order_status: any = [];
  //Dropdwon and value for project history -> Project
  allProjectStatus = [
    { id: 1, name: 'Project Started' },
    { id: 2, name: 'Estimate Created, Under Review' },
    { id: 3, name: 'Estimate Approved, Ready to Send' },
    { id: 4, name: 'Won - Converted to Order' },
    { id: 6, name: 'Project Rejected' },
    { id: 7, name: 'Request for Change to Estimate - Internal' },
    { id: 10, name: 'Awaiting Accountant Approval' },
    { id: 11, name: 'Order Placed' },
    { id: 12, name: 'Request Sent to Supplier' },
    { id: 14, name: 'Estimate Sent to Customer' },
    { id: 15, name: 'Project Lost' },
    { id: 16, name: 'Request for Change to Estimate by Customer' },
    { id: 17, name: 'Estimate Approved by Customer' },
    { id: 18, name: 'Estimate Updated by Supplier' },
    { id: 19, name: 'Revise Estimate Created Under Review' },
    { id: 20, name: 'Project Assigned to Estimator' },
    { id: 21, name: 'Revised Estimate Sent to Customer' },
  ];
  select_project_status_project_history_project: any = [1, 2, 3, 4, 6, 7, 10, 11, 12, 14, 15, 16, 17, 18, 19, 20, 21];
    
//Dropdwon and value for project history -> order
allOrderStatus = [
      { id: 1, name: 'Created' },
      { id: 2, name: 'In Progress' },
      { id: 3, name: 'Completed' },
      { id: 6, name: 'On Hold' },
      { id: 7, name: 'Shipped' },
      { id: 8, name: 'Canceled' },
      { id: 10, name: 'Under Review' },
      { id: 11, name: 'Initiated' },
      { id: 12, name: 'Pre Press' },
      { id: 13, name: 'Proof Approved' },
      { id: 14, name: 'In Production' },
      { id: 15, name: 'Partially Shipped' },
      ];
  select_order_status_project_history_order: any = [1, 2, 3, 6, 7, 8, 10, 11, 12, 13, 14, 15];
  
  filteredStatusIds: any = null; // Require while loading Project, Archive Project or Sales Order listing page
  userData: any = {};
  isAdmin = false;
  isAccountant = false;
  isCSR = false;
  isSalesRep = false;
  isEstimator = false;
  
  //Dropdwon and value for sales order view
  orderStatusForSalesOrderView = [
    { id: 1, name: 'Created' },
    { id: 2, name: 'In Progress' },
    { id: 6, name: 'On Hold' },
    { id: 7, name: 'Shipped' },
    { id: 10, name: 'Under Review' },
    { id: 11, name: 'Initiated' },
    { id: 12, name: 'Pre Press' },
    { id: 13, name: 'Proof Approved' },
    { id: 14, name: 'In Production' },
    { id: 15, name: 'Partially Shipped' },
    ];
  select_order_status_sales_order: any = [1, 2, 6, 7, 10, 11, 12, 13, 14, 15];
  //Dropdwon and value for project view
  projectStatusForProjectView = [
    { id: 1, name: 'Project Started' },
    { id: 2, name: 'Estimate Created, Under Review' },
    { id: 3, name: 'Estimate Approved, Ready to Send' },
    { id: 6, name: 'Project Rejected' },
    { id: 7, name: 'Request for Change to Estimate - Internal' },
    { id: 10, name: 'Awaiting Accountant Approval' },
    { id: 12, name: 'Request Sent to Supplier' },
    { id: 14, name: 'Estimate Sent to Customer' },
    { id: 16, name: 'Request for Change to Estimate by Customer' },
    { id: 17, name: 'Estimate Approved by Customer' },
    { id: 18, name: 'Estimate Updated by Supplier' },
    { id: 19, name: 'Revise Estimate Created Under Review' },
    { id: 20, name: 'Project Assigned to Estimator' },
    { id: 21, name: 'Revised Estimate Sent to Customer' },
  ];
  select_project_status_project : any = [1, 2, 3,  6, 7, 10,  12, 14, 16, 17, 18, 19, 20, 21];

  options: any = {
    locale: { format: 'MM/DD/YYYY' },
    alwaysShowCalendars: false,
    startDate: moment().startOf('year'),
    endDate: moment().endOf('year'),
    ranges: {
      'Today': [moment(), moment()],
      'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'This Week': [moment().startOf('week'), moment().endOf('week')],
      'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
      'This Year': [moment().startOf('year'), moment().endOf('year')],
      'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
      'All': [moment("2019-01-01"), moment()] //moment().subtract(3, 'year').startOf('year')

    }
  };
  searchControl = new FormControl();
  date_filter: any = {
    from: moment().startOf('year').format(),
    to: moment().endOf('year').format(),
    filter_by: 'created'
  };

  currentPage = 1;
  recordsFiltered = 0;
  paginationInfo = '';

  api_error = {}
  @ViewChild('stickyNotesBoardModel') public stickyNotesBoardModel: ModalDirective;
  @ViewChild('createStickyNoteModel') public createStickyNoteModel: ModalDirective;

  private selectProjectStatusProjectHistoryProject: NgSelectComponent;
  @ViewChild('selectProjectStatusProjectHistoryProject') set setProjectStatusProjectHistoryProject(content: NgSelectComponent) {
    if (content) { // initially setter gets called with undefined
      this.selectProjectStatusProjectHistoryProject = content;
    }
  }

  private selectProjectStatusProject: NgSelectComponent;
  @ViewChild('selectProjectStatusProject') set setProjectStatusProject(content: NgSelectComponent) {
    if (content) { // initially setter gets called with undefined
      this.selectProjectStatusProject = content;
    }
  }

  private selectOrderStatusSalesOrder: NgSelectComponent;
  @ViewChild('selectOrderStatusSalesOrder') set setOrderStatusSalesOrder(content: NgSelectComponent) {
    if (content) { // initially setter gets called with undefined
      this.selectOrderStatusSalesOrder = content;
    }
  }

  private selectOrderStatusProjectHistoryOrder: NgSelectComponent;
  @ViewChild('selectOrderStatusProjectHistoryOrder') set setOrderStatusProjectHistoryOrder(content: NgSelectComponent) {
    if (content) { // initially setter gets called with undefined
      this.selectOrderStatusProjectHistoryOrder = content;
    }
  }

  showStickyNotesBoard: boolean = false;
  stickyNotesBoardLoader: boolean = false;
  currentStickyBoardProjectId: any;
  projectStickyNotesData: any = [];
  stickyNoteForm: FormGroup = this._fb.group({
    id: [''],
    content: [''],
    project_id: ['']
  });

  @ViewChild('invoiceModal') invoiceModal: ModalDirective;
  invoiceCreateForm: FormGroup;
  project_order_type = '';
  taxTypes: any = [];
  isEmailSend: any = 0;
  showInvoiceModel: boolean = false;
  termsList = [{ 'id': 1, 'name': 'On Receipt' }, { 'id': 7, 'name': "7" }, { 'id': 15, 'name': "15" }, { 'id': 30, 'name': "30" }, { 'id': 45, 'name': "45" }, { 'id': 60, 'name': "60" }, { 'id': 75, 'name': "75" }, { 'id': 90, 'name': "90" }];

  // For update order status
  statusValuePopup: any = null;
  idValuePopup: any = null;
  updateStatusEmailSend: boolean = false;
  receivedLatestUpdateStatusCallResponse: boolean = false;

  async ngOnInit() {
    this.selectAllForDropdownItemsProjectHistoryOrder(this.allOrderStatus);
    this.selectAllForDropdownItemsProjectHistoryProject(this.allProjectStatus);
    this.selectAllForDropdownItemsSalesOrder(this.orderStatusForSalesOrderView);
    this.selectAllForDropdownItemsProjects(this.projectStatusForProjectView);
    
    this.franchiseId = this.commonService.currentFranchise();
    this.currentURL = window.location.href;
    if (this.currentURL.endsWith('archive-project')) {
      this.component = 'Archive Project';
    } else if (this.currentURL.endsWith('project')) {
      this.component = 'Project';
    } else if (this.currentURL.endsWith('sales-order')) {
      this.component = 'Sales Order';
    }

    this.userData = this.franchiseService.franchiseData.getValue();

    await new Promise(resolve => {
      this.authService.userData.subscribe((res) => {
        this.currentUserId = res['id'];
        resolve(res);
      });
    });

    if (this.userData['role']['original_name'] === AppConstants['roles']['sales_rep']) {
      this.isSalesRep = true;
      this.sales_rep_id = this.currentUserId;
    } else if (this.userData['role']['original_name'] === AppConstants['roles']['estimator_hod']) {
      this.isCSR = true;
      this.csr_user_id = this.currentUserId;
    } else if (this.userData['role']['original_name'] === AppConstants['roles']['estimator']) {
      this.isEstimator = true;
      this.estimator_id = this.currentUserId;
    } else if (this.userData['role']['original_name'] === AppConstants['roles']['admin']) {
      this.isAdmin = true;
    } else if (this.userData['role']['original_name'] === AppConstants['roles']['accountant']) {
      this.isAccountant = true;
    }

    if (!this.isSalesRep) {
      this.userService.getFranchiseUsersByRole('Sales Rep').subscribe((res) => {
        this.salesRepList = res['data'];
      });
    }

    if (!this.isCSR) {
      this.userService.getFranchiseUsersByRole('Estimator HOD').subscribe((res) => {
        this.csrList = res['data'];
      });
    }

    if (!this.isEstimator) {
      this.userService.getFranchiseUsersByRole('Estimator').subscribe((res) => {
        this.estimatorList = res['data'];
      });
    }

    this.userService.getContactOptions().subscribe((res) => {
      this.contactList = res['data'];
    });

    let configStatus: any = this.commonService.configStatus.getValue();
    if (Object.keys(configStatus).length === 0) {
      await new Promise(resolve => {
        this.commonService.getConfigStatusData().subscribe(res => {
          configStatus = res;
          this.commonService.configStatus.next(res);
          resolve(res);
        });
      });
    }

    this.projectStatuses = configStatus.project_status || {};
    this.project_status_id = Object.keys(this.projectStatuses);

    this.orderStatuses = configStatus.project_order || {};
    this.order_status_id = Object.keys(this.orderStatuses);

    // Set default status id array to be filtered based on component
    if (this.component == 'Project') {
      this.project_status_id = this.project_status_id.filter((project_status) => {
        // We have removed statuses of archived projects
        return project_status != 4 && project_status != 11 && project_status != 13 && project_status != 15;
      });
      this.filteredStatusIds = JSON.parse(JSON.stringify(this.project_status_id));
      this.selectedTab = '';
    } else if (this.component == 'Archive Project') {
      this.project_status_id = [4, 11, 13, 15];
      this.filteredStatusIds = JSON.parse(JSON.stringify(this.project_status_id));
      this.selectedTab = '';
    } else if (this.component == 'Sales Order') {
      this.filteredStatusIds = JSON.parse(JSON.stringify(this.order_status_id));
      this.selectedTab = '';
    }

    this.searchControl = new FormControl();
    this.searchControl.valueChanges
      .debounceTime(1000)
      // .distinctUntilChanged()
      .subscribe((res) => {
        this.tableData.search.value = res;
        this.tableData['order'] = [];
        this.tableData['start'] = 0;
        this.currentPage = 1;
        this.getProjectHistory();
      });

    this.getProjectHistory();
    this.getAllTaxTypes();

    $("body").delegate("#saveAndSend", "click", () => {
      this.updateStatusEmailSend = true;
      $('.swal2-confirm').click();
    });
  }

    getProjectHistory() {
    if (this.selectProjectStatusProjectHistoryProject != undefined) {
      this.selectProjectStatusProjectHistoryProject.close();
    }
    if (this.selectProjectStatusProject != undefined) {
      this.selectProjectStatusProject.close();
    }
    if (this.selectOrderStatusSalesOrder != undefined) {
      this.selectOrderStatusSalesOrder.close();
    }
    if (this.selectOrderStatusProjectHistoryOrder != undefined) {
      this.selectOrderStatusProjectHistoryOrder.close();
    }
      
    let configStatus: any = this.commonService.configStatus.getValue();

    let data = this.tableData;
    data['sales_rep_id'] = this.sales_rep_id;
    data['estimator_id'] = this.estimator_id;
    data['csr_user_id'] = this.csr_user_id;
    data['contact_id'] = this.contact_id;

    if (this.isSalesRep && this.component == 'Project History') {
      data['projects_of_all_my_clients'] = true;
    } else if (this.isCSR && this.component == 'Project History') {
      data['projects_of_all_my_clients_csr'] = true;
    }

    data.project_status_id = [];
    data.order_status_id = [];

    if (this.selectedTab == 'All') {
      // If we want data for all statuses then no need to send project_status_id OR order_status_id key in request. It will reduce condtion in query at backend which will improve performance
      delete data.project_status_id;
      delete data.order_status_id;
    } else if (this.selectedTab == 'Projects') {
      delete data.order_status_id;
      data.project_status_id = this.select_project_status_project_history_project;
    } else if (this.selectedTab == 'Archived Projects') {
      // 4 = Won - Converted to Order
      // 11 = Order Placed
      // 13 = Order in Progress
      // 15 = Project Lost
      data.project_status_id = [4, 11, 13, 15];
      data.order_status_id = [];
    } else if (this.selectedTab == 'Orders') {
      delete data.project_status_id;
      data.order_status_id = this.select_order_status_project_history_order;
    } else if (this.selectedTab == 'Completed Orders') {
      data.project_status_id = [];
      // 3 = Order Completed
      // 12 = Pre Press
      // 13 = Proof Approved
      // 14 = In Production
      data.order_status_id = [3, 12, 13, 14];
    } else if (this.component == 'Project') {
      delete data.order_status_id;
      //Remove 5, 4,11,13,15 from project view.
      data.project_status_id = this.select_project_status_project;
    } else if (this.component == 'Archive Project') {
      data.project_status_id = this.filteredStatusIds;
    } else if (this.component == 'Sales Order') {
      delete data.project_status_id;
      //Remove 5, 4,11,13,15 from sales order view.
      data.order_status_id = this.select_order_status_sales_order;
    } else {
      if (Object.keys(configStatus.project_status || {}).length != (this.project_status_id || []).length) {
        data.project_status_id = this.project_status_id;
      }
      if (Object.keys(configStatus.project_order || {}).length != (this.order_status_id || []).length) {
        data.order_status_id = this.order_status_id;
      }
    }

    data.date_filter = this.date_filter;
    this.loading = true;
    this.projectService.getProjectHistory(data).subscribe((response: any) => {
      if (response && response.data && response.data.original && response.data.original.data) {
        let currentDate = response.current_time || moment().format("MM/DD/YYYY HH:mm:ss");
        this.recordsFiltered = response.data.original.recordsFiltered;
        this.paginationInfo = (this.tableData.start + 1) + ' to ' + (Math.min(this.tableData.start + this.tableData.length, this.recordsFiltered)) + ' of ' + this.recordsFiltered + ' entries';

        this.project_history = (response.data.original.data || []).map((item) => {
          if (item['updated_at']) {
            let updatedDate = moment(item['updated_at']).format("MM/DD/YYYY HH:mm:ss");
            let diff = Math.abs(+new Date(updatedDate) - +new Date(currentDate)) / 1000;
            const hours = Math.floor(+diff / 3600);
            diff -= +hours * 3600;
            let displaydate = moment(item['updated_at']).format("MM-DD-YYYY HH:mm:ss");
            item['display_update_date'] = displaydate;
            item['hours'] = hours;
            if (hours > 24) {
              item['days'] = Math.floor(hours / 24);
            } else {
              item['days'] = 0;
            }
          }

          /**
           * 4 => Won - Converted to Order
           * 11 => Order Placed
           * 13 => Order in Progress
           * 15 => Project Lost
           */
          if (item.status == 4 || item.status == 11 || item.status == 13 || item.status == 15) {
            item.hideEstimate = true;
          }

          // For sales order
          if (item['order_of_project'] && item['order_of_project']['updated_at']) {
            let updatedDate = moment(item['order_of_project']['updated_at']).format("MM/DD/YYYY HH:mm:ss");
            let diff = Math.abs(+new Date(updatedDate) - +new Date(currentDate)) / 1000;
            const hours = Math.floor(+diff / 3600);
            diff -= +hours * 3600;
            let displaydate = moment(item['order_of_project']['updated_at']).format("MM-DD-YYYY HH:mm:ss");
            item['order_of_project']['display_update_date'] = displaydate;
            item['order_of_project']['hours'] = hours;
            if (hours > 24) {
              item['order_of_project']['days'] = Math.floor(hours / 24);
            } else {
              item['order_of_project']['days'] = 0;
            }
          }

          // 15 => Partially Shipped
          if (item['order_of_project'] && item['order_of_project']['status'] == 15) {
            //let totalItems = 0;
            //let shippedItems = 0;
            //(item['order_of_project']['order_items'] || []).forEach(orderItem => {
            //  if (!orderItem.is_additional_charge) totalItems++;
            //  if (orderItem.tracking_number) shippedItems++;
            //});
            item['order_of_project']['shipped_status'] = `Order Shipped`;
          }

          // If login user is distributor but he is not main distributor of the project then hide action buttons
          if (this.isSalesRep && this.currentUserId != item.sales_rep_id) {
            item.hideAction = true;
          } else if (this.isCSR && this.currentUserId != item.csr_user_id) {
            // If login user is Account Manager but if he is not current Account Manager then hide action buttons
            item.hideAction = true;
          }

          return item;
        });

        setTimeout(() => {
          this.loading = false;
        }, 0);
      }
    });
  }

  
  // Filter projects, archived projects and sales order by color
  colorfilterProjects = (color) => {
    switch (color) {
      // For projects filter
      case 'orange':
        this.filteredStatusIds = [1, 6, 12, 10, 18, 20];
        break;
      case 'yellow':
        this.filteredStatusIds = [7, 2, 14, 16, 17, 19, 21];
        break;
      case 'green':
        this.filteredStatusIds = [5, 3];
        break;

      // For archived projects filter
      case 'blue':
        this.filteredStatusIds = [4, 11, 13];
        break;
      case 'black':
        this.filteredStatusIds = [15];
        break;

      // For sales orders filter
      case 'dark-blue':
        //Shipped Partially Shipped
        this.filteredStatusIds = [7,15];
        //this.select_order_status = this.filteredStatusIds;
        this.select_order_status_sales_order = this.filteredStatusIds;
        break;
      case 'dark-black':
        //Hold Cancel
        this.filteredStatusIds = [6, 8];
        //this.select_order_status = this.filteredStatusIds;
        this.select_order_status_sales_order = this.filteredStatusIds;
        break;
      case 'dark-green':
        //In Progress
        this.filteredStatusIds = [2];
        //this.select_order_status = this.filteredStatusIds;
        this.select_order_status_sales_order = this.filteredStatusIds;
        break;
      case 'dark-red':
        //Workflow
        this.filteredStatusIds = [1, 10, 11, 12, 13, 14];
        //this.select_order_status = this.filteredStatusIds;
        this.select_order_status_sales_order = this.filteredStatusIds;
        break;
    }
    this.getProjectHistory();
  }

  resetProjectHistory = () => {
    if (!this.isSalesRep) {
      this.sales_rep_id = null;
    }

    if (!this.isCSR) {
      this.csr_user_id = null;
    }

    if (!this.isEstimator) {
      this.estimator_id = null;
    }

    if (this.component == 'Project') {
      this.filteredStatusIds = JSON.parse(JSON.stringify(this.project_status_id));
    } else if (this.component == 'Archive Project') {
      this.filteredStatusIds = JSON.parse(JSON.stringify(this.project_status_id));
    } else if (this.component == 'Sales Order') {
      this.filteredStatusIds = JSON.parse(JSON.stringify(this.order_status_id));
    } else {
      this.project_status_id = Object.keys(this.projectStatuses);
      this.order_status_id = Object.keys(this.orderStatuses);
    }
    this.searchControl.setValue("", { emitEvent: true });
  }

  getAllTaxTypes = () => {
    this._taxService.getTaxOptions().subscribe((res) => {
      this.taxTypes = res['data'];
    });
  }

  filterSwitchChange(checked) {
    if (checked) {
      this.date_filter.filter_by = 'updated';
    } else {
      this.date_filter.filter_by = 'created';
    }

    this.getProjectHistory();
  }

  selectedDate(value: any) {
    this.date_filter.from = value.start.format();
    this.date_filter.to = value.end.format();
    this.getProjectHistory();
  }

  firstPage() {
    if (this.currentPage > 1) {
      this.currentPage = 1;
      this.tableData.start = 0;
      this.getProjectHistory();
    }
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage = this.currentPage - 1;
      this.tableData.start = (this.currentPage * this.tableData.length) - this.tableData.length;
      this.getProjectHistory();
    }
  }

  nextPage() {
    let lastPage = Math.ceil(this.recordsFiltered / this.tableData.length);
    if (this.currentPage < lastPage) {
      this.currentPage = this.currentPage + 1;
      this.tableData.start = (this.currentPage * this.tableData.length) - this.tableData.length;
      this.getProjectHistory();
    }
  }

  lastPage() {
    let lastPage = Math.ceil(this.recordsFiltered / this.tableData.length);
    if (this.currentPage < lastPage) {
      this.currentPage = lastPage;
      this.tableData.start = (this.currentPage * this.tableData.length) - this.tableData.length;
      this.getProjectHistory();
    }
  }

  // For sticky notes
  openStickyNotesBoard = (projectId) => {
    this.currentStickyBoardProjectId = projectId;
    this.getProjectStickyNotesData();
    this.stickyNotesBoardModel.show();
  }

  getProjectStickyNotesData = () => {
    this.stickyNotesBoardLoader = true;
    this.projectService.getProjectStickyNotesByProjectId(this.currentStickyBoardProjectId).subscribe((res) => {
      this.stickyNotesBoardLoader = false;
      if (res['data']) {
        this.projectStickyNotesData = res['data'];
      }
    }, (error) => {
      this.stickyNotesBoardLoader = false;
      console.log("error : ", error);
    });
  }

  closeStickyNotesBoard = () => {
    this.stickyNotesBoardModel.hide();
    this.currentStickyBoardProjectId = null;
    this.projectStickyNotesData = [];
  }

  closeStickyNoteFormModel = () => {
    this.stickyNoteForm.reset();
    this.api_error = {};
    this.createStickyNoteModel.hide();
  }

  createStickyNote = () => {
    this.stickyNoteForm.get('project_id').setValue(this.currentStickyBoardProjectId);
    let postData = this.stickyNoteForm.value;
    this.loading = true
    this.projectService.createProjectStickyNote(postData).subscribe((res) => {
      this.loading = false;
      if (res['data']) {
        this.stickyNoteForm.reset();
        this.createStickyNoteModel.hide();
        this.getProjectStickyNotesData();
        this.getProjectHistory
      }
    }, (error) => {
      this.loading = false;
      if (error['error']) {
        this.api_error = error['error']['data'];
      }
    });
  }

  deleteStickyNote = (noteData) => {
    this.currentStickyBoardProjectId = noteData['project_id'];
    this.stickyNotesBoardLoader = true;
    this.projectService.deleteProjectStickyNote(noteData['id']).subscribe((res) => {
      this.getProjectStickyNotesData();
    }, (error) => {
      this.stickyNotesBoardLoader = false;
    });
  }

  editStickyNote = (noteData) => {
    this.stickyNoteForm.get('id').setValue(noteData['id']);
    this.stickyNoteForm.get('content').setValue(noteData['content']);
    this.stickyNoteForm.get('project_id').setValue(noteData['project_id']);
    this.createStickyNoteModel.show();
  }

  unarchieveProject = (id) => {
    this.loading = true;
    let data = { 'id': id, 'status': 14 };
    this.projectService.getProjectArchieve(data).subscribe(res => {
      this.loading = false;
      this.firstPage();
    })
  }

  ArchieveProject = (id) => {
    let data = { 'id': id };
    Swal.fire({
      title: "Are you Sure?",
      type: 'warning',
      text: "This Project will go under the archive list!",
      confirmButtonText: "Ok",
      showCancelButton: true,
      showLoaderOnConfirm: true,
      preConfirm: (value1) => {
        return this.projectService.getProjectArchieve(data).toPromise().then((res) => {
          return true;
        })
          .catch(() => {
            return true;
          });
      },
    }).then((res) => {
      if (res && res.value) {
        this.loading = false;
        console.log(res);
        this.route.navigate([this.userData.id + '/archive-project'])
      }
    });
  }

  openDeleteProject = (id) => {
    Swal.fire({
      title: "Select Delete Reason",
      html: "<select id='project-reason' class='form-control'><option value=''>--Select Reason--</option><option value='1'>Closed Lost</option><option value='2'>Converted Through Their Portal</option><option value='3'>On Hold/Nurturing</option><option value='4'>Ghost</option></select>",
      showCancelButton: true,
      showLoaderOnConfirm: true,
      inputPlaceholder: "Write something",
      inputValidator: (value) => {
        return new Promise((resolve) => {
          value = value.trim();
          if (value !== '') {
            resolve('');
          } else {
            resolve('Please select Comment');
          }
        });
      },
      preConfirm: (value1) => {
        var val = document.getElementById('project-reason')["value"];
        var postData = {}
        postData['reason'] = val
        val = val.trim();
        if (val != '') {
          return this.projectService.deleteProject(id, postData).toPromise().then((res) => {
            if (res['data']) {
              this.getProjectHistory();
              return true;
            }
          })
            .catch(() => {
              return true;
            });
        }
        else {
          Swal.showValidationMessage('Please select Reason')
        }
      }
    }).then((res) => {
      if (res['value']) {
        this.firstPage();
      }
    });
  }

  // For order invoice
  createInvoice = (id) => {
    this.api_error = {};
    let data = { 'id': id };
    let total_both = 0;
    let shipping_amount = 0;
    let tax_value = 0;
    this.invoiceModal.show();
    this.showInvoiceModel = true;
    this.loading = true;
    this.salesOrderService.getInvoiceDetail(id).subscribe(response => {
      this.loading = false;
      let productdata = response['data']['orderData'];
      let orderItems = response['data']['orderData']['order_items'];
      const discount = productdata['discount_amount'];
      this.project_order_type = response['data']['orderData']['project_of_order'] ? response['data']['orderData']['project_of_order']['project_type'] : ''
      this.invoiceCreateForm.get('discount_amount').setValue((+discount).toFixed(2));
      this.invoiceCreateForm.get('estimation_id').setValue(productdata['estimation_id']);
      this.invoiceCreateForm.get('estimator_id').setValue(productdata['estimator_id']);
      const productItemControls = <FormArray>this.invoiceCreateForm.get('products');
      if (Object.keys(productdata).length || productdata.length) {
        for (const data of productdata.order_items) {
          productItemControls.push(this.initProductItems(data));
        }
      }
      let productarray = this.invoiceCreateForm.get('products');
      productarray['controls'].map((item) => {
        this.deriveTotalAmounts(item);
        total_both += +item.get('total').value;
        shipping_amount += +item.get('shipping_amount').value;
        tax_value += +item.get('tax_value').value;
      })
      let total = total_both; //- discount
      this.invoiceCreateForm.get('total_amount').setValue(Number(+total).toFixed(2));
      this.invoiceCreateForm.get('shipping_amount').setValue(Number(+shipping_amount).toFixed(2));
      this.invoiceCreateForm.get('tax_value').setValue(Number(+tax_value).toFixed(2));
    });

    this.invoiceCreateForm = this._fb.group({
      id: id,
      project_order_id: id,
      estimation_id: [''],
      estimator_id: [''],
      discount_amount: [''],
      total_amount: [''],
      tax_value: [''],
      shipping_amount: [''],
      products: this._fb.array([]),
      due_date: [new Date()],
      reciept_date: [new Date()],
      invoice_date: [new Date()],
      terms: [''],
      comment:['']
    });
  }

  initProductItems = (data) => {
    return this._fb.group({
      item_id: [data['id'] ? data['id'] : ''],
      item_type: [data['item_type'] ? data['item_type'] : ''],
      item_spec: [data['item_spec']], //?data['item_spec']:''
      item_description: [data['item_description']],
      digital_service_name: [data['digital_service_name'] ? data['digital_service_name'] : ''],
      supplier_id: [data['supplier_id']], //?data['supplier_id']:''
      turnaround_time: [data['turnaround_time'] ? data['turnaround_time'] : ''],
      quantity: [data['quantity'] ? data['quantity'] : ''],
      cost_per_piece: [data['total_cost'] && data['quantity'] ? (Number(+data['total_cost'] / +data['quantity']).toFixed(5)) : ''],
      total_cost: [data['total_cost'] ? Number(+data['total_cost']).toFixed(2) : ''],
      markup: [data['markup'] ? Number(+data['markup']).toFixed(2) : 0.00],
      price_per_piece: [data['price_per_piece'] ? Number(+data['price_per_piece']).toFixed(5) : ''],
      price: [data['price'] ? Number(+data['price']).toFixed(2) : ''],
      tax_value: [data['tax_value'] ? Number(+data['tax_value']).toFixed(2) : ''],
      total: [data['total_price'] ? Number(+data['total_price']).toFixed(2) : ''], //+ +data['tax_value'] - +data['discount_amount']
      tax_type_id: [data['tax_type_id']],
      qb_tax_id: [data['qb_tax_id']],
      tax_applicable: data['tax_applicable'],
      discount_value: [data['discount_value'] ? data['discount_value'] : '0.00'],
      discount_type: [data['discount_type']],
      discount_amount: [data['discount_amount']],
      shipping_cost: [((data['shipping_cost']) ? Number(+data['shipping_cost']).toFixed(2) : '')],
      shipping_markup: [(data['shipping_markup']) ? Number(data['shipping_markup']).toFixed(2) : ''],
      shipping_markup_amount: [(data['shipping_markup_amount'] || data['shipping_markup_amount'] == 0) ? Number(data['shipping_markup_amount']).toFixed(2) : ''],
      shipping_included: [data['shipping_included'] !== undefined ? data['shipping_included'] : ''],
      shipping_included_markup: [(data['shipping_included_markup'] !== undefined) ? data['shipping_included_markup'] : ''], // new logic
      shipping_amount: [Number(data['shipping_amount']).toFixed(2)],
      is_additional_charge: [data['is_additional_charge'] || 0],
      parent_order_invoice_item_id: [data['parent_order_invoice_item_id'] || ''],
    })
  }
  deriveTotalAmounts = async (controlGroup: any = {}, field = '', calculateTax = true) => {
    if (Object.keys(controlGroup).length > 0) {
      let discountAmount = 0;
      let taxAmount = 0;
      let taxableAmount = 0;
      let total = 0;
      let shippingCharge = 0;
      let originalPrice = 0;
      let total_amount = 0;
      let total_both = 0;
      let totalTax = 0;
      let taxOriginalPrice = 0;
      let shipping_total = 0;
      let discount = 0;

      const discountValue = +controlGroup.get('discount_value').value;
      originalPrice = +controlGroup.get('price').value;
      if (+discountValue > 0) {
        if (+controlGroup.get('discount_type').value == 1) {
          // calculate by percent
          discount += (discountValue * originalPrice) / 100;
        } else {
          // calculate by value
          discount += discountValue;
        }
      }
      controlGroup.get('discount_amount').setValue(Number(+discount).toFixed(2));
      taxOriginalPrice = +controlGroup.get('price').value - Number(+discount);
      taxableAmount = Number(taxOriginalPrice);
      if (calculateTax) {
        if (+controlGroup.get('tax_applicable').value) {
          const taxRate = 6.35;
          taxAmount = (taxRate * taxableAmount) / 100;
          controlGroup.get('tax_value').setValue(Number(taxAmount).toFixed(2));
        } else {
          taxAmount = 0;
          controlGroup.get('tax_value').setValue(Number(taxAmount).toFixed(2));
        }
      }

      if (field != 'shipping_price') {
        if (!controlGroup.get('shipping_included').value || controlGroup.get('shipping_included').value == 0 || controlGroup.get('shipping_included').value == '' || controlGroup.get('shipping_included').value == 1) {
          if (controlGroup.get('shipping_markup_amount').value) {
            shippingCharge = (+controlGroup.get('shipping_cost').value + +controlGroup.get('shipping_markup_amount').value); // shipping_cost
          } else {
            if (controlGroup.get('shipping_markup_amount').value > 0 && !controlGroup.get('shipping_cost').value) { // shipping_cost
              shippingCharge = (+controlGroup.get('shipping_markup_amount').value)
            } else {
              shippingCharge = (+controlGroup.get('shipping_cost').value) // shipping_cost
            }
          }
        }
        controlGroup.get('shipping_amount').setValue(Number(+shippingCharge).toFixed(2));
      } else {
        shippingCharge = +controlGroup.get('shipping_amount').value;
        if (+controlGroup.get('shipping_amount').value == 0) {
          controlGroup.get('shipping_markup_amount').setValue('0.00');
        }
        controlGroup.get('shipping_cost').setValue(+controlGroup.get('shipping_amount').value - +controlGroup.get('shipping_markup_amount').value);
      }

      taxAmount = +controlGroup.get('tax_value').value;
      // discountAmount = +this.invoiceCreateForm.get('discount_amount').value;

      total += originalPrice + taxAmount + shippingCharge - discount;
      controlGroup.get('total').setValue(Number(+total).toFixed(2));
      let productarray = this.invoiceCreateForm.get('products');
      productarray['controls'].map((item) => {
        total_both += +item.get('total').value;
        totalTax += +item.get('tax_value').value;
        shipping_total += +item.get('shipping_amount').value;
        discountAmount += +item.get('discount_amount').value;
      })
      total_amount += +total_both; //- +discountAmount
      this.invoiceCreateForm.get('discount_amount').setValue(Number(+discountAmount).toFixed(2))
      this.invoiceCreateForm.get('total_amount').setValue(Number(+total_amount).toFixed(2))
      this.invoiceCreateForm.get('tax_value').setValue(Number(+totalTax).toFixed(2))
      this.invoiceCreateForm.get('shipping_amount').setValue(Number(+shipping_total).toFixed(2))
    }
  }

  getTaxRateById = (taxId) => {
    let taxRate = 0;
    this.taxTypes.map(item => {
      if (item['id'] === +taxId) {
        taxRate = item['rate'];
      }
    });
    return +taxRate;
  }

  isInvoiceWithEmail = (flag) => {
    this.isEmailSend = flag
  }

  checkError = () =>{
    this.api_error = {};
    let products = this.invoiceCreateForm.value.items;
    if(products) {
      for (let i = 0; i < products.length; i++) {
        if(products[i].item_type === ""){
        this.api_error['products.' + i + '.item_type'] = [];
        this.api_error['products.' + i + '.item_type'][0] = "Item Type field is required";
        }
      }
    }
    if(Object.keys(this.api_error).length !== 0){
      return true;
    }

  }

  saveInvoice = () => {
    if(this.checkError()){
      return false;
    }
    this.loading = true;
    let postData = { ...this.invoiceCreateForm.value };
    postData['isEmailSend'] = this.isEmailSend
    if (postData['due_date']) {
      postData['due_date'] =
        this.datePipe.transform(postData['due_date'], 'y-MM-d');
    }
    if (postData['invoice_date']) {
      postData['invoice_date'] =
        this.datePipe.transform(postData['invoice_date'], 'y-MM-d');
    }
    this.salesOrderService.createInvoice(postData).subscribe(response => {
      this.loading = false;
      this.invoiceModal.hide();
      this.api_error = {};
      this.getProjectHistory();
    }, error => {
      this.loading = false;
      this.api_error = error['error']['data'];
      if (error['error']['data'] == 'Duplicate Entry') {
        this._toast.pop('error', error['error']['message']);
      }
    });
  }

  onQuantityChanges = (control: FormGroup) => {
    if ((Number)(control.get('price').value) > 0 && (Number)(control.get('quantity').value) > 0) {
      const price_per_piece = +control.get('price').value / +control.get('quantity').value;
    } else {
      control.get('price_per_piece').setValue('');
    }
    if ((Number)(control.get('cost_per_piece').value) > 0 && (Number)(control.get('quantity').value) > 0) {
      const total_cost = Number(control.get('cost_per_piece').value * control.get('quantity').value);
      control.get('total_cost').setValue(Number(+total_cost).toFixed(2));
    } else {
      control.get('total_cost').setValue('');
    }
    //added to not blank markup while edit quantity
    if (control.get('markup').value > 0) {
      const markupAmount = ((+control.get('markup').value) * (+control.get('total_cost').value) / 100);
      // control.get('markup_amount').setValue(Number(+markupAmount).toFixed(2));
      const finalPrice = (markupAmount + (+control.get('total_cost').value));
      control.get('price').setValue(Number(+finalPrice).toFixed(2));
      if (+control.get('quantity').value > 0) {
        const pricePerPiece = +control.get('price').value / +control.get('quantity').value;
        control.get('price_per_piece').setValue(Number(+pricePerPiece).toFixed(5));
      }
    }
    this.deriveTotalAmounts(control);
  }

  onPricePerPieceChange = (control: FormControl) => {
    if (+control.get('quantity').value > 0) {
      const price = Number(control.get('price_per_piece').value) * Number(control.get('quantity').value);
      const total_cost = Number(control.get('cost_per_piece').value) * Number(control.get('quantity').value);
      control.get('total_cost').setValue(Number(+total_cost).toFixed(2));
      control.get('price').setValue(Number(+price).toFixed(2));
      this.onPriceChanges(control, false);
    } else {
      control.get('price').setValue('0.00');
      control.get('total_cost').setValue('0.00');
      this.deriveTotalAmounts(control);
    }
  }

  onPriceChanges = (control: FormControl, changePerPiece = true) => {
    if (Number(control.get('price').value) > 0) {
      this.validatePriceTotalCost(control);
      const totalCost = (Number(control.get('total_cost').value) > 0 ? Number(control.get('total_cost').value) : 0);
      const markUpAmount = Number(control.get('price').value) - totalCost;
      if (markUpAmount > 0) {
        const markupPer = (markUpAmount * 100) / (totalCost > 0 ? totalCost : 1);
        control.get('markup').setValue(Number(+markupPer).toFixed(2));
      } else {
        control.get('markup').setValue('');
      }
      if (changePerPiece && +control.get('quantity').value > 0) {
        const pricePerPiece = Number(control.get('price').value) / +control.get('quantity').value;
        control.get('price_per_piece').setValue(Number(+pricePerPiece).toFixed(5));
      }
    } else {
      control.get('price_per_piece').setValue('');
      control.get('markup').setValue('');
      this.deriveTotalAmounts(control);
    }
  }

  validatePriceTotalCost = (control: FormControl, field = '') => {
    if (+control.get('price').value < +control.get('total_cost').value) {
      control.get('price').setErrors({ min: 'price must be greater than or equal to total cost' });
    } else {
      control.get('price').setErrors(null);
    }
    this.deriveTotalAmounts(control, field);
  }

  onTotalPriceChange = (control: FormControl, field = '') => {
    if (control.get('markup').value > 0) {
      const markupAmount = ((+control.get('markup').value) * (+control.get('total_cost').value) / 100);
      const finalPrice = (markupAmount + (+control.get('total_cost').value));
      control.get('price').setValue(Number(+finalPrice).toFixed(2));
      if (+control.get('quantity').value > 0) {
        const pricePerPiece = +control.get('price').value / +control.get('quantity').value;
        control.get('price_per_piece').setValue(Number(+pricePerPiece).toFixed(5));
        const cost_per_piece = +control.get('total_cost').value / +control.get('quantity').value;
        control.get('cost_per_piece').setValue(Number(+cost_per_piece).toFixed(5));
      }
    } else {
      const price_per_piece = control.get('total_cost').value / +control.get('quantity').value;
      control.get('price').setValue(Number((+control.get('total_cost').value)).toFixed(2));
      if (+control.get('quantity').value > 0) {
        control.get('price_per_piece').setValue(Number(+price_per_piece).toFixed(5));
        control.get('cost_per_piece').setValue(Number(+price_per_piece).toFixed(5));
      }
    }

    if (+control.get('quantity').value === 0) {
      if (+control.get('markup').value > 1) {
        const markup = ((+control.get('markup').value) *
          (+control.get('total_cost').value) / 100) + (+control.get('total_cost').value);
        control.get('price').setValue(Number(+markup).toFixed(2));
      } else {
        control.get('price').setValue(Number(+control.get('total_cost').value).toFixed(2));
        control.get('price_per_piece').setValue('');
      }
    }
    if (+control.get('total_cost').value === 0) {
      control.get('price_per_piece').setValue('');
      control.get('price').setValue('');
    }
    this.validatePriceTotalCost(control, field);
  }

  onMarkupChange = (control: FormControl, markupPercentChange = true) => {
    if (!markupPercentChange || Number(control.get('markup').value) > 0) {
      if ((control.get('total_cost').value) > 0) {
        const finalPrice = ((control.get('markup').value) *
          (Number(control.get('total_cost').value)) / 100) + (Number(control.get('total_cost').value));
        control.get('price').setValue(Number(+finalPrice).toFixed(2));
      }

    } else {
      control.get('price').setValue(Number(+control.get('total_cost').value));
    }

    if (+control.get('quantity').value !== 0) {
      const price_per_piece = Number(control.get('price').value) / Number(control.get('quantity').value);
      control.get('price_per_piece').setValue(Number(+price_per_piece).toFixed(5));
    } else {
      control.get('price_per_piece').setValue('');
    }
    this.deriveTotalAmounts(control);
  }

  disabledFiledsMarkup = (control: FormControl) => {
    if (control.get('shipping_included_markup').value) {
      control.get('shipping_markup').setValue('0.00');
      control.get('shipping_markup_amount').setValue('0.00');
    } else {
      control.get('shipping_markup').setValue('');
      control.get('shipping_markup_amount').setValue('');
    }
    this.deriveTotalAmounts(control);
  }

  onTaxApplicable = (controlGroup, i) => {
    if (i == 'on') {
    controlGroup.get('tax_applicable').setValue(1);
    controlGroup.get('tax_value').enable();
    } else {
      controlGroup.get('tax_applicable').setValue(0);
      controlGroup.get('tax_value').setValue('0');
      controlGroup.get('tax_value').disable();
    }
    this.deriveTotalAmounts(controlGroup);
  }

  onSelectTax = (controlGroup, id) => {
    this.deriveTotalAmounts(controlGroup);
  }

  onSelectTerms = (term = null) => {
    if (!term) {
      term = this.invoiceCreateForm.get('terms').value;
    }
    if (term && parseInt(term) != 1) {
      let tt = this.invoiceCreateForm.get('invoice_date').value;
      let date = new Date(tt);
      let newdate = new Date(date);
      if (term == 1) {
        newdate.setDate(newdate.getDate() + 0);
      } else {
        newdate.setDate(newdate.getDate() + +term);
      }

      let dd = newdate.getDate();
      let mm = newdate.getMonth() + 1;
      let y = newdate.getFullYear();
      var someFormattedDate = mm + '/' + dd + '/' + y;
      if (someFormattedDate) {
        var nm = someFormattedDate.split('/');
        var updated = new Date(nm[0] + '/' + nm[1] + '/' + nm[2]);
        this.invoiceCreateForm.get('due_date').setValue(updated)
      }

      this.invoiceCreateForm.get('terms').setValue(term);
    }
  }

  onDueDateChange = () => {
    this.invoiceCreateForm.get('terms').setValue(1);
  }

  openDeleteSalesOrder = (id) => {
    Swal.fire({
      title: "Enter Delete Reason",
      html: "<textarea id='sales-order-reason' placeholder='Reason' class='form-control'></textarea>",
      showCancelButton: true,
      showLoaderOnConfirm: true,
      inputPlaceholder: "Write something",
      inputValidator: (value) => {
        return new Promise((resolve) => {
          value = value.trim();
          if (value !== '') {
            resolve('');
          } else {
            resolve('Please enter Comment');
          }
        });
      },
      preConfirm: (value1) => {
        var val = document.getElementById('sales-order-reason')["value"];
        var postData = {}
        postData['reason'] = val
        val = val.trim();
        if (val != '') {
          return this.salesOrderService.deleteSalesOrder(id, postData).toPromise().then((res) => {
            if (res['data']) {
              return true;
            }
          })
            .catch(() => {
              return true;
            });
        }
        else {
          Swal.showValidationMessage('Please enter Reason')
        }
      }
    }).then((res) => {
      if (res['value']) {
        this.getProjectHistory();
      }
    });
  }

  updateOrderStatus = (order) => {
    Swal.fire({
      title: 'Select Order Status',
      input: 'select',
      showLoaderOnConfirm: true,
      inputOptions: this.commonService.getOrderStatus('project_order'),
      customClass: {
        input: 'status-select'
      },
      inputPlaceholder: 'Select a Order Status',
      inputValue: order.status,
      showCancelButton: true,
      cancelButtonColor: '#d33',
      onOpen: () => {
        // 6 => On Hold, 7 => Order Shipped, 8 => Canceled
        if (order.status != 6 && order.status != 7 && order.status != 8) {
          this.setSaveAndSendButton();
        }
        const input = Swal.getInput()
        input.onchange = () => {
          let selectedOption = $('.swal2-select').children("option:selected").val();
          let popupButtonsLength = $('.swal2-actions button').length;
          if (selectedOption != 6 && selectedOption != 7 && selectedOption != 8 && popupButtonsLength == 2) {
            this.setSaveAndSendButton();
          } else if ((selectedOption == 6 || selectedOption == 7 || selectedOption == 8) && popupButtonsLength == 3) {
            this.removeSaveAndSendButton();
          }
        }
      },
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value == '15') {
            resolve('Select "Order Shipped" instead of "Partially Shipped"');
          } else if (value !== '') {
            resolve('');
          } else {
            resolve('Select any one option:');
          }
        });
      },
      preConfirm: (value) => {
        const data = {};
        data['id'] = order.id;
        data['status'] = +value;
        this.statusValuePopup = data['status']
        this.idValuePopup = data['id']
        if (this.statusValuePopup != 6 && this.statusValuePopup != 7 && this.statusValuePopup != 8) {
          if (this.updateStatusEmailSend) {
            data['sendEmail'] = 1;
            this.setSaveAndSendButton(true);
          }
          if (!this.receivedLatestUpdateStatusCallResponse) {
            this.receivedLatestUpdateStatusCallResponse = true;
            return this.salesOrderService.updateOrderStatus(data).toPromise()
              .then((res) => {
                window['storedRoutes'] = {};
                if (res['data']) {
                  this.updateStatusEmailSend = false;
                  this.receivedLatestUpdateStatusCallResponse = false;
                  this.getProjectHistory();
                  return true;
                }
              })
              .catch(() => {
                this.updateStatusEmailSend = false;
                this.receivedLatestUpdateStatusCallResponse = false;
                return true;
              });
          }
        }
      },
    }).then((res) => {
      if (res['value']) {
        if (this.statusValuePopup == 6 || this.statusValuePopup == 8) {
          this.showSecondPopup(this.idValuePopup, this.statusValuePopup);
        } else if (this.statusValuePopup == 7) {
          this.showShippedPopup(this.idValuePopup, this.statusValuePopup, order);
        } else {
          this.getProjectHistory();
        }
      }
    });
    let selectedOderData = this.project_history.find(project => {
      return project.order_of_project.id == order.id;
    });
    const selectBox = document.getElementsByClassName('status-select');
    var arr = Array.prototype.slice.call(selectBox[0])
    for (var i = 0; i < arr.length; i++) {
      if (!isNaN(+arr[i].value)) {
        if (order.status == 14) {
          if (i == 1 || i == 2 || i == 7 || i == 8 || i == 9 || i == 10) {
            selectBox[0][i].disabled = true
          }
        } else if (order.status == 11 && i != 4 && i != 6 && i != 11) {
          selectBox[0][i].disabled = true
        } else if (order.status == 1 && i != 4 && i != 6 && i != 2) {
          selectBox[0][i].disabled = true
        } else if (order.status == 7 && i != 4 && i != 6) {
          if (i == 3) {
            if (!selectedOderData.order_of_project.order_invoice || selectedOderData.order_of_project.purchase_closed != 1) {
              selectBox[0][i].disabled = true
            }
          } else {
            selectBox[0][i].disabled = true
          }
        } else if (order.status == 2 && i != 4 && i != 6 && i != 5 && i != 11) {
          selectBox[0][i].disabled = true
        } else if (order.status == 6 && i != 2 && i != 6) {
          selectBox[0][i].disabled = true
        } else if (order.status == 8 && i != 2) {
          selectBox[0][i].disabled = true
        } else if (order.status == 13) {
          if (i == 1 || i == 7 || i == 9) {
            selectBox[0][i].disabled = true
          }
        }

        if (i == 3 || i == 12) {
          selectBox[0][i].disabled = true
        }
      }
    }
  }

  setSaveAndSendButton = (loading: boolean = false) => {
    if (!loading) {
      $('.swal2-actions button:eq(0)').show().text('Save');
      let m = `<button type="button" id="saveAndSend" class="swal2-styled save-and-send-loader" aria-label="" style="display: inline-block; border-left-color: rgb(48, 133, 214); border-right-color: rgb(48, 133, 214);border: 0;border-radius: .25em;background: initial;background-color: #3085d6;color: #fff;font-size: 1.0625em;">Save & Send</button>`;
      $('.swal2-actions button:eq(0)').after(m);
      $('.swal2-actions button:eq(2)').show();
    } else {
      $('.swal2-actions button:eq(0)').removeClass('swal2-confirm');
      $('.swal2-actions button:eq(0)').css({ 'border': '0', 'border-radius': '.25em', 'background': 'initial', 'background-color': '#3085d6', 'color': '#fff', 'font-size': '1.0625em' });
      $('.swal2-actions button:eq(0)').hide();
      $('.swal2-actions button:eq(2)').hide();
      $('.swal2-actions button:eq(1)').remove();
      let loadingIcon = `<button type="button" id="saveAndSend" class="swal2-styled mx-4" aria-label="" style="box-sizing: border-box;  width: 2.5em;  height: 2.5em;  margin: .46875em;  padding: 0;  -webkit-animation: swal2-rotate-loading 1.5s linear 0s infinite normal;  animation: swal2-rotate-loading 1.5s linear 0s infinite normal;  border: .25em solid transparent;  border-radius: 100%;  border-color: transparent;  background-color: transparent!important;  color: transparent;  cursor: default;  -webkit-user-select: none;  -moz-user-select: none;  -ms-user-select: none;  user-select: none;display: flex;border-left-color: rgb(48, 133, 214);border-right-color: rgb(48, 133, 214);">Save & Send</button>`;
      $('.swal2-actions button:eq(0)').after(loadingIcon);
    }
  }

  removeSaveAndSendButton = () => {
    $('.swal2-actions button:eq(0)').text('OK');
    $('.swal2-actions button:eq(1)').remove();
  }

  showSecondPopup = (id, status) => {
    Swal.fire({
      title: "Enter Comment",
      html: "<textarea id='text' placeholder='Comment' class='form-control'></textarea>",
      showCancelButton: true,
      showLoaderOnConfirm: true,
      inputPlaceholder: "Write something",
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value !== '') {
            resolve('');
          } else {
            resolve('Please enter Comment');
          }
        });
      },
      onOpen: () => {
        this.setSaveAndSendButton();
      },
      preConfirm: (value1) => {
        var val = document.getElementById('text')["value"];
        if (val != '') {
          const data = {};
          data['id'] = id;
          data['status'] = +status;
          data['comment'] = val
          if (this.updateStatusEmailSend) {
            data['sendEmail'] = 1;
            this.setSaveAndSendButton(true);
          }

          if (!this.receivedLatestUpdateStatusCallResponse) {
            this.receivedLatestUpdateStatusCallResponse = true;
            return this.salesOrderService.updateOrderStatus(data).toPromise()
              .then((res) => {
                if (res['data']) {
                  this.updateStatusEmailSend = false;
                  this.receivedLatestUpdateStatusCallResponse = false;
                  this.getProjectHistory();
                  return true;
                }
              })
              .catch(() => {
                this.updateStatusEmailSend = false;
                this.receivedLatestUpdateStatusCallResponse = false;
                return true;
              });
          }
        }
        else {
          Swal.showValidationMessage('Please enter Comment')
        }
      }
    }).then((res) => {
      if (res['value']) {
        this.getProjectHistory();
      }
    });
  }

  showShippedPopup = (id, status, order) => {
    //check if all line item has shipping details, if yes no need to display popup.
    let isTrackingForAll = true;
    (order.order_items || []).forEach((item) => {
      isTrackingForAll = (isTrackingForAll && item.tracking_number && item.tracking_number.length > 0);
    });
    if (isTrackingForAll) {
      let formData = new FormData();
      formData.append('id', id);
      formData.append('status', status);
      if (this.updateStatusEmailSend) {
        formData.append('sendEmail', '1');
      }
      (order.order_items || []).forEach((item) => {
        formData.append('order_item_id[]', item.id);
        formData.append('carrier', item.carrier);
        formData.append('tracking_number', item.tracking_number);
        formData.append('tracking_url', item.tracking_url);
        this.salesOrderService.updateOrderStatus(formData).toPromise()
          .then((res) => {
            if (res['data']) {
              window['storedRoutes'] = {};
              this.getProjectHistory();
              return true;
            }
          })
          .catch(() => {
            return true;
          });
      });
      return;
    }

    Swal.fire({
      allowOutsideClick: false,
      title: "Enter Tracking Information",
      // html: "<input type='text' id='num' class='form-control' placeholder='Tracking Number' /><br/><input type='text' class='form-control' placeholder='Carrier' id='provider'/><br/><input type='text' class='form-control' placeholder='Tracking Url' id='tracking_url'/>",
      html: `
        <form id="tracking-form" autocomplete="off">
          ${(() => (order.order_items || [])
          //.filter(item => !item.is_additional_charge)
          .map((item) => {
            return `
              <div class="alert border m-0 text-left" title="${item.item_spec}">
                <label class="m-0 order_status w-100 text-truncate" style="user-select: none;">
                  ${(() => (!item.tracking_number) ? `<input type="checkbox" name="order_item_id[]" value="${item.id}">` : '')()} ${item.item_spec}
                </label>
              </div>
            `;
          }).join(''))()}
          <input type="text" name="tracking_number" class="form-control my-1" placeholder="Tracking Number"/>
          <input type="text" name="carrier" class="form-control my-1" placeholder="Carrier"/>
          <input type="text" name="tracking_url" class="form-control my-1" placeholder="Tracking URL"/>
          <label class="form-control my-1" for="shipping-date" onclick="document.querySelector('#shipping-date').showPicker();"></label>
          <input type="date" name="shipping_date" required id="shipping-date" onchange="(()=>{if(this.value) {let sDate = (this.value).split('-'); document.querySelector('label[for=shipping-date]').innerText = sDate[1]+'/'+sDate[2]+'/'+sDate[0];}})()"/>
        </form>
      `,
      showCancelButton: true,
      showLoaderOnConfirm: true,
      inputPlaceholder: "Write something",
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value !== '') {
            resolve('');
          } else {
            resolve('Please enter Comment');
          }
        });
      },
      onOpen: () => {
        this.setSaveAndSendButton();

        setTimeout(() => {
          let d = new Date();
          let element: any = document.querySelector('#shipping-date');
          element.value = d.toISOString().slice(0, 10);
          element.max = element.value;
          element.dispatchEvent(new Event('change'));
        }, 0);
      },
      preConfirm: async (data) => {
        let form: any = document.querySelector("#tracking-form");
        let formData = new FormData(form);

        if (!formData.get("tracking_number") || !formData.get("carrier")) {
          Swal.showValidationMessage('Please enter above details');
          return false;
        } else if ((formData.getAll("order_item_id[]") || []).length == 0) {
          Swal.showValidationMessage('At least select one item');
          return false;
        }

        formData.append('id', id);
        formData.append('status', status);
        if (this.updateStatusEmailSend) {
          formData.append('sendEmail', '1');
        }
        this.setSaveAndSendButton(true);

        if (!this.receivedLatestUpdateStatusCallResponse) {
          this.receivedLatestUpdateStatusCallResponse = true;
          await this.salesOrderService.updateOrderStatus(formData).toPromise()
            .then((res) => {
              if (res['data']) {
                this.updateStatusEmailSend = false;
                this.receivedLatestUpdateStatusCallResponse = false;
                window['storedRoutes'] = {};
                this.getProjectHistory();
                return true;
              }
            })
            .catch(() => {
              this.updateStatusEmailSend = false;
              this.receivedLatestUpdateStatusCallResponse = false;
              return true;
            });
        }
        return true;
      }
    }).then((res) => {
      if (res['value']) {
        // this.getProjectHistory();
      }
    });
  }

  handleTaxValueChange = (controlGroup) => {
    this.deriveTotalAmounts(controlGroup, '', false)
  }

  selectAllForDropdownItems(items: any[]) {
    let allSelect = items => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }

  selectAllForDropdownItemsProjectHistoryOrder(items: any[]) {
    let allSelect = items => {
      items.forEach(element => {
        element['selectAllForDropdownItemsProjectHistoryOrder'] = 'selectAllForDropdownItemsProjectHistoryOrder';
      });
    };
    allSelect(items);
  }

  selectAllForDropdownItemsProjectHistoryProject(items: any[]) {
    let allSelect = items => {
      items.forEach(element => {
        element['selectAllForDropdownItemsProjectHistoryProject'] = 'selectAllForDropdownItemsProjectHistoryProject';
      });
    };
    allSelect(items);
  }
  selectAllForDropdownItemsSalesOrder(items: any[]) {
    let allSelect = items => {
      items.forEach(element => {
        element['selectedAllGroupSalesOrder'] = 'selectedAllGroupSalesOrder';
      });
    };
    allSelect(items);
  }

  selectAllForDropdownItemsProjects(items: any[]) {
    let allSelect = items => {
      items.forEach(element => {
        element['selectedAllGroupProjects'] = 'selectedAllGroupProjects';
      });
    };
    allSelect(items);
  }

  changePageSize(size) {
    this.tableData.length = parseInt(size, 10);
    this.getProjectHistory();
  }

  changeItemType = (item_type_index, event) => {
    this.api_error['products.' + item_type_index + '.item_type'] = [];
    if(event.target.value === ""){
      this.api_error['products.' + item_type_index + '.item_type'][0] = "Item Type field is required";
    }
  }
  onRemoveOrderStatusProjectHistoryOrder(deletedItem){
    var options = this.selectOrderStatusProjectHistoryOrder.itemsList.items
    .filter(x => x.label === deletedItem)[0];
    this.selectOrderStatusProjectHistoryOrder.unselect(options);
    this.getProjectHistory();
  }

  onRemoveProjectStatusProjectHistoryProject(deletedItem){
    var options = this.selectProjectStatusProjectHistoryProject.itemsList.items
    .filter(x => x.label === deletedItem)[0];
    this.selectProjectStatusProjectHistoryProject.unselect(options);
    this.getProjectHistory();
  }

  onRemoveProjectStatusProject(deletedItem){
    var options = this.selectProjectStatusProject.itemsList.items
    .filter(x => x.label === deletedItem)[0];
    this.selectProjectStatusProject.unselect(options);
    this.getProjectHistory();
  }

  onRemoveOrderStatusSalesOrder(deletedItem){
    var options = this.selectOrderStatusSalesOrder.itemsList.items
    .filter(x => x.label === deletedItem)[0];
    this.selectOrderStatusSalesOrder.unselect(options);
    this.getProjectHistory();
  }
  
  
}
