import { Component, OnInit } from '@angular/core';
import { FranchiseService } from '../../service/franchise.service';
import { AuthenticationService } from '../../service/authentication.service';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { RolepermissionService } from '../../service/rolepermission.service';
import { CommonService } from '../../service/common.service';

@Component({
  templateUrl: './franchise-selection.component.html',
  styleUrls: ['./franchise-selection.component.scss']
})
export class FranchiseSelectionComponent implements OnInit {
  franchises: any = [];
  logo_url = '';
  static_image = environment.STATIC_IMAGE;
  userData: any = [];
  profileImage: any = '';
  loading = false;
  isSuperAdmin: boolean = false;
  user_status:any = [];

  constructor(private router: Router,
    private authService: AuthenticationService,
    private franchiseService: FranchiseService,
    private rolePermissionService: RolepermissionService,
    public _cs: CommonService
  ) {
  }

  ngOnInit() {
    this.userData = this.authService.userData.getValue();
    this.isSuperAdmin = false;
    for(let i=0;i<this.userData['franchises'].length;i++) {
      if(!this.isSuperAdmin) {
        if(this.userData['franchises'][i]['role']['original_name'] == 'Super Admin') {
          this.isSuperAdmin = true;
        }
      }
    }
    
    this.profileImage = '../../../assets/img/profile.jpg';
    this.logo_url = environment.AWS_FILE_PATH;
    if (localStorage.getItem('franchise_ids') || localStorage.getItem('designation')) {
      this.router.navigate(['dashboard']);
    }
    this.authService.userData.subscribe(res => {
      if (Object.keys(res).length > 0) {
        this.userData = res;
        if (this.userData['profile_image']) {
          this.profileImage = environment.AWS_PROFILE_PATH + this.userData['profile_image'];
        }
        this.franchises = res['franchises'];
        this.user_status = res['user_franchises'];
      } else {
        this.authService.refreshUserdata();
      }
    });
    $(document).ready(function () {
      $('div:empty').remove();
     });
  }

  selectFranchise = (franchise) => {
    let franchiseid
    if(franchise['id']){
      franchiseid = franchise['id'];
    }else{
      franchiseid = '';
    }
    this.franchiseService.refreshFranchiseData(franchiseid).then(
      (val) =>console.log(""),
      (err) =>console.log("")
    );
    if (franchise['id']) {
      this.franchiseService.franchiseData.next(franchise);
      // localStorage.setItem('franchise_id', franchise['id']);
      this.loading = true;
      this.rolePermissionService.getPermissions(franchise['id']).subscribe(res => {
        this.loading = false;
        if (res) {
          this.rolePermissionService.permissions.next(res['data']['permissions']);
          this.rolePermissionService.unread.next(res['data']['unread']);
          this.rolePermissionService.notificationData.next(res['data']['notification']);
          this.rolePermissionService.projectsData.next(res['data']['projects']);
        }
        this.router.navigate([franchise['id'], 'dashboard']);
      });
    }
  }

  checkIfUserActive = (franchise_id) => {
    try {
      for (var user_franchise in this.user_status) {
        if (this.user_status[user_franchise]["franchise_id"] === franchise_id
          && this.user_status[user_franchise]["is_active"]) {
          return true;
        }
      }
    } catch (err) {
      console.log("error in checking franchise")
    }
    return false;
  }
  
  logout = () => {
    this.loading = true;
    this.authService.logout().subscribe((response) => {
      this.loading = false;
      localStorage.clear();
      this.router.navigate(['/login']);
    }, (error) => {
      this.loading = false;
      localStorage.clear();
      this.router.navigate(['/login']);
    });
  }
}
