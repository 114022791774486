import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SupplierService } from '../../../../service/supplier.service';
import { CommonService } from '../../../../service/common.service';
import { DatePipe } from '@angular/common';
import { AccountingService } from '../../../../service/accounting.service';
import { TypeaheadMatch } from 'ngx-bootstrap';
import Swal from 'sweetalert2';
import * as moment from 'moment';

@Component({
  selector: 'app-create-payable',
  templateUrl: './create-payable.component.html',
  styleUrls: ['./create-payable.component.scss']
})

export class CreatePayableComponent implements OnInit, OnDestroy {
  payableForm: FormGroup;
  billUid = '';
  idFound = false;
  bills: any = [];
  api_error: any = [];
  paymentTypes: any = [];
  paymentAccount : any = [];
  suppliers: any = [];
  amountData: any = [];
  orderId = '';
  loading = false;
  selectedBill: any = [];
  selectedSupplier: any = [];
  supplierFound = true;
  @Output() billPaid = new EventEmitter();
  @Input() inputOrderId: any;
  @Input() modalRef: any;
  @Input() id: any;

  dropdownSettings = {
    singleSelection: true,
    idField: 'id',
    textField: 'bill_uid',
    placeholder: 'Select Bill',
    allowSearchFilter: true,
    searchPlaceholderText: 'Search Bill Number',
    closeDropDownOnSelection: true
  };


  dropdownSettingsSupplier = {
    singleSelection: true,
    idField: 'id',
    textField: 'display_name',
    placeholder: 'Select Supplier',
    allowSearchFilter: true,
    searchPlaceholderText: 'Search Supplier',
    closeDropDownOnSelection: true
  };

  constructor(private _fb: FormBuilder,
    private router: Router,
    public _cs: CommonService,
    private _dp: DatePipe,
    private _activatedRoute: ActivatedRoute,
    private _supplierService: SupplierService,
    private _accountingService: AccountingService) {
  }

  async ngOnInit() {
    this.initPayableForm();
    if (this.inputOrderId) {
      this.orderId = this.inputOrderId;
      this.loading = true;
      this._supplierService.getBillOptionsOfOrder(this.orderId).subscribe(
        (res) => {
          this.loading = false;
          this.bills = res['data'];
        }
      );
    } else {
      this._activatedRoute.params.subscribe(res => {
        if (res['bill_id']) {
          this.idFound = true;
          this.payableForm.get('bill_id').setValue(res['bill_id']);
          // this.getBillUid(res['bill_id']);
          this.getBillAmounts(res['bill_id']);
        } else {
          this.loading = true;

          this._supplierService.getBillOptions().subscribe(
            (response) => {
              this.loading = false;
              this.bills = response['data'];
            }
          );
        }
      });
    }
    const configStatus = this._cs.configStatus.getValue();
    if (Object.keys(configStatus).length === 0) {
      this.getConfigStatus();
    } else {
      const types = this._cs.getByType('payment_types');
      Object.keys(types).forEach(key => {
        this.paymentTypes.push({ 'value': key, 'name': types[key]['name'] });
      });
      const account = this._cs.getByType('qb_payment_account');
      Object.keys(account).forEach(key => {
        this.paymentAccount.push({ 'value': account[key]['value'], 'name': account[key]['type'] });
      });
    }

    await new Promise((resolve) => {
      this._supplierService.getSupplierList().subscribe(resData => {
        if (resData['data']) {
          this.suppliers = resData['data'];
          resolve(resData);
        }
      });
    });

    if (this.id) {
      this.getPaidBillPayment(this.id);
    }
  }

  getBillAmounts = (billId) => {
    this.amountData = [];
    this.loading = true;
    this._supplierService.getBillAmounts(billId).subscribe(
      (res) => {
        this.loading = false;
        this.amountData = res['data'];
        this.payableForm.get('due_amount').setValue(this.amountData['due']);
        this.payableForm.get('amount').setValue(this.amountData['due'].toFixed(2));


        if (res['data']['supplier_id'] || res['data']['supplier_name']) {
          this.payableForm.get('supplier_found').setValue(true);
          this.supplierFound = true;
          if (res['data']['supplier_id']) {
            this.suppliers.forEach(supplier => {
              if (+supplier['id'] === +res['data']['supplier_id']) {
                this.payableForm.get('supplier').setValue(supplier['display_name']); //supplier['full_name']
                this.payableForm.get('supplier_id').setValue(supplier['id']);
              }
            });
          } else {
            this.payableForm.get('supplier_id').setValue('');
            this.payableForm.get('supplier').setValue(res['data']['supplier_name']);
            this.payableForm.get('supplier_name').setValue(res['data']['supplier_name']);
          }
        } else {
          this.supplierFound = false;
          this.payableForm.get('supplier_found').setValue(false);
          this.payableForm.get('supplier_id').setValue('');
          this.payableForm.get('supplier').setValue('');
          this.payableForm.get('supplier_name').setValue('');
        }
        this.billUid = this.amountData['uid'];
      }
    );
  }

  getConfigStatus = async () => {
    const data = await this._cs.getConfigStatusData().toPromise();
    this._cs.configStatus.next(data);
    const types = this._cs.getByType('payment_types');
    Object.keys(types).forEach(key => {
      this.paymentTypes.push({ 'value': key, 'name': types[key]['name'] });
    });
    const account = this._cs.getByType('qb_payment_account');
    Object.keys(account).forEach(key => {
      this.paymentAccount.push({ 'value': account[key]['value'], 'name': account[key]['type'] });
    });
  }

  initPayableForm = () => {
    this.payableForm = this._fb.group({
      id: [''],
      bill_id: [''],
      amount: [''],
      payment_type: [''],
      supplier: [''],
      supplier_name: [''],
      supplier_id: [''],
      supplier_found: [true],
      paid_date: [new Date()],
      account_from: [''],
      ref_number: [''],
      comment: [''],
      due_amount: []
    });
  }

  getPaidBillPayment = (id) => {
    this.loading = true;
    this._accountingService.getPaidBillPayment(id).subscribe((res) => {
      if (res['data']) {
        this.payableForm.patchValue({
          id: res['data']['id'],
          bill_id: res['data']['bill_id'],
          amount: res['data']['amount'],
          payment_type: res['data']['payment_type'],
          supplier_found: (res['data']['supplier_id'] || res['data']['supplier_name']) ? true : false,
          paid_date: moment(res['data']['paid_date'], "DD/MM/YYYY"),
          account_from: res['data']['account_from'],
          ref_number: res['data']['ref_number'],
          comment: res['data']['comment'],
          due_amount: parseFloat(res['data']['bill_data']['amount']) - parseFloat(res['data']['total_paid'])
        });

        if (res['data']['supplier_id']) {
          this.suppliers.forEach(supplier => {
            if (+supplier['id'] === +res['data']['supplier_id']) {
              this.payableForm.get('supplier').setValue(supplier['display_name']); //supplier['full_name']
              this.payableForm.get('supplier_name').setValue(supplier['display_name']);
              this.payableForm.get('supplier_id').setValue(supplier['id']);
            }
          });

          this.selectedBill = [{id: res['data']['bill_id'], bill_uid: res['data']['bill_data']['bill_uid']}];
          this.billUid = res['data']['bill_data']['bill_uid'];
          this.amountData = {
            total_amount: parseFloat(res['data']['bill_data']['amount']),
            received: parseFloat(res['data']['total_paid']),
            due: parseFloat(res['data']['bill_data']['amount']) - parseFloat(res['data']['total_paid']),
            due_after_adding_old_amount: parseFloat(res['data']['bill_data']['amount']) - parseFloat(res['data']['total_paid']) +  parseFloat(res['data']['amount'])
          };
        } else {
          this.payableForm.get('supplier_id').setValue('');
          this.payableForm.get('supplier').setValue(res['data']['supplier_name']);
          this.payableForm.get('supplier_name').setValue(res['data']['supplier_name']);
        }
      }
      this.loading = false;
    });
  }

  createPayable = () => {
    const postData = { ...this.payableForm.value };
    if (postData['paid_date']) {
      postData['paid_date'] = this._dp.transform(postData['paid_date'], 'y-MM-d');
    }
    if((!postData['id'] && postData['amount'] > postData['due_amount']) 
      || (postData['id'] && postData['amount'] > this.amountData.due_after_adding_old_amount)) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to pay more amount',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        //showLoaderOnConfirm: true,
        preConfirm: (value) => {
          this.payBillAmount(postData);
        },
      }).then((res) => {
      });
    }else{
      this.payBillAmount(postData)
    }
  }
  
  payBillAmount = (postData) => {
    this.loading = true;
    this._accountingService.savePayable(postData).subscribe(
      (res) => {
        this.loading = false;
        this.billPaid.emit();
        if (!this.inputOrderId) {
          this.selectedBill = [];
          this.onDeselectBill();
        } else {
          this.back();
        }
      }, error => {
        this.loading = false;
        if (error['error']) {
          this.loading = false;
          this.api_error = error['error']['data'];
        }
      }
    );
  }
  onSelectBill = (id) => {
    this.billUid = '';
    this.selectedSupplier = [];
    if (id) {
      this.bills.map((item) => {
        if (item['id'] === +id) {
          this.initPayableForm();
          // this.billUid = item['bill_uid'];
          this.payableForm.get('bill_id').setValue(id);
          this.getBillAmounts(id);
        }
      });
    }
  }


  onDeselectBill = () => {
    this.payableForm.get('bill_id').setValue('');
    this.billUid = '';
    this.amountData = [];
  }

  back = () => {
    this.id = null;
    if (this.modalRef) {
      this.modalRef.hide();
    } else {
      if (this.idFound) {
        this.router.navigate(['../../'], { relativeTo: this._activatedRoute });
      } else {
        this.router.navigate(['../'], { relativeTo: this._activatedRoute });
      }
    }
  }

  onKeyUp = (value) => {
    this.payableForm.get('supplier_name').setValue(value);
    this.payableForm.get('supplier_id').setValue(null);
  }

  typeaheadOnSelect(e: TypeaheadMatch): void {
    if (e.item) {
      this.payableForm.get('supplier_id').setValue(e.item['id']);
    }
    this.payableForm.get('supplier_name').setValue('');
  }

  onSelectSupplier = (id) => {
    this.selectedBill = [];
    this.billUid = '';
  }

  onDeselectSupplier = (event) => {

  }

  resetInputs = () => {
    this.selectedSupplier = [];
    this.api_error = {};
  }

  ngOnDestroy() {
    this.id = null;
  }
}
