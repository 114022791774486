import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Subject } from 'rxjs';
import { CommonService } from './common.service';

@Injectable({ providedIn: 'root' })
export class ContactCreditService {
  constructor(private _http: HttpClient, private _cs: CommonService) {

  }

  saveContactCredit = (data) => {
    return this._http.post(environment.API_URL + 'credits/save-credit', data, this._cs.setHeader());
  }
}
