import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class DevUserService {

  constructor(private _http: HttpClient) {
  }

  getUserData = (userData) => {
    return this._http.post<any>(environment.API_URL + 'developer-admin/user-by-email', userData);
  }

  getCommissionType = () => {
    return this._http.get(environment.API_URL + 'developer-admin/get-commission-types');
  }

  createUser = (userData) => {
    return this._http.post<any>(environment.API_URL + 'developer-admin/create-user', userData);
  }

  getUserById = (id) => {
    return this._http.post<any>(environment.API_URL + 'developer-admin/get-user-by-id', { id });
  }

  updateUser = (userData) => {
    return this._http.post<any>(environment.API_URL + 'developer-admin/update-user', userData);
  }

  getEstimatorHodList = (id) => {
    return this._http.post(environment.API_URL + 'developer-admin/get-estimator-hods', { franchise_id: id });
  }
  
  getAllUsers = (id) => {
    return this._http.get(environment.API_URL + 'users/get-franchise-users/' + id);
  }
}