export const productoption = [
  {
    'name': 'Adhesive Vinyl',
    'value': 'adhesive_vinyl'
  },
  {
    'name': 'Banner Stand',
    'value': 'banner_stand'
  },
  {
    'name': 'Booklet',
    'value': 'booklet'
  },
  {
    'name': 'Bookmark',
    'value': 'bookmark'
  },
  {
    'name': 'Brochure',
    'value': 'brochure'
  },
  {
    'name': 'Bumper Sticker',
    'value': 'bumper_sticker'
  },
  {
    'name': 'Business Card',
    'value': 'business_card'
  },
  {
    'name': 'Calendar',
    'value': 'calendar'
  },
  {
    'name': 'Car Magnets',
    'value': 'car_magnets'
  },
  {
    'name': 'Coupon',
    'value': 'coupon'
  },
  {
    'name': 'Custom Product',
    'value': 'custom_product'
  },
  {
    'name': 'Door Hanger',
    'value': 'door_hanger'
  },
  {
    'name': 'Envelope',
    'value': 'envelope'
  },
  {
    'name': 'Fabric Banner',
    'value': 'fabric_banner'
  },
  {
    'name': 'Feather Flags',
    'value': 'feather_flags'
  },
  {
    'name': 'Flyer',
    'value': 'flyer'
  },
  {
    'name': 'Folded Card',
    'value': 'folded_card'
  },
  {
    'name': 'Hang Tag',
    'value': 'hang_tag'
  },
  {
    'name': 'Indoor Banner',
    'value': 'indoor_banner'
  }, {
    'name': 'Insert',
    'value': 'insert'
  },
  {
    'name': 'Large Posters',
    'value': 'large_posters'
  },
  {
    'name': 'Letterhead',
    'value': 'letterhead'
  },
  {
    'name': 'NCR Carbonless Forms',
    'value': 'NCR_Carbonless_Forms'
  },
  {
    'name': 'Notepad',
    'value': 'notepad'
  },
  {
    'name': 'Outdoor Banner',
    'value': 'outdoor_banner'
  },
  {
    'name': 'Pearl Postcards',
    'value': 'pearl_postcards'
  },
  {
    'name': 'Pole Flags',
    'value': 'pole_flags'
  },
  {
    'name': ' Post - It Notepads',
    'value': ' post_it_notepads'
  },
  {
    'name': 'Postcard',
    'value': 'postcard'
  },
  {
    'name': 'Poster',
    'value': 'poster'
  },
  {
    'name': 'Presentation Folder',
    'value': 'presentation_folder'
  },
  {
    'name': 'Radiance Business Cards',
    'value': 'radiance_business_cards'
  },
  {
    'name': 'Roll Labels',
    'value': 'roll_labels'
  },
  {
    'name': 'Signs',
    'value': 'signs'
  },
  {
    'name': 'Tabletop Retractable Display',
    'value': 'tabletop_retractable_display'
  },
  {
    'name': 'Teardrop Flags',
    'value': 'teardrop_flags'
  },
  {
    'name': 'Velvet Business Cards',
    'value': 'velvet_business_cards'
  },
  {
    'name': 'Vibrant Edge Business Cards',
    'value': 'vibrant_edge_business_cards'
  },
  {
    'name': 'Window Graphic',
    'value': 'window_graphic'
  }
];
