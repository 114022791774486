import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Subject } from 'rxjs';
import { CommonService } from './common.service';

@Injectable({ providedIn: 'root' })
export class SupplierCreditService {
  constructor(private _http: HttpClient, private _cs: CommonService) {

  }

  saveSupplierCredit = (data) => {
    return this._http.post(environment.API_URL + 'supplier-credits/save', data, this._cs.setHeader());
  }
}
