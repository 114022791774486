import { Component, OnInit, ViewChild } from '@angular/core';
import { ProjectService } from '../../service/project.service';
import { CommonService } from '../../service/common.service';
import { UserService } from '../../service/user.service';
import { SalesOrderService } from '../../service/sales-order.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ToasterService } from 'angular2-toaster';
import { SupplierService } from '../../service/supplier.service';
import * as moment from 'moment';

@Component({
  templateUrl: './order-shipping-charge.component.html',
  styleUrls: ['./order-shipping-charge.component.scss']
})
export class OrderShippingChargeComponent implements OnInit {

  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  loading = false;
  salesOrderWithCompleted = false;
  suppliers: any = [];
  bill_uid: any = '';
  bill_date: any = new Date();
  due_date: any = new Date();
  selectedTab = 'All';
  salesOrders = [];
  filteredOrders = [];
  showDataTable = true;
  filesArr: any = [];
  docArr: any = [];
  today = new Date();

  constructor(
    private projectService: ProjectService,
    public csService: CommonService,
    private userService: UserService,
    private salesOrderService: SalesOrderService,
    private _supplierService: SupplierService,
    private _toast: ToasterService,
  ){}

  ngOnInit() {
    this.getSupplierList();

    this.dtOptions = {
      paging: false,
      info: false,
      processing: true,
      language: {
        searchPlaceholder: 'Search...',
        search: ''
      },
      columns: [
        { name: 'order_of_project.sales_order_uid' },
        { name: 'sales_rep.first_name', orderable: false },
        { name: null, orderable: false, searchable: false },
        { name: null, orderable: false, searchable: false }
      ],
      scrollY: '400px'
    };

    this.getSalesOrder();
  }

  async getSupplierList() {
    await new Promise(resolve => {
      this._supplierService.getSupplierList().subscribe(resData => {
        if (resData['data']) {
          this.suppliers = resData['data'];
          resolve(resData);
        }
      });
    });
  }

  async getSalesOrder() {
    let configStatus:any = this.csService.configStatus.getValue();
    if (Object.keys(configStatus).length === 0) {
      await new Promise(resolve => {
        this.csService.getConfigStatusData().subscribe(res => {
          configStatus = res;
          this.csService.configStatus.next(res);
          resolve(res);
        });
      });
    }

    // We have omit 3 AND 10 because we don't want order which are completed OR under review
    let order_status_id = [];
    if (this.salesOrderWithCompleted) {
      order_status_id = Object.keys(configStatus.project_order || {}).filter((status_id:any) => { return status_id != 10; });
    } else {
      order_status_id = Object.keys(configStatus.project_order || {}).filter((status_id:any) => { return status_id != 3 && status_id != 10; });
    }

    let data = {
      start: 0,
      search: {
        value: '',
        regex: false 
      },
      order: [],
      project_status_id: [],
      order_status_id: order_status_id
    };

    this.loading = true;
    this.projectService.getProjectHistory(data).subscribe((response: any) =>{
      if (response && response.data && response.data.original && response.data.original.data) {
        this.salesOrders = response.data.original.data;
        this.filterOrderList();
      }
      this.loading = false;
    }, (error: any) => {
      console.log(error);
      this.loading = false;
    });
  }

  filterOrderList() {
    if (this.selectedTab == 'All') {
      this.filteredOrders = this.salesOrders;
    } else {
      this.filteredOrders = (this.filteredOrders || []).filter((item) => {
        return parseFloat(item.shipping_cost) > 0;
      });
    }

    this.reloadSalesOrderTable();
  }

  reloadSalesOrderTable() {
    this.showDataTable = false;

    setTimeout(() => {
     this.showDataTable = true; 
    }, 0);
  }

  uploadDoc = (event: any) => {
    const files = event.target.files;
    let i = this.filesArr.length;
    for (const file of files) {
      const ext = file['name'].substring(file['name'].lastIndexOf('.') + 1).toLowerCase();
      let flag = true;
      this.docArr.forEach((value) => {
        if (value === file.name) {
          flag = false;
        }
      });
      if (flag) {
        this.docArr.push(file.name);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event1: Event) => {
          this.filesArr[i] = {};
          this.filesArr[i]['file'] = file;
          const pathValue = this.getLogoUrl(ext);
          if (!pathValue) {
            this.filesArr[i]['url'] = event1.target['result'];
          } else {
            this.filesArr[i]['url'] = pathValue;
          }
          i++;
        };
      }
    }
  }

  removeDoc = (doc, i) => {
    this.filesArr.splice(i, 1);
    this.docArr.forEach((value, key) => {
      if (key === +i) {
        this.docArr.splice(key, 1);
      }
    });
  }

  getLogoUrl = (ext) => {
    if (ext === 'jpg' || ext === 'JPEG' || ext === 'jpeg' || ext === 'png' || ext === 'PNG') {
      return false;
    } else if (ext === 'pdf') {
      return '../../../../assets/img/pdf.jpg';
    } else if (ext === 'doc' || ext === 'docx') {
      return '../../../../assets/img/doc.jpg';
    } else {
      return '../../../../assets/img/file.png';
    }
  }

  addOrderShippingCharge = (sendMail = false) => {
    if (!this.bill_date) {
      this._toast.pop('error', 'Bill date is required!');
      return false;
    }

    if (!this.due_date) {
      this._toast.pop('error', 'Due date is required!');
      return false;
    }

    if (!this.bill_uid) {
      this._toast.pop('error', 'Bill number is required!');
      return false;
    }
    let missing_supplier = false;

    let order_shipping_charge:any = (this.filteredOrders || []).filter((item) => {
      return parseFloat(item.shipping_cost) > 0;
    }).map((filtered_item) => {
      if (!filtered_item.supplier_id) {
        missing_supplier = true;
      }
      
      let delivery_date_array = filtered_item.order_of_project.delivery_date.split('-');
      return {
        order_id: filtered_item.order_of_project.id,
        sales_order_uid: filtered_item.order_of_project.sales_order_uid, // Optional to pass
        shipping_cost: parseFloat(filtered_item.shipping_cost),
        delivery_date: delivery_date_array[2]+'-'+delivery_date_array[1]+'-'+delivery_date_array[0],
        bill_date: moment(this.bill_date).format("YYYY-MM-DD"),
        due_date: moment(this.due_date).format("YYYY-MM-DD"),
        bill_uid: this.bill_uid,
        supplier_id: filtered_item.supplier_id,
        supplier: filtered_item.supplier || '',
        supplier_email: filtered_item.supplier_email || '',
        name: filtered_item.contact_info.full_name || '',
        email: filtered_item.contact_info.email || '',
        phone: filtered_item.contact_info.phone || '',
        address_line1: filtered_item.contact_info.address_line_1 || '',
        address_line2: filtered_item.contact_info.address_line_2 || '',
        city: filtered_item.contact_info.city || '',
        state_code: filtered_item.contact_info.state_code || '',
        zip_code: filtered_item.contact_info.zip_code || '',
        country: filtered_item.contact_info.country || '',
        estimator_id: filtered_item.estimator_id || null
      };
    });

    if (missing_supplier) {
      this._toast.pop('error', 'Supplier is missing for some order!');
      return false;
    }

    const fData: FormData = new FormData;
    if ((order_shipping_charge || []).length == 0) {
      this._toast.pop('error', 'Provide shipping charge for at least one order!');
      return false;
    } else {
      order_shipping_charge.forEach((item, index) => {
        // To append each order object in order_shipping_charge array
        Object.keys(item).forEach((key) => {
          fData.append(`order_shipping_charge[${index}][${key}]`, item[key]);
        });
      });
    }

    if (this.filesArr.length > 0) {
      for (const file of this.filesArr) {
        if (file['file']) {
          fData.append('files[]', file['file']);
        }
      }
    } else {
      this._toast.pop('error', 'Bill is compulsory to attach!');
      return false;
    }

    if (sendMail) {
      fData.append('isSaveSend', '');
    } else {
      fData.append('isSaveSend', 'save_only');
    }

    this.loading = true;
    this.salesOrderService.addOrderShippingCharge(fData).subscribe((response) => {
      if (response.message == 'Error') {
        let error = (response.data && response.data[0]) ? response.data[0] : 'PO & Bill has not been created for some of the selected orders!';
        this._toast.pop('error', error);
      }
      this.loading = false;
      this.resetOrderShippingForm();
    }, (error) => {
      console.log(error);
      this._toast.pop('error', 'There is some server issue!');
      this.loading = false;
    });
  }

  onChangeSupplier = (order, event) => {
    order.supplier = event.full_name || '';
    order.supplier_email = event.email || '';
  }

  resetOrderShippingForm = () => {
    this.bill_uid = '';
    this.bill_date = new Date();
    this.due_date = new Date();
    this.filesArr = [];
    this.docArr = [];

    this.filteredOrders.forEach((item) => {
      item.supplier_id = null;
      item.shipping_cost = null;
    });
    this.selectedTab = 'All';
    this.filterOrderList();

    this.reloadSalesOrderTable();
  }
}