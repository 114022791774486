import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { CommonService } from './common.service';

@Injectable({ providedIn: 'root' })
export class DigitalService {
  constructor(private _http: HttpClient, private _cs: CommonService) {
  }

  saveService = (data) => {
    return this._http.post(environment.API_URL + 'digital-services/save', data, this._cs.setHeader());
  }

  getAllService = () => {
    return this._http.get(environment.API_URL + 'digital-services', this._cs.setHeader());
  }
  getServiceById = (data) => {
    return this._http.get(environment.API_URL + 'digital-services/service/' + data, this._cs.setHeader());
  }

  deleteService = (data) => {
    return this._http.get(environment.API_URL + 'digital-services/service/delete/' + data, this._cs.setHeader());
  }

  getServiceByCategory = (data) => {
    return this._http.post(environment.API_URL + 'digital-services/category', data, this._cs.setHeader());
  }
  //   getCategory = () => {
  //     return this._http.get(environment.API_URL + 'category/getCategory', this._cs.setHeader());
  //   }
  getCategory = () => {
    return this._http.get(environment.API_URL + 'digital-services/get-category', this._cs.setHeader());
  }
  createDigitalProductEstimation = (data) => {
    return this._http.post<any>(environment.API_URL + 'estimation/create-digital-estimation', data, this._cs.setHeader());
  }
  getDigitalServices = () => {
    return this._http.get(environment.API_URL + 'digital-services/get-category-service', this._cs.setHeader());
  }
  getDigitalServicesCategory = () => {
    return this._http.get(environment.API_URL + 'digital-services/get-category-service-test', this._cs.setHeader());
  }
  saveServiceCategory = (data) => {
    return this._http.post(environment.API_URL + 'digital-services/save-category', data, this._cs.setHeader());
  }
  saveServiceCategoryVideo = (data) => {
    let header:any = this._cs.setHeader();
    header['reportProgress'] = true;
    header['observe'] = "events";
    return this._http.post(environment.API_URL + 'digital-services/save-category-video', data, header);
  }
  getServiceByIdCategory = (data) => {
    return this._http.get(environment.API_URL + 'digital-services/get-category-data/' + data, this._cs.setHeader());
  }
  removeServiceCategory = (id) => {
    return this._http.get(environment.API_URL + 'digital-services/delete-category/' + id, this._cs.setHeader());
  }
  siteAuditRequest = (data) => {
    return this._http.post(environment.API_URL + 'digital-services/site-audit-request', data, this._cs.setHeader());
  }
  getSitePublic = (id, franchise) => {
    return this._http.get(environment.API_URL + 'digital-site-audit-request/' + franchise + '/' + id, this._cs.setHeader());
  }
  publicSiteAudit = (data, franchiseId = '') => {
    return this._http.post(environment.API_URL + 'save-digital-site-audit-link', data, this._cs.setHeader(franchiseId));
  }

  saveDigitalUrl = (data) => {
    return this._http.post(environment.API_URL + 'digital-services/save-url', data, this._cs.setHeader());
  }

  getDigitalUrlById = (id) => {
    return this._http.get(environment.API_URL + 'digital-services/get-url' + '/' + id, this._cs.setHeader());
  }
  saveDigitalQuestion = (data) => {
    return this._http.post(environment.API_URL + 'digital-services/save-question', data, this._cs.setHeader());

  }
  getQuestionById = (id) => {
    return this._http.get(environment.API_URL + 'digital-services/get-question-data' + '/' + id, this._cs.setHeader());
  }

  getAllQuestions = (category) => {
    return this._http.post(environment.API_URL + 'digital-services/get-all-questions', { postData: category }, this._cs.setHeader());
  }

  getDynamicButtons = () => {
    return this._http.post(environment.API_URL + 'digital-services/get-all-url', "", this._cs.setHeader());
  }
  
  saveRequestProposal = (postData) => {
    return this._http.post(environment.API_URL + 'digital-services/save-digital-proposal-request', postData, this._cs.setHeader());
  }
  getRequestSiteUrl = (id, franchise) => {
    return this._http.get(environment.API_URL + 'digital-proposal-request/' + franchise + '/' + id, this._cs.setHeader());
  }
  publicDigitalProposal = (data, franchiseId = '') => {
    return this._http.post(environment.API_URL + 'save-digital-proposal-link', data, this._cs.setHeader(franchiseId));
  }
  sendProposal = (id) => {
    return this._http.get(environment.API_URL + 'digital-services/send-proposal-to-customer' + '/' + id, this._cs.setHeader());
  }
  saveDigitalReview = (data, franchiseId = '') => {
    return this._http.post(environment.API_URL + 'save-digital-proposal-client', data, this._cs.setHeader(franchiseId));
  }
  digitalTeam = (id) => {
    return this._http.get(environment.API_URL + 'digital-services/send-proposal-to-digital-team'+ '/' + id, this._cs.setHeader());
  }
  saveApparelProductDetail = (productData) => {  // Apparel(STAKES MFG) Save Product Data 
    return this._http.post<any>(environment.API_URL + 'apparel-products/save', productData, this._cs.setHeader());
  };
  getApparelProductDetailById = (id) => {
    return this._http.get(environment.API_URL + 'apparel-products' + '/' + id, this._cs.setHeader());
  }
}