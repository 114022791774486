import { Component, OnInit, Input,Output,EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { CommonService } from '../../../service/common.service';

@Component({
  selector: 'app-eddm-form',
  templateUrl: 'eddm-form.component.html',
  styleUrls: ['eddm-form.component.scss']
})

export class EddmFormComponent implements OnInit {
  eddmForm: FormGroup;
  @Input() eddmFiles = [];
//   @Input() eddmArr = []
eddmArr :any= []
  @Output() onDatePicked = new EventEmitter<any>();
  api_error = {};
  docArrEddm: any = [];
  oldDocArr: any = [];
  AnotherOptions:any = []
  showDropdown :boolean = false
  Envelope:any = []
  Post_Card:any = []
  Self_Mailer:any = []
  custom_product : boolean = false;
  self_mailer_text: boolean = false;
  booklet_text:boolean = false
  show_envelop_text:boolean = false
  showEnvelopOther:boolean = false;
  SubMenuText:any = ''
  @Input() quoteCreateForm: FormGroup;
  @Input() editData: any = undefined;
  constructor(public _fb: FormBuilder, public csService: CommonService) {

  }

  ngOnInit() {
    this.Envelope = [{'name' : '#10','value':'#10'},{'name' : '6x9','value':'6x9'},{'name' : '9x12','value':'9x12'},{'name' : 'other','value':'other'}]
    this.Post_Card = [{'name' : 'Letter (6 1/8 x 11.5 maximum dimensions)','value':'Letter (6 1/8 x 11.5 maximum dimensions)'},{'name' : 'Flat  (exceeds any of the maximum dimensions above)','value':'Flat  (exceeds any of the maximum dimensions above)'}]
    this.Self_Mailer = [{'name':'Fold on bottom (recommended)','value':'Fold on bottom (recommended'},{'name':'Fold on top','value':'Fold on top'},{'name':'Fold on Leading edge','value':'Fold on Leading edge'},{'name':'Fold on Trailing edge','value':'Fold on Trailing edge'}]
    if(this.editData){
      const formdata = this.editData['quote_details'];
      if(formdata['mailing_list']){
        // this.eddmFiles = formdata['mailing_list']
        this.docArrEddm = formdata['mailing_list']
        this.oldDocArr = [...formdata['mailing_list']];
        
  

        this.docArrEddm.forEach((value, key) => {
          this.eddmFiles[key] = [];
          const pathValue = this.csService.checkImageTypeAws(value, 'eddm_images');
          if (!pathValue) {
            this.eddmFiles[key]['url'] = this.csService.UPLOAD_FILE_AWS('eddm_images') + value;
          } else {
            this.eddmFiles[key]['url'] = pathValue;
          }
        });
        this.eddmArr = [...formdata['mailing_list']]
        // this.oldEddmDocArr = [...formdata['mailing_list']]
        // console.log("oldeddmdoc",this.eddmArr)
      }
      this.initEddm(formdata);
      this.showOtherOptions();
    }else{
    this.initEddm();
    }
  }
  subOptionChange = (event) => {
    this.showEnvelopOther = false
    if(event.target.value == 'other'){
        this.showEnvelopOther = true
    }
  }
  showOtherOptions = () => {
    var value = this.quoteCreateForm.get('type_of_mailing_piece').value
    var option = this.quoteCreateForm.get('mail_piece_options').value
    if(option == 'other'){
        this.showEnvelopOther = true
    }
    if(value == 'Envelope'){
      this.AnotherOptions=this.Envelope
      this.showDropdown = true;
      this.show_envelop_text = true
      this.SubMenuText = "Envelope Size"
    }
    else if(value == 'Post Card'){
      this.AnotherOptions=this.Post_Card
      this.showDropdown = true;
      this.SubMenuText = "Post Card Type"
    }
    else if(value == 'Self Mailer'){
      this.AnotherOptions=this.Self_Mailer
      this.showDropdown = true;
      this.self_mailer_text = true
      this.SubMenuText = "Fold Type"
    }else if(value == 'Booklet'){
      this.booklet_text=true
    }else if(value == 'Custom product'){
      this.custom_product=true
      
    }
  }
  onMailingPriceSelect = (event) => {
    console.log("event",event.target.value)
    if(event.target.value){
      this.showDropdown = false;
      this.self_mailer_text = false;
      this.booklet_text=false;
      this.custom_product=false;
      this.show_envelop_text = false
      this.showEnvelopOther = false
      this.quoteCreateForm.get('mail_piece_options').setValue('')
      if(event.target.value == 'Envelope'){
        this.AnotherOptions=this.Envelope
        this.show_envelop_text = true
        this.showDropdown = true;
        this.SubMenuText = "Envelope Size"
      }
      else if(event.target.value == 'Post Card'){
        this.AnotherOptions=this.Post_Card
        this.showDropdown = true;
        this.SubMenuText = "Post Card Type"
      }
      else if(event.target.value == 'Self Mailer'){
        this.AnotherOptions=this.Self_Mailer
        this.showDropdown = true;
        this.self_mailer_text = true
        this.SubMenuText = "Fold Type"
      }else if(event.target.value == 'Booklet'){
        this.booklet_text=true
      }else if(event.target.value == 'Custom product'){
        this.custom_product=true
      }
    
    }
  }
  onChangeMAilingList = (event) => {
    if(event.target.value){
        this.quoteCreateForm.get('brief_description').setValue('');
    }
  }
  initEddm = (formdata=null) => {
    const controls = [
      'width', 'height', 'class_of_mail', 'pieces_mailed_at_a_time', 'project_type', 'versions_of_mail_piece',
      'finished_width', 'finished_height', 'type_of_mailing_piece', 'mailing_list', 'age', 'income', 'length_of_residence',
      'children', 'mailing_address_type', 'mail_date','mail_piece_options','envelope_other','envelop_pages','flat_size','finished_size','booklet_finished_size','booklet_pages','brief_description','mailing_request','brief_Desc'
    ];

    controls.map(control => {
      let newVar = formdata?control != 'mailing_list'?formdata[control]:'':'';
      this.quoteCreateForm.addControl(control, new FormControl(newVar));
    });
    if(!this.editData){
        this.quoteCreateForm.get('mailing_request').setValue(1);
    }
    // this.eddmForm = this._fb.group({
    //   width: [''],
    //   height: [''],
    //   class_of_mail: [''],
    //   pieces_mailed_at_a_time: [''],
    //   project_type: [''],
    //   versions_of_mail_piece: [''],
    //   finished_width: [''],
    //   finished_height: [''],
    //   type_of_mailing_piece: [''],
    //   mailing_list: [''],
    //   age: [''],
    //   income: [''],
    //   length_of_residence: [''],
    //   children: [''],
    //   mailing_address_type: [''],
    //   mail_date: ['']
    // });
  }

  uploadDocEddm = (event: any) => {
    const files = event.target.files;
    let i = this.eddmFiles.length;
    for (const file of files) {
      const ext = file['name'].substring(file['name'].lastIndexOf('.') + 1).toLowerCase();
      let flag = true;
      this.docArrEddm.forEach((value) => {
        if (value === file.name) {
          flag = false;
        }
      });
      if (flag) {
        this.docArrEddm.push(file.name);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event1: Event) => {
          this.eddmFiles[i] = {};
          this.eddmFiles[i]['file'] = file;
          const pathValue = this.csService.getLogoUrl(ext);
          if (!pathValue) {
            this.eddmFiles[i]['url'] = event1.target['result'];
          } else {
            this.eddmFiles[i]['url'] = pathValue;
          }
          i++;
        };
      }
    }
  }

  removeDocEddm = (doc, i) => {
    this.eddmFiles.splice(i, 1);
    this.docArrEddm.forEach((value, key) => {
      if (key === +i) {
        this.docArrEddm.splice(key, 1);
      }
    });
    
    this.eddmArr.forEach((value, key) => {
      if (key === +i) {
        this.eddmArr.splice(key, 1);
      }
    });
    this.onDatePicked.emit(this.eddmArr)
    this.oldDocArr.forEach((value, key) => {
      if (key === +i) {
        this.oldDocArr.splice(key, 1);
      }
    });
  }

  onSubmit = () => {

  }

}
