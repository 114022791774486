import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { DataTableService } from '../../service/datatable.service';
import { CommonService } from '../../service/common.service';
import * as XLSX from 'xlsx';
import { FranchiseService } from '../../service/franchise.service';
import { AuthenticationService } from '../../service/authentication.service';
import { UserService } from '../../service/user.service';
import { RolepermissionService } from '../../service/rolepermission.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import {DateFilterPipe} from '../../pipes/datefilter.pipe';

@Component({
  templateUrl: './global-margin-report.component.html',
  styleUrls: ['./global-margin-report.component.scss'],
  selector: 'app-global-margin-report',
  providers: [DateFilterPipe]
})
export class GlobalMarginReportComponent implements OnInit, OnDestroy {
  constructor(
    private _dtService: DataTableService,
    private userService: UserService,
    public commonService: CommonService,
    private franchiseService: FranchiseService,
    private authService: AuthenticationService,
    private rolePermissionService: RolepermissionService,
    private router: Router,
    private dateFilterPipe: DateFilterPipe
  ){}

  orderCommissionDatatable: any = null;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  loading = false;  

  currentUser: any = null;
  salesOrders = [];
  grand_total_sales_amount = 0;
  grand_total_purchase_amount = 0;
  grand_total_csr_commission = 0;
  grand_total_distributor_commission = 0;

  franchiseList:any = [];
  franchise_id:any = null;
  csrList:any = [];
  csr_user_id: any = null;
  salesRepList:any = [];
  sales_rep_id:any = null;
  complete_orders = false;

  userData:any = {};

  options: any = {
    locale: { format: 'MM/DD/YYYY' },
    alwaysShowCalendars: false,
    startDate: moment().startOf('month'),
    endDate: moment().endOf('month'),
    ranges: {
      'Today': [moment(), moment()],
      'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
      'All' : [moment("2019-01-01"), moment()] //moment().subtract(3, 'year').startOf('year')
    }
  };

  date_filter:any = {
    from: moment().startOf('month').format(),
    to: moment().endOf('month').format(),
    filter_by: 'created'
  };

  async ngOnInit() {
    this.userData = this.franchiseService.franchiseData.getValue();
    await new Promise(resolve => {
      this.authService.userData.subscribe((res) => {
        this.currentUser = res['id'];
        resolve(res);
      });
    });

    this.franchiseService.getAllFranchise().subscribe((res) => {
      this.franchiseList = res['data'];
    });

    this.userService.getUsersOfAllFranchiseByRole({role: 'Sales Rep'}).subscribe((res) => {
      this.salesRepList = res['data'];
    });

    this.userService.getUsersOfAllFranchiseByRole({role: 'Estimator HOD'}).subscribe((res) => {
      this.csrList = res['data'];
    });

    let configStatus:any = this.commonService.configStatus.getValue();
    if (Object.keys(configStatus).length === 0) {
      await new Promise(resolve => {
        this.commonService.getConfigStatusData().subscribe(res => {
          configStatus = res;
          this.commonService.configStatus.next(res);
          resolve(res);
        });
      });
    }

    this.getOrderCommissionReport();
  }

  getOrderCommissionReport() {
    // Note: Here we want grand total of sales, purchase and commission which require execution of commission calculation on each order therefor we've implemented client side pagination instead of serverside pagination
    let data:any = {
      franchise_id: (this.franchise_id || []).map(item => item.id),
      sales_rep_id: this.sales_rep_id,
      csr_user_id: this.csr_user_id,
      from: this.date_filter.from,
      to: this.date_filter.to,
      complete_orders: this.complete_orders,

      search: '',
      order: []
    };

    this.loading = true;
    if (this.orderCommissionDatatable) {
      this.orderCommissionDatatable.clear().destroy();
    }

    let dataTablesParameters:any = {};
    this._dtService.getTableData(dataTablesParameters, "global-margin-report", data)
    .subscribe((res) => {
      this.salesOrders = res.data.original.data || [];

      this.grand_total_sales_amount = ((this.salesOrders || []).reduce((previous, current) => previous + (current.sale_amt || 0), 0)).toFixed(2);
      this.grand_total_purchase_amount = ((this.salesOrders || []).reduce((previous, current) => previous + (current.purchase_amt || 0), 0)).toFixed(2);
      this.grand_total_csr_commission = ((this.salesOrders || []).reduce((previous, current) => previous + (current.csr_commission_amt || 0), 0)).toFixed(2);
      this.grand_total_distributor_commission = ((this.salesOrders || []).reduce((previous, current) => previous + (current.sales_rep_commission_amt || 0), 0)).toFixed(2);

      setTimeout(()=> {
        this.rerender();
      }, 1000);
    });
  }

  rerender() {
    this.dtOptions = {
      dom: "<'row'<'col-sm-12 col-md-4'l><'col-sm-12 col-md-4 top'i><'col-sm-12 col-md-4 top'f>>" +
      "<'row'<'col-sm-12'tr>>" +
      "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
      pagingType: "full_numbers",
      pageLength: 10,
      lengthMenu: [5, 10, 25, 50],
      scrollX: true,
      scrollY: "500px",
      language: {
        searchPlaceholder: "Search using Order No..",
        emptyTable: "No record found!",
        search: "",
      },
      ordering: false,
      destroy: true
    };

    this.orderCommissionDatatable = $('#orders-commission-table').DataTable(this.dtOptions);
    this.loading = false;
  }

  dropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    placeholder: 'Distributor Network',
    allowSearchFilter: true,
    closeDropDownOnSelection: true
  };

  selectedDate (value: any) {
    this.date_filter.from = value.start.format();
    this.date_filter.to = value.end.format();
  }

  exportToExcel(type = 'XLSX') {
    this.loading = true;
    let xlsJSON = [];
        
    (this.salesOrders || []).forEach((item) => {  
      let obj = {};
      obj["Distributor Network"] = item.franchise.name;
      obj["Order Created On"] = item["created_at"] ? this.dateFilterPipe.transform(item["created_at"]) : "";
      obj["Invoice"] = item["invoice_uid"];
      obj["Order"] = item["sales_order_uid"];
      obj["Bill"] = ((item['order_bills'] || []).map(bill => bill.bill_uid)).join(", ");
      obj["Client"] = item["client_name"];
      obj["Order Status"] = this.commonService.getStatusName(item['status'],'project_order');
      obj["Base Sale"] = parseFloat(item["net_sale_amt"] || 0);
      obj["Shipping"] = parseFloat(item["total_shipping_cost"] || 0);
      obj["Total Sale"] = parseFloat(item["sale_amt"] || 0);
      obj["Purchase Cost"] = parseFloat(item["net_purchase_amt"] || 0);
      obj["Shipping Cost"] = parseFloat(item["net_shipping_cost"] || 0);
      obj["Total Order Cost"] = parseFloat(item["purchase_amt"] || 0);
      obj["Bank Charge"] = parseFloat(item["bank_charge_amt"] || 0);
      obj["Gross Profit"] = parseFloat(item["gross_profit"] || 0);
      obj["Distributor"] = (item.project_of_order.sales_rep) ? item.project_of_order.sales_rep.full_name : '';
      obj["Distributor Commission"] = parseFloat(item.sales_rep_commission_amt || 0);
      obj["Secondary Distributor"] = (item.project_of_order.second_sales_rep) ? item.project_of_order.second_sales_rep.full_name : '';
      obj["Secondary Distributor Commmission"] = parseFloat(item.second_sales_rep_commission_amt || 0);
      obj["Account Manager"] = (item.project_of_order.csr) ? item.project_of_order.csr.full_name : '';
      obj["Account Manager Commmission"] = parseFloat(item.csr_commission_amt || 0);
      obj["PO-Bill Variation"] = item.amount_flag;
      obj["Payment Date"] = this.customDateChange(item['payment_date']);
      obj["Payment Due"] = parseFloat(item.order_payment_due || 0);
      xlsJSON.push(obj);
    });

    xlsJSON.push({});
    xlsJSON.push({
      'Distributor Network': 'Total Order Value',
      'Order Created On': this.grand_total_sales_amount || 0
    });
    xlsJSON.push({
      'Distributor Network': 'Total Order Cost',
      'Order Created On': this.grand_total_purchase_amount || 0
    });
    xlsJSON.push({
      'Distributor Network': 'Total Account Manager Commission',
      'Order Created On': this.grand_total_csr_commission || 0
    });
    xlsJSON.push({
      'Distributor Network': 'Total Distributor Commission',
      'Order Created On': this.grand_total_distributor_commission || 0
    });

    const worksheet1: XLSX.WorkSheet = XLSX.utils.json_to_sheet(xlsJSON);
    const csvOutput: string = XLSX.utils.sheet_to_csv(worksheet1);
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet1, 'Sheet1');
  
    if (type == 'CSV') {
      XLSX.writeFile(workbook,'Margin Report.csv')
    } else {
      XLSX.writeFile(workbook,'Margin Report.xlsx', { bookType: 'xlsx', type: 'buffer' } );
    }

    this.loading = false;
  }

  navigateTo(franchiseId, salesOrderId, page = 'Sales Order') {
    this.franchiseService.refreshFranchiseData(franchiseId).then(() => {});
    if (franchiseId) {
      this.loading = true;
      this.rolePermissionService.getPermissions(franchiseId).subscribe(res => {
        if (res) {
          this.rolePermissionService.permissions.next(res['data']['permissions']);
          this.rolePermissionService.unread.next(res['data']['unread']);
          this.rolePermissionService.notificationData.next(res['data']['notification']);
          this.rolePermissionService.projectsData.next(res['data']['projects']);
        }
        this.loading = false;
        if (page == 'Sales Order') {
          this.router.navigateByUrl('/'+franchiseId+'/sales-order/view/'+salesOrderId);
        } else if (page == 'Invoice') {
          this.router.navigateByUrl('/'+franchiseId+'/sales-order/print-invoice/'+salesOrderId);
        }
      });
    }
  }

  customDateChange = (date) => {
    // input format dd-mm-yyyy
    if (date) {
      let dateArray = date.split("-");
      let newDate = dateArray[1] + "-" + dateArray[0] + "-" + dateArray[2];
      return newDate;
    }
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }
}