import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class ShippingAddressService {

  constructor(private _http: HttpClient, private _cs: CommonService) { }

  getShippingAddress = (id) => {
    return this._http.get(environment.API_URL + 'shipping-address/get-Shipping-address/' + id, this._cs.setHeader());
  }

  saveShippingAddress = (address: any) => {
    return this._http.post(environment.API_URL + 'shipping-address/save', address, this._cs.setHeader());
  }

  deleteShippingAddress = (id) => {
    return this._http.delete(environment.API_URL + 'shipping-address/delete/' + id, this._cs.setHeader());
  }

  shippingAddressByContactId = (id) => {
    return this._http.get(environment.API_URL + 'shipping-address/addresses-by-contact/' + id, this._cs.setHeader());
  }

  importAddress = (file: File, id: number) => {
    const formData = new FormData();
    formData.append('file', file);
    return this._http.post(environment.API_URL + 'shipping-address/save-multiple-address/' + id, formData, this._cs.setHeader());
  };

  updateShippingAddress = (payload) => {
    return this._http.post(environment.API_URL + 'shipping-address/update-multiple-address', payload, this._cs.setHeader());
  }
}