import { Component, OnInit } from '@angular/core';
import { DecorationCalculatorService } from '../../service/decoration-calculator.service';
import { HttpEventType } from '@angular/common/http';
import Swal from 'sweetalert2';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-price-list',
  templateUrl: './price-list.component.html',
  styleUrls: ['./price-list.component.scss']
})
export class PriceListComponent implements OnInit {

  selectedFiles: { [key: number]: File | null } = {};
  loadingStates: { [key: number]: { upload: boolean; download: boolean } } = {};


  uploadCards: any[] = [
    { id: 1, title: 'Screen Printing', fileName: 'No file chosen', downloadFileName:'screen-printing-price.xlsx' },
    { id: 2, title: 'DTG/DTF Printing', fileName: 'No file chosen', downloadFileName:'dtf-printing-price.xlsx' },
    { id: 3, title: 'Embroidery Printing', fileName: 'No file chosen', downloadFileName:'embriodery-printing-price.xlsx' }
  ];

  constructor(private _decorationCalculatorService: DecorationCalculatorService) { }

  ngOnInit() {
  }

  onFileSelected(event: Event, cardId: number): void {
    const element = event.target as HTMLInputElement;
    const file = element.files ? element.files[0] : null;

    if (file) {
      this.selectedFiles[cardId] = file;
      const card = this.uploadCards.find(c => c.id === cardId);
      if (card) {
        card.fileName = file.name;
      } else {
        console.error(`Card with id ${cardId} not found.`);
      }
    } else {
      console.error('No file selected.');
    }
  }

uploadFile(cardId: number): void {
  this.loadingStates[cardId] = { upload: true, download: false };
  const file = this.selectedFiles[cardId];
  if (file) {
    const serviceMethodMap: { [key: number]: (file: File) => Observable<any> } = {
      1: this._decorationCalculatorService.uploadScreenPrintingPrice,
      2: this._decorationCalculatorService.uploadDtfPrintingPrice,
      3: this._decorationCalculatorService.uploadEmbroideryPrintingPrice
    };

    const uploadMethod = serviceMethodMap[cardId];

    if (uploadMethod) {
      uploadMethod.call(this._decorationCalculatorService, file).subscribe(
        res => {
          delete this.selectedFiles[cardId];
          const card = this.uploadCards.find(c => c.id === cardId);
          Swal.fire({
            title: 'Success',
            text: `File ${card.fileName} uploaded successfully.`,
            type: 'success',
            confirmButtonText: 'OK'
          });
          if (cardId) {
            card.fileName = "No file chosen";
          }
          this.loadingStates[cardId] = { upload: false, download: false };
        },
        (error) => {
          delete this.selectedFiles[cardId];
          const card = this.uploadCards.find(c => c.id === cardId);
          console.error(`File ${card.fileName} upload failed:`, error);
          Swal.fire({
            title: 'Error',
            text: `File ${cardId} upload failed.`,
            type: 'error',
            confirmButtonText: 'OK'
          });
          if (cardId) {
            card.fileName = "No file chosen";
          }
          this.loadingStates[cardId] = { upload: false, download: false };
        }
      );
    }
  } else {
    delete this.selectedFiles[cardId];
    const card = this.uploadCards.find(c => c.id === cardId);
    console.error(`No file selected for card ${cardId}.`);
    Swal.fire({
      title: 'Error',
      text: `No file selected for card ${cardId}.`,
      type: 'error',
      confirmButtonText: 'OK'
    });
    if (cardId) {
      card.fileName = "No file chosen";
    }
    this.loadingStates[cardId] = { upload: false, download: false };
  }
}


downloadFile(cardId: number): void {
  this.loadingStates[cardId] = { upload: false, download: true };
  const card = this.uploadCards.find(c => c.id === cardId);
    this._decorationCalculatorService.downloadScreenPriceList(card.downloadFileName).subscribe(
      blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = card.downloadFileName
        a.click();
        window.URL.revokeObjectURL(url);
        this.loadingStates[cardId] = { upload: false, download: false };
      },
      error => {
        console.error('Download failed', error);
        this.loadingStates[cardId] = { upload: false, download: false };
      }
    );
}

}
