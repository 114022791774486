import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DecorationCalculatorService {

  constructor(private _http: HttpClient) {}

  getColorsOptions = () => {
    return this._http.get(environment.PROMO_STANDARDS_URL + 'printing/screen/colorsOptions');
  }

  getSizeOptions = () => {
    return this._http.get(environment.PROMO_STANDARDS_URL + 'printing/dtf/sizeOptions');
  }

  getStitchOptions = () => {
    return this._http.get(environment.PROMO_STANDARDS_URL + 'printing/embriodery/stitchesOptions');
  }

  getCalculateDtfPrintingPrice = (qyt,option,basePrice) => {
    return this._http.get(environment.PROMO_STANDARDS_URL + 'printing/dtf/priceBySizeAndQyt/'+qyt+'/'+option+'/'+basePrice);
  }

  getCalculateScreenPrintingPrice = (qyt,option,basePrice) => {
    return this._http.get(environment.PROMO_STANDARDS_URL + 'printing/screen/priceByColorAndQyt/'+qyt+'/'+option+'/'+basePrice);
  }

  getCalculateEmrioderyPrintingPrice = (qyt,option,basePrice) => {
    return this._http.get(environment.PROMO_STANDARDS_URL + 'printing/embriodery/priceByStitchesAndQyt/'+qyt+'/'+option+'/'+basePrice);
  }

  uploadScreenPrintingPrice(file: File) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    const url = encodeURI(`${environment.PROMO_STANDARDS_URL}printing/screen/uploadPriceSheet`);
    return this._http.post(url, formData);
  }

  uploadDtfPrintingPrice(file: File) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    const url = encodeURI(`${environment.PROMO_STANDARDS_URL}printing/dtf/uploadPriceSheet`);
    return this._http.post(url, formData);
  }

  uploadEmbroideryPrintingPrice(file: File) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    const url = encodeURI(`${environment.PROMO_STANDARDS_URL}printing/embriodery/uploadPriceSheet`);
    return this._http.post(url, formData);
  }

  downloadScreenPriceList = (name) => {
    return this._http.get(environment.PROMO_STANDARDS_URL + 'printing/downloadPriceList/'+name,{ responseType: 'blob' });
  }
}
