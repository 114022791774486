import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../../service/authentication.service';

@Component({
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotpasswordForm: FormGroup;
  api_error = [];
  successMessage = false;

  ngOnInit() {
    this.initForgotPasswordForm();
  }

  constructor(private _fb: FormBuilder, private authservice: AuthenticationService) {
  }

  initForgotPasswordForm = () => {
    this.forgotpasswordForm = this._fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  changePassword = () => {
    if (this.forgotpasswordForm.valid) {
      const email = this.forgotpasswordForm.value;
      this.authservice.changePassword(email).subscribe((res) => {
        if (res) {
          this.successMessage = true;
        }
      },
        error => {
          if (error['error']) {
            this.api_error = error['error']['data'];
          }
        });
    }
  }

  clearapiError = (value) => {
    delete this.api_error[value];
  }
}
