import { Component, OnInit, OnDestroy, NgZone, ViewChild, ElementRef, Renderer2, HostListener, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { navItems } from './../../_nav';
import { AuthenticationService } from '../../service/authentication.service';
import { RolepermissionService } from '../../service/rolepermission.service';
import { DevRolepermissionService } from '../../service/DevAdmin/rolepemission.service';
import { environment } from '../../../environments/environment';
import { FranchiseService } from '../../service/franchise.service';
import { BootController } from '../../../boot-controller';
import { CommonService } from '../../service/common.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ChatService } from '../../service/chat.service';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { Utils } from '../../utils';
import { PusherService } from '../../service/pusher.service';
import { NotificationService } from '../../service/notification.service';
import { UserService } from '../../service/user.service';
import * as introJs from 'intro.js/intro.js';
import { introSteps } from '../../../assets/intro-json/intro-steps';
import { AppConstants } from '../../constants/app.constants';
import { ModalDirective } from 'ngx-bootstrap';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss'],
  host: {
    '(document:click)': 'onOutsideClick($event)',
  },
})

export class DefaultLayoutComponent implements OnInit, OnDestroy {
  @ViewChild('MarketingAssets') public modal: ModalDirective;
  public navItems: any;
  public chatForm: FormGroup;
  public sidebarMinimized = true;
  public isNotSalesRep = false;
  public isChat = false;
  public outSide = false;
  private changes: MutationObserver;
  public element: HTMLElement = document.body;
  public sub = new Subscription();
  public userData = {};
  public searchUser = '';
  public searchProject = '';
  public salesrep = false;
  public franchiseData = {};
  public myNavItems: any = [];
  public userList: any = [];
  public projectList = [];
  public tempProjectList = [];
  public selectedUser = {};
  public logo = '';
  private roleService = null;
  public loaded = false;
  public profileImage: any = '';
  public defaultImage = '';
  public messages: any = [];
  public franchiseId = '';
  public unreadCount: any;
  public selectedProject: any = [];
  public projectUnreadCount = 0;
  public userUnreadCount = 0;
  public showModelByCookie: boolean = false;
  currentUserUserName:string = ''
  currentUserEmail:string = '';
  isModalShown:boolean = false;
  canViewProjectChat = false;
  notificationData: any = [];
  loading = false;
  franchiseUrl = '';
  safeVideoURL:SafeResourceUrl;
  videoUrl:string = 'https://www.youtube.com/embed/'+'Jv4F5gwDMrI';
  status: any = {
    loading: false,
    success: false,
    error: false
  };
  public imagePath = '';
  public currentIntroSteps = [];
  newUpdate : any = [];
  animation : boolean = true;
  isEstimator:boolean = false;
  showChat:boolean = false;
  // public intro = introJs();
  @ViewChild('users') users: ElementRef;
  @ViewChild('projects') projects: ElementRef;
  @ViewChild('msgContainer') msgContainer: ElementRef;
  @ViewChild('msgScrollBar') msgScrollBar: PerfectScrollbarComponent;
  @ViewChild('chatDiv') chatDiv: ElementRef;
  @ViewChild('chatIcon') chatIcon: ElementRef;

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private _fb: FormBuilder,
    public sanitizer: DomSanitizer,
    private franchiseService: FranchiseService,
    public csService: CommonService,
    private chatService: ChatService,
    private rolePermissionService: RolepermissionService,
    private devRolePermission: DevRolepermissionService,
    private ngZone: NgZone,
    private pusherService: PusherService,
    private utils: Utils,
    private renderer: Renderer2,
    private activatedRoute: ActivatedRoute,
    private _notifService: NotificationService,
    private userService:UserService,
    private _el: ElementRef
  ) {
    this.changes = new MutationObserver(mutations => {
      this.sidebarMinimized = document.body.classList.contains(
        'sidebar-minimized'
      );
    });
    this.safeVideoURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.videoUrl);
    if (localStorage.getItem('designation') && localStorage.getItem('designation') === 'da') {
      this.roleService = this.devRolePermission;
      const logo = environment.STATIC_IMAGE;
      this.logo = logo;
      const elementRef = this.element.getElementsByClassName(
        'navbar-brand-full'
      );
      if (elementRef.length > 0) {
        elementRef.item(0).setAttribute('src', this.logo);
      }
    } else {
      this.roleService = this.rolePermissionService;
    }
    this.navItems = JSON.parse(JSON.stringify(navItems));

    this.changes.observe(<Element>this.element, {
      attributes: true
    });
  }

  close = () => {
    this.selectedProject = [];
    this.selectedUser = {};
    this.isChat = false;
  }

  ngOnInit() {
    this.subscribeToIntroType();
    this.initChatForm();
    this.onNewUpdateClick();
    this.defaultImage = '../../../assets/img/profile_card.png';
    this.imagePath = environment.AWS_PROFILE_PATH;
    this.activatedRoute.params.subscribe((res) => {
      if (res['id']) {
        this.franchiseId = res['id'];
      }
    });

    this.franchiseUrl = this.csService.getUrlTillFranchise();
    const franchiseData = this.franchiseService.franchiseData.getValue();
    
    if(franchiseData && franchiseData['role']){
      if (franchiseData['role']['original_name'] === AppConstants['roles']['estimator']) {
        this.isEstimator = true;
      }
    }

    this.profileImage = '../../../assets/img/profile.jpg';

    new Promise(resolve => {
      const userDataSubscription = this.authService.userData.subscribe(resData => {
        let cookieKey = resData['username'];
        let cookievalue = this.getCookie(cookieKey);
  
        if(resData['email'] == cookievalue) {
          this.showModelByCookie = true;
        }
  
        const url = window.location.pathname;
        var parts = url.split('/');
        var lastSegment = parts.pop() || parts.pop(); 
  
        if(lastSegment == 'dashboard' && !this.showModelByCookie) {
          this.openMarketingAssetsModel();
        }
  
        this.currentUserUserName = resData['username'];
        this.currentUserEmail = resData['email'];
        this.setUserCookie();
  
        if (Object.keys(resData).length > 0) {
          this.userData = resData;
          if (this.userData['roles'][0]['id'] !== 1) {
            this.notificationData = this.roleService.notificationData.getValue();
            this.unreadCount = this.roleService.unread.getValue();
          }
          if (this.userData['profile_image']) {
            this.profileImage = environment.AWS_PROFILE_PATH + this.userData['profile_image'];
          } else {
            this.profileImage = '../../../assets/img/profile_card.png';
          }
          if (this.userData['roles'] && this.userData['roles'].length > 0) {
            if (this.userData['roles'][0]['id'] !== 1) {
              const franchiseDataSubscription = this.franchiseService.franchiseData.subscribe(
                res => {
                  if (Object.keys(res).length > 0) {
                    this.franchiseData = res;
                    if (res['logo'] !== '') {
                      const logo = this.csService.UPLOAD_FILE_AWS('franchise_images');
                      this.logo = logo + res['logo'];
                    } else {
                      this.logo = this.csService.UPLOAD_FILE_AWS('franchise_images', true);
                    }
                    const elementRef = this.element.getElementsByClassName(
                      'navbar-brand-full'
                    );
                    if (elementRef.length > 0) {
                      elementRef.item(0).setAttribute('src', this.logo);
                    }
                  }
                }
              );
              this.sub.add(franchiseDataSubscription);
            }
          }
        }

        // Permanent secondary distributor can see only some menus on his dashboard
        if (resData['is_secondary_distributor'] == 1) {
          const rootMenus = ['Dashboard', 'Reports', 'My Accounts'];
          const subMenus = [
            'Project Report', 'Sales Report', 'Invoice Report', 'Commission Report',
            'Accounts'
          ];
          this.navItems = this.navItems.filter(item => rootMenus.includes(item.name))
          .map(item => {
            if (item.children && item.children.length) {
              item.children = item.children.filter(childMenu => subMenus.includes(childMenu.name));
            }
            return item;
          });
        }
        resolve(resData);
      });
      this.sub.add(userDataSubscription);
    }).then(res => {
      const permissionSubscription = this.roleService.permissions.subscribe(permissions => {
        const tempUserData = this.authService.userData.getValue();
        if (Object.keys(tempUserData).length > 0
          && (tempUserData['roles'][0]['id'] === 1 || this.roleService.permissions.getValue().length > 0)) {
  
          if (tempUserData['roles'][0]['id'] !== 1) {
            this.pusherNotification();
          }
  
          this.myNavItems = [];
          this.navItems.forEach((item, key) => {
            const navItem = JSON.parse(JSON.stringify(item));
            if (navItem['data']) {
              if (navItem['data']['onlyAdmin']) {
                if (tempUserData['roles'][0]['id'] === 1) {
                  this.myNavItems.push(navItem);
                }
              } else if (navItem['data']['permissions']) {
                let hasPermission = false;
                hasPermission = this.roleService.hasAnyPermissions(navItem['data']['permissions']);
                if (hasPermission) {
                  if (item['children'] && item['children'].length > 0) {
                    navItem['children'] = [];
                    for (let x = 0; x < item['children'].length; x++) {
                      if (item['children'][x]['data']) {
                        if (this.roleService.hasAnyPermissions(item['children'][x]['data']['permissions'])) {
                          item['children'][x]['url'] = '/' + this.franchiseId + item['children'][x]['url'];
                          navItem['children'].push(item['children'][x]);
                        }
                      }
                    }
                  }
                  navItem['url'] = '/' + this.franchiseId + navItem['url'];
                  this.myNavItems.push(navItem);
                }
              } else {
                this.myNavItems.push(navItem);
              }
            } else {
              navItem['url'] = '/' + this.franchiseId + navItem['url'];
              this.myNavItems.push(navItem);
            }
          });
          this.myNavItems = [...this.myNavItems];
        }
      });
      this.sub.add(permissionSubscription);
    });
  }

  removeAnimation = () => {
    this.animation = false;
    $('.blinking').removeClass("blinking")
    $('.btn-blue-text').removeClass('btn-blue-text')
    $("#new").removeAttr("style");
  }

  viewAllNotification = () => {
    const url = window.location.pathname;
    const urlArray = url.split('/');
    const franchises = JSON.parse(localStorage.getItem('franchise_ids'));
    if (franchises) {
      if (franchises.length > 0) {
        if (!isNaN(+urlArray[1]) && franchises.includes(+urlArray[1])) {
          this.router.navigate([urlArray[1] + '/notification']);
        }
      }
    } else {
      this.router.navigate(['/notification']);
    }
  }

  pusherNotification = () => {
    this.pusherService.chatChannel.bind('App\\Events\\PusherChat', res => {
      const data = res['data'];

      if (Object.keys(this.selectedUser).length === 0) {
        this.unreadCount += 1;
        if (this.userList.length > 0) {
          for (const user of this.userList) {
            if (+data['sender_id'] === +user['id']) {
              user['unread'] = +user['unread'] + 1;
            }
          }
        }
      } else {
        if (this.selectedUser['id'] === +data['sender_id']) {
          this.messages.push(data);
        } else {
          if (this.userList.length > 0) {
            this.unreadCount += 1;
            for (const user of this.userList) {
              if (+data['sender_id'] === +user['id']) {
                user['unread'] = +user['unread'] + 1;

              }
            }
          }
        }
        this.scrollToLatestMessage(true);
      }
      this.sortBy('unread_count');

    });

    // end

    // for project group channel
    let franchiseData = this.franchiseService.franchiseData.getValue();
    if(franchiseData['role']) {
      if (franchiseData['role']['original_name'] !== 'Supplier') {
        this.pusherService.projectChatChannel.forEach(singleChannel => {
          singleChannel.bind('App\\Events\\PusherProjectChat', res => {
            if (res['data']) {
              if (+res['data']['sender_id'] !== +this.userData['id']) {
                const data = res['data'];
                if (this.selectedProject.length === 0) {
                  if (this.userList.length > 0) {
                    this.unreadCount += 1;
                    this.projectUnreadCount += 1;
                    for (const project of this.projectList) {
                      if (+data['project_id'] === +project['id']) {
                        project['unread'] = +project['unread'] + 1;
                      }
                    }
                  } else {
                    this.unreadCount += 1;
                  }
                } else {
                  if (this.selectedProject.length > 0) {
                    if (this.selectedProject[0]['id'] === res['project_id']) {
                      this.messages.push(res['data']);
                      this.scrollToLatestMessage(true);
                    } else {
                      this.projectUnreadCount += 1;
                      for (const project of this.projectList) {
                        if (+data['project_id'] === +project['id']) {
                          project['unread'] = +project['unread'] + 1;
                        }
                      }
                    }

                  } else {
                    if (this.userList.length === 0) {
                      this.unreadCount += 1;
                    }
                  }
                }
              }
            }
          });
        });
      }
    }else {
      this.franchiseService.refreshFranchiseData();
      this.franchiseService.getFranchiseData().subscribe((res) => {
        franchiseData = res['data'];
        if (franchiseData['role']['original_name'] !== 'Supplier') {
          this.pusherService.projectChatChannel.forEach(singleChannel => {
            singleChannel.bind('App\\Events\\PusherProjectChat', res => {
              if (res['data']) {
                if (+res['data']['sender_id'] !== +this.userData['id']) {
                  const data = res['data'];
                  if (this.selectedProject.length === 0) {
                    if (this.userList.length > 0) {
                      this.unreadCount += 1;
                      this.projectUnreadCount += 1;
                      for (const project of this.projectList) {
                        if (+data['project_id'] === +project['id']) {
                          project['unread'] = +project['unread'] + 1;
                        }
                      }
                    } else {
                      this.unreadCount += 1;
                    }
                  } else {
                    if (this.selectedProject.length > 0) {
                      if (this.selectedProject[0]['id'] === res['project_id']) {
                        this.messages.push(res['data']);
                        this.scrollToLatestMessage(true);
                      } else {
                        this.projectUnreadCount += 1;
                        for (const project of this.projectList) {
                          if (+data['project_id'] === +project['id']) {
                            project['unread'] = +project['unread'] + 1;
                          }
                        }
                      }
    
                    } else {
                      if (this.userList.length === 0) {
                        this.unreadCount += 1;
                      }
                    }
                  }
                }
              }
            });
          });
        }
      });
    }
    // end

    // for activity notification channel
    this.pusherService.notificationChannel.bind('App\\Events\\NotifyEvent', res => {
      if (res['data']) {
        this.notificationData['data'].unshift(res['data']);
        this.notificationData['unread'] = +this.notificationData['unread'] + 1;
      }
    });
    // end
  }

  testNotif = () => {
    if (!('Notification' in window)) {
      alert('Web Notification is not supported');
      return;
    }
    Notification
      .requestPermission()
      .then(() => {
        const _notify = new Notification('AWI Notification',
          { body: 'test message', icon: '../../../assets/img/optahub.svg' }
        );
        setTimeout(() => {
          _notify.close();
        }, 5000);
      });
  }

  sortBy(field: string) {
    const users = [...this.userList];
    users.sort((a: any, b: any) => {
      if (a['unread'] && b['unread']) {
        if (a['unread'] > b['unread']) {
          return 1;
        }
        return 0;
      }
      return 0;
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.pusherService.chatChannel.unbind('App\\Events\\PusherChat');
    this.pusherService.notificationChannel.unbind('App\\Events\\NotifyEvent');
    this.pusherService.unbindProjectChannels();
  }

  AllActivitylist = () => {
    const url = window.location.pathname;
    const urlArray = url.split('/');
    const franchises = JSON.parse(localStorage.getItem('franchise_ids'));
    if (franchises) {
      if (franchises.length > 0) {
        if (!isNaN(+urlArray[1]) && franchises.includes(+urlArray[1])) {
          this.router.navigate([urlArray[1] + '/activity']);
        }
      }
    } else {
      this.router.navigate(['activity']);
    }
  }


  scrollToLatestMessage(scrollToEndOfConversation = false) {
    if (scrollToEndOfConversation) {
      setTimeout(() => {
        this.msgScrollBar.directiveRef.scrollToBottom(0, 500);
      }, 100);
    }
  }

  chatOpen = (user, index) => {
    this.selectedProject = [];
    if (user['id']) {
      this.chatForm.get('project_id').setValue('');
      if (this.unreadCount > 0) {
        this.unreadCount = this.unreadCount - user['unread'];
      }

      setTimeout(() => {
        user['unread'] = 0;
      }, 200);
      this.chatForm.get('receiver_id').setValue(user['id']);
      this.userList.forEach(key => {
        if (key['id'] === +user['id']) {
          this.selectedUser = key;
        }
      });
      this.status = this.utils.showContainers(this.status, 'loading');
      this.chatService.getMessageOfUser(user['id']).subscribe(res => {
        this.messages = res['data'];
        this.scrollToLatestMessage(true);
        this.status = this.utils.showContainers(this.status, 'success');
      });
    }
    Object.keys(this.users['nativeElement']['children']).forEach(key => {
      if (index === +key) {
        this.renderer.addClass(this.users['nativeElement']['children'][key], 'active');
      } else {
        this.renderer.removeClass(this.users['nativeElement']['children'][key], 'active');
      }
    });
  }

  openProjectChat = (project, index) => {
    this.selectedUser = {};
    this.selectedProject = [];
    this.selectedProject.push(project);
    this.status = this.utils.showContainers(this.status, 'loading');

    const unread = this.unreadCount - project['unread'];
    this.unreadCount = unread > 0 ? unread : 0;

    const projectUnread = this.projectUnreadCount - project['unread'];
    this.projectUnreadCount = projectUnread > 0 ? projectUnread : 0;

    setTimeout(() => {
      project['unread'] = 0;
    }, 200);

    this.chatService.getMessagesOfProject(project['id']).subscribe(
      (res) => {
        this.messages = res['data'];
        this.scrollToLatestMessage(true);
        this.status = this.utils.showContainers(this.status, 'success');
        this.chatForm.get('project_id').setValue(project['id']);
      }
    );
    Object.keys(this.projects['nativeElement']['children']).forEach(key => {
      if (index === +key) {
        this.renderer.addClass(this.projects['nativeElement']['children'][key], 'active');
      } else {
        this.renderer.removeClass(this.projects['nativeElement']['children'][key], 'active');
      }
    });
  }

  selectedUserLength = () => {
    return Object.keys(this.selectedUser).length;
  }

  initChatForm = () => {
    this.chatForm = this._fb.group({
      receiver_id: [''],
      message: [''],
      project_id: ['']
    });
  }

  openChat = () => {
    this.searchUser = '';
    this.selectedUser = {};
    this.selectedProject = [];
    this.isChat = !this.isChat;
    if(this.userData['roles'][0]['original_name'] !== 'Sales Rep'){
    if (this.userList.length === 0) {
      this.chatService.getAllUserForChat().subscribe(res => {
        this.userList = res['data'];
      });
    }}else{
      if (this.userList.length === 0) {
        this.userService.getAllSalesRep().subscribe(res => {
          let data = []
          if(res['admin']){
            data.push(res['admin'])
          }
          if(res['users']){
            res['users'].map((use)=>{
              if(use['Estimator']){
                data.push(use['Estimator'][0])
              }
              data.push(use)
            })
          }
          if(data){
          this.userList = data
          }
        });
      }
    }
    if (this.projectList.length === 0) {
      const franchiseData = this.franchiseService.franchiseData.getValue();
      if (franchiseData['role']['original_name'] === 'Sales Rep') {
        this.salesrep = true;
      }

      if (franchiseData['role']['original_name'] !== 'Supplier') {
        this.canViewProjectChat = true;
        this.chatService.getAllProjectList(this.salesrep).subscribe(res => {
          this.projectList = res['data'];
          this.projectList.forEach(item => {
            this.projectUnreadCount += item['unread'];
          });
          this.tempProjectList = JSON.parse(JSON.stringify(this.projectList));
        });
      } else {
        this.canViewProjectChat = false;
      }
    }
  }

  sendMessage = () => {
    const message = '';
    if (this.chatForm.get('message').value !== null) {
      let postData: any = {};
      const tempMessageString = this.chatForm.get('message').value.trim();
      if (tempMessageString !== '') {
        postData = { ...this.chatForm.value };
      } else {
        return;
      }
      this.chatForm.get('message').setValue('');
      if (postData['project_id']) {
        this.chatService.sendProjectChatMessage(postData).subscribe(
          (res) => {
            this.messages.push(res['data']);
            this.scrollToLatestMessage(true);
          }
        );
      } else {
        this.chatService.sendMessages(postData).subscribe(res => {
          this.messages.push(res['data']);
          this.scrollToLatestMessage(true);
        });
      }
    }
  }

  sendMessageOnEnter(event) {
    // console.log('in');
    // if (event.keyCode === 13 && !event.shiftKey) {
    this.sendMessage();
    // }
  }

  logout = () => {
    this.loading = true;
    this.authService.logout().subscribe(res => {
      if (res) {
        this.loading = false;
        localStorage.clear();
        this.ngZone.runOutsideAngular(() => BootController.getbootControl().restart());
        this.router.navigate(['login']);
      }
    }, error => {
      this.loading = false;
      localStorage.clear();
      this.ngZone.runOutsideAngular(() => BootController.getbootControl().restart());
    });
  }

  profile = () => {
    const url = window.location.pathname;
    const urlArray = url.split('/');
    const franchises = JSON.parse(localStorage.getItem('franchise_ids'));
    if (franchises) {
      if (franchises.length > 0) {
        if (!isNaN(+urlArray[1]) && franchises.includes(+urlArray[1])) {
          this.router.navigate([urlArray[1] + '/user-profile']);
        }
      }
    } else {
      this.router.navigate(['user-profile']);
    }
  }

  changePassword = () => {
    const url = window.location.pathname;
    const urlArray = url.split('/');
    const franchises = JSON.parse(localStorage.getItem('franchise_ids'));
    if (franchises) {
      if (franchises.length > 0) {
        if (!isNaN(+urlArray[1]) && franchises.includes(+urlArray[1])) {
          this.router.navigate([urlArray[1] + '/change-password']);
        }
      }
    } else {
      this.router.navigate(['change-password']);
    }
  }

  onNotifClick = () => {
    if (+this.notificationData['unread'] > 0) {
      setTimeout(() => {
        this._notifService.updateNotifications().subscribe(
          (res) => {
            this.notificationData['unread'] = 0;
            this.notificationData['data'].forEach(item => {
              item['is_read'] = 1;
            });
          }
        );
      }, 1000);
    }
  }
  onNewUpdateClick = () => {
    this.franchiseService.getNewUpdate().subscribe(res=> {
      if (res['data']){
        this.newUpdate = res['data']
      }
    })
  }

  showMessage = (message, user, createdAt) => {
    const createdUser = (user) ? user['first_name'] + ' ' + user['last_name'] : '';
    return message.replace(createdUser, '<strong>' + createdUser + ' <span>' + createdAt + '</span></strong>');
    // return message.replace(createdUser, '<strong>' + createdUser + ' <span>' + moment(createdAt).fromNow() + '</span></strong>');
  }


  salesRepList = () => {
    const url = window.location.pathname;
    const urlArray = url.split('/');
    const franchises = JSON.parse(localStorage.getItem('franchise_ids'));
    if (
      franchises) {
      if (franchises.length > 0) {
        if (!isNaN(+urlArray[1]) && franchises.includes(+urlArray[1])) {
          this.router.navigate([urlArray[1] + '/sales-rep-list']);
        }
      }
    } else {
      this.router.navigate(['sales-rep-list']);
    }
  }
  redirectToLink = (redirectUrl) => {
    const url = window.location.pathname;
    const urlArray = url.split('/');
    const franchises = JSON.parse(localStorage.getItem('franchise_ids'));
    if (franchises) {
      const franchiseData = this.franchiseService.franchiseData.getValue();

      if (franchises.length > 0) {
        switch (redirectUrl) {
          case 'purchase-order':
            if (franchiseData['role']['original_name'] === 'Accountant') {
              redirectUrl = 'accounting/purchase-orders';
            } else {
              // tslint:disable-next-line:no-unused-expression
              redirectUrl;
            }
            break;
          case 'sales-order':
            if (franchiseData['role']['original_name'] === 'Accountant') {
              redirectUrl = 'orders';
            } else {
              // tslint:disable-next-line:no-unused-expression
              redirectUrl;
            }
            break;
          default:
            // tslint:disable-next-line:no-unused-expression
            redirectUrl;
        }
        if (!isNaN(+urlArray[1]) && franchises.includes(+urlArray[1])) {
          this.router.navigate([urlArray[1] + '/' + redirectUrl]);
        }
      }
    } else {
      this.router.navigate(['user-profile']);
    }
  }

  onOutsideClick = (event) => {
    // const chatIconElement = this.chatIcon.nativeElement as HTMLElement;
    // console.log(event.target.contains(chatIconElement));

    // if (this.chatDiv &&
    //   !this.chatDiv.nativeElement.contains(event.target)) {
    //   console.log('in');
    //   this.isChat = false;
    // }
  }

  onSearchProject = (value: string) => {
    if (value.trim()) {
      let tempProjects = [];
      tempProjects = this.projectList.filter(project => {
        return project['project_name'].toLowerCase().indexOf(value.toLowerCase()) > -1
          // || project['project_uid'].toLowerCase().startsWith(value.toLowerCase());
      });
      this.tempProjectList = tempProjects;
    } else {
      this.tempProjectList = this.projectList;
    }
  }

  onComponentDestroy = (event) => {
    this.currentIntroSteps = [];
  }

  subscribeToIntroType = () => {
    this.csService.introType.subscribe(
      (type) => {
        this.currentIntroSteps = [];
        if (type && introSteps[type]) {
          this.currentIntroSteps = introSteps[type];
        }
        // console.log(this.currentIntroSteps);
      }
    );
  }

  startIntro() {
    const intro = introJs();
    intro.setOptions({ steps: this.currentIntroSteps });
    intro.start();
  }

  setUserCookie = () => { 
    var now = new Date();
    var time = now.getTime();
    var expireTime = time + 3600 * 1000 * 24 * 365 * 10;
    now.setTime(expireTime);
    document.cookie = this.currentUserUserName+'='+this.currentUserEmail+';expires='+now.toUTCString()+';path=/';
    //console.log(document.cookie);  // 'Wed, 31 Oct 2012 08:50:17 UTC'
  }

  getCookie = (cname) =>{
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  
  openMarketingAssetsModel = () => {
    this.isModalShown = true;
  }
}