import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../service/authentication.service';
import { FranchiseService } from '../service/franchise.service';
import { RolepermissionService } from '../service/rolepermission.service';
import { DevRolepermissionService } from '../service/DevAdmin/rolepemission.service';

/*
 * @usage Auth Guard check if authenticated or not. If authenticated then
 *        continue otherwise redirect to login.
 */

@Injectable()
export class AuthGuard implements CanActivate {

  rolePermisionService = null;

  constructor(private _router: Router,
    private authService: AuthenticationService,
    private franchiseService: FranchiseService,
    private userRolePermission: RolepermissionService,
    private devRolePermission: DevRolepermissionService) {
    if (localStorage.getItem('designation') && localStorage.getItem('designation') === 'da') {
      this.rolePermisionService = this.devRolePermission;
    } else {
      this.rolePermisionService = this.userRolePermission;
    }
  }

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
    // console.log('in can activate');
    const urlArr = state.url.split('/');
    let userData = this.authService.userData.getValue();
    if (localStorage.getItem('token')) {
      if (Object.keys(userData).length > 0) {
        if (localStorage.getItem('designation') === 'da') {
          if (this.rolePermisionService.permissions.getValue().length > 0) {
            return true;
          } else {
            return this.rolePermisionService.refreshPermissions().then(perm => {
              return true;
            });
          }
        } else {
          if (!isNaN(+urlArr[1]) && localStorage.getItem('franchise_ids')) {
            const allFranchises = JSON.parse(localStorage.getItem('franchise_ids'));
            if (!allFranchises.includes(+urlArr[1]) && !isNaN(+urlArr[1])) {
              this._router.navigate(['account-selection']);//franchise-selection
              return false;
            }
            if (Object.keys(this.franchiseService.franchiseData.getValue()).length > 0) {
              if (this.rolePermisionService.permissions.getValue().length > 0) {
                return true;
              } else {
                return this.rolePermisionService.refreshPermissions().then(perm => {
                  return true;
                });
              }
            } else {
              return this.franchiseService.refreshFranchiseData().then(franchises => {
                if (this.rolePermisionService.permissions.getValue().length > 0) {
                  return true;
                } else {
                  return this.rolePermisionService.refreshPermissions().then(perm => {
                    let franchiseData = this.franchiseService.franchiseData.getValue();
                    if(userData['user_franchises']) {
                      let currentFranchiseUser = userData['user_franchises'].filter((value) => {
                        if(value['franchise_id'] == franchiseData['id']) {
                          return value;
                        }
                      });
                      currentFranchiseUser = currentFranchiseUser[0] ? currentFranchiseUser[0] : {}; 
                      if(currentFranchiseUser['is_active']) {
                        if(currentFranchiseUser['is_active'] == 1) {
                          return true;
                        }else {
                          this._router.navigate(['account-selection']);//franchise-selection
                          return false;
                        }
                      }else {
                        this._router.navigate(['account-selection']);//franchise-selection
                        return false;
                      }
                    }else {
                      this._router.navigate(['account-selection']);//franchise-selection
                      return false;
                    }
                  });
                }
              });
            }
          } else {
            if (state.url === '/account-selection') { //franchise-selection
              return true;
            } else {
              this._router.navigate(['account-selection']); //franchise-selection
              return false;
            }
          }
        }
      } else {
        return this.authService.refreshUserdata().then(res => {
          const temp_data = res;
          if (localStorage.getItem('designation') === 'da') {
            if (this.rolePermisionService.permissions.getValue().length > 0) {
              return true;
            } else {
              return this.rolePermisionService.refreshPermissions().then(perm => {
                return true;
              });
            }
          } else {
            if (!isNaN(+urlArr[1]) && localStorage.getItem('franchise_ids')) {
              const allFranchises = JSON.parse(localStorage.getItem('franchise_ids'));
              if (!allFranchises.includes(+urlArr[1]) && !isNaN(+urlArr[1])) {
                this._router.navigate(['account-selection']); //franchise-selection
                return false;
              }
              if (Object.keys(this.franchiseService.franchiseData.getValue()).length > 0) {
                if (this.rolePermisionService.permissions.getValue().length > 0) {
                  return true;
                } else {
                  return this.rolePermisionService.refreshPermissions().then(perm => {
                    return true;
                  });
                }
              } else {
                return this.franchiseService.refreshFranchiseData().then(franchises => {
                  if (this.rolePermisionService.permissions.getValue().length > 0) {
                    return true;
                  } else {
                    return this.rolePermisionService.refreshPermissions().then(perm => {
                      let franchiseData = this.franchiseService.franchiseData.getValue();
                      if(userData['user_franchises']) {
                        let currentFranchiseUser = userData['user_franchises'].filter((value) => {
                          if(value['franchise_id'] == franchiseData['id']) {
                            return value;
                          }
                        });
                        currentFranchiseUser = currentFranchiseUser[0] ? currentFranchiseUser[0] : {}; 
                        if(currentFranchiseUser['is_active']) {
                          if(currentFranchiseUser['is_active'] == 1) {
                            return true;
                          }else {
                            this._router.navigate(['account-selection']);//franchise-selection
                            return false;
                          }
                        }else {
                          this._router.navigate(['account-selection']);//franchise-selection
                          return false;
                        }
                      }else {
                        this._router.navigate(['account-selection']);//franchise-selection
                        return false;
                      }
                    });
                  }
                });
              }
            } else {
              if (state.url === '/account-selection') { //franchise-selection
                userData = this.authService.userData.getValue();
                if (userData['is_supplier']) {
                  this.franchiseService.franchiseOptions.next(userData['franchises']);
                  this._router.navigate(['supplier/dashboard']);
                  return true;
                }
                return true;
              } else {
                this._router.navigate(['account-selection']); //franchise-selection
                return false;
              }
            }
          }

        });
      }
    } else {
      this._router.navigate(['/login']);
      return false;
    }
  }

}
