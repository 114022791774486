import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../../../service/common.service';
import { FormGroup, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { SupplierService } from '../../../service/supplier.service';
import { PurchaseOrderService } from '../../../service/purchase-order.service';
import { FranchiseService } from '../../../service/franchise.service';
import { ProjectService } from '../../../service/project.service';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { ToasterService } from 'angular2-toaster';

@Component({
  templateUrl: './supplier-bill.component.html',
  styleUrls: ['supplier-bill.component.scss']
})
export class SupplierBillComponent implements OnInit {
  poId: '';
  billForm: FormGroup;
  api_error: any = {};
  filesArr: any = [];
  docArr: any = [];
  oldDocArr: any = [];
  franchiseData: any = [];
  poData: any = [];
  loading = false;
  isDirect = false;
  seletectedFranchise = '';
  project_type: string | number = '';
  estimatorID:any;
  progress:any = '';
  showProgress : boolean = false;
  // Edit Bill
  billId = '';
  billData: any = [];
  editBill:boolean = false;
  billEmailSend:any = '';
  today = new Date();
  previousUrl: any = null;
  franchiseId = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    public commonService: CommonService,
    private poService: PurchaseOrderService,
    private franchiseService: FranchiseService,
    private supplierService: SupplierService,
    private router: Router,
    private _fb: FormBuilder,
    private datePipe: DatePipe,
    private projectService: ProjectService,
    private toasterService: ToasterService) {

  }

  ngOnInit() {
    this.commonService.previousUrl$
    .subscribe((previousUrl: string) => {
        this.previousUrl = previousUrl
    });

    this.franchiseId = this.commonService.currentFranchise();

    let path = this.router.url;
    let pathArr = path.split('/');
    this.initBillForm();
    this.activatedRoute.params.subscribe(resID => {
      if (resID['franchiseId']) {
        this.seletectedFranchise = resID['franchiseId'];
      }
      if(pathArr[3] == 'edit-bill') {
        this.editBill = true;
        this.billId = resID['billId'];
        this.loading = true;
        this.poService.getBillDetails(this.billId).subscribe(res => {
          this.loading = false;
          this.billData = res['data'];
          this.poData = res['data']['po_details'] || [];
          this.poId = this.billData['po_id'];
          this.estimatorID = this.billData['estimator_id'];
          this.billForm.get('id').setValue(this.billData['id']);
          this.billForm.get('bill_uid').setValue(this.billData['bill_uid']);
          this.billForm.get('comment').setValue(this.billData['comment']);
          this.billForm.get('order_id').setValue(this.billData['order_id']);
          this.billForm.get('bill_date').setValue(this.billData['bill_date']);
          this.billForm.get('due_date').setValue(this.billData['due_date']);

          this.docArr = (this.billData['bill']) ? this.billData['bill'] : [];
          this.oldDocArr = [...this.billData['bill']];

          this.docArr.forEach((value, key) => {
            this.filesArr[key] = [];
            const pathValue = this.commonService.checkImageTypeAws(value, 'bill_images');
            this.filesArr[key]['download_url'] = this.commonService.UPLOAD_FILE_AWS('bill_images') + value;
            if (!pathValue) {
              this.filesArr[key]['url'] = this.commonService.UPLOAD_FILE_AWS('bill_images') + value;
            } else {
              this.filesArr[key]['url'] = pathValue;
            }
          });

          const productControls = <FormArray>this.billForm.get('item_id');
          const productItemControls = <FormArray>this.billForm.get('items');
          for (const data of this.billData['bill_items']) {
            productControls.push(this.initItemsArray(data));
            productItemControls.push(this.initProductItems(data));
            this.calculateFinalAmount();
          }
        },(error) => {
          if(error['error']['data'] == 'invalid_request') {
            this.toasterService.pop('error', error['error']['message']);
            this.router.navigate(['../../../dashboard'], { relativeTo: this.activatedRoute });
          }
        });
      }else{
        if (resID['poId']) {
          this.poId = resID['poId'];
          this.loading = true;
          this.poService.getPoForLinkBill(this.poId, this.seletectedFranchise).subscribe(res => {
            this.loading = false;
            this.poData = res['data'];
            this.isDirect = res['data']['isDirect'];
            this.billForm.get('order_id').setValue(this.poData['order_id']);
            // getOrderData = () => {
            this.projectService.getOrderData(this.poData['order_id']).subscribe(res => {
              this.estimatorID = res['data']['estimator_id'];
              this.project_type = res['data']['project_of_order'] ? res['data']['project_of_order']['project_type'] : ''
            });
  
            const productControls = <FormArray>this.billForm.get('item_id');
            const productItemControls = <FormArray>this.billForm.get('items');
            for (const data of this.poData['order_items']) {
              productControls.push(this.initItemsArray(data));
              productItemControls.push(this.initProductItems(data));
              this.calculateFinalAmount();
            }
          },(error) => {
            if(error['error']['data'] == 'invalid_request') {
              this.toasterService.pop('error', error['error']['message']);
              this.router.navigate(['../../../dashboard'], { relativeTo: this.activatedRoute });
            }
          });
        } else {
          this.poId = resID['id'];
          this.loading = true;
          this.poService.getPoForBill(this.poId).subscribe(
            (res) => {
              this.loading = false;
              this.poData = res['data'];
              this.isDirect = res['data']['isDirect'];
              this.billForm.get('order_id').setValue(this.poData['order_id']);
              this.projectService.getOrderData(this.poData['order_id']).subscribe(res => {
  
                this.project_type = res['data']['project_of_order'] ? res['data']['project_of_order']['project_type'] : ''
                this.estimatorID = res['data']['estimator_id'] ? res['data']['estimator_id']  : ''
  
              },(error) => {
                if(error['error']['data'] == 'invalid_request') {
                  this.toasterService.pop('error', error['error']['message']);
                  this.router.navigate(['../../../dashboard'], { relativeTo: this.activatedRoute });
                }
              });
              const productControls = <FormArray>this.billForm.get('item_id');
              const productItemControls = <FormArray>this.billForm.get('items');
              for (const data of this.poData['order_items']) {
                productControls.push(this.initItemsArray(data));
                productItemControls.push(this.initProductItems(data));
                this.calculateFinalAmount();
              }
            }
          );
        } 
      }

    });
    if (this.seletectedFranchise) {
      this.franchiseService.getFranchiseData(this.seletectedFranchise).subscribe(
        (res) => {
          this.franchiseData = res['data'];
        }
      );
    } else {
      let fData = this.franchiseService.franchiseData.getValue();
      if(fData['role']) {
        this.franchiseData = fData;
      }else {
        this.franchiseService.getFranchiseData(pathArr[1]).subscribe((res) => {
          this.franchiseData = res['data'];
        },(error) => {
          console.log(error);
        });
      }
    }
    this.setFields();
  }
  onItemSelect = (controlGroup, value) => {
    const cost = +controlGroup;
    let price = +this.billForm.get('amount').value;
    if (value == 'on') {
      price += +cost;
    } else {
      price -= cost;
    }
    this.billForm.get('amount').setValue(price < 0 ? '0.00' : Number(+price).toFixed(2));
  }

  initBillForm = () => {
    this.billForm = this._fb.group({
      bill: [''],
      amount: [''],
      comment: [''],
      bill_uid: [''],
      item_id: this._fb.array([]),
      bill_date: [new Date()],
      due_date: [new Date()],
      items: this._fb.array([]),
      order_id: [''],
      id:[''],
      estimator_id:[''],
    });
  }

  initProductItems = (data) => {
    if(this.editBill) {
      return this._fb.group({
        id:[data['bill_item_id'] ? data['bill_item_id'] : ''],
        digital_service_name: [data['digital_service_name'] ? data['digital_service_name'] : ''], //??
        item_code: [data['item_code'] ? data['item_code'] : ''],
        item_specification: [data['item_spec'] ? data['item_spec'] : ''],
        item_description: [data['bill_item_description'] ? data['bill_item_description'] : ''],
        turnaround_time: [data['bill_item_turnaround_time'] ? data['bill_item_turnaround_time'] : ''],
        quantity: [data['bill_item_quantity'] ? data['bill_item_quantity'] : ''],
        cost_per_piece: [data['bill_item_cost_per_piece'] ? Number(+data['bill_item_cost_per_piece']).toFixed(5) : ''],
        total_cost: [data['bill_item_total_cost'] ? Number(+data['bill_item_total_cost']).toFixed(2) : ''], //???
        shipping_cost: [data['bill_item_shipping_cost'] ? Number(+data['bill_item_shipping_cost']).toFixed(2) : ''],
        cost: [data['bill_item_cost'] ? Number(+data['bill_item_cost']).toFixed(2) : ''],
        // item_id: [data['bill_order_item_id'] ? data['bill_order_item_id'] : ''],
        sales_rep_id: [data['po_item_sales_rep_id'] ? data['po_item_sales_rep_id'] : ''], //??
        estimator_id: [data['estimator_id'] ? data['estimator_id'] : ''], //??
        // po_item_id: [data['po_item_id'] ? data['po_item_id'] : '']
        is_additional_charge: [data['is_additional_charge'] || 0],
        parent_purchase_order_item_id: [data['parent_purchase_order_item_id'] || null]
      });
    }else {
      return this._fb.group({
        id:[''],
        digital_service_name: [data['digital_service_name'] ? data['digital_service_name'] : ''],
        item_code: [data['item_code'] ? data['item_code'] : ''],
        item_specification: [data['po_item_spec'] ? data['po_item_spec'] : ''],
        item_description: [data['po_item_description'] ? data['po_item_description'] : ''],
        turnaround_time: [data['po_item_turnaround_time'] ? data['po_item_turnaround_time'] : ''],
        quantity: [data['po_item_quantity'] ? data['po_item_quantity'] : ''],
        cost_per_piece: [data['po_item_cost_per_piece'] ? Number(+data['po_item_cost_per_piece']).toFixed(5) : ''],
        total_cost: [data['po_item_total_cost'] ? Number(+data['po_item_total_cost']).toFixed(2) : ''],
        shipping_cost: [data['po_item_shipping_cost'] ? Number(+data['po_item_shipping_cost']).toFixed(2) : ''],
        cost: [data['po_item_cost'] ? Number(+data['po_item_cost']).toFixed(2) : ''],
        item_id: [data['po_item_id'] ? data['po_item_id'] : ''],
        sales_rep_id: [data['po_item_sales_rep_id'] ? data['po_item_sales_rep_id'] : ''],
        estimator_id: [data['estimator_id'] ? data['estimator_id'] : ''],
        po_item_id: [data['po_item_id'] ? data['po_item_id'] : ''],
        project_order_item_id: [data['id'] ? data['id'] : ''],
        is_additional_charge: [data['is_additional_charge'] || 0],
        parent_purchase_order_item_id: [data['parent_purchase_order_item_id'] || null]
      });
    }
  }

  initItemsArray = (data) => {
    if(this.editBill) {
      return this._fb.group({
        id: [data['bill_item_id'] ? data['bill_item_id'] : ''],
        status: [false],
        po_item_id: [data['po_item_id']]
      });
    }else {
      return this._fb.group({
        id: [data['id'] ? data['id'] : ''],
        status: [false],
        po_item_id: [data['po_item_id']]
      });
    }
  }

  createBill = () => {
    const postData = { ...this.billForm.value };
    if (postData['bill_date']) {
      postData['bill_date'] = this.datePipe.transform(postData['bill_date'], 'y-MM-d');
    }
    if (postData['due_date']) {
      postData['due_date'] = this.datePipe.transform(postData['due_date'], 'y-MM-d');
    }
    postData['bill_email_send'] = this.billEmailSend;
    postData['estimator_id'] = this.estimatorID;
    postData['item_ids'] = [];
    postData['po_id'] = this.poId;
    postData['our_cost'] = +this.poData['our_cost'];
    postData['amount'] = +postData['amount'];
    postData['item_id'].map(item => {
      if (item['status']) {
        postData['item_ids'].push(item);
      }
    });
    delete postData['item_id'];
    delete postData['bill'];
    const fData: FormData = new FormData;
    if (this.filesArr.length > 0) {
      for (const file of this.filesArr) {
        if (file['file']) {
          fData.append('files[]', file['file']);
        }
      }
    }

    if (+postData.amount != +postData.our_cost) {
      postData['amount_flag'] = 1;
      fData.append('postData', JSON.stringify(postData));
      Swal.fire({
        title: 'Are you sure?',
        text: 'Your amount is differ than total cost of PO ! Do you want to generate bill?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        //showLoaderOnConfirm: true,
        preConfirm: (value) => {
          this.generateBill(fData);
        },
      }).then((res) => {
      });
    } else {
      postData['amount_flag'] = 0;
      fData.append('postData', JSON.stringify(postData));
      this.generateBill(fData);
    }
  }

  generateBill = (fData) => {
    this.showProgress = true
    this.supplierService.saveBill(fData, this.isDirect, this.seletectedFranchise).subscribe((event) => {
      this.progress= this.commonService.showProgress(event);
        if(event['body']) {
          if(event['body']['data']) {
            this.router.navigateByUrl(this.previousUrl || '/'+this.franchiseId+'/project');
          }
        }
      }, error => {
        this.progress= this.commonService.showProgress(event);
        if (error['error']) {
          this.showProgress = false
          this.api_error = error['error']['data'];
        }
    });
  }

  onTotalPriceChange = (control: FormControl, total_cost: boolean = false) => {
    if (+control.get('quantity').value >= 1 && +control.get('total_cost').value > 0) {
      if (total_cost) {
        const cost_per_piece = (+control.get('total_cost').value) / (+control.get('quantity').value);
        control.get('cost_per_piece').setValue(Number(+cost_per_piece).toFixed(5));
      }
      control.get('cost').setValue(Number((+control.get('total_cost').value)).toFixed(2));
    }
    if (+control.get('quantity').value > 0 && +control.get('cost_per_piece').value > 0) {
      if (total_cost == false) {
         control.get('total_cost').setValue(((Number(+control.get('quantity').value)) * (Number(+control.get('cost_per_piece').value))).toFixed(2));
        control.get('total_cost').setValue(Number(control.get('total_cost').value).toFixed(2));
      }
      control.get('cost').setValue(Number((+control.get('total_cost').value)).toFixed(2));
    }
    if (+control.get('quantity').value === 0) {
      control.get('total_cost').setValue('0.00');
      control.get('cost').setValue(Number(+control.get('total_cost').value).toFixed(2));
    }
    if (+control.get('total_cost').value === 0) {
      control.get('cost').setValue('0.00');
    }
    if (+control.get('shipping_cost').value > 0) {
      if (+control.get('quantity').value === 0) {
      } else {
        const price = Number(+control.get('cost').value) + Number(+control.get('shipping_cost').value);
        control.get('cost').setValue(Number(+price).toFixed(2));
        if (!control.get('cost_per_piece').value) {
          const cost_per_piece = +control.get('total_cost').value / +control.get('quantity').value;
          control.get('cost_per_piece').setValue(Number(+cost_per_piece).toFixed(5));
        }
      }
    }
    this.calculateFinalAmount();
  }
  onPricePerPieceChange = (control: FormControl) => {
    if (+control.get('quantity').value >= 1) {
      const price = Number(+control.get('cost_per_piece').value) * Number(+control.get('quantity').value) + Number(+control.get('shipping_cost').value);
      control.get('cost').setValue(Number(+price).toFixed(2));
      this.onPriceChanges(control, false);
    } else {
      control.get('cost').setValue('0.00');
      control.get('total_cost').setValue('0.00');
    }
    this.calculateFinalAmount();
  }
  onPriceChanges = (control: FormControl, changePerPiece = true) => {
    if (Number(control.get('cost').value) > 0) {
      const newCost = +control.get('cost').value - +control.get('shipping_cost').value;
      const newTotalCost = control.get('cost_per_piece').value * control.get('quantity').value;
      if (newCost < 0) {
        control.get('total_cost').setValue(Number(+control.get('cost').value).toFixed(2));
        control.get('shipping_cost').setValue('0.00');
      } else {
        control.get('total_cost').setValue(Number(+newTotalCost).toFixed(2));
      }
      if (changePerPiece) {
        this.calculatePricePerPiece(control);
      }
    } else {
      control.get('cost_per_piece').setValue('');
      control.get('total_cost').setValue('0.00');
    }
    this.calculateFinalAmount();
  }
  calculatePricePerPiece = (control: FormControl) => {
    if (+control.get('quantity').value > 0 && +control.get('cost').value > 0) {
      const price_per_piece = +control.get('cost').value / +control.get('quantity').value;
      control.get('cost_per_piece').setValue(Number(+price_per_piece).toFixed(5));
    }
    this.calculateFinalAmount();
  }
  calculateFinalAmount = async () => {
    let controls = { ...this.billForm.value };
    let price = 0;
    controls['items'].map((item) => {
      price += +item['cost'];
    })
    this.billForm.get('amount').setValue(price < 0 ? '0.00' : Number(+price).toFixed(2));
  }
  uploadDoc = (event: any) => {
    const files = event.target.files;
    let i = this.filesArr.length;
    for (const file of files) {
      const ext = file['name'].substring(file['name'].lastIndexOf('.') + 1).toLowerCase();
      let flag = true;
      this.docArr.forEach((value) => {
        if (value === file.name) {
          flag = false;
        }
      });
      if (flag) {
        this.docArr.push(file.name);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event1: Event) => {
          this.filesArr[i] = {};
          this.filesArr[i]['file'] = file;
          const pathValue = this.getLogoUrl(ext);
          if (!pathValue) {
            this.filesArr[i]['url'] = event1.target['result'];
          } else {
            this.filesArr[i]['url'] = pathValue;
          }
          i++;
        };
      }
    }
  }

  getLogoUrl = (ext) => {
    if (ext === 'jpg' || ext === 'JPEG' || ext === 'jpeg' || ext === 'png' || ext === 'PNG') {
      return false;
    } else if (ext === 'pdf') {
      return '../../../../assets/img/pdf.jpg';
    } else if (ext === 'doc' || ext === 'docx') {
      return '../../../../assets/img/doc.jpg';
    } else {
      return '../../../../assets/img/file.png';
    }
  }

  removeDoc = (doc, i) => {
    this.filesArr.splice(i, 1);
    this.docArr.forEach((value, key) => {
      if (key === +i) {
        this.docArr.splice(key, 1);
      }
    });
    this.oldDocArr.forEach((value, key) => {
      if (key === +i) {
        this.oldDocArr.splice(key, 1);
      }
    });
  }

  setFields = () => {
    $(document).ready(() => {
      var costPerPiece = setInterval(() => {
        let totalCostPerPiece = $("[customFormatAttribute=true]").length;
        let counter = 0; 
          $("[customFormatAttribute=true]").each((index, value)=> {
          value.addEventListener('focusout', () => {
            this.formatFields('price_per_piece');
            if(value.getAttribute('formcontrolname') == 'total_cost') {
              this.formatFields('cost_per_piece');
            }
          });
          counter++;
          if(totalCostPerPiece == counter) {
            clearInterval(costPerPiece);
          }
        })
      }, 10);
    });
  }

  formatFields = (field) => {
    if(field == 'price_per_piece') {
      var PricePerPiece = setInterval(() => {
        let totalPricePerPiece = $("[formControlName=price_per_piece]").length;
        let counter = 0; 
        $("[formControlName=price_per_piece]").each((index, value)=> {
          value.focus();
          value.blur();
          counter++;
          if(totalPricePerPiece == counter) {
            clearInterval(PricePerPiece);
          }
        })
      }, 10);
    }

    if(field == 'cost_per_piece') {
      var CostPerPiece = setInterval(() => {
        let totalCostPerPiece = $("[formControlName=cost_per_piece]").length;
        let counter = 0; 
        $("[formControlName=cost_per_piece]").each((index, value)=> {
          value.focus();
          value.blur();
          counter++;
          if(totalCostPerPiece == counter) {
            clearInterval(CostPerPiece);
          }
        })
      }, 10);
    }
  }

  canBillEmailSend = (sendEmail) => {
    if(sendEmail) {
      this.billEmailSend = 'send_email';
    }
  }
}