import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ElementRef,ChangeDetectorRef ,AfterViewInit,OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, NgForm } from '@angular/forms';
import { CommonService } from '../../../../service/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SalesOrderService } from '../../../../service/sales-order.service';
import { AccountingService } from '../../../../service/accounting.service';
import { DatePipe } from '@angular/common';
import { BsDatepickerDirective } from 'ngx-bootstrap';
import { FranchiseService } from '../../../../service/franchise.service';
import { AppConstants } from '../../../../constants/app.constants';
import { UserService } from '../../../../service/user.service';
import { DecimalPipe } from '@angular/common';
import { ProjectService } from '../../../../service/project.service';
import * as moment from 'moment';
import { ToasterService } from 'angular2-toaster';

@Component({
  selector: 'app-create-receivable',
  templateUrl: './create-receivable.component.html',
  styleUrls: ['./create-receivable.component.scss']
})

export class CreateReceivableComponent implements OnInit,AfterViewInit, OnDestroy {
  paymentForm: FormGroup;
  paymentTypes: any = [];
  paymentAccount: any = [];
  api_error: any = [];
  invoices: any = [];
  contacts: any = [];
  invoiceId = 0;
  amountData: any = [];
  invoiceUid = '';
  loading = false;
  selectedInvoice: any = [];

  // for contact
  selectedContact: any = [];
  contactList = [];
  // end

  // for stripe payment
  // @ViewChild('cardInfo') cardInfo: ElementRef;
  error: any = '';
  onChange = ( error ) => {
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    this.cd.detectChanges();
  }
  selectedPaymentMethod:any;
  paymentOption:any;
  card_errors:any = {}
  bankTransferPaymentForm: FormGroup;
  cardPaymentForm: FormGroup;
  errorAmountCard:any = ''
  errorAmountBankTransfer:any = ''
  stripePaymentMethod:any = 'card';
  card: any;
  cardHandler = this.onChange.bind(this);
  amount:any;
  reciept:any = ''
  frenchiseId: any = undefined;
  // end

  dropdownSettings = {
    singleSelection: true,
    idField: 'id',
    textField: 'invoice_uid',
    placeholder: 'Select Invoice',
    allowSearchFilter: true,
    searchPlaceholderText: 'Search Invoice Number',
    closeDropDownOnSelection: true
  };

  contactSettings = {
    singleSelection: true,
    idField: 'id',
    textField: 'full_name',
    placeholder: 'Select Customer',
    allowSearchFilter: true,
    searchPlaceholderText: 'Search Customer',
    closeDropDownOnSelection: true
  };

  monthsArray:any = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  yearsArray:any = [];
  showCardPaymentErrors:boolean = false;
  showBankPaymentErrors:boolean = false;
  quickbookPaymentError:any = [];

  @Output() receivedPayment = new EventEmitter();
  @Output() closeModel = new EventEmitter();
  @Input() inputInvoiceId: any;
  @Input() modalRef: any;
  @Input() paymentMethod: any;
  @Input() selctedPaymentOption: any;
  @Input() inputOrderId:any = null;
  @Input() paymentAmounts:any;
  @Input() id: any;
  @ViewChild(BsDatepickerDirective) datepicker: BsDatepickerDirective;

  constructor(public _fb: FormBuilder,
    public _cs: CommonService,
    public _activatedRoute: ActivatedRoute,
    public _salesOrderService: SalesOrderService,
    public _router: Router,
    public _dp: DatePipe,
    public _accountingService: AccountingService,
    public franchiseService: FranchiseService,
    private userService: UserService,
    private cd: ChangeDetectorRef,
    private decimalPipe: DecimalPipe,
    private projectService: ProjectService,
    private toasterService: ToasterService) {}

  ngOnInit() {
    let currentYear = moment().year();
    for(let i = 0; i <= 30; i++)  {
      this.yearsArray.push(currentYear + i);
    }

    if(this.paymentMethod) {
      this.selectedPaymentMethod = this.paymentMethod;
    }
    if(this.selctedPaymentOption) {
      this.paymentOption = this.selctedPaymentOption;
    }

    this.bankTransferPaymentForm = this._fb.group({
      id: [''],
      payment_amount: ['', Validators.required],
      routing_number: ['', [Validators.required]], //, Validators.minLength(9), Validators.maxLength(9)
      account_number: ['', [Validators.required]], //, Validators.minLength(16), Validators.maxLength(17)
      phone_number: ['', [Validators.required]], //, Validators.minLength(10), Validators.maxLength(10)
      account_holder: ['', [Validators.required]]
    });

    this.cardPaymentForm = this._fb.group({
      card_number: ['', [Validators.required]], //, Validators.minLength(16),Validators.min(1111111111111111),Validators.max(9999999999999999)
      exp_month: ['', [Validators.required]], //,Validators.minLength(1),Validators.maxLength(2),Validators.min(1),Validators.max(12)
      exp_year: ['', [Validators.required]], //,Validators.minLength(4),Validators.maxLength(4),Validators.min(1111),Validators.max(9999)
      postal_code: ['', [Validators.required]], //, Validators.minLength(5), Validators.maxLength(5)
      cvc: ['', [Validators.required]] //,Validators.minLength(3),Validators.maxLength(4)
    });

    this.initPaymentForm();
    const franchiseRes = this.franchiseService.franchiseData.getValue();
    this.frenchiseId = franchiseRes['id'];
    if (this.inputInvoiceId) {
      this.invoiceId = this.inputInvoiceId;
      this.paymentForm.get('invoice_id').setValue(this.invoiceId);
      this.getInvoiceAmounts(this.invoiceId);
    } else {     
      if(this.selectedPaymentMethod != 'Stripe') {
        this._salesOrderService.getInvoiceRoleWise().subscribe((res) => {
          this.invoices = res['data'];
        });
        this.getContacts();
        this.getOrderAmounts(this.inputOrderId);
      }else {
        this.setReceivedData();
      }
    }
    const configStatus = this._cs.configStatus.getValue();
    if (Object.keys(configStatus).length === 0) {
      this.getConfigStatus();
    } else {
      const types = this._cs.getByType('payment_types');
      Object.keys(types).forEach(key => {
        this.paymentTypes.push({ 'value': key, 'name': types[key]['name'] });
      });
      const account = this._cs.getByType('qb_payment_account');
      Object.keys(account).forEach(key => {
        this.paymentAccount.push({ 'value': account[key]['value'], 'name': account[key]['type'] });
      });
    }
    
    if (this.id) {
      this.getReceivedPayment(this.id);
    }
  }

  getContacts = () => {
    this.userService.getContactOptions().subscribe(res => {
      this.contactList = res['data'];
      this.contactList.map((contact,index)=> {
        if(contact['primary_contact']){ 
          this.contactList[index]['full_name'] = contact['full_name'] + ' ' + '(Primary Contact)'
        }
      })
    });
  }

  getConfigStatus = async () => {
    const data = await this._cs.getConfigStatusData().toPromise();
    this._cs.configStatus.next(data);
    const types = this._cs.getByType('payment_types');
    Object.keys(types).forEach(key => {
      this.paymentTypes.push({ 'value': key, 'name': types[key]['name'] });
    });
    const account = this._cs.getByType('qb_payment_account');
    Object.keys(account).forEach(key => {
      this.paymentAccount.push({ 'value': account[key]['value'], 'name': account[key]['type'] });
    });
  }

  initPaymentForm = () => {
    this.paymentForm = this._fb.group({
      id: [''],
      order_id: [''],
      invoice_id: [''],
      amount: [''],
      payment_type: [''],
      received_date: [new Date()],
      deposited_to: [''],
      ref_number: [''],
      comment: [''],
      due_amount: [],
      payment_through: ['']
    });
  }

  getReceivedPayment = (id) => {
    this.loading = true;
    this._accountingService.getReceivedPayment(id).subscribe((res) => {
      if (res['data']) {
        if (res['data']['transaction_id']) {
          let paymentResponse = JSON.parse(res['data']['payment_response'] || {});
          this.bankTransferPaymentForm.patchValue({
            id: res['data']['id'],
            payment_amount: parseFloat(res['data']['amount'] || 0),
            routing_number: '',
            account_number: '',
            phone_number: paymentResponse.bankAccount ? paymentResponse.bankAccount.phone : '',
            account_holder: paymentResponse.bankAccount ? paymentResponse.bankAccount.name : '',
          });
        } else {
          this.paymentForm.patchValue({
            id: res['data']['id'],
            order_id: res['data']['order_id'],
            invoice_id: res['data']['invoice_id'],
            amount: parseFloat(res['data']['amount'] || 0),
            payment_type: res['data']['payment_type'],
            received_date: moment(res['data']['received_date'], "DD/MM/YYYY"),
            deposited_to: res['data']['deposited_to'],
            ref_number: res['data']['ref_number'],
            comment: res['data']['comment'],
            payment_through: res['data']['payment_through']
          });
        }
      }
      this.loading = false;
    });
  }

  createPayment = () => {
    const postData = { ...this.paymentForm.value };
    if (!postData.payment_through) {
      this.toasterService.pop('error', 'The payment is received from the bank or from the card?');
      return false;
    }

    if (postData['received_date']) {
      postData['received_date'] = this._dp.transform(postData['received_date'], 'y-MM-d');
    }

    this.loading = true;
    this._accountingService.saveReceivedPayment(postData).subscribe(
      (res) => {
        window['storedRoutes'] = {};
        this.loading = false;
        this.receivedPayment.emit();
        this.api_error = {};
        if (!this.inputInvoiceId) {
          this.selectedInvoice = [];
          this.onDeselectInvoice();
          this.back();
        } else {
          this.back();
        }
      }, error => {
        this.toasterService.pop('error', 'Payment can not be done!');
        if (error['error']) {
          this.loading = false;
          this.api_error = error['error']['data'];
        }
      }
    );
  }

  getInvoiceAmounts = (invoiceId) => {
    this.amountData = [];
    this.loading = true;
    this._salesOrderService.getOrderAmountsByInvoice(invoiceId).subscribe(
      (res) => {
        if (res['data']) {
          this.loading = false;
          this.amountData = res['data'];
          this.paymentForm.get('due_amount').setValue(this.amountData['due']);
          this.paymentForm.get('order_id').setValue(this.amountData['order_id']);
          this.paymentForm.get('payment_through').setValue(this.amountData['contact']['payment_through'] || 'Bank');
          this.invoiceUid = this.amountData['uid'];
        }
      }
    );
  }

  getOrderAmounts = (orderId) => {
    this.amountData = [];
    this.loading = true;
    this._salesOrderService.getOrderAmounts(orderId).subscribe(
      (res) => {
        if (res['data']) {
          this.loading = false;
          this.amountData = res['data'];
          this.paymentForm.get('due_amount').setValue(this.amountData['due']);
          this.paymentForm.get('order_id').setValue(orderId);
          this.amountData['order_uid'] = this.amountData['uid'];
        }
      }
    );
  }

  onSelectInvoice = (id) => {
    this.invoiceUid = '';
    this.api_error = {};
    if (id) {
      this.initPaymentForm();
      this.paymentForm.get('invoice_id').setValue(id);
      this.getInvoiceAmounts(id);
    }
    this.selectedContact = [];
  }

  onDeselectInvoice = () => {
    this.paymentForm.get('invoice_id').setValue('');
    this.invoiceUid = '';
    this.amountData = [];
  }

  onSelectContact = (id) => {
    this.invoiceId = 0;
    this.selectedInvoice = [];
    this.invoiceUid = '';
  }

  onDeselectContact = (event) => {

  }

  back = () => {
    this.id = null;
    if (this.modalRef) {
      this.modalRef.hide();
    } else {
      if (this.invoiceId) {
        this._router.navigate(['../../'], { relativeTo: this._activatedRoute });
      } else {
        this._router.navigate(['../'], { relativeTo: this._activatedRoute });
      }
    }
  }

  resetInputs = () => {
    this.selectedContact = [];
    this.api_error = {};
  }

  // for stripe payment
  ngAfterViewInit() {
    if(this.selectedPaymentMethod == 'Stripe' && (this.paymentOption == 1 || this.paymentOption == 3)) {  // for stripe payment
      const style = {
        base: {
          lineHeight: '24px',
          fontFamily: 'monospace',
          fontSmoothing: 'antialiased',
          fontSize: '19px',
          '::placeholder': {
            color: 'purple'
          },
          width:'500px',
          heigh:'500px',
        }
      };
      // this.card = elements.create('card');
      $(document).ready(() => {
        let interval = setInterval(() => {
            if($('#card-info')[0]) {
              clearInterval(interval);
              this.card.mount('#card-info');
              var iframe = document.getElementsByTagName('iframe')
              iframe[0].style.width = "430px";
              this.card.addEventListener('change', this.cardHandler);
            }
        }, 100);
      });
    }
  }

  ngOnDestroy() {
    this.id = null;
    if(this.selectedPaymentMethod == 'Stripe' && $('#card-info')[0]) {  // for stripe payment
      this.card.removeEventListener('change', this.cardHandler);
      this.card.destroy();
    }
  }

  async onSubmit(form: NgForm) {
    if((+this.amount < 0) || !this.amount){
      this.errorAmountCard = "Please Enter Amount!"
    }
    if(this.cardPaymentForm.valid) {
      let cardForm = this.cardPaymentForm.value;
      if(typeof(this.amount) == 'string') {
        this.amount = Number(this.amount.replace(/,/g, ''));
      }

      // Stripe Card Payment
      // const { token, error } = await stripe.createToken(this.card);
      // if (error) {
      //     this.loading = false;
      // this.card_errors = error
      // } else {
      //   this.card_errors = {}
      //     if(token && +this.amount > 0){
      //         let data = token
      //         data['sourceID']=token['id']
      //         data['payment_type'] = token['type']
      //         data['order_id'] = this.inputOrderId;
      //         data['frenchise_id'] = this.frenchiseId
      //         data['amount'] = +this.amount;
      //         this._salesOrderService.makeOrderPayment(this.frenchiseId,data).subscribe((res)=>{
      //             this.loading = false;
      //             this.amount = null;
      //             this.card.clear();
      //             $('#card_payment_amount').val('');
      //             if(res['data']){
      //              this.reciept =   res['data']['receipt_url']
      //             }
      //             this.ngOnInit()
      //             this.loading = false
      //             this.receivedPayment.emit();
      //             this.closeModel.emit();
      //         },(error)=>{
      //             this.loading = false
      //             if (error['error']) {
      //                 this.api_error = error['error']['message'];
      //             }

      //         })
      //     }else {
      //       this.loading = false
      //       this.errorAmountCard = "Please Enter Amount!"
      //     }
      //     if(token && !this.amount){
      //       this.errorAmountCard = "Please Enter Amount!"
      //     }
      // }
      // End Stripe Card Payment

      // Quickbook Card Payment
      this.card_errors = {}
      if(+this.amount > 0){
          let data = cardForm;
          data['payment_type'] = 'card';
          data['order_id'] = this.inputOrderId;
          data['frenchise_id'] = this.frenchiseId;
          data['amount'] = (+this.amount).toFixed(2);
          this.loading = true;
          this.quickbookPaymentError = [];
          this._salesOrderService.makeOrderPayment(this.frenchiseId,data).subscribe((res)=>{
              this.loading = false;
              this.amount = null;
              $('#card_payment_amount').val('');
              if(res['data']){
                this.reciept =   res['data']['receipt_url']
              }
              this.ngOnInit()
              this.loading = false
              this.receivedPayment.emit();
              this.closeModel.emit();
          },(error)=>{
              this.loading = false
              if (error['error']) {
                  this.api_error = error['error']['message'];
                  if(error['error']['data']) {
                    this.quickbookPaymentError = error['error']['data'];
                  }
              }

          })
      }else {
        this.loading = false
        this.errorAmountCard = "Please Enter Amount!"
      }
      if(!this.amount){
        this.errorAmountCard = "Please Enter Amount!"
      }
      // End Quickbook Card Payment
    }else {
      this.showCardPaymentErrors = true;
    }
  }

  // Stripe bank Payment
  // async payThroughBankTransfer(formData)  { 
  //   if(typeof(this.amount) == 'string') {
  //     this.amount = Number(this.amount.replace(/,/g, ''));
  //   }
  //   let data = this.bankTransferPaymentForm.value;
  //   data['order_id'] = this.inputOrderId;
  //   data['frenchise_id'] = this.frenchiseId
  //   //added for create token
  //   this.loading = true;
  //   const { token, error } = await stripe.createToken('bank_account',{
  //     country: 'US',
  //     currency: 'usd',
  //     routing_number: data['routing_amount'],
  //     account_number: data['account_number'],
  //     account_holder_name:data['account_holder'] ,
  //     account_holder_type: 'individual',
  //   })
  //   if (error) {
  //     this.loading = false;
  //     this.api_error = error['message'];
  //   } else {
  //       if(token && +this.amount > 0){
  //           let data = token
  //           data['sourceID']=token['bank_account']['id']
  //           data['order_id'] = this.inputOrderId;
  //           data['frenchise_id'] = this.frenchiseId
  //           data['payment_type'] = token['type']
  //           data['amount'] = +this.amount;
  //           data['type'] = "bank_transfer"
  //           this._salesOrderService.makeOrderPayment(this.frenchiseId,data).subscribe((res)=>{
  //             this.amount = null;
  //               if(res['data']){
  //                this.reciept =   res['data']['receipt_url']
  //               }
  //               this.ngOnInit()
  //               this.loading = false
  //               this.receivedPayment.emit();
  //               this.closeModel.emit();
  //           },(error)=>{
  //               this.loading = false
  //               if (error['error']) {
  //                   this.api_error = error['error']['message'];
  //               }

  //           })
  //       }else {
  //         this.loading = false
  //         this.errorAmountBankTransfer = "Please Enter Amount!"
  //       }
  //       if(token && !this.amount){
  //         this.errorAmountBankTransfer = "Please Enter Amount!"
  //       }
  //   }
  // } 

  // Quickbook Bank/Echeck Payment
  async payThroughBankTransfer(formData)  {
    if((+this.amount < 0) || !this.amount){
      this.errorAmountBankTransfer = "Please Enter Amount!"
    }
    if(this.bankTransferPaymentForm.valid) {
      if(typeof(this.amount) == 'string') {
        this.amount = Number(this.amount.replace(/,/g, ''));
      }
      let data = this.bankTransferPaymentForm.value;
    
      if(+this.amount > 0){
          data['order_id'] = this.inputOrderId;
          data['frenchise_id'] = this.frenchiseId
          data['amount'] = (+this.amount).toFixed(2);
          data['payment_type'] = "bank_account"
          if (data['phone_number']) {
            data['phone_number'] = data['phone_number'].replace(/[^0-9]/g,'');
          }
          this.loading = true;
          this.quickbookPaymentError = [];
          this._salesOrderService.makeOrderPayment(this.frenchiseId,data).subscribe((res)=>{
            this.amount = null;
              if(res['data']){
                this.reciept =   res['data']['receipt_url']
              }
              this.ngOnInit()
              this.loading = false
              this.receivedPayment.emit();
              this.closeModel.emit();
          },(error)=>{
              this.loading = false;
              this.toasterService.pop('error', 'Bank transaction can not be done!');
              if (error['error']) {
                  this.api_error = error['error']['message'];
                  if(error['error']['data']) {
                    this.quickbookPaymentError = error['error']['data'];
                  }
              }
          })
      }else {
        this.loading = false
        this.errorAmountBankTransfer = "Please Enter Amount!"
      }
      if(!this.amount){
        this.errorAmountBankTransfer = "Please Enter Amount!"
      }
    }else {
      this.showBankPaymentErrors = true;
    }
  } 

  setPaymentMethod = (event) => {
    let payment_method = event.target.innerText;
    if(payment_method == 'Bank Transfer') {
      this.stripePaymentMethod = 'bank_transfer';
    }else if(payment_method == 'Card'){
      this.stripePaymentMethod = 'card';
    }
  }

  formatAmount = (event) => {
    let unformattedvalue = event.target.value;
    unformattedvalue = Number(unformattedvalue.replace(/,/g, ''));
    event.target.value = this.decimalPipe.transform(unformattedvalue, '1.2-2');
    this.amount = event.target.value;
  } 

  onAmountUpdate = (event) => {
    let unformattedvalue = event.target.value;
    unformattedvalue = Number(unformattedvalue.replace(/,/g, ''));
    if(event.target.value){
      this.api_error = '';
      if(unformattedvalue > 0) {
        if(this.stripePaymentMethod == 'card') {
          this.errorAmountCard = '';
          this.card_errors = {};
        }else {
          this.errorAmountBankTransfer = '';
        }
      }
      this.amount = event.target.value
    }else{
      this.amount = 0;
    }
  }

  setReceivedData = () => {
      this.amountData['total_amount'] = this.paymentAmounts['total'];
      this.amountData['due'] = this.paymentAmounts['due'];
      this.amountData['received'] = this.paymentAmounts['received'];
  }

  get c() { return this.cardPaymentForm.controls; }

  get b() { return this.bankTransferPaymentForm.controls; }
}