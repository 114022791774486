import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonService } from './common.service';
import { environment } from '../../environments/environment';


@Injectable({ providedIn: 'root' })
export class ReportService {
  constructor(private _http: HttpClient,
    private _cs: CommonService) {
  }
  getSalesRepReport = (data) => {
    return this._http.post<any>(environment.API_URL + 'reports/sales-report', data, this._cs.setHeader());
  }
  getCompleteCommissionReport = (data) => {
    return this._http.post<any>(environment.API_URL + 'reports/complete-commission-report', data, this._cs.setHeader());
  }
  getInvoiceReport = (data) => {
    return this._http.post<any>(environment.API_URL + 'reports/invoice-report', data, this._cs.setHeader());
  }
  getCommissionForExport = (data) => {
    return this._http.post<any>(environment.API_URL + 'reports/filter-commission-orders', data, this._cs.setHeader());
  }
  getVariantReport = (data)=> {
    return this._http.post<any>(environment.API_URL + 'reports/variant-report', data, this._cs.setHeader());
  }
  getKPIPresentationReport = (data) => {
    return this._http.post<any>(environment.API_URL + 'reports/kpi-presentation-report', data, this._cs.setHeader());
  }
  getKPIEstimateReport = (data) => {
    return this._http.post<any>(environment.API_URL + 'reports/kpi-estimate-report', data, this._cs.setHeader());
  }
  getKPISalesOrderReport = (data) => {
    return this._http.post<any>(environment.API_URL + 'reports/kpi-sales-order-report', data, this._cs.setHeader());
  }
  getSalesOrder = (data) => {
    return this._http.post<any>(environment.API_URL + 'reports/filter-sales-orders', data, this._cs.setHeader());
  }
  getSalesOrderFilterByClientList = (data) => {
    return this._http.post<any>(environment.API_URL + 'reports/filter-sales-order-by-client-list', data, this._cs.setHeader());
  }
  getPoOrder = (data) => {
    return this._http.post<any>(environment.API_URL + 'reports/filter-purchase-orders', data, this._cs.setHeader());
  }
  getInvoicesOrder = (data) => {
    return this._http.post<any>(environment.API_URL + 'reports/filter-invoice-orders-report', data, this._cs.setHeader());
  }

  getInvoiceData = (data) => {
    return this._http.post<any>(environment.API_URL + 'reports/filter-invoice-orders', data, this._cs.setHeader());
  }
}
