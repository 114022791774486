import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { CommonService } from './common.service';

@Injectable({ providedIn: 'root' })
export class ProjectService {
  constructor(private _http: HttpClient, private _cs: CommonService) { }

  getTurnArounds = () => {
    return this._http.get(environment.API_URL + 'get-turnarounds', this._cs.setHeader());
  }
  saveProject = (fData) => {
    let header:any = this._cs.setHeader();
    header['reportProgress'] = true;
    header['observe'] = "events";
    return this._http.post<any>(environment.API_URL + 'projects/save', fData, header);
  }
  digitalProjectsave = (fData) => {
    return this._http.post<any>(environment.API_URL + 'projects/save', fData, this._cs.setHeader());
  }

  getMarginVariantReport = () =>{
    return this._http.get(environment.API_URL + 'reports/margin-variant-report',this._cs.setHeader());
  }

  getProjectById = (id) => {
    return this._http.get<any>(environment.API_URL + 'projects/' + id, this._cs.setHeader());
  }
  
  getProjectSupplier = (project_id, franchiseId = '') => {
    return this._http.get(environment.API_URL + 'projects/get-project/' + project_id, this._cs.setHeader(franchiseId));
  }
  getProjectBidData = (id) => {
    return this._http.post<any>(environment.API_URL + 'projects/get-project-bids', { id }, this._cs.setHeader());
  }
  getProjectBidDataNew = (id) => {
    return this._http.post<any>(environment.API_URL + 'projects/get-project-link-bids', { id }, this._cs.setHeader());
  }
  getProjectBidHistory = (id) => {
    return this._http.get(environment.API_URL + 'projects/bid-sent-history/' + id, this._cs.setHeader());
  }
  approveProjectBid = (id) => {
    return this._http.get(environment.API_URL + 'supplier/approve-bid/' + id, this._cs.setHeader());
  }
  rejectProjectBid = (data) => {
    return this._http.post(environment.API_URL + 'supplier/reject-bid', data, this._cs.setHeader());
  }
  getTaxTypes = () => {
    return this._http.get(environment.API_URL + 'get-tax-types', this._cs.setHeader());
  }
  getProjectDataEstimation = (id) => {
    return this._http.get(environment.API_URL + 'projects/get-project-for-estimation/' + id, this._cs.setHeader());
  }
  createEstimate = (data) => {
    let header:any = this._cs.setHeader();
    header['reportProgress'] = true;
    header['observe'] = "events";
    return this._http.post<any>(environment.API_URL + 'estimation/save', data, header);
  }
  createDirectEstimate = (data) => {
    let header:any = this._cs.setHeader();
    header['reportProgress'] = true;
    header['observe'] = "events";
    return this._http.post<any>(environment.API_URL + 'estimation/create-estimation', data, header);
  }
  createDirectEstimate1 = (data) => {
    return this._http.post<any>(environment.API_URL + 'estimation/create-digital-estimation', data, this._cs.setHeader());
  }
  getProjectApproveBids = (id) => {
    return this._http.post<any>(environment.API_URL + 'projects/get-project-approved-bids', { id }, this._cs.setHeader());
  }
  getEstimateData = (id) => {
    return this._http.get(environment.API_URL + 'estimation/get-estimation/' + id, this._cs.setHeader());
  }
  estimateSeenByDistributor = (id) => {
    return this._http.get(environment.API_URL + 'estimation/estimate-seen-by-distributor/' + id, this._cs.setHeader());
  }
  getEstimatePostData = (id, franchiseId) => {
    return this._http.get(environment.API_URL + 'estimation/get-estimation/' + franchiseId + '/' + id);
  }
  getEstimationOfOrder = (id) => {
    return this._http.get(environment.API_URL + 'estimation/get-order-estimation/' + id, this._cs.setHeader());
  }
  getCustomerInvoiceData = (id) => {
    return this._http.get(environment.API_URL + 'estimation/get-customer-invoices-data/' + id, this._cs.setHeader());
  }
  getOrderData = (id) => {
    return this._http.get(environment.API_URL + 'order/get/' + id, this._cs.setHeader());
  }
  getOrderDetailData = (id) => {
    return this._http.get(environment.API_URL + 'order/get-detail/' + id, this._cs.setHeader());
  }
  getProjectStatus = (id) => {
    return this._http.get(environment.API_URL + 'projects/project-status/' + id, this._cs.setHeader());
  }

  getProjectsByUser = (sales_rep_or_csr_id, contact_id = null) => {
    let url = environment.API_URL + 'projects/get-projects-by-user/' + sales_rep_or_csr_id;
    if (contact_id) {
      url += '/' + contact_id;
    }
    return this._http.get(url, this._cs.setHeader());
  }

  approveEstimation = (id) => {
    return this._http.post(environment.API_URL + 'estimation/approve-estimation', { id: id }, this._cs.setHeader());
  }

  rejectEstimation = (data) => {
    return this._http.post(environment.API_URL + 'estimation/reject-estimation', data, this._cs.setHeader());
  }

  estimationChangeRequest = (data) => {
    return this._http.post(environment.API_URL + 'estimation/change-request', data, this._cs.setHeader());
  }

  createSalesOrder = (data, id, projecttype) => {
    let header:any = this._cs.setHeader();
    header['reportProgress'] = true;
    header['observe'] = "events";
    if (id && projecttype != 0) {
      return this._http.post<any>(environment.API_URL + 'order/save', data, header);
    } else {
      return this._http.post<any>(environment.API_URL + 'order/create-est-order', data, header);
    }
  }
  updateOrderItems = (data) => {
    return this._http.post<any>(environment.API_URL + 'order/update-order-items', data, this._cs.setHeader());
  }
  updateOrderItemsValidate = (data) => {
    return this._http.post<any>(environment.API_URL + 'order/update-order-items-validate', data, this._cs.setHeader());
  }
  getAllProjectDataTable = (data, url) => {
    return this._http.post<any>(environment.API_URL + url, data, this._cs.setHeader());
  }
  getAllActivityList = () => {
    return this._http.get(environment.API_URL + 'activitiy-list', this._cs.setHeader());
  }

  downloadAllPdf = (data) => {
    return this._http.post(environment.API_URL + 'estimation/download-estimation', data, this._cs.setexportHeader());
  }
  downloadAllPdfNew = (data) => {
    return this._http.post(environment.API_URL + 'estimation/download-estimation-pdf', data, { responseType: 'blob' as 'blob' });
  }
  downloadInvoicePdf = (data) => {
    return this._http.post(environment.API_URL + 'order/download-invoice', data, this._cs.setexportHeader());
  }
  publicDownloadInvoicePdf = (data) => {
    return this._http.post(environment.API_URL + 'public-download-invoice', data, { responseType: 'blob' as 'blob' });
  }
  downloadPurchasePdf = (data, franchiseId = '') => {
    return this._http.post(environment.API_URL + 'purchase-order/purchase-order-pdf', data, this._cs.setexportHeader(franchiseId));
  }
  downloadQuotesPdf = (data) => {
    return this._http.post(environment.API_URL + 'print-products/quote/quotes-pdf', data, this._cs.setexportHeader());
  }
  DownloadEstimation = (data) => {
    return this._http.post(environment.API_URL + 'estimation/estimation-invoice', data, this._cs.setexportHeader());
  }
  downloadSalesOrderPdf = (data) => {
    return this._http.post(environment.API_URL + 'order/download-sales-order', data, this._cs.setexportHeader());
  }
  approveSalesOrder = (id, status) => {
    return this._http.post(environment.API_URL + 'order/approve-order', { id: id, status: status }, this._cs.setHeader());
  }

  sendEstimationToCustomer = (estimationId, val = null, is_downloaded = false) => {
    let data = {
      estimation_id: estimationId,
      notes_to_customer: val,
      is_downloaded: is_downloaded
    };
    return this._http.post(environment.API_URL + 'estimation/send-estimate-to-customer', data, this._cs.setHeader());
  }

  
  approveOrRejectCustomerEstimation = (frenchiseId, postData) => {
    return this._http.post(environment.API_URL + 'estimation/approve-reject-customer-estimation/' + frenchiseId, postData, this._cs.setHeader());
  }
  getProDetailsForSupplier = (projectId,frenchiseId) => {
    return this._http.get(environment.API_URL + 'get-project-details/' + frenchiseId+'/'+projectId, this._cs.setHeader());
  }
  getProjectArchieve = (data) => {
    return this._http.post<any>(environment.API_URL + 'projects/archieve-projects',data, this._cs.setHeader());
  }
  deleteProject = (id,data) => {
    return this._http.post(environment.API_URL + 'projects/delete-project/'+id, data, this._cs.setHeader());
  }
  applycolorFilter = (data) => {
    let header:any = this._cs.setHeader();
  //  header['reportProgress'] = true;
   // header['observe'] = "events";
    return this._http.post(environment.API_URL + 'projects/color-filter',data, header);
  }
  getProjectByIdDigital = (id) => {
    return this._http.get<any>(environment.API_URL + 'estimation/get-digital-project/' + id, this._cs.setHeader());
  }
  getProjectComment = (data) => {
    return this._http.post<any>(environment.API_URL + 'projects/project-status-with-comment', data, this._cs.setHeader());
  }
  getCommentsFromHistory = (data) => {
    return this._http.post(environment.API_URL + 'estimation/get-customer-comments-history', data, this._cs.setHeader());
  }
  savePresseroOrder = (data) => {
    let header:any = this._cs.setHeader();
    header['reportProgress'] = true;
    header['observe'] = "events";
    return this._http.post(environment.API_URL + 'estimation/create-presser-order', data, header);
  }
  saveOrder = (data) => {
    let header:any = this._cs.setHeader();
    header['reportProgress'] = true;
    header['observe'] = "events";
    return this._http.post(environment.API_URL + 'estimation/create-order', data, header);
  }
  createRequest = (data) => {
    let header:any = this._cs.setHeader();
    header['reportProgress'] = true;
    header['observe'] = "events";
    return this._http.post(environment.API_URL + 'request_form/save-storefront-request', data, header); 
  }
  updateRequest = (data) => {
    let header:any = this._cs.setHeader();
    header['reportProgress'] = true;
    header['observe'] = "events";
    return this._http.post(environment.API_URL + 'request_form/save-storefrontupdate-request', data, header); 
  }
  artworkRequest = (data) => {
    let header:any = this._cs.setHeader();
    header['reportProgress'] = true;
    header['observe'] = "events";
    return this._http.post(environment.API_URL + 'request_form/save-artwork-request', data, header);
  }
  getRequestById = (id) => {
    return this._http.get<any>(environment.API_URL + 'request_form/storefront-get-request/' + id, this._cs.setHeader());
  }
  getUpdateById = (id) => {
    return this._http.get<any>(environment.API_URL + 'request_form/storefront-update-request/' + id, this._cs.setHeader());
  }
  updateRequestStatus = (data) => {
    // request_form/storefrontupdate-request-change-status
    return this._http.post(environment.API_URL + 'request_form/storefrontupdate-request-change-status', data, this._cs.setHeader()); 
  }
  updateStatus = (data) => {
    return this._http.post(environment.API_URL + 'request_form/storefront-request-change-status', data, this._cs.setHeader());
  }
  saveStorefrontRequestAdditionalDetails = (data) => {
    return this._http.post(environment.API_URL + 'request_form/save-storefront-request-additional-details', data, this._cs.setHeader()); 
  }
  updateArtworkStatus = (data) => {
    return this._http.post(environment.API_URL + 'request_form/artwork-request-change-status', data, this._cs.setHeader());
  }
  getUpdateartworkById = (id) => {
    return this._http.get<any>(environment.API_URL + 'request_form/artwork-update-request/' + id, this._cs.setHeader());
  }
  getSiteAudit = (id) => {
    return this._http.get<any>(environment.API_URL + 'digital-services/site-audit-request/' + id, this._cs.setHeader());
  }
  updateSiteRequestStatus = (data) => {
    return this._http.post(environment.API_URL + 'digital-services/site-audit-change-status', data, this._cs.setHeader()); 
  }
  saveCampaignRequest = (data) => {
    return this._http.post(environment.API_URL + 'digital-services/save-campaign-request', data, this._cs.setHeader()); 
  }

  editCampaignRequest = (id) => {
    return this._http.get<any>(environment.API_URL + 'digital-services/edit-campaign-request/' + id, this._cs.setHeader());
  }

  getUpdateCampaignRequestById = (id) => {
    return this._http.get<any>(environment.API_URL + 'digital-services/campaign-request/' + id, this._cs.setHeader());
  }
  saveEmailGeneratorRequest = (data) => {
    return this._http.post(environment.API_URL + 'digital-services/save-email-generator', {"postData":data}, this._cs.setHeader()); 
  }
  getEmailGeneratorRequestById = (id) => {
    return this._http.get<any>(environment.API_URL + 'digital-services/email-generator/' + id, this._cs.setHeader());
  }
  saveCreativeRequest = (data) => {
    return this._http.post(environment.API_URL + 'digital-services/save-creative-request', data, this._cs.setHeader());
  }
  getUpdateCreativeRequestById = (id) => {
    return this._http.get<any>(environment.API_URL + 'digital-services/creative-update-request/' + id, this._cs.setHeader());
  }
  updateCreativeRequestStatus = (data) => {
    return this._http.post(environment.API_URL + 'digital-services/creative-request-change-status', data, this._cs.setHeader());
  }
  getPageIntroduction = (id) => {
    return this._http.get<any>(environment.API_URL + 'page-intro/get-page-intro/' + id, this._cs.setHeader());
  }
  savePageIntroduction = (postData)=> {
    let header:any = this._cs.setHeader();
    header['reportProgress'] = true;
    header['observe'] = "events";
    return this._http.post(environment.API_URL + 'page-intro/save', postData, header);
  }
  getCampaignRequestTitles = () => {
    return this._http.get(environment.API_URL + 'campaign-request/get-title', this._cs.setHeader());
  }
  getCampaignRequestIndustry = () => {
    return this._http.get(environment.API_URL + 'campaign-request/get-industry', this._cs.setHeader());
  }
  saveContentGeneratorRole = (data) => {
    return this._http.post(environment.API_URL + 'campaign-request/save-role', data, this._cs.setHeader());
  }
  getContentRole = () =>{
    return this._http.get(environment.API_URL + 'campaign-request/get-role', this._cs.setHeader());
  }
  saveContentEmail = (data) => {
    return this._http.post(environment.API_URL + 'campaign-request/save-content-email', data, this._cs.setHeader());
  }
  getContentEmail = () =>{
    return this._http.get(environment.API_URL + 'campaign-request/get-content-email', this._cs.setHeader());
  }
  saveContentDropdown = (data) => {
    return this._http.post(environment.API_URL + 'campaign-request/save-email-data', data, this._cs.setHeader());
  }
  getRoleWiseEmail = (id) => {
    return this._http.get(environment.API_URL + 'campaign-request/get-content-email-by-role/' + id, this._cs.setHeader());
  }
  getEmailContent = (id) => {
    return this._http.get(environment.API_URL + 'campaign-request/email-content-data/' + id, this._cs.setHeader());
  }
  getEmailGenerators(){
    return this._http.get(environment.API_URL + 'campaign-request/get-email-generators', this._cs.setHeader());
  }
  saveCampaignSource = (data) =>{
     return this._http.post(environment.API_URL + 'campaign-request/save-source', data, this._cs.setHeader());
  }
  removeCampaignSource = (id) => {
    return this._http.get(environment.API_URL + 'campaign-request/delete-source/' + id, this._cs.setHeader());
  }
  getCampaignSource = () => {
    return this._http.get(environment.API_URL + 'campaign-request/get-source', this._cs.setHeader());
  }
  saveCreativeResourceCategory = (data) => {
    let header:any = this._cs.setHeader();
    header['reportProgress'] = true;
    header['observe'] = "events";
    return this._http.post(environment.API_URL + 'campaign-request/save-creative-resource-category', data, header);
  }
  saveCreativeResourceDocument = (data) => {
    let header:any = this._cs.setHeader();
    header['reportProgress'] = true;
    header['observe'] = "events";
    return this._http.post(environment.API_URL + 'campaign-request/save-creative-resource-document', data, header);
  }
  getResourceCategory = () => {
    return this._http.get(environment.API_URL + 'campaign-request/get-creative-resource-category', this._cs.setHeader());
  }
  getAllCategory = () => {
    return this._http.get(environment.API_URL + 'campaign-request/get-creative-resource-category', this._cs.setHeader());
  }
  getDocumentBycategory = (id) => {
    return this._http.get<any>(environment.API_URL + 'campaign-request/get-creative-resource-document-by-id/' + id, this._cs.setHeader());
  }
  deleteCreativeDocument = (id) => {
    return this._http.get(environment.API_URL + 'campaign-request/delete-creative-resource-document/'+id, this._cs.setHeader());
  }
  deleteCreativeCategory = (id) => {
    return this._http.get(environment.API_URL + 'campaign-request/delete-creative-resource-category/'+id, this._cs.setHeader());
  }
  //added for delete email, role and option
  deleteEmailContent = (id) => {
    return this._http.get(environment.API_URL + 'campaign-request/delete-content-email/'+id, this._cs.setHeader());
  }
  deleteRole = (id) => {
    return this._http.get(environment.API_URL + 'campaign-request/delete-campaign-role/'+id, this._cs.setHeader());
  }
  deleteDropdown = (id) => {
    return this._http.get(environment.API_URL + 'campaign-request/delete-email-data/'+id, this._cs.setHeader());
  }

  saveIntroVideo = (fData) => {
    let header:any = this._cs.setHeader()
    header['reportProgress'] = true;
    header['observe'] = "events";
    return this._http.post<any>(environment.API_URL + 'page-intro/save-video', fData ,header);
  }
  sendSiteDocument = (data) => {
    return this._http.post(environment.API_URL + 'digital-services/send-document', data, this._cs.setHeader());
  }
  createProjectStickyNote = (postData) => {
    return this._http.post<any>(environment.API_URL + 'project-sticky-note/save', postData, this._cs.setHeader());
  }
  getProjectStickyNotesByProjectId = (projectId) => {
    return this._http.get<any>(environment.API_URL + 'project-sticky-note/get-notes-by-project-id/'+projectId, this._cs.setHeader());
  }
  deleteProjectStickyNote = (id) => {
    return this._http.get(environment.API_URL + 'project-sticky-note/delete-note/'+id, this._cs.setHeader());
  }
  validatePublicEstimationLinkByToken = (franchiseId, token) => {
    return this._http.get(environment.API_URL + 'estimation/validate-public-link-by-token/'+ franchiseId +'/' + token, this._cs.setHeader());
  }

  getProjectHistory = (data) => {
    return this._http.post(environment.API_URL + 'projects/history',data, this._cs.setHeader());
  }
}
