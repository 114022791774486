import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

import * as moment from 'moment';

@Pipe({
  name: 'CustomDateFilter'
})

export class DateFilterPipe implements PipeTransform {

  constructor(private datePipe: DatePipe) { }

  transform(date: any): any {
     var issafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    let newDate: any = new Date(date);
   
    if(issafari){
      if (date.split(" ").length > 1) {
        return this.datePipe.transform(date.replace(/\s/g,"T"), 'MM-dd-yyyy');
      }else{
          let newOne :any = date.split("-")
          newOne = newOne[2] +  newOne[1]  +  newOne[0] + " " + "00:00:00"
        return this.datePipe.transform(newOne.replace(/\s/g,"T"), 'MM-dd-yyyy');
      }
    }else{
      if (isNaN(newDate.getTime())) {
        const dateArr = date.split('-');
        newDate = new Date(dateArr[2], dateArr[1] - 1, dateArr[0]);
      //  newDate =  newDate.timestamp_out.replace(/\s/g,"T")
      }
      return this.datePipe.transform(newDate, 'MM-dd-yyyy');
    }
    // return this.datePipe.transform(newDate, 'MM-dd-yyyy');
    // return this.datePipe.transform(newDate.replace(/\s/g,"T"), 'MM-dd-yyyy');
  }
}
