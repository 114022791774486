import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { CommonService } from './common.service';

@Injectable({ providedIn: 'root' })
export class ApparealService {
  constructor(private _http: HttpClient, private _cs: CommonService) { }

  getApparealOption = (postData) => {
    return this._http.post(environment.API_URL + 'apparel-products/get-all', {'filter'  : postData },this._cs.setHeader());
  }
  getProductDetails = (description,manufacturer_style_number) => {
    return this._http.post<any>(environment.API_URL + 'apparel-products/get-quote-product', {'description' : description,'manufacturer_style_number' : manufacturer_style_number}, this._cs.setHeader());
  }
  addToCart = (postData) => {
    return this._http.post<any>(environment.API_URL + 'apparel-products/add-to-cart', postData, this._cs.setHeader());
  }
  getCartData = (franchiseId='') => {
    return this._http.post<any>(environment.API_URL + 'apparel-products/get-cart-data',franchiseId, this._cs.setHeader());
  }
  shippingCostCalculate = (postData,cart) => {
    return this._http.post<any>(environment.API_URL + 'apparel/calculate-shipping-cost', {'postData' :postData,'cartData' : cart}, this._cs.setHeader());
  }
  getCategory = () => {
    return this._http.get(environment.API_URL + 'apparel/category-data',this._cs.setHeader());
  }
  getProduct = (postData,pageNumber) => {
      return this._http.post<any>(environment.API_URL + 'apparel/product-data',{'filter'  : postData,'page' : pageNumber}, this._cs.setHeader());
  }
  getApparelProductDetails = (product_name) => {
    product_name = btoa(product_name);
    return this._http.get(environment.API_URL + 'apparel/product-info/' + product_name,this._cs.setHeader());
  }
  saveScalableEstimation = (postData) => {
    return this._http.post(environment.API_URL + 'estimation/create-scalable-estimation',  postData ,this._cs.setHeader());
  }
  getPrice = (postData) => {
    return this._http.post<any>(environment.API_URL + 'apparel/get-quote', postData, this._cs.setHeader());
  }
  updateImages  = (postData) => {
    return this._http.post<any>(environment.API_URL + 'apparel/get-temp-img', postData, this._cs.setHeader());
  }
  deleteCartItem = (id) => {
    return this._http.get(environment.API_URL + 'apparel-products/delete-cart-data/' + id,this._cs.setHeader());
  }
}

