import { OnInit, Component, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import { FranchiseService } from '../../service/franchise.service';
import {CommonService} from '../../service/common.service';

@Component({
  selector: 'app-card-component',
  templateUrl: './card-component.component.html',
  styleUrls: ['./card-component.component.scss']
})
export class CardComponent implements OnInit {
  @Input() icon: string;
  @Input() title: string;
  @Input() btnUrl: string;
  @Input() urlParms: string;
  @Input() btnIcon: string;
  @Input() btnTitle: string;
  @Input() btnFunction: boolean;
  @Input() btnPermission: string;
  @Input() btnId: string;
  @Input() isModal: boolean;
  @Input() projectStatus: boolean;
  @Input() digital : boolean;
  @Input() modalRef: string;
  @Input() imgUrl: string;
  @Input() customClass: string;
  @Input() direct:boolean;
  @Output() eventBtn = new EventEmitter();
  franchiseId = '';
  roleUser = ''; //added to set value of whoes role is active
  is_estimator = false;
  previousUrl: any = null;

  constructor(
    private router: Router,
    private _route1: ActivatedRoute,
    private franchiseService: FranchiseService,
    public commonService: CommonService
  ) {}

  ngOnInit() {
    this.commonService.previousUrl$
    .subscribe((previousUrl: string) => {
        this.previousUrl = previousUrl
    });

    const urlArr = window.location.pathname.split('/');
    if (!isNaN(+urlArr[1])) {
      this.franchiseId = urlArr[1];
      let franchiseData = this.franchiseService.franchiseData.getValue();
      if(!franchiseData['role']) {
        this.franchiseService.getFranchiseData(this.franchiseId).subscribe((res) => {
          franchiseData = res['data'];
          this.roleUser = franchiseData['role']['original_name'];
          if(this.roleUser === 'Estimator'){
            this.is_estimator = true;
          }
        },(error) => {
          console.log(error);
        });
      }else {
        this.roleUser = franchiseData['role']['original_name'];
        if(this.roleUser === 'Estimator'){
          this.is_estimator = true;
        }
      }
    }   
  }

  redirectToPage = () => {
    if (this.btnUrl === 'back') {
      if(this.previousUrl){
        this.router.navigateByUrl(this.previousUrl || '/'+this.franchiseId+'/project');
      }else{
        var path = window.location.pathname.split('/')
        this.router.navigate(['/' + this.franchiseId + '/' + path[2]],{ relativeTo: this._route1 })
      }
    } else {
      if (this.urlParms) {
        if (this.franchiseId) {
          this.router.navigate(['/' + this.franchiseId + '/' + this.btnUrl, this.urlParms]);
        } else {
          this.router.navigate([this.btnUrl, this.urlParms]);
        }
      } else {
        if (this.franchiseId) {
          this.router.navigate(['/' + this.franchiseId + '/' + this.btnUrl]);
        } else {
          this.router.navigate([this.btnUrl]);
        }
      }
    }
  }

  openModal = (modal) => {
    modal.show();
  }

  onFunctionEvent = () => {
    this.eventBtn.emit('test');
  }
}
