import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserService } from '../../../service/user.service';
import { ToasterService } from 'angular2-toaster';
import { CommonService } from '../../../service/common.service';
import { countryCodes } from '../../../../assets/json-options/country-codes';
import { states } from '../../../../assets/json-options/usa-states';
import {FranchiseService} from '../../../service/franchise.service';
import {AppConstants} from '../../../constants/app.constants';

@Component({
  selector: 'app-contact-company-create',
  templateUrl: './contact-company-create.component.html',
  styleUrls: ['./contact-company-create.component.scss']
})

export class ContactCompanyCreateComponent implements OnInit {
  loading = false;
  api_error: any = {};
  
  company: any = {
    billing_state: null,
    billing_country: 'US',
    shipping_state: null,
    shipping_country: null
  };

  isAccountant = false;
  @Input() contact_company_id = null;
  @Output() isSaved:EventEmitter<boolean>= new EventEmitter();

  countryList = countryCodes;
  stateList = states;
  
  constructor(
    private userService: UserService,
    private _toast: ToasterService,
    public _csService: CommonService,
    private franchiseService: FranchiseService
  ) {}

  ngOnInit() {
    const franchiseRes = this.franchiseService.franchiseData.getValue();
    if(franchiseRes['role']['original_name'] === AppConstants['roles']['accountant']) {
      this.isAccountant = true;
    }
    
    if (this.contact_company_id) {
      this.getContactCompanyDetails();
    } else {
      this.initContactCompany();
    }
  }

  initContactCompany(data = []) {
    this.company = {
      id: data['id'],
      name: data['name'],
      customer_source : data['customer_source'] || '',
      credit_limit: data['credit_limit'] || 0,
      accounting_email: data['accounting_email'] || '',
      billing_address_1: data['billing_address_1'] || '',
      billing_address_2: data['billing_address_2'] || '',
      billing_city: data['billing_city'] || '',
      billing_state: data['billing_state'] || '',
      billing_zipcode: data['billing_zipcode'] || '',
      billing_country: data['billing_country'] || 'US',
      shipping_address_1: data['shipping_address_1'] || '',
      shipping_address_2: data['shipping_address_2'] || '',
      shipping_city: data['shipping_city'] || '',
      shipping_state: data['shipping_state'] || '',
      shipping_zipcode: data['shipping_zipcode'] || '',
      shipping_country: data['shipping_country'] || 'US',
      credit_term: data['credit_term'] || 'PRE_PAYMENT',
    };
  }

  getContactCompanyDetails() {
    this.loading = true;
    this.userService.getContactCompany(this.contact_company_id).subscribe(response => {
      if (response['data']) {
        this.loading = false;
        this.initContactCompany(response['data'])
      }
    }, error => {
      this.loading = false;
      this._toast.pop('error', 'There is some server issue!');
    });
  }

  isSameAddress = (event) => {
    if (event && event.currentTarget && event.currentTarget.checked) {
      this.company.shipping_address_1 = this.company.billing_address_1
      this.company.shipping_address_2 = this.company.billing_address_2
      this.company.shipping_city = this.company.billing_city
      this.company.shipping_state = this.company.billing_state
      this.company.shipping_zipcode = this.company.billing_zipcode
      this.company.shipping_country = this.company.billing_country
    } else {
      this.company.shipping_address_1 = '';
      this.company.shipping_address_2 = '';
      this.company.shipping_city = '';
      this.company.shipping_state = '';
      this.company.shipping_zipcode = '';
      this.company.shipping_country = '';
    }
  }

  saveContactCompany() {
    this.loading = true;
    this.userService.saveContactCompany(this.company).subscribe(response => {
      if (response['data']) {
        this.loading = false;
        if (response['data']['error']) {
          this.api_error = {};
        } else {
          this.isSaved.emit(response['data']);
        }
      }
    }, error => {
      this.loading = false;
      if (error['error'] && error['error']['data']) {
        this.api_error = error['error']['data'];
      } else {
        this._toast.pop('error', 'There is some server issue!');
      }
    });
  }

  cancel() {
    this.isSaved.emit(false);
  }
}