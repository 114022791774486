import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { CommonService } from '../common.service';

@Injectable()
export class PrintProductContactService {

  contactList: any = new BehaviorSubject([]);
  error: any = new BehaviorSubject({});

  constructor(private _http: HttpClient, private _cs: CommonService) { }

  fetchContactList = () => {
    if (this.contactList.getValue().length > 0) {
      this.contactList.next(this.contactList.getValue());
    } else {
      this.callContactListAPI().subscribe((response) => {
        if (response['data']) {
          response['data'].map(val => {
            val['full_name'] = val['full_name'] + ' (' + val['email'] + ')';
          })
          this.contactList.next(response['data']);
        }
      }, error => {
        this.error.next({ 'contact_list': true });
      });
    }
  }

  callContactListAPI = () => {
    return this._http.get(environment.API_URL + 'contacts', this._cs.setHeader());
  }

  fetchClientDetails = (client_id) => {
    return this._http.get(environment.API_URL + 'contacts/' + client_id, this._cs.setHeader())
  }

  createContact = (data) => {
    return this._http.post(environment.API_URL + 'print-products/create-contact', data, this._cs.setHeader());
  }

  deleteCompany = (client_id) => {
    return this._http.delete(environment.API_URL + 'contacts/' + client_id, this._cs.setHeader())
  }
    
}