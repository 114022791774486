import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { takeUntil } from 'rxjs/operators';
import { HttpCancelService } from '../service/httpCancle.service';

/*
 To cancle old http requests when route changes
*/

@Injectable({ providedIn: 'root' })
export class HTTPCancleInterceptor implements HttpInterceptor {

  constructor(private inj: Injector) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const _http_cancle = this.inj.get(HttpCancelService);
    return next.handle(request).pipe(takeUntil(_http_cancle.onCancelPendingRequests()));
  }
}
