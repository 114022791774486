import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CommonService } from './common.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class AsapQuoteService {
  constructor(private _http: HttpClient,
    private _cs: CommonService) {
  }
  saveQuote = (data) => {
    return this._http.post<any>(environment.API_URL + 'quote/save', data, this._cs.setHeader());
  }

}
