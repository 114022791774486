declare const Pusher: any;
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { FranchiseService } from './franchise.service';
import { AuthenticationService } from './authentication.service';
import { RolepermissionService } from './rolepermission.service';



@Injectable()
export class PusherService {
  pusher: any;
  channel: any;
  chatChannel: any;
  projectChatChannel: any = [];
  notificationChannel: any;
  notificationChannelSupplier: any = [];
  constructor(private _http: HttpClient,
    private franchiseService: FranchiseService,
    private authService: AuthenticationService,
    private rolePermissionService: RolepermissionService) {
    this.subscribeToChannel();
  }

  subscribeToChannel = () => {
    const franchiseData = this.franchiseService.franchiseData.getValue();
    const userData = this.authService.userData.getValue();
    const projectData = this.rolePermissionService.projectsData.getValue();

    this.pusher = new Pusher(environment.pusher.key, {
      cluster: environment.pusher.cluster,
      encrypted: true
    });

    if (userData['is_supplier']) {
      // this.chatChannel =
      //   this.pusher.subscribe('channel-' + environment['pusher']['channel_prefix'] + franchiseData['id'] + '-' + userData['id']);
      const supplierFranchises = this.franchiseService.franchiseOptions.getValue();
      supplierFranchises.forEach(franchise => {
        this.notificationChannelSupplier.push(
          this.pusher.subscribe('notify-channel-' + environment['pusher']['channel_prefix'] + franchise['id'] + '-' + userData['id']));
      });
    } else {
      this.chatChannel =
        this.pusher.subscribe('channel-' + environment['pusher']['channel_prefix'] + franchiseData['id'] + '-' + userData['id']);
      this.notificationChannel =
        this.pusher.subscribe('notify-channel-' + environment['pusher']['channel_prefix'] + franchiseData['id'] + '-' + userData['id']);
      /*
      if (projectData.length > 0) {
        projectData.forEach(element => {
          this.projectChatChannel.push(this.pusher
            .subscribe('channel-' + environment['pusher']['channel_prefix'] + franchiseData['id'] + '-' + element['project_uid']));
        });
      }
      */
    }

    Pusher.log = function (message) {
      if (window.console && window.console.log) {
        // window.console.log(message);
      }
    };

  }

  unbindProjectChannels = () => {
    if (this.projectChatChannel.length > 0) {
      this.projectChatChannel.forEach(channel => {
        channel.unbind('App\\Events\\PusherProjectChat');
      });
    }
  }

  unbindSupplierNotifChannels = () => {
    if (this.notificationChannelSupplier.length > 0) {
      this.notificationChannelSupplier.forEach(channel => {
        channel.unbind('App\\Events\\NotifyEvent');
      });
    }
  }

}
