import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { CommonService } from './common.service';
@Injectable({ providedIn: 'root' })
export class FranchiseService {
  franchiseData = new BehaviorSubject({});
  franchiseOptions = new BehaviorSubject([]);

  constructor(private _http: HttpClient, private _cs: CommonService) {
  }

  saveFranchiseDetail = (franchiseData) => {
    return this._http.post<any>(environment.API_URL + 'franchise/save', franchiseData, this._cs.setHeader());
  }
  getFranchiseData = (franchiseId = '') => {
    return this._http.get(environment.API_URL + 'franchise', this._cs.setHeader(franchiseId));
  }
  refreshFranchiseData = (franchiseId = '') => {
    return new Promise((resolve, reject) => {
      return this.getFranchiseData(franchiseId).subscribe(res => {
        if (res['data']) {
          this.franchiseData.next(res['data']);
          resolve(true);
        } else {
          resolve(true);
        }
      });
    });
  }
  postQuickbookSettingDetail = (settingdata) => {
    return this._http.post<any>(environment.API_URL + 'franchise/save-quickbook-setting', settingdata, this._cs.setHeader());
  }
  getQuickbookSettingDetail = (franchiseid) => {
    return this._http.get(environment.API_URL + 'franchise/quickbook-setting/'+ franchiseid, this._cs.setHeader());
  }
  saveNewUpdateData = (data) => {
    return this._http.post<any>(environment.API_URL + 'franchise/save-update', data, this._cs.setHeader());
  }
  getNewUpdate = () => {
    return this._http.get(environment.API_URL + 'franchise/get-new-updates-notificaton', this._cs.setHeader());
  }
  getUpdateById = (id) => {
    return this._http.get(environment.API_URL + 'franchise/get-update-id/'+ id, this._cs.setHeader());
  }
  
  getAllFranchise = () => {
    return this._http.get(environment.API_URL + 'all-franchise');
  }
}
