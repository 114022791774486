import { Component, OnInit } from '@angular/core';
import { Utils } from '../../../../utils';
import { CommonService } from '../../../../service/common.service';
import { ActivatedRoute } from '@angular/router';
import { ProjectService } from '../../../../service/project.service';
import Swal from 'sweetalert2';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SupplierService } from '../../../../service/supplier.service';

declare const $: any;

@Component({
  templateUrl: 'supplier-public-estimation.component.html',
  styleUrls: ['./supplier-public-estimation.component.scss']
})

export class SupplierPublicEstimationComponent implements OnInit {
  products = [];
  status: any = {
    loading: false,
    success: false,
    error: false
  };

  // for view and edit quote
  projectData = {};
  franchiseLogo = '';
  loading = false;
  item: any = 'item0'
  api_error = {};
  total: any = 0;
  frenchiseId: any = undefined;
  printQuote = {};
  iisa_quote = {};
  additionalQuantities = [];
  iisa_additionalQuantities = [];

  projectId: any = undefined
  supplierBidForm: FormGroup;
  filesArr: any = [];
  docArr: any = [];
  oldDocArr: any = [];
  checkExist: boolean = false;
  supplierId: any = undefined
  supplierEditData: any = undefined
  edit: boolean = false;
  supplierBidEditId: any = undefined
  project_supplier_id :any = undefined
  isvalue :boolean = false;


  // end
  constructor(
    private utils: Utils,
    private supplierService: SupplierService,
    public commonService: CommonService,
    private _activatedRoute: ActivatedRoute,
    private projectService: ProjectService,
    private _fb: FormBuilder,
  ) {}

  ngOnInit() {
    // console.log("supplier public link called")
    this._activatedRoute.params.subscribe(
      (params) => {
        if (params['franchise_id'] && params['project_id']) {
          try { this.frenchiseId = atob(params["franchise_id"]); }
          catch (e) { this.frenchiseId = atob( decodeURIComponent(params["franchise_id"]) ); }

          try { this.projectId = atob(params["project_id"]); }
          catch (e) { this.projectId = atob( decodeURIComponent(params["project_id"]) ); }

          try { this.supplierId = atob(params["supplier_id"]); }
          catch (e) { this.supplierId = atob( decodeURIComponent(params["supplier_id"]) ); }

          this.project_supplier_id = params['project_supplier_id']
          this.filesArr = []
          this.docArr = []
          this.oldDocArr = []
          this.supplierBidForm = this._fb.group({
            notes: [''],
            bid_doc: [''],
            item: this._fb.array([this.initItemForm()]),
            requirement_id:[''],
            project_suppliers_req_id:[''],
          });
          this.getProjectDetails(this.projectId, this.frenchiseId);
        }
      }
    );
    this.setFields();
  }

  commonChange = (control: FormControl) => {
    this.isvalue = true;
  }

  onTotalPriceChange = (control: FormControl,total_cost:false) => {
    this.isvalue = false;
    if (+control.get('quantity').value >= 1 && +control.get('total_cost').value > 0) {
      this.isvalue = true;
      if (total_cost) {
        const price_per_piece = (control.get('total_cost').value) / (+control.get('quantity').value);
        control.get('price_per_piece').setValue(Number(+price_per_piece).toFixed(5));
      }
      //control.get('total_cost').setValue(((Number(+control.get('quantity').value)) * (Number(+control.get('price_per_piece').value))).toFixed(2));
      control.get('price').setValue(Number((+control.get('total_cost').value)).toFixed(2));
    }
    if (+control.get('quantity').value >= 1 && +control.get('price_per_piece').value >= 1) {
      this.isvalue = true;
      if(!total_cost){
      control.get('total_cost').setValue(((Number(+control.get('quantity').value)) * (Number(+control.get('price_per_piece').value))).toFixed(5));
      }
      control.get('price').setValue(Number((+control.get('total_cost').value)).toFixed(2));
    }
    if (+control.get('quantity').value === 0) {
      control.get('total_cost').setValue('0.00');
      control.get('price').setValue(Number(+control.get('total_cost').value).toFixed(2));
      //  control.get('price_per_piece').setValue(''); 
    }
    if (+control.get('total_cost').value === 0) {
      this.isvalue = false;
      // control.get('price_per_piece').setValue('');
      control.get('price').setValue('0.00');
    }
    if (+control.get('shipping_cost').value > 0) {
      this.isvalue = true;
      if (+control.get('quantity').value === 0) {
        // control.get('price_per_piece').setValue('');
      } else {
        const price = (+control.get('price').value) + (+control.get('shipping_cost').value);
        control.get('price').setValue(Number(+price).toFixed(2));
        // const price_per_piece = control.get('price').value / +control.get('quantity').value;
        //control.get('price_per_piece').setValue(Number(+price_per_piece).toFixed(2));
        if (!control.get('price_per_piece').value) { //added new
          const price_per_piece = control.get('total_cost').value / +control.get('quantity').value;
          control.get('price_per_piece').setValue(Number(+price_per_piece).toFixed(5));
        }
      }
    }
  }

  onPriceChanges = (control: FormControl, changePerPiece = true) => {
    this.isvalue = false;
    if (Number(control.get('price').value) > 0) {
      this.isvalue = true;
      const newCost = +control.get('price').value - +control.get('shipping_cost').value;
      const newTotalCost = control.get('price_per_piece').value * control.get('quantity').value;
      if (newCost < 0) {
        control.get('total_cost').setValue(Number(+control.get('price').value).toFixed(2));
        control.get('shipping_cost').setValue('0.00');
      } else {
        control.get('total_cost').setValue(Number(+newTotalCost).toFixed(2));
      }
      
      if (changePerPiece) {
        this.calculatePricePerPiece(control);
      }
    } else {
      this.isvalue = false;
      control.get('total_cost').setValue('0.00');
    }
  }

  onPricePerPieceChange = (control: FormControl) => {
   this.isvalue = false;
    if (+control.get('quantity').value >= 1) {
      this.isvalue = true;
      const price = Number(control.get('price_per_piece').value) * Number(control.get('quantity').value) + Number(control.get('shipping_cost').value);
      control.get('price').setValue(Number(+price).toFixed(2));
      this.onPriceChanges(control, false);
    } else {
      this.isvalue = false;
      control.get('price').setValue('0.00');
      control.get('total_cost').setValue('0.00');
    }
  }
  calculatePrice = (control: FormControl) => {
    this.isvalue = true;
    let price = 0;
    if (+control.get('total_cost').value > 0) {
      price += +control.get('total_cost').value;
    }

    if (+control.get('shipping_cost').value > 0) {
      price += +control.get('shipping_cost').value;
    }
    control.get('price').setValue(Number(+price).toFixed(2));
  }

  calculatePricePerPiece = (control: FormControl) => {
    this.isvalue = false
    if (+control.get('quantity').value > 0 && +control.get('price').value > 0) {
      this.isvalue = true
      const price_per_piece = +control.get('price').value / +control.get('quantity').value;
      control.get('price_per_piece').setValue(Number(+price_per_piece).toFixed(5));
    }
  }

  onShippingIncluded = (control: FormControl) => {
    this.isvalue = true;
    if (control.get('shipping_included').value) {
      control.get('shipping_cost').setValue('0.00');

      //added to reset the price field when we select "yes" for the shopping include
      if (control.get('shipping_included').value === 1) {
        const total_price = +control.get('total_cost').value;
        if (total_price) {
          control.get('price').setValue(Number(+total_price).toFixed(2));
        } else {
          control.get('price').setValue('');
        }
      }
      else{
        const total_price = +control.get('total_cost').value;
        if (+control.get('shipping_cost').value === 0) {
          if (total_price) {
            control.get('price').setValue(Number(+total_price).toFixed(2));
          }
        }
      }
    }
  }

  isLastItem = (index) => {
    if (+index === (this.supplierBidForm.get('item')['controls'].length - 1)) {
      return true;
    }
    return false;
  }

  initItemForm = (data:any = {}) => {
    return this._fb.group({
      item_code: [data.item_code || ''],
      item_spec: [data.item_spec || ''],
      item_description: [data.item_description || ''],
      is_additional_charge: [data['is_additional_charge'] || 0], // Boolean either 0 or 1
      item_detail: this._fb.array([this.initItemDetailForm(data.item_details || {})])
    });
  }

  initItemDetailForm = (data:any = {}) => {
    return this._fb.group({
      standard_turnaround_time: [data.standard_turnaround_time || ''],
      shipping_included: [data.shipping_included || null],
      quantity: [data.quantity || ''],
      total_cost: [data.total_cost || ''],
      shipping_cost: [data.shipping_cost || ''],
      price: [data.price || ''],
      price_per_piece: [data.price_per_piece || '']
    });
  }

  addItem = () => {
    const control = <FormArray>this.supplierBidForm.get('item');
    control.push(this.initItemForm());
    this.setFields();
  }

  addAdditionalCharge = (index) => {
    let item = this.supplierBidForm.get('item')['controls'][index];
    if (item && item.controls.is_additional_charge.value == 1) {
      for(let i = index; i >= 0; i--) {
        item = this.supplierBidForm.get('item')['controls'][i];
        if (item && item.controls.is_additional_charge.value == 0) {
          break;
        }
      }
    }

    const control = <FormArray>this.supplierBidForm.get('item');
    let prefillData = {
      item_code: item.controls.item_code.value || '',
      item_spec: 'Setup charge' + ((item.controls.item_spec.value) ? (' for ' + item.controls.item_spec.value) : ''),
      item_description: 'Setup charge' + ((item.controls.item_code.value) ? (' for ' + item.controls.item_spec.value) : ''),
      is_additional_charge: 1,
      item_details: {
        standard_turnaround_time: 1,
        shipping_included: 1,
        quantity: 1,
        shipping_cost: '0.00',
      }
    };
    control.insert(index + 1, this.initItemForm(prefillData));
    this.setFields();
  }

  addItemDetails = (i) => {
    const control = <FormArray>this.supplierBidForm.get('item')['controls'][i].get('item_detail');
    control.push(this.initItemDetailForm());
  }

  removeItem(i) {
    const control = <FormArray>this.supplierBidForm.get('item');
    control.removeAt(i);

  }

  removeItemDetail(i, j) {
    const control = <FormArray>this.supplierBidForm.get('item')['controls'][i].get('item_detail');
    control.removeAt(j);

  }
  //added for print product specification display in capital
  getCapitalisedString = (str) => {
    const result = ((str.toString()).split('_')).join(' ');
    return result.charAt(0).toUpperCase() + result.slice(1);
  }

  //added for print product specification get additional quantity
  getAdditionalQuantities = (quantities) => {
    const qtys = Object.entries(quantities);
    const final = qtys.filter(row => {
      return row[1] !== null && row[1] !== '';
    });
    return final;
  }

  //added to display additional quantity
  getQtyHeading = (str) => {
    const name = str.substring(0, 3);
    return name.charAt(0).toUpperCase() + 'ty ' + str.charAt((str.length - 1));
  }

  getFormatedDate = (date) => {
    if (date) {
      const dateArr = date.split('-');
      return dateArr[1] + '-' + dateArr[0] + '-' + dateArr[2];
    } else {
      return date;
    }
  }

  getProjectDetails = (projectId, franchiseId) => {
    this.status = this.utils.showContainers(this.status, 'loading');
    this.projectService.getProDetailsForSupplier(projectId, franchiseId).subscribe(res => {
      this.loading = true
      this.projectData = res['data'];
      //new added
      //for IISA quote
      if(this.projectData['iisa_quote']){
        this.iisa_quote = this.projectData['iisa_quote']
       if(this.iisa_quote['additional_quantities']){
          this.iisa_additionalQuantities = this.getAdditionalQuantities(JSON.parse(this.iisa_quote['additional_quantities']));
          delete this.iisa_quote['additional_quantities'];
       }
        
        delete this.iisa_quote['deleted_at']
        delete this.iisa_quote['updated_at']
        delete this.iisa_quote['created_at']
        delete this.iisa_quote['id']
        delete this.iisa_quote['project_id']
        delete this.iisa_quote['shipping_preference']
        this.iisa_quote['quote_details'] = Object.entries(this.iisa_quote);
      }
      if (this.projectData['print_quote']) {
        this.printQuote = this.projectData['print_quote'];
        if (this.printQuote['quote_details']['additional_quntities']) {
          this.additionalQuantities = this.getAdditionalQuantities(this.printQuote['quote_details']['additional_quntities']);
          delete this.printQuote['quote_details']['additional_quntities'];
        }
        this.printQuote['quote_details'] = Object.entries(this.printQuote['quote_details']);
      }
      //ended
      if (this.projectData['project_spec']) {
        this.projectData['project_spec'] = JSON.parse(this.projectData['project_spec'])
      }
      if (this.projectData['rush_date']) {
        this.projectData['rush_date'] = this.getFormatedDate(this.projectData['rush_date']);
      }
      if (this.projectData['bids'].length) {
        for (let i = 0; i < this.projectData['bids'].length; i++) {
          // this.projectData['bids'][i]['project_supplier_id'] == this.supplierId && this.projectData['bids'][i]['status'] != 1
          if (this.projectData['project_suppliers_req_id'] == this.project_supplier_id && this.projectData['project_suppliers_req_status'] != 2) {
            this.edit = true;
            this.supplierBidEditId = this.projectData['bids'][i]['id'];
            this.supplierEditData = this.projectData['bids'][i];
            this.supplierBidForm.get('notes').setValue(this.supplierEditData['notes'])
            this.docArr = this.supplierEditData['bid_doc'] ? this.supplierEditData['bid_doc'] : [];
            this.oldDocArr = this.supplierEditData['bid_doc'] ? [...this.supplierEditData['bid_doc']] : [];

            this.docArr.forEach((value, keyDoc) => {
              this.filesArr[keyDoc] = [];
              // const ext = value.substring(value.lastIndexOf('.') + 1).toLowerCase();
              // const pathValue = this.commonService.getLogoUrl(ext);
              const pathValue = this.commonService.checkImageTypeAws(value, 'bid_images', false, false, this.frenchiseId);
              if (!pathValue) {
                this.filesArr[keyDoc]['url'] = pathValue + value;
              } else {
                this.filesArr[keyDoc]['url'] = pathValue;
              }
            });
          }
          else if(this.projectData['project_suppliers_req_id'] == this.project_supplier_id && this.projectData['project_suppliers_req_status'] == 2){
            this.checkExist = true;
          }
        }
      }
      const logo = this.commonService.UPLOAD_FILE_AWS('franchise_images', false, franchiseId);
      this.franchiseLogo = logo + this.projectData['franchise_logo'];
      this.status = this.utils.showContainers(this.status, 'success');
      this.loading = false
    }, (error) => {
      this.status = this.utils.showContainers(this.status, 'success');
    })
  }

  uploadDoc = (event: any) => {
    const files = event.target.files;
    let i = this.filesArr.length;
    for (const file of files) {
      const ext = file['name'].substring(file['name'].lastIndexOf('.') + 1).toLowerCase();
      let flag = true;
      this.docArr.forEach((value) => {
        if (value === file.name) {
          flag = false;
        }
      });
      if (flag) {
        this.docArr.push(file.name);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event1: Event) => {
          this.filesArr[i] = {};
          this.filesArr[i]['file'] = file;
          const pathValue = this.commonService.getLogoUrl(ext);
          if (!pathValue) {
            this.filesArr[i]['url'] = event1.target['result'];
          } else {
            this.filesArr[i]['url'] = pathValue;
          }
          i++;
        };
      }
    }
  }
  
  removeDoc = (doc, i) => {
    this.filesArr.splice(i, 1);
    this.docArr.forEach((value, key) => {
      if (key === +i) {
        this.docArr.splice(key, 1);
      }
    });
    this.oldDocArr.forEach((value, key) => {

      if (key === +i) {
        this.oldDocArr.splice(key, 1);
      }
    });
  }

  saveSupplierBid() {
    const postData = this.supplierBidForm.value;
    postData['bid_doc'] = this.oldDocArr;
    postData['project_id'] = this.projectData['id'];
    postData['project_supplier_id'] = this.supplierId;
    postData['sales_rep_id'] = this.projectData['sales_rep']['id'];
    postData['edit'] = this.edit;
    postData['requirement_id'] = this.projectData['requirement_id']
    postData['project_suppliers_req_id'] = this.project_supplier_id
    if(!this.isvalue){
      postData['item'] = []
    }
    if (this.edit) {
      postData['editbid'] = this.supplierBidEditId;
    }
    const fData: FormData = new FormData;
    if (this.filesArr.length > 0) {
      for (const file of this.filesArr) {
        if (file['file']) {
          fData.append('files[]', file['file']);
        }
      }
    }
    else if (this.filesArr.length == 0) {
      fData.append('files[]', '');
    }
    fData.append('postData', JSON.stringify(postData));
    // this.loading = true;
    if(!this.filesArr.length){
      Swal.fire({
        title: 'Are you sure?',
        text: 'We suggest you to upload a related template for the product for reference. (if Any)',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        showLoaderOnConfirm: true,
        preConfirm: (value) => {
          this.status = this.utils.showContainers(this.status, 'loading');
          this.supplierService.saveSupplierPublicBid(fData, this.frenchiseId).subscribe(res => {
            this.loading = true
            if (res['data']) {
              this.status = this.utils.showContainers(this.status, 'success');
              this.loading = false;
              this.ngOnInit();
            }
          }, error => {
            this.status = this.utils.showContainers(this.status, 'success');
            this.loading = false;
            if (error['error']) {
              this.api_error = error['error']['data'];
            }
          });
        },
      }).then((res) => {
      });
    }else{
      this.status = this.utils.showContainers(this.status, 'loading');
      this.supplierService.saveSupplierPublicBid(fData, this.frenchiseId).subscribe(res => {
        this.loading = true
        if (res['data']) {
          this.status = this.utils.showContainers(this.status, 'success');
          this.loading = false;
          this.ngOnInit();
        }
      }, error => {
        this.status = this.utils.showContainers(this.status, 'success');
        this.loading = false;
        if (error['error']) {
          this.api_error = error['error']['data'];
        }
      });
    }
  }

  setFields = () => {
    $(document).ready(() => {
      var costPerPiece = setInterval(() => {
        let totalCostPerPiece = $("[customFormatAttribute=true]").length;
        let counter = 0; 
          $("[customFormatAttribute=true]").each((index, value)=> {
          value.addEventListener('focusout', () => {
            this.formatFields('price_per_piece');
            if(value.getAttribute('formcontrolname') == 'total_cost') {
              this.formatFields('cost_per_piece');
            }
          });
          counter++;
          if(totalCostPerPiece == counter) {
            clearInterval(costPerPiece);
          }
        })
      }, 10);
    });
  }

  formatFields = (field) => {
    if(field == 'price_per_piece') {
      var PricePerPiece = setInterval(() => {
        let totalPricePerPiece = $("[formControlName=price_per_piece]").length;
        let counter = 0; 
        $("[formControlName=price_per_piece]").each((index, value)=> {
          value.focus();
          value.blur();
          counter++;
          if(totalPricePerPiece == counter) {
            clearInterval(PricePerPiece);
          }
        })
      }, 10);
    }

    if(field == 'cost_per_piece') {
      var CostPerPiece = setInterval(() => {
        let totalCostPerPiece = $("[formControlName=cost_per_piece]").length;
        let counter = 0; 
        $("[formControlName=cost_per_piece]").each((index, value)=> {
          value.focus();
          value.blur();
          counter++;
          if(totalCostPerPiece == counter) {
            clearInterval(CostPerPiece);
          }
        })
      }, 10);
    }
  }
}
