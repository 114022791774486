import { Component, OnInit, ViewChild } from "@angular/core";
import { Utils } from "../../../../utils";
import { CommonService } from "../../../../service/common.service";
import { ActivatedRoute } from "@angular/router";
import { ProjectService } from "../../../../service/project.service";
import Swal from "sweetalert2";
import { ToasterService } from "angular2-toaster";
import { ModalDirective } from "ngx-bootstrap";
import { environment } from "../../../../../environments/environment";
declare const $: any;

@Component({
  templateUrl: "public-estimation.component.html",
  styleUrls: ["./public-estimation.component.scss"],
})
export class PublicEstimationComponent implements OnInit {
  products = [];
  status: any = {
    loading: false,
    success: false,
    error: false,
  };
  @ViewChild("previewModal") previewModal: ModalDirective;
  // for view and edit quote
  estimationData = {};
  franchiseLogo = "";
  loading = false;
  item: any = "item0";
  api_error = {};
  total: any = 0;
  frenchiseId: any = undefined;
  approveRejectReason: any = "";
  showErrorMsg: any = false;
  showItemErrorMsg: any = false;
  approvedOrRejectedEstimation: any = {};
  flag: boolean = true;
  estId: any = undefined;
  approvalCheck: any = 1;
  approvalRejectComment: any = undefined;
  approvalText: any = undefined;
  newDisplaySelectedTotal: any = 0;
  project_type = "";
  checkedElements: any = [];
  message = "";
  Path: any = "";
  imagePreviewSource: any = "";
  showItemSelectMsg: boolean = false;
  linkValidationStatus: any = "";
  // end
  constructor(
    private utils: Utils,
    public commonService: CommonService,
    private _activatedRoute: ActivatedRoute,
    private projectService: ProjectService,
    private toaster: ToasterService
  ) {}

  ngOnInit() {
    this._activatedRoute.params.subscribe((params) => {
      if (params["franchise_id"] && params["estimation_id"]) {
        try { this.frenchiseId = atob(params["franchise_id"]); }
        catch(e) { this.frenchiseId = atob(decodeURIComponent(params["franchise_id"])); }

        this.Path = environment.AWS_FILE_PATH + this.frenchiseId + "/apparel_with_logo/";
        
        let estimateIdWithToken = params["estimation_id"];
        estimateIdWithToken = estimateIdWithToken.split("-4468616E616E6A61792043686F6B7369-");

        try { this.estId = atob(estimateIdWithToken[0]); }
        catch(e) { this.estId = atob(decodeURIComponent(estimateIdWithToken[0])); }

        let token = estimateIdWithToken[1];

        this.projectService.validatePublicEstimationLinkByToken(this.frenchiseId, token).subscribe((res) => {
          if (res["data"]) {
            this.linkValidationStatus = res["data"]["validation_status"];
            this.getEstimation(this.estId, this.frenchiseId);
          }
        }, (error) => {
          console.log("error : ", error);
        });
      }
    });
  }

  openImagePreview = (imageSource) => {
    this.imagePreviewSource = imageSource;
    this.previewModal.show();
  };

  checkIsChecked = (event) => {
    if (event.target.checked) {
      this.checkedElements.push(event.target.id);
    } else {
      let test = this.checkedElements.indexOf(event.target.id);
      if (test > -1) {
        this.checkedElements.splice(test, 1);
      }
    }
  };

  returnStringWithBold = (description) => {
    if (+this.project_type == 8) {
      let string = description;
      let final = [];
      var c = string.split(", ");
      c.map((item) => {
        var char = item.split(":");
        if (char[0] && char[1]) {
          final.push(char[0].bold() +' : '+ char[1] +'<br>')
        }
      });
      return final.join(" ");
    } else {
      return description;
    }
  };

  getEstimation = (estimationId, franchiseId) => {
    this.newDisplaySelectedTotal = 0;
    this.status = this.utils.showContainers(this.status, "loading");
    this.projectService.getEstimatePostData(estimationId, franchiseId).subscribe((res) => {
          this.loading = true;
          this.estimationData = res["data"];
          this.project_type = res["data"]["project_of_estimation"] ? res["data"]["project_of_estimation"]["project_type"] : "";
          for (let i = 0; i < this.estimationData["estimation_specs"].length; i++) {
            var success = 0;
            for (let k = 0; k < this.estimationData["estimation_specs"][i]["estimation_spec_items"].length; k++) {
              if (success == 0) {
                // this.total += this.estimationData['estimation_specs'][i]['estimation_spec_items'][k]['total_price']
              }
              if ((this.estimationData["estimation_specs"][i]["estimation_spec_items"][k]["is_item_approved"] ||
                  this.estimationData["estimation_specs"][i]["estimation_spec_items"][k]["is_item_rejected"]) &&
                  this.approvalCheck) {
                this.approvalCheck = 0;
                this.approvalRejectComment = this.estimationData["estimation_specs"][i]["estimation_spec_items"][k]["approved_reject_comment"];
                if (this.estimationData["estimation_specs"][i]["estimation_spec_items"][k]["is_item_approved"]) {
                  this.approvalText = "You have approved this estimation.";
                  if (this.estimationData["project_of_estimation"]["status"] == 7) {
                    this.approvalText = "Your estimate will update soon.";
                  }
                } else if (this.estimationData["estimation_specs"][i]["estimation_spec_items"][k]["is_item_rejected"]) {
                  this.approvalText = "You have requested for change in this estimation.";
                }
              }
              success++;
            }
          }

          if (!this.approvalCheck) {
            for (let i = 0; i < this.estimationData["estimation_specs"].length; i++) {
              for (let k = 0;k < this.estimationData["estimation_specs"][i]["estimation_spec_items"].length; k++) {
                if (this.estimationData["estimation_specs"][i]["estimation_spec_items"][k]["is_item_approved"]) {
                  this.newDisplaySelectedTotal += this.estimationData["estimation_specs"][i]["estimation_spec_items"][k]["total_price"];
                } else if (this.estimationData["estimation_specs"][i]["estimation_spec_items"][k]["is_item_rejected"]) {
                  this.newDisplaySelectedTotal += this.estimationData["estimation_specs"][i]["estimation_spec_items"][k]["total_price"];
                }
              }
            }
          }

          const logo = this.commonService.UPLOAD_FILE_AWS("franchise_images", false, franchiseId);
          this.franchiseLogo = logo + this.estimationData["franchise_logo"];
          this.status = this.utils.showContainers(this.status, "success");
          this.loading = false;
        },
        (error) => {
          this.status = this.utils.showContainers(this.status, "success");
        }
      );
  };

  onRadioChange(i) {
    this.item = i;
  }

  validateAndDownload = () => {
    this.estimationData["franchise_logo"] = this.franchiseLogo;
    this.estimationData["franchise_id"] = this.frenchiseId;
    this.loading = true;
    this.projectService.downloadAllPdfNew(this.estimationData).subscribe((res: any) => {
      this.api_error = {};
      this.loading = false;
      const blobData = new Blob([res], { type: "application/octet-stream" });
      const a = document.createElement("a");
      a.href = URL.createObjectURL(blobData);
      a.download = this.estimationData["estimation_uid"] + "_Estimate.pdf";
      document.body.appendChild(a);
      a.click();
    },
    (error) => {
      this.loading = false;
      if (error["error"]["data"]) {
        this.api_error = error["error"]["data"];
      }
    });
  };

  clearRadio = () => {
    $(".form-check-input").prop("checked", false);
    this.approvedOrRejectedEstimation = {};
  };

  changeOption(e, index) {
    let item = this.estimationData["estimation_specs"][index];
    let itemCount = this.estimationData["estimation_specs"].length;
    this.total = 0;

    for (let i = 0; i < itemCount; i++) {
      let val = 0;
      if (index == i) {
        val = e.target.value;
        this.approvedOrRejectedEstimation["item_" + i] = val;
      } else if ($('input[name="item' + i + '"]:checked').length > 0 && index != i) {
        val = $('input[name="item' + i + '"]:checked').val();
        this.approvedOrRejectedEstimation["item_" + i] = val;
      }

      if (this.estimationData["estimation_specs"][i].is_additional_charge == 1 && item.id == this.estimationData["estimation_specs"][i]["parent_item_spec_id"]) {
        val = this.estimationData["estimation_specs"][i]["estimation_spec_items"][0]["total_price"] || 0;
        this.approvedOrRejectedEstimation["item_" + i] = val;
        let element: any = document.querySelector("#inlineCheckbox11" + i + "0");
        element.checked = true;
      }

      this.total = this.total + +val;
    }
    if (Object.keys(this.approvedOrRejectedEstimation).length > 0) {
      this.flag = false;
    }
  }

  openComment(btnTitle) {
    if (this.flag) {
      this.showItemSelectMsg = true;
    } else {
      this.showItemSelectMsg = false;
    }
    if (!this.flag) {
      if (btnTitle == "Approve") {
        if (this.checkedElements.length == 2) {
          Swal.fire({
            title: "Thank you for your order! \n You will receive an order receipt shortly",
            html: "<textarea id='text' placeholder='' class='form-control' [(ngModel)]='approveRejectReason'></textarea>",
            showCancelButton: true,
            confirmButtonText: btnTitle,
            showLoaderOnConfirm: true,
            inputPlaceholder: "Write something",
            inputValidator: (value) => {
              return new Promise((resolve) => {
                if (value !== "") {
                  resolve('');
                } else {
                  resolve("Please enter Comment");
                }
              });
            },
            preConfirm: (value1) => {
              var val = document.getElementById("text")["value"];
              if (val != "") {
                var btnText;
                if (btnTitle == "Approve") {
                  btnText = "approve";
                } else if (btnTitle == "Request For Change") {
                  btnText = "reject";
                }
                if (!Object.keys(this.approvedOrRejectedEstimation).length) {
                  this.showItemErrorMsg = true;
                } else {
                  this.showItemErrorMsg = false;
                }

                if (val == "") {
                  this.showErrorMsg = true;
                } else {
                  this.showErrorMsg = false;
                }

                var postData = {};
                postData["id"] = this.estId;
                postData["items"] = [];
                for (let i = 0; i < this.estimationData["estimation_specs"].length; i++) {
                  postData["items"][i] = {};
                  for (let k = 0; k < this.estimationData["estimation_specs"][i]["estimation_spec_items"].length; k++) {
                    let approveRejectVal = this.approvedOrRejectedEstimation["item_" + i];
                    if (this.estimationData["estimation_specs"][i]["estimation_spec_items"][k]["total_price"] == approveRejectVal) {
                      postData["items"][i]["id"] = this.estimationData["estimation_specs"][i]["estimation_spec_items"][k]["id"];
                      postData["items"][i]["project_estimation_spec_id"] = this.estimationData["estimation_specs"][i]["estimation_spec_items"][k]["project_estimation_spec_id"];
                      postData["items"][i]["total_price"] = this.estimationData["estimation_specs"][i]["estimation_spec_items"][k]["total_price"];
                    }
                  }
                }
                postData["btnText"] = btnText;
                postData["approveRejectReason"] = val;

                if (!this.showItemErrorMsg && !this.showErrorMsg) {
                  return this.projectService.approveOrRejectCustomerEstimation(this.frenchiseId, postData)
                  .toPromise()
                  .then((res) => {
                    if (res["data"]) {
                      return true;
                    }
                  })
                  .catch(() => {
                    return true;
                  });
                }
              } else {
                Swal.showValidationMessage("Please enter Comment");
              }
            },
          }).then((res) => {
            if (res["value"]) {
              Swal.fire({
                title: "Acknowledgement",
                type: "warning",
                text: "Your acceptance has been received. We will now move this to production. You will receive an email from our accounting department soon. Thank you, again. We look forward to working with you!",
                confirmButtonText: "Ok",
                showLoaderOnConfirm: true,
                preConfirm: (value1) => {
                  this.ngOnInit();
                  return true;
                },
              });
            }
          });
        } else {
          this.message = "Please confirm Shipping address and In-Hands date!";
        }
      } else {
        Swal.fire({
          title: "Thank you for your request to change, we will update accordingly",
          html: "<textarea id='text' placeholder='' class='form-control' [(ngModel)]='approveRejectReason'></textarea>",
          // --------------^-- define html element with id
          // html: "true",
          showCancelButton: true,
          confirmButtonText: btnTitle,
          showLoaderOnConfirm: true,
          inputPlaceholder: "Write something",
          inputValidator: (value) => {
            return new Promise((resolve) => {
              if (value !== "") {
                resolve('');
              } else {
                resolve("Please enter Comment");
              }
            });
          },
          preConfirm: (value1) => {
            var val = document.getElementById("text")["value"];
            if (val != "") {
              var btnText;
              if (btnTitle == "Approve") {
                btnText = "approve";
              } else if (btnTitle == "Request For Change") {
                btnText = "reject";
              }
              if (!Object.keys(this.approvedOrRejectedEstimation).length) {
                this.showItemErrorMsg = true;
              } else {
                this.showItemErrorMsg = false;
              }

              if (val == "") {
                this.showErrorMsg = true;
              } else {
                this.showErrorMsg = false;
              }

              var postData = {};
              postData["id"] = this.estId;
              postData["items"] = [];
              for (
                let i = 0;
                i < this.estimationData["estimation_specs"].length;
                i++
              ) {
                postData["items"][i] = {};
                for (let k = 0; k < this.estimationData["estimation_specs"][i]["estimation_spec_items"].length; k++) {
                  let approveRejectVal = this.approvedOrRejectedEstimation["item_" + i];
                  if (this.estimationData["estimation_specs"][i]["estimation_spec_items"][k]["total_price"] == approveRejectVal) {
                    postData["items"][i]["id"] = this.estimationData["estimation_specs"][i]["estimation_spec_items"][k]["id"];
                    postData["items"][i]["project_estimation_spec_id"] = this.estimationData["estimation_specs"][i]["estimation_spec_items"][k]["project_estimation_spec_id"];
                    postData["items"][i]["total_price"] = this.estimationData["estimation_specs"][i]["estimation_spec_items"][k]["total_price"];
                  }
                }
              }
              postData["btnText"] = btnText;
              postData["approveRejectReason"] = val;

              if (!this.showItemErrorMsg && !this.showErrorMsg) {
                return this.projectService
                .approveOrRejectCustomerEstimation(this.frenchiseId, postData)
                .toPromise()
                .then((res) => {
                  if (res["data"]) {
                    return true;
                  }
                })
                .catch(() => {
                  return true;
                });
              }
            } else {
              Swal.showValidationMessage("Please enter Comment");
            }
          },
        }).then((res) => {
          if (res["value"]) {
            Swal.fire({
              title: "Acknowledgement",
              type: "warning",
              text: "Your change request has been received. We will adjust the estimate accordingly.Thank you again. We look forward to working with you!",
              confirmButtonText: "Ok",
              showLoaderOnConfirm: true,
              preConfirm: (value1) => {
                this.ngOnInit();
                return true;
              },
            });
          }
        });
      }
    }
  }

  saveComment(approveRejectReason, btnText) {
    if (!Object.keys(this.approvedOrRejectedEstimation).length) {
      this.showItemErrorMsg = true;
    } else {
      this.showItemErrorMsg = false;
    }

    if (approveRejectReason == "") {
      this.showErrorMsg = true;
    } else {
      this.showErrorMsg = false;
    }

    var postData = {};
    postData["id"] = this.estId;
    postData["items"] = [];
    for (let i = 0; i < this.estimationData["estimation_specs"].length; i++) {
      postData["items"][i] = {};
      for (let k = 0; k < this.estimationData["estimation_specs"][i]["estimation_spec_items"].length; k++) {
        let approveRejectVal = this.approvedOrRejectedEstimation["item_" + i];
        if (this.estimationData["estimation_specs"][i]["estimation_spec_items"][k]["total_price"] == approveRejectVal) {
          postData["items"][i]["id"] = this.estimationData["estimation_specs"][i]["estimation_spec_items"][k]["id"];
          postData["items"][i]["project_estimation_spec_id"] = this.estimationData["estimation_specs"][i]["estimation_spec_items"][k]["project_estimation_spec_id"];
          postData["items"][i]["total_price"] = this.estimationData["estimation_specs"][i]["estimation_spec_items"][k]["total_price"];
        }
      }
    }
    postData["btnText"] = btnText;
    postData["approveRejectReason"] = approveRejectReason;

    if (!this.showItemErrorMsg && !this.showErrorMsg) {
      this.projectService
      .approveOrRejectCustomerEstimation(this.frenchiseId, postData)
      .subscribe((data) => {
        if (data["message"]) {
          this.toaster.pop("success", data["message"], "");
        }
      },
      (error) => {
        this.loading = false;
        if (error["error"]["data"]) {
          this.api_error = error["error"]["data"];
        }
      });
    }
  }
}