import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class StoragemanagerService {

  static storeToken = data => {
    localStorage.setItem('token', JSON.stringify(data));
  }

  static storeFranchiseid = id => {
    localStorage.setItem('franchiseid', JSON.stringify(id));
  }

  static getFranchiseid = () => {
    return JSON.parse(localStorage.getItem('franchiseid'));
  }

  static getToken = () => {
	  if(localStorage.getItem('token') == "undefined"){
		StoragemanagerService.clearStorage();
	  }else{
		return JSON.parse(localStorage.getItem('token'));
	  }
  }

  static setStorage = (name, value, is_array = false) => {
    if (is_array) {
      localStorage.setItem(name, JSON.stringify(value));
    } else {
      localStorage.setItem(name, value);
    }
  }

  static clearStorage = () => {
    localStorage.clear();
  }
}
