import { Component, OnInit } from "@angular/core";
import { Utils } from "../../../../utils";
import { CommonService } from "../../../../service/common.service";
import { ActivatedRoute } from "@angular/router";
import { DigitalService } from "../../../../service/digital.service";
import { FormBuilder, FormGroup } from "@angular/forms";

@Component({
  templateUrl: "digital-proposal-public.component.html",
  styleUrls: ["./digital-proposal-public.component.scss"],
})
export class DigitalProposalPublicComponent implements OnInit {
  products = [];
  status: any = {
    loading: false,
    success: false,
    error: false,
  };

  // for view and edit quote
  projectData = {};
  franchiseLogo = "";
  loading = false;
  item: any = "item0";
  api_error = {};
  total: any = 0;
  frenchiseId: any = undefined;
  printQuote = {};
  iisa_quote = {};
  additionalQuantities = [];
  iisa_additionalQuantities = [];

  projectId: any = undefined;
  supplierBidForm: FormGroup;
  filesArr: any = [];
  docArr: any = [];
  oldDocArr: any = [];
  checkExist: boolean = false;
  supplierId: any = undefined;
  supplierEditData: any = undefined;
  edit: boolean = false;
  supplierBidEditId: any = undefined;
  project_supplier_id: any = undefined;
  isvalue: boolean = false;
  id: any = "";
  category: any = [];
  category_name: any = [];

  // end
  constructor(
    private utils: Utils,
    public commonService: CommonService,
    private _activatedRoute: ActivatedRoute,
    private _fb: FormBuilder,
    private digitalService: DigitalService
  ) {}

  ngOnInit() {
    this.digitalService.getDigitalServices().subscribe((res) => {
      if (res["data"]) {
        this.category = res["data"]["original"]["data"];
        if (this.category) {
          this._activatedRoute.params.subscribe((params) => {
            if ( params["frenchiseId"] && params["salesrep"] && params["proposalid"] ) {
              try { this.frenchiseId = atob(params["frenchiseId"]); }
              catch (e) { this.frenchiseId = atob( decodeURIComponent(params["frenchiseId"]) ); }

              try { this.projectId = atob(params["salesrep"]); }
              catch (e) { this.projectId = atob(decodeURIComponent(params["salesrep"])); }

              try { this.id = atob(params["proposalid"]); }
              catch (e) { this.id = atob(decodeURIComponent(params["proposalid"])); }

              this.filesArr = [];
              this.docArr = [];
              this.oldDocArr = [];
              this.supplierBidForm = this._fb.group({
                document: [""],
                sales_rep_id: [""],
                id: [""],
              });
              this.getProjectDetails(this.id, this.frenchiseId);
            }
          });
        }
      }
    });
  }

  //added for print product specification display in capital
  getCapitalisedString = (str) => {
    const result = ((str.toString()).split("_")).join(" ");
    return result.charAt(0).toUpperCase() + result.slice(1);
  };

  //added for print product specification get additional quantity
  getAdditionalQuantities = (quantities) => {
    const qtys = Object.entries(quantities);
    const final = qtys.filter((row) => {
      return row[1] !== null && row[1] !== "";
    });
    return final;
  };

  //added to display additional quantity
  getQtyHeading = (str) => {
    const name = str.substring(0, 3);
    return name.charAt(0).toUpperCase() + "ty " + str.charAt(str.length - 1);
  };

  getFormatedDate = (date) => {
    if (date) {
      const dateArr = date.split("-");
      return dateArr[1] + "-" + dateArr[0] + "-" + dateArr[2];
    } else {
      return date;
    }
  };

  getProjectDetails = (projectId, franchiseId) => {
    this.status = this.utils.showContainers(this.status, "loading");
    this.digitalService.getRequestSiteUrl(projectId, franchiseId).subscribe(
      (res) => {
        this.loading = true;
        this.projectData = res["data"]["data"];
        let category = this.projectData["category"].split(",");
        if (category.length > 0) {
          category.map((cat) => {
            this.category.map((ctg) => {
              if (cat == ctg["id"]) {
                this.category_name.push(ctg["name"]);
              }
            });
          });
        }
        this.projectData["frenchise_data"] = res["data"]["frenchise_data"];
        this.projectData["franchise_logo"] = res["data"]["franchise_logo"];
        //ended
        if (this.projectData) {
          if (this.projectData["status"] != 1 && this.projectData["status"] != 4) {
            this.checkExist = true;
          }
        }
        const logo = this.commonService.UPLOAD_FILE_AWS("franchise_images", false, franchiseId);
        this.franchiseLogo = logo + this.projectData["franchise_logo"];
        this.status = this.utils.showContainers(this.status, "success");
        this.loading = false;
      },
      (error) => {
        this.status = this.utils.showContainers(this.status, "success");
      }
    );
  };

  uploadDoc = (event: any) => {
    const files = event.target.files;
    let i = this.filesArr.length;
    for (const file of files) {
      const ext = file["name"].substring(file["name"].lastIndexOf(".") + 1).toLowerCase();
      let flag = true;
      this.docArr.forEach((value) => {
        if (value === file.name) {
          flag = false;
        }
      });
      if (flag) {
        this.docArr.push(file.name);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event1: Event) => {
          this.filesArr[i] = {};
          this.filesArr[i]["file"] = file;
          const pathValue = this.commonService.getLogoUrl(ext);
          if (!pathValue) {
            this.filesArr[i]["url"] = event1.target["result"];
          } else {
            this.filesArr[i]["url"] = pathValue;
          }
          i++;
        };
      }
    }
  };

  removeDoc = (doc, i) => {
    this.filesArr.splice(i, 1);
    this.docArr.forEach((value, key) => {
      if (key === +i) {
        this.docArr.splice(key, 1);
      }
    });
    this.oldDocArr.forEach((value, key) => {
      if (key === +i) {
        this.oldDocArr.splice(key, 1);
      }
    });
  };

  saveSupplierBid() {
    this.status = this.utils.showContainers(this.status, "loading");
    const postData = this.supplierBidForm.value;
    postData["document"] = this.oldDocArr;
    postData["id"] = this.projectData["id"];
    postData["edit"] = this.edit;
    if (this.edit) {
      postData["editbid"] = this.supplierBidEditId;
    }
    postData["old_document"] = this.oldDocArr != null ? this.oldDocArr : [];
    const fData: FormData = new FormData();
    if (this.filesArr.length > 0) {
      for (const file of this.filesArr) {
        if (file["file"]) {
          fData.append("document[]", file["file"]);
        }
      }
    } else if (this.filesArr.length == 0) {
      fData.append("document[]", "");
    }
    fData.append("id", postData["id"]);
    fData.append("old_document", postData["old_document"]);
    this.digitalService
    .publicDigitalProposal(fData, this.frenchiseId)
    .subscribe((res) => {
      this.loading = true;
      if (res["data"]) {
        this.api_error = [];
        this.status = this.utils.showContainers(this.status, "success");
        this.loading = false;
        this.ngOnInit();
      }
    },
    (error) => {
      this.status = this.utils.showContainers(this.status, "success");
      this.loading = false;
      if (error["error"]) {
        this.api_error = error["error"]["data"];
      }
    });
  }
}