import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { hmrBootstrap } from './hmr';
import { BootController } from './boot-controller';

if (environment.production) {
  enableProdMode();
}

const init = () => {
  const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

  if (environment.hmr) {
    if (module['hot']) {
      hmrBootstrap(module, bootstrap);
    } else {
      console.error('HMR is not enabled for webpack-dev-server!');
    }
  } else {
    bootstrap().catch(err => console.log(err));
  }
}

// Init app on first load
init();

// restart app on logout
const boot = BootController.getbootControl().watchReboot().subscribe(() => init());
// platformBrowserDynamic().bootstrapModule(AppModule)
//   .catch(err => console.log(err));
