import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';
import { CommonService } from './common.service';


@Injectable({ providedIn: 'root' })
export class ExportService {

  constructor(private _http: HttpClient, private _cs: CommonService) {

  }

  getContactCsv = (data) => {
      return this._http.post(environment.API_URL + 'export/contacts', data, this._cs.setexportHeader());
  }
  getSalesRepPaymentCsv = (id) => {
    return this._http.get(environment.API_URL + 'export/sales-rep-payments/' + id, this._cs.setexportHeader());
  }

  getSupplierCsv = () => {
    return this._http.get(environment.API_URL + 'export/suppliers', this._cs.setexportHeader());
  }

  getSageProductsCSV = (ids) => {
    return this._http.post(environment.API_URL + 'export/sage-products', ids, this._cs.setexportHeader());
  }

  getSuppliersCSV = (data = {}) => {
    return this._http.post(environment.API_URL + 'export/suppliers', data, this._cs.setexportHeader());
  }

}