import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from "@angular/core";
import {FormArray, FormGroup} from "@angular/forms";
import {ModalDirective} from "ngx-bootstrap";
import {ProjectService} from "../../../service/project.service";
import {UserService} from "../../../service/user.service";
import Swal from 'sweetalert2';
import {CommonService} from "../../../service/common.service";

@Component({
  selector: 'app-contact-transfer',
  templateUrl: './contact-transfer.component.html',
  styleUrls: ['./contact-transfer.component.scss']
})
export class ContactTransferComponent implements OnInit, OnChanges {
  @Input() deletedUser: any = null; // {user_id: sales_rep_or_csr_id, role: Sales Rep | Estimator HOD, contact_id: null}
  @Output() closeContactTransfer = new EventEmitter<boolean>();

  @ViewChild('contactsAssignModel') public contactsAssignModel: ModalDirective;
  loading = false;

  // Delete Distributor with transferring it's all stuff to another distributor
  projectsTransferForm: FormGroup;
  projectsArray: FormArray;
  showProjectsTransferFormError: boolean = false;

  commissionTypes: any = [];
  salesRepList: any = [];
  csrList: any = [];
  contacts: any = [];
  assignContactsErrorMessage: any = null;
  current_user_id: any = null;

  // To set new distributor details for all clients
  allClientsToOne = false;
  distributorId: any = null;
  distributorCommissionType = 2;
  distributorCommissionPercent: any = null;
  distributorSystemCost: any = null;

  secondDistributorId: any = null;
  secondDistributorCommissionType = 2;
  secondDistributorCommissionPercent: any = null;
  secondDistributorSystemCost: any = null;

  accountManagerId: any = null;
  accountManagerCommissionType = 2;
  accountManagerCommissionPercent: any = null;
  accountManagerSystemCost: any = null;

  sales_rep_id_for_other_requests: any = null;

  showDistributor = false;
  showSecondaryDistributor = false;
  showAccountManager = false;

  constructor(
    private userService: UserService,
    private projectService: ProjectService,
    public commonService: CommonService
  ) {}
  
  ngOnInit(): void {
    // OnInit
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (propName == 'deletedUser') {
        const deletedUser = changes[propName];
        if (deletedUser.currentValue && deletedUser.currentValue.user_id && deletedUser.currentValue.role) {
          this.showDistributor = false;
          this.showSecondaryDistributor = false;
          this.showAccountManager = false;

          if (deletedUser.currentValue.contact_id) {
            if (deletedUser.currentValue.role == 'Sales Rep') {
              this.showDistributor = true;
            } else if (deletedUser.currentValue.role == 'Estimator HOD') {
              this.showAccountManager = true;
            }
          } else {
            this.showDistributor = true;
            this.showSecondaryDistributor = true;
            this.showAccountManager = true;
          }
          this.openContactsAssignModel();
        }
      }
    }
  }

  /** Code to assign distributor's clients/contacts and their projects to another distributor */
  openContactsAssignModel = () => {
    this.current_user_id = this.deletedUser.user_id;

    this.allClientsToOne = false;
    this.distributorId = null;
    this.distributorCommissionType = 2;
    this.distributorCommissionPercent = null;
    this.distributorSystemCost = null;

    this.secondDistributorId = null;
    this.secondDistributorCommissionType = 2;
    this.secondDistributorCommissionPercent = null;
    this.secondDistributorSystemCost = null;

    this.accountManagerId = null;
    this.accountManagerCommissionType = 2;
    this.accountManagerCommissionPercent = null;
    this.accountManagerSystemCost = null;

    this.assignContactsErrorMessage = null;
    this.sales_rep_id_for_other_requests = null;
    this.contactsAssignModel.show();
    this.getCommissionTypes();
    this.getDistributors();
    this.getCSRList();
    this.getContacts();
  }

  getCommissionTypes() {
    this.userService.getCommissionType().subscribe(res => {
      this.commissionTypes = res['data'];
    });
  }

  getDistributors = () => {
    this.userService.getFranchiseUsersByRole('Sales Rep').subscribe((res) => {
      this.salesRepList = (res['data'] || []).filter((item) => {
        return item.id != this.current_user_id;
      });
    });
  }

  getCSRList = () => {
    this.userService.getFranchiseUsersByRole('Estimator HOD').subscribe((res) => {
      this.csrList = (res['data'] || []).filter((item) => {
        return item.id != this.current_user_id;
      });
    });
  }

  getContacts = () => {
    this.loading = true;
    this.projectService.getProjectsByUser(this.deletedUser.user_id, this.deletedUser.contact_id).subscribe((res) => {
      this.contacts = (res['data'] || []).map((item) => {
        item.distributor_commission_type = null;
        item.second_distributor_commission_type = null;
        item.account_manager_commission_type = null;

        // To filter projects from client_projects
        item.project_list = (item.client_projects || []).filter((project) => !project.order_of_project);

        // To filter ordres from client_projects
        item.order_list = (item.client_projects || []).filter((project) => {
          return project.order_of_project && project.order_of_project.status != 3;
        });

        // Set title/tooltip
        let title = "Distributor: " + item.sales_rep.full_name;
        if (item.second_sales_rep) {
          title += "\nSecondary Distributor: " + item.second_sales_rep.full_name;
        }
        title += "\nAccount Manager: " + item.csr.full_name;
        item.title = title;

        return item;
      });
      this.loading = false;

      setTimeout(() => {
        this.expandAllClients();
      });
    });
  }

  expandAllClients = () => {
    document.querySelectorAll("#contacts-assign-modal details:not([open])").forEach(item => item.setAttribute("open", "true"));
  }

  collapseAllClients = () => {
    document.querySelectorAll("#contacts-assign-modal details[open]").forEach(item => item.removeAttribute("open"));
  }

  onChangeOneOnlyUser = () => {
    (this.contacts || []).forEach(item => {
      item.distributor_id = null;
      item.second_distributor_id = null;
      item.account_manager_id = null;
    });
  }

  toggleAllProjectSelection = (event, contact_id, selectionName) => {
    let checked = event.target.checked;
    let className = selectionName == 'Projects' ? ".project-"+contact_id : ".order-"+contact_id;
    let elements = document.querySelectorAll(className);
    if (checked) {
      elements.forEach((element:any) => {element.checked = true;});
    } else {
      elements.forEach((element:any) => {element.checked = false;});
    }
  }

  assignContactsToAnotherUser = (deleteUser = false) => {
    // Note: sales_rep_id, second_sales_rep_id and csr_user_id keys are old details of client WHEREAS distributor_id, second_distributor_id and account_manager_id keys are newly selected details of client

    this.assignContactsErrorMessage = null;

    // Validate selection
    if (deleteUser) {
      if (this.deletedUser.role == 'Sales Rep' && !this.sales_rep_id_for_other_requests) {
        this.assignContactsErrorMessage = "Kindly, Select distributor to assign other requests!";
      } else {
        if (this.allClientsToOne) {
          if (this.deletedUser.role == 'Sales Rep' && !this.distributorId) {
            this.assignContactsErrorMessage = "Kindly, Select distributor to assign all client's data!";
          } else if (this.deletedUser.role == 'Estimator HOD' && !this.accountManagerId) {
            this.assignContactsErrorMessage = "Kindly, Select account manager to assign all client's data!";
          } else if (!(parseFloat(this.distributorCommissionPercent) >= 0 ||  parseFloat(this.accountManagerCommissionPercent) >= 0)) {
            this.assignContactsErrorMessage = "Invalid commission percentage!";
          } else if (this.distributorId == this.secondDistributorId) {
            this.assignContactsErrorMessage = "Distributor and Secondary Distributor can not be same!";
          }
        } else {
          let isDuplicateDistributor = this.contacts.find((item) => { return item.distributor_id && item.distributor_id == item.second_distributor_id });
          if (isDuplicateDistributor) {
            this.assignContactsErrorMessage = "Distributor and Secondary Distributor can not be same!";
          } else if (this.deletedUser.role == 'Sales Rep') {
            let notSelectedDistributor = this.contacts.find((item) => { return !item.distributor_id });
            if (notSelectedDistributor) {
              this.assignContactsErrorMessage = "Kindly, Select distributor for all clients!";
            }
          } else if (this.deletedUser.role == 'Estimator HOD') {
            let notSelectedAccountManager = this.contacts.find((item) => { return !item.account_manager_id });
            if (notSelectedAccountManager) {
              this.assignContactsErrorMessage = "Kindly, Select account manager for all clients!";
            }
          }          
        }
      }
    } else {
      if (this.deletedUser.role == 'Sales Rep') {
        let isSelectedDistributorForAnyClient = this.contacts.find((item) => { return item.distributor_id });
        if (!this.sales_rep_id_for_other_requests && !isSelectedDistributorForAnyClient) {
          this.assignContactsErrorMessage = "Kindly, Select distributor for at least one client OR other requests!";
        }
      } else if (this.deletedUser.role == 'Estimator HOD') {
        let isSelectedAccountManagerForAnyClient = this.contacts.find((item) => { return item.account_manager_id });
        if (!isSelectedAccountManagerForAnyClient) {
          this.assignContactsErrorMessage = "Kindly, Select account manager for at least one client!";
        }
      }
      
      try {
        this.contacts.forEach((item, index) => {
          if (item.distributor_id && item.distributor_id == item.second_distributor_id) {
            throw 'Distributor and Secondary Distributor can not be same at record no: ' + (index + 1);
          }
        });
      } catch (error) {
        this.assignContactsErrorMessage = error;
      }      
    }

    if (this.assignContactsErrorMessage) {
      document.querySelector("#contacts-assign-modal .modal-body").scrollTop = 0;
      return false;
    }

    let confirmMessage = ["After transfer all data you won't be able to revert it!"];
    let confirmButtonText = "Yes, Transfer it!";
    if (deleteUser) {
      confirmMessage = ["After transfer all data the user will be deleted and you won't be able to revert it!"];
      confirmButtonText = "Yes, Delete it!";
    } else if (!this.allClientsToOne) {
      let contactWithMissingSelection = false;
      let misMatchUser = false;

      (this.contacts || []).forEach((item) => {
        if (item.distributor_id || item.account_manager_id) {
          // For contactWithMissingSelection
          let hasProject = document.querySelector(".project-"+item.id+":checked");
          if (!hasProject) {
            let hasOrder = document.querySelector(".order-"+item.id+":checked");
            if (!hasOrder) {
              contactWithMissingSelection = true;
            }
          }

          // For misMatchUser
          if(this.deletedUser.role == 'Sales Rep' && item.sales_rep_id != this.current_user_id && item.sales_rep_id != item.distributor_id) {
            misMatchUser = true;
          } else if (this.deletedUser.role == 'Estimator HOD' && item.csr_user_id != this.current_user_id && item.csr_user_id != item.account_manager_id) {
            misMatchUser = true;
          }
        }
      });

      if (contactWithMissingSelection) {
        confirmMessage.push("There are some contacts that you are trying to transfer without any selection of project OR order.");
      }
      if (misMatchUser) {
        confirmMessage.push("Some client is already handeled by another user but you are trying to assign their projects to different user.");
      } 
    }

    Swal.fire({
      title: 'Are you sure?',
      html: "<div class='text-left'>"+confirmMessage.join("<br/><br/>")+"</div>",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: confirmButtonText
    }).then((result) => {
      if (result.value) {
        let contacts = [];
        if (this.allClientsToOne) {
          (this.contacts || []).forEach(item => {
            let projectIds = [];
            (item.client_projects || []).forEach(project => {
              if (!project.order_of_project || project.order_of_project.status != 3) {
                projectIds.push(project.id);
              }
            });

            contacts.push({
              contact_id: item.id,
              project_ids: projectIds,
              sales_rep_id: this.distributorId || null,
              sales_rep_commission_type_id: this.distributorCommissionType || 2,
              sales_rep_commission_percentage: parseFloat(this.distributorCommissionPercent) || 0,
              sales_rep_system_cost_percentage: parseFloat(this.distributorSystemCost) || 0,

              second_sales_rep_id: this.secondDistributorId || null,
              second_sales_rep_commission_type_id: this.secondDistributorCommissionType || 2,
              second_sales_rep_commission_percentage: parseFloat(this.secondDistributorCommissionPercent) || 0,
              second_sales_rep_system_cost_percentage: parseFloat(this.secondDistributorSystemCost) || 0,

              csr_user_id: this.accountManagerId || null,
              csr_user_commission_type_id: this.accountManagerCommissionType || 2,
              csr_user_commission_percentage: parseFloat(this.accountManagerCommissionPercent) || 0,
              csr_user_system_cost_percentage: parseFloat(this.accountManagerSystemCost) || 0
            });
          });
        } else {
          (this.contacts || []).forEach(item => {
            if ((this.deletedUser.role == 'Sales Rep' && item.distributor_id) || (this.deletedUser.role == 'Estimator HOD' && item.account_manager_id)) {
              let projectIds = [];
              let className = ".project-"+item.id+":checked";
              let elements = document.querySelectorAll(className);
              elements.forEach((element:any) => {
                projectIds.push(element.getAttribute('data-id'));
              });

              className = ".order-"+item.id+":checked";
              elements = document.querySelectorAll(className);
              elements.forEach((element:any) => {
                projectIds.push(element.getAttribute('data-id'));
              });

              contacts.push({
                contact_id: item.id,
                project_ids: projectIds,
                sales_rep_id: item.distributor_id || null,
                sales_rep_commission_type_id: item.distributor_commission_type || 2,
                sales_rep_commission_percentage: parseFloat(item.distributor_commission_percentage) || 0,
                sales_rep_system_cost_percentage: parseFloat(item.distributor_system_cost_percentage) || 0,

                second_sales_rep_id: item.second_distributor_id || null,
                second_sales_rep_commission_type_id: item.second_distributor_commission_type || 2,
                second_sales_rep_commission_percentage: parseFloat(item.second_distributor_commission_percentage) || 0,
                second_sales_rep_system_cost_percentage: parseFloat(item.second_distributor_system_cost_percentage) || 0,

                csr_user_id: item.account_manager_id || null,
                csr_user_commission_type_id: item.account_manager_commission_type || 2,
                csr_user_commission_percentage: parseFloat(item.account_manager_commission_percentage) || 0,
                csr_user_system_cost_percentage: parseFloat(item.account_manager_system_cost_percentage) || 0
              });
            }
          });
        }

        let postData = {
          current_user_id: this.current_user_id,
          role: this.deletedUser.role,
          sales_rep_id_for_other_requests: this.sales_rep_id_for_other_requests,
          contacts: contacts,
          delete_user: deleteUser
        };

        this.loading = true;
        this.userService.transferUserData(postData).subscribe((res) => {
          this.loading = false;
          this.contactsAssignModelHide(true);
        }, (error) => {
          this.loading = false;
        });
      }
    });
  }

  contactsAssignModelHide = (isSaved = false) => {
    this.contactsAssignModel.hide();
    this.closeContactTransfer.emit(isSaved);
  }
}