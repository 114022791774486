import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivationEnd } from '@angular/router';
import { ToasterConfig } from 'angular2-toaster';
import {filter} from 'rxjs/operators';
import {CommonService} from './service/common.service';
import { HttpCancelService } from './service/httpCancle.service';
@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<router-outlet></router-outlet><toaster-container [toasterconfig]="toasterConfig"></toaster-container>'
})
export class AppComponent implements OnInit {
  public toasterConfig: ToasterConfig = new ToasterConfig({
    tapToDismiss: true,
    timeout: 2000,
    preventDuplicates: true
  });

  previousUrl: string = null;
  currentUrl: string = null;

  constructor(private router: Router, private _httpCancle: HttpCancelService, private commonService: CommonService) {}

  ngOnInit() {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.previousUrl = this.currentUrl;
      this.currentUrl = event.url;
      this.commonService.setPreviousUrl(this.previousUrl);
    });
    
    this.router.events.subscribe((event) => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    this.router.events.subscribe(event => {
      if (event instanceof ActivationEnd) {
        this._httpCancle.cancelPendingRequests();
      }
    });
  }
}
