import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';
import { CommonService } from './common.service';

@Injectable({ providedIn: 'root' })

export class DataTableService {
  rerenderDatatable = new Subject();
  constructor(private _http: HttpClient, private _cs: CommonService) {
  }

  getTableData = (dataTableParameters: DataTablesResponse, url, custom_data, franchiseId = '', global = false) => {
    if (!global) {
      return this._http.post<any>(environment.API_URL + url, Object.assign(dataTableParameters, custom_data),
        this._cs.setHeader(franchiseId.toString()));
    }
    else {
      return this._http.post<any>(environment.API_URL + url, Object.assign(dataTableParameters, custom_data));
    }
  }
}

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}
