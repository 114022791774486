import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { CommonService } from './common.service';

@Injectable({ providedIn: 'root' })
export class SalesOrderService {
  constructor(private _http: HttpClient, private _cs: CommonService) {
  }

  getOrderItems = (id) => {
    return this._http.get(environment.API_URL + 'order/order-items/' + id, this._cs.setHeader());
  }
  getOrderItemsPO = (id) => {
    return this._http.get(environment.API_URL + 'order/order-items-po/' + id, this._cs.setHeader());
  }

  getInvoice = (id) => {
    return this._http.get(environment.API_URL + 'order/get-invoice/' + id, this._cs.setHeader());
  }
  updateOrderStatus = (data) => {
    return this._http.post<any>(environment.API_URL + 'order/update-order-status', data, this._cs.setHeader());
  }
  updateProductStatus = (data) => {
    return this._http.post<any>(environment.API_URL + 'order/update-product-status', data, this._cs.setHeader());
  }

  getAllSalesOrderOption = () => {
    return this._http.get(environment.API_URL + 'order/get-all-options', this._cs.setHeader());
  }

  getOrderAmounts = (orderId) => {
    return this._http.get(environment.API_URL + 'order/get-order-amounts/' + orderId, this._cs.setHeader());
  }

  closePurchaseOrder = (id) => {
    return this._http.post<any>(environment.API_URL + 'order/close-purchase', { id }, this._cs.setHeader());
  }

  reopenPurchaseOrder = (id) => {
    return this._http.post<any>(environment.API_URL + 'order/reopen-purchase', { id }, this._cs.setHeader());
  }

  getOrderInvoice = (orderId) => {
    return this._http.get(environment.API_URL + 'order/get-invoice-data/' + orderId, this._cs.setHeader());
  }

  getPublicOrderInvoice = (franchiseId, orderId) => {
    return this._http.get(environment.API_URL + 'get-public-invoice/'+franchiseId+'/' + orderId, this._cs.setHeader());
  }

  updateInvoiceData = (postData) => {
    return this._http.post(environment.API_URL + 'order/update-invoice', postData, this._cs.setHeader());
  }

  updateOrderInvoice = (data) => {
    return this._http.post<any>(environment.API_URL + 'order/update-invoice-order-items', data, this._cs.setHeader());
  }

  sendInvoice = (data) => {
    return this._http.post<any>(environment.API_URL + 'order/send-invoice', data, this._cs.setHeader());
  }

  getOrderAmountsByInvoice = (invoiceId) => {
    return this._http.get<any>(environment.API_URL + 'order/order-amounts-invoice/' + invoiceId, this._cs.setHeader());
  }

  getInvoiceList = () => {
    return this._http.get(environment.API_URL + 'order/invoice-list', this._cs.setHeader());
  }

  getInvoiceRoleWise = () => {
    return this._http.post<any>(environment.API_URL + 'order/invoice-list-rolewise', {}, this._cs.setHeader());
  }

  getInvoicesByCustomer = (id) => {
    return this._http.get(environment.API_URL + 'order/customer-invoices/' + id, this._cs.setHeader());
  }

  payMultipleInvoice = (data) => {
    return this._http.post(environment.API_URL + 'order/pay-multiple', data, this._cs.setHeader());
  }

  payInvoicesUsingCredit = (data) => {
    return this._http.post(environment.API_URL + 'order/pay-invoices-by-credit', data, this._cs.setHeader());
  }
  createInvoice = (data) => {
    return this._http.post(environment.API_URL + 'order/create-invoice', data, this._cs.setHeader());
  }
  sendInvoiceTo = (data) => {
    return this._http.post(environment.API_URL + 'order/send-invoice-quickbook', data, this._cs.setHeader());
  }
  getInvoiceDetail = (id) => {
    return this._http.get(environment.API_URL + 'order/get-detail/' + id, this._cs.setHeader());
  }
  updatePONumber = (data) => {
    return this._http.post(environment.API_URL + 'order/update-customer-po-number', data, this._cs.setHeader());
  }
  deleteSalesOrder = (id, data) => {
    return this._http.post(environment.API_URL + 'order/delete-order/' + id, data, this._cs.setHeader());
  }
  getAllSalesOrderData = (data) => {
    return this._http.post<any>(environment.API_URL + 'order/color-filter', data, this._cs.setHeader());
  }
  applycolorFilter = (data) => {
    return this._http.post<any>(environment.API_URL + 'order/color-filter',data, this._cs.setHeader());
  }
  uploadProof = (data) => {
    return this._http.post<any>(environment.API_URL + 'order/save-proof',data, this._cs.setHeader());
  }
  getProofData = (orderId,franchiseId,itemId) => {
    return this._http.get(environment.API_URL + 'order-proof/' + franchiseId + '/' + orderId + '/' + itemId);
  }
  uploadPublicProof = (franchiseId,data)=> {
    return this._http.post<any>(environment.API_URL + 'order-proof-save/' +franchiseId,data);
  }
  makeOrderPayment = (franchiseId,data)=> {
    return this._http.post<any>(environment.API_URL + 'order-payment/' +franchiseId,data, this._cs.setHeader(franchiseId));
  }
  getOrderPublicData = (franchiseId,orderId) => {
    return this._http.get(environment.API_URL + 'order-data/' + franchiseId + '/' + orderId);
  }
  sendOrderEmail = (data) => {
    return this._http.post<any>(environment.API_URL + 'order/send-order-email',data, this._cs.setHeader());
  }
  sendInvoiceEmail = (data) => {
    return this._http.post<any>(environment.API_URL + 'order/send-invoice-email',data, this._cs.setHeader());
  }

  addOrderShippingCharge = (data) => {
    return this._http.post<any>(environment.API_URL + 'order/add-order-shipping-charge',data, this._cs.setHeader());
  }

  // makeOrderPayment = (franchiseId,data) => {
  //   return this._http.get(environment.API_URL + '/order-payment/' + franchiseId, this._cs.setHeader());
  // }
}