import { Pipe, PipeTransform } from '@angular/core';


@Pipe({ name: 'searchFilterPipe' })
export class searchFilterPipe implements PipeTransform {
  transform(value: any[], term: string): any[] {
    return value.filter((x: any) =>
      x.first_name.toLowerCase().startsWith(term.toLowerCase())
      || x.last_name.toLowerCase().startsWith(term.toLowerCase())
    );

  }
}
