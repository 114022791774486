export class AppConstants {
  public static roles =
    {
      'super_admin': 'Super Admin',
      'sales_rep': 'Sales Rep',
      'estimator': 'Estimator',
      'admin': 'Admin',
      'estimator_hod': 'Estimator HOD',
      'supplier': 'Supplier',
      'accountant': 'Accountant',
    };
    public static identifier = {
      'dashboard' : 'dashboard',
      'project' : 'project',
      'archive' : 'archive',
      'direct-estimates': 'direct-estimates',
      'digital-products': 'digital-products',
      'digital-proposal': 'digital-proposal',
      'site-audit'  : 'site-audit-request',
      'content-generator' : 'content-generator',
      'contact' : 'contacts',
      'data-request-form' : 'data-request-form',
      'hubspot-import-contact':'hubspot-import-contact',
      'creative-request-form' : 'creative-request-form',
      'product-catalog':'product-catalog',
      'cart-details':'cart-details',
      'sales-orders' : 'sales-orders',
      'order-invoices'  : 'order-invoices',
      'promotional-search-products' : 'promotional-search-products',
      'all-presentation'  : 'all-presentation',
      'suppliers-directory' : 'suppliers-directory',
      'new-storefront-requests' : 'new-storefront-requests',
      'portal-request' : 'portal-request',
      'existing-storefront-update-request'  : 'existing-storefront-update-request',
      'artwork-request' : 'artwork-request',
      'salesrep-report' : 'salesrep-report',
      'invoice-report'  : 'invoice-report',
      'commission-report' : 'commission-report',
      'variant-report'  : 'variant-report',
      'presentation-created-report' : 'presentation-created-report',
      'estimate-created-report' : 'estimate-created-report',
      'sales-order-created-report'  : 'sales-order-created-report',
      'score-card'  : 'score-card',
      'brand-guidelines' : 'brand-guidelines',
      'promotional-create-presentation'  : 'promotional-create-presentation',
      'print-all-orders'  : 'print-all-orders',
      'print-all-quotes'  : 'print-all-quotes',
      'print-search-products' : 'print-search-products',
      'quote-custom-specifications' : 'quote-custom-specifications',
      'quote-print-specifications' : 'quote-print-specifications',
      'quote-direct-mail-specifications' : 'quote-direct-mail-specifications',
      'instant-quote-request' : 'instant-quote-request',
      'create-content-generator' : 'create-content-generator'
    }
}
