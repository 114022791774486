import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { productoption } from '../../../assets/quote-forms-json/quote-product-option';
import { FormGroup, FormBuilder, FormControl, Validators, FormGroupDirective, FormArray } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { AsapQuoteService } from '../../service/asap-quote.service';
import { CommonService } from '../../service/common.service';
import { BookletComponent } from './booklet/booklet.component';
import { CalendarComponent } from './calendar/calendar.component';
import {Router} from '@angular/router';

@Component({
  selector: 'app-quote-forms',
  templateUrl: './quote-forms.component.html',
  styleUrls: ['./quote-forms.component.scss']
})
export class QuoteFormsComponent implements OnInit {
  productOption = productoption;
  // quoteCreateForm: FormGroup;
  formData: any = [];
  show_other_fields = false;
  extra_fields: any = [];
  extra_fields_visibility: any = [];
  show_custom_field = false;
  isBookletOrCalendar = false;
  productType = '';
  loading = false;
  previousUrl: any = null;
  franchiseId = '';

  @Input() quoteCreateForm: FormGroup;
  @Input() projectFormRef: any;
  @Input() editData: any = undefined;
  @Output() productTypeChange = new EventEmitter();
  @Output() bookletOrCalendar = new EventEmitter();
  @Output() quoteCreateFormData = new EventEmitter()
  @Output() changeProductType = new EventEmitter()
  @Output() isQuoteValid = new EventEmitter()
  @ViewChild('booklet') bookletComponent: BookletComponent;
  @ViewChild('calendar') calendarComponent: CalendarComponent;
  @ViewChild('quoteForm') form;

  constructor(
    private router: Router,
    private _fb: FormBuilder,
    private _httpclient: HttpClient,
    private _quoteService: AsapQuoteService,
    public commonService: CommonService
  ) { }

  ngOnInit() {
    this.franchiseId = this.commonService.currentFranchise();

    this.commonService.previousUrl$
    .subscribe((previousUrl: string) => {
        this.previousUrl = previousUrl
    });

    if(this.editData) {
      // console.log("editdata",this.editData)
      this.show_other_fields = true
      let p = this.editData['product_type']
      this.productType = this.editData['product_type']
      this.changeProductType.emit(p);
      this.quoteCreateForm = this._fb.group({
        product_type : [this.editData['product_type']],
        additional_quntities : this._fb.group({
          qty1 : [this.editData['quote_details']['additional_quntities']['qty1']],
          qty2 : [this.editData['quote_details']['additional_quntities']['qty2']],
          qty3 : [this.editData['quote_details']['additional_quntities']['qty3']],
          qty4 : [this.editData['quote_details']['additional_quntities']['qty4']],
          qty5 : [this.editData['quote_details']['additional_quntities']['qty5']]
        }),
        additional_comments : this.editData['quote_details']['additional_comments']
      })

      if(this.editData['product_type']) {
        if (this.editData['product_type'] === 'booklet' || this.editData['product_type'] === 'calendar') {
          // this.initBookletForm();
          this.isBookletOrCalendar = true;
          // this.quoteCreateFormData.emit(this.quoteCreateForm);
        } else {
          this._httpclient.get('../../../assets/quote-forms-json/' + this.editData['product_type'] + '.json').subscribe(
            data => {
              this.formData = data;
              this.formData.map((item,index) => {
                if (item['type'] === 'text') {
                  this.quoteCreateForm.addControl(item.name, new FormControl(this.editData['quote_details'][item.name], item['required'] ? [Validators.required] : []));
                } else {
                  // this.quoteCreateForm.addControl(item.name, new FormControl(item['options'][0],
                  //   item['required'] ? [Validators.required] : []));
                  let quoteDetails = this.editData['quote_details']
                  let key = item['name'] 
                  this.quoteCreateForm.addControl(item.name, new FormControl(quoteDetails[key],
                    item['required'] ? [Validators.required] : []));
                }
                if (item['extra_fields'] && item['extra_fields'].length > 0) {
                  item['extra_fields'].map((extra,extrakey) => {
                    let quoteDetails = this.editData['quote_details']
                    let key = extra['name'] 

                    if(quoteDetails[extra.name] != '' && extra['visibility_depends_on'].indexOf(quoteDetails[item.name]) > -1) {
                      this.formData[index]['extra_fields'][extrakey]['visibility'] = true;
                    }
                    else {
                      this.formData[index]['extra_fields'][extrakey]['visibility'] = false;
                    }

                    this.quoteCreateForm.addControl(extra.name, new FormControl(quoteDetails[key], extra['required'] ? [Validators.required] : []));
                    if (extra['extra_fields'] && extra['extra_fields'].length > 0) {
                      extra['extra_fields'].map((inner_extra,innerkey) => {
                        this.quoteCreateForm.addControl(inner_extra.name,
                          new FormControl(quoteDetails[inner_extra.name], inner_extra['required'] ? [Validators.required] : []));
                        
                          if (quoteDetails[inner_extra.name] != '' && (inner_extra['visibility_depends_on']).indexOf(quoteDetails[extra.name]) > -1) {
                            this.formData[index]['extra_fields'][extrakey]['extra_fields'][innerkey]['visibility'] = true;
                          } else {
                            this.formData[index]['extra_fields'][extrakey]['extra_fields'][innerkey]['visibility'] = false;
                          }
                      });
                    }
                  });
                }
              });
              
              this._httpclient.get('../../../assets/quote-forms-json/extra_fields.json').subscribe(
                data => {
                  this.extra_fields = data;
                  this._httpclient.get('../../../assets/quote-forms-json/extra_fields_visibility.json').subscribe(
                    data => {
                      this.extra_fields_visibility = data;
                      
                      

                      const form_data = this.quoteCreateForm.value;

                      let settled = false;
                      this.extra_fields_visibility.map(val => {
                        if (val['name'] === form_data['product_type']) {
                           
                          settled = true;
                          this.extra_fields.map((field, key) => {
                            let quoteDetails = this.editData['quote_details']
                            let keyname = field['name']
                            if (val['value'].indexOf(field.name) > -1) {
                              if (val['extra'] && val['extra'].length > 0 && val['extra'][0]['name'] === field.name) {
                                this.quoteCreateForm.addControl(field.name,
                                  new FormControl({ value: quoteDetails[keyname], disabled: val['extra'][0]['disabled'] },
                                    field['required'] ? [Validators.required] : []));
                                if (val['extra'][0]['default'] === 'Yes') {
                                  if(this.extra_fields[key]['extra_fields']) {
                                    this.extra_fields[key]['extra_fields'][0]['visibility'] = true;
                                  }
                                }

                              } else {
                                if (field.name === 'pockets') {
                                  // this.quoteCreateForm.addControl(field.name + '_1', new FormControl(field['options'][0]['0'][0],
                                  //   field['required'] ? [Validators.required] : []));
                                  // this.quoteCreateForm.addControl(field.name + '_2', new FormControl(field['options'][1]['1'][0],
                                  //   field['required'] ? [Validators.required] : []));
                                  this.quoteCreateForm.addControl(field.name + '_1', new FormControl('',
                                    field['required'] ? [Validators.required] : []));
                                  this.quoteCreateForm.addControl(field.name + '_2', new FormControl('',
                                    field['required'] ? [Validators.required] : []));
                                } else {
                                  this.quoteCreateForm.addControl(field.name, new FormControl(quoteDetails[keyname],
                                    field['required'] ? [Validators.required] : []));
                                  // this.quoteCreateForm.addControl(field.name, new FormControl(field['options'][0],
                                  //   field['required'] ? [Validators.required] : []));
                                }
                              }
                              if (field['extra_fields'] && field['extra_fields'].length > 0) {
                                let n = field['extra_fields'][0]['name']
                                this.quoteCreateForm.addControl(field['extra_fields'][0]['name'], new FormControl(quoteDetails[n],
                                  field['required'] ? [Validators.required] : []));
                              }
                              if (field.name !== 'permit' && field.name !== 'postal_class') {
                                this.extra_fields[key]['visibility'] = true;
                              }
                            } else {
                              this.extra_fields[key]['visibility'] = false;
                            }
                          });
                        } else {
                          if (!settled) {
                            // this.resetExtraFields();
                          }
                        }
                      });
                      




                      
                      this.extra_fields.map((field, key) => {
                        const name = field['name']
                        var val;
                        if(this.quoteCreateForm.get(name)) {
                          val = this.quoteCreateForm.get(name).value;
                        }
                         
                        let quoteDetails = this.editData['quote_details']
                        if (field['name'] && field['name'] !== 'direct_mail') {
                          if (field['extra_fields'] && field['extra_fields'].length > 0) {
                            if (val && val === 'Yes') {
                              this.quoteCreateForm.controls[this.extra_fields[key]['extra_fields'][0]['name']].setValue(quoteDetails[name+'_notes']);
                              this.extra_fields[key]['extra_fields'][0]['visibility'] = true;
                            } else if(val && val !== 'Yes') {
                              this.quoteCreateForm.controls[this.extra_fields[key]['extra_fields'][0]['name']].setValue(quoteDetails[name+'_notes']);
                              this.extra_fields[key]['extra_fields'][0]['visibility'] = false;
                            }
                          }
                        }
                        if (name === 'direct_mail') {
                          if (field['name'] === 'postal_class' || field['name'] === 'permit') {
                            if (val && val === 'Yes') {
                              this.extra_fields[key]['visibility'] = true;
                            } else if(val && val !== 'Yes') {
                              this.extra_fields[key]['visibility'] = false;
                            }
                          }
                        }
                      });

                      this.isBookletOrCalendar = false;


                      
                      
                     
                    }
                  );
                }
              );

              // console.log("extra fields",this.extra_fields)
              // console.log("extra fields visibility",this.extra_fields_visibility)
              
            },
            
          );
          this.show_other_fields = this.editData['product_type'] !== '' ? true : false;
          

          
          
        }
        this.quoteCreateFormData.emit(this.quoteCreateForm);
        
      }
      
    }
    else {
      this.initQuoteForm();
    }
  }

  checkValidityBooklet(data) {
    if(data) {
      this.isQuoteValid.emit(true);
    }
    else {
      this.isQuoteValid.emit(false);
    }
  }

  checkValidityCalender(data) {
    if(data) {
      this.isQuoteValid.emit(true);
    }
    else {
      this.isQuoteValid.emit(false);
    }
  }

  setAdditionalComment(e) {
    let commentValue = e.target.value;
    this.quoteCreateForm.get('additional_comments').setValue(commentValue);
    this.quoteCreateFormData.emit(this.quoteCreateForm);
  }

  initQuoteForm = (value = '') => {
    // this.quoteCreateForm.addControl('quote_name', new FormControl('', [Validators.required]));
    this.quoteCreateForm.addControl('product_type', new FormControl('', [Validators.required]));
    this.quoteCreateForm.addControl('additional_quntities', new FormGroup({
      qty1: new FormControl(null),
      qty2: new FormControl(null),
      qty3: new FormControl(null),
      qty4: new FormControl(null),
      qty5: new FormControl(null)
    }));
    // this.quoteCreateForm.addControl('additional_comments', this._fb.group({
    //   qty1: [null],
    //   qty2: [null],
    //   qty3: [null],
    //   qty4: [null],
    //   qty5: [null],
    // }));
    this.quoteCreateForm.addControl('additional_comments', new FormControl(''));
    // this.quoteCreateForm = this._fb.group({
    //   quote_name: ['', [Validators.required]],
    //   product_type: [value, [Validators.required]],
    //   additional_quntities: this._fb.group({
    //     qty1: [null],
    //     qty2: [null],
    //     qty3: [null],
    //     qty4: [null],
    //     qty5: [null],
    //   }),
    //   additional_comments: ['']
    // });
  }

  cleanFormGroup = () => {
    Object.keys(this.quoteCreateForm.controls).forEach((key) => {
      this.quoteCreateForm.removeControl(key);
    });
  }

  validateQuoteForm = (data: any) => {
    if (data === true) {
      if(this.form && this.form.valid) {
        this.isQuoteValid.emit(true);
      }
      else {
        this.isQuoteValid.emit(false);
      }
    }
  }

  validateBooklet() {
    this.bookletComponent.checkBooklet(true);
  }

  validateCalender() {
    this.calendarComponent.checkCalender(true);
  }

  initExtraFields = () => {
    if (this.extra_fields.length > 0) {
      this.initExtraFieldVisibility();
    } else {
      this._httpclient.get('../../../assets/quote-forms-json/extra_fields.json').subscribe(
        data => {
          this.extra_fields = data;
          this.initExtraFieldVisibility();
        }
      );
    }
  }

  initExtraFieldVisibility = () => {
    if (this.extra_fields_visibility.length > 0) {
      this.setExtraFields();
    } else {
      this._httpclient.get('../../../assets/quote-forms-json/extra_fields_visibility.json').subscribe(
        data => {
          this.extra_fields_visibility = data;
          this.setExtraFields();
        }
      );
    }
  }

  setExtraFields = () => {
    const form_data = this.quoteCreateForm.value;
    let settled = false;
    this.extra_fields_visibility.map(val => {
      if (val['name'] === form_data['product_type']) {
        settled = true;
        this.extra_fields.map((field, key) => {
          if (val['value'].indexOf(field.name) > -1) {
            if (val['extra'] && val['extra'].length > 0 && val['extra'][0]['name'] === field.name) {
              this.quoteCreateForm.addControl(field.name,
                new FormControl({ value: val['extra'][0]['default'], disabled: val['extra'][0]['disabled'] },
                  field['required'] ? [Validators.required] : []));
              if (val['extra'][0]['default'] === 'Yes') {
                if(this.extra_fields[key]['extra_fields']) {
                  this.extra_fields[key]['extra_fields'][0]['visibility'] = true;
                }
              }
            } else {
              if (field.name === 'pockets') {
                // this.quoteCreateForm.addControl(field.name + '_1', new FormControl(field['options'][0]['0'][0],
                //   field['required'] ? [Validators.required] : []));
                // this.quoteCreateForm.addControl(field.name + '_2', new FormControl(field['options'][1]['1'][0],
                //   field['required'] ? [Validators.required] : []));
                this.quoteCreateForm.addControl(field.name + '_1', new FormControl('',
                  field['required'] ? [Validators.required] : []));
                this.quoteCreateForm.addControl(field.name + '_2', new FormControl('',
                  field['required'] ? [Validators.required] : []));
              } else {
                this.quoteCreateForm.addControl(field.name, new FormControl('',
                  field['required'] ? [Validators.required] : []));
                // this.quoteCreateForm.addControl(field.name, new FormControl(field['options'][0],
                //   field['required'] ? [Validators.required] : []));
              }
            }
            if (field['extra_fields'] && field['extra_fields'].length > 0) {
              this.quoteCreateForm.addControl(field['extra_fields'][0]['name'], new FormControl('',
                field['required'] ? [Validators.required] : []));
            }
            if (field.name !== 'permit' && field.name !== 'postal_class') {
              this.extra_fields[key]['visibility'] = true;
            }
          } else {
            this.extra_fields[key]['visibility'] = false;
          }
        });
      } else {
        if (!settled) {
          this.resetExtraFields();
        }
      }
    });
  }

  resetExtraFields = () => {
    this.extra_fields.map((field, key) => {
      this.extra_fields[key]['visibility'] = false;
      if (field['extra_fields'] && field['extra_fields'].length > 0) {
        this.extra_fields[key]['extra_fields'][0]['visibility'] = false;
      }
    });
  }

  showExtraNotes = (event, name) => {
    const val = event.target.value;
    this.extra_fields.map((field, key) => {
      if (field['name'] === name && name !== 'direct_mail') {
        if (field['extra_fields'] && field['extra_fields'].length > 0) {
          if (val === 'Yes') {
            this.extra_fields[key]['extra_fields'][0]['visibility'] = true;
          } else {
            this.quoteCreateForm.controls[this.extra_fields[key]['extra_fields'][0]['name']].setValue('');
            this.extra_fields[key]['extra_fields'][0]['visibility'] = false;
          }
        }
      }
      if (name === 'direct_mail') {
        if (field['name'] === 'postal_class' || field['name'] === 'permit') {
          if (val === 'Yes') {
            this.extra_fields[key]['visibility'] = true;
          } else {
            this.extra_fields[key]['visibility'] = false;
          }
        }
      }
    });
  }

  onchangeProductType = (event, formDirective: FormGroupDirective) => {
    // this.show_other_fields = false;
    this.isBookletOrCalendar = false
    
    this.cleanFormGroup();
    this.initQuoteForm(event);
    formDirective.resetForm();
    
    this.quoteCreateForm.controls['product_type'].setValue(event);
    
    this.productType = event;
    this.productTypeChange.emit(event);
    
    if (event) {
      if (event === 'booklet' || event === 'calendar') {
        // this.initBookletForm();
        this.isBookletOrCalendar = true;
      } else {
        this._httpclient.get('../../../assets/quote-forms-json/' + event + '.json').subscribe(
          data => {
            this.formData = data;
            this.formData.map(item => {
              if (item['type'] === 'text') {
                this.quoteCreateForm.addControl(item.name, new FormControl('', item['required'] ? [Validators.required] : []));
              } else {
                // this.quoteCreateForm.addControl(item.name, new FormControl(item['options'][0],
                //   item['required'] ? [Validators.required] : []));
                this.quoteCreateForm.addControl(item.name, new FormControl('',
                  item['required'] ? [Validators.required] : []));
              }
              if (item['extra_fields'] && item['extra_fields'].length > 0) {
                item['extra_fields'].map(extra => {
                  this.quoteCreateForm.addControl(extra.name, new FormControl('', extra['required'] ? [Validators.required] : []));
                  if (extra['extra_fields'] && extra['extra_fields'].length > 0) {
                    extra['extra_fields'].map(inner_extra => {
                      this.quoteCreateForm.addControl(inner_extra.name,
                        new FormControl('', inner_extra['required'] ? [Validators.required] : []));
                    });
                  }
                });
              }
            });
            this.isBookletOrCalendar = false;
          },
        );
      }
    } else {
      this.formData = [];
    }
    
    if(this.editData) {
      if(this.editData['product_type'] != event) {
        this.changeProductType.emit(event);
        this.quoteCreateFormData.emit(this.quoteCreateForm);
      }
    }
    this.show_other_fields = event !== '' ? true : false;
    this.initExtraFields();
  }

  ShowFields = (event) => {
    const ele = event[0];
    const value = event[1];
    this.formData.map((item, key) => {
      // if (item['name'] === ele) {
      if (this.formData[key]['extra_fields'] && this.formData[key]['extra_fields'].length > 0) {
        this.formData[key]['extra_fields'].map((v, k) => {
          if (item['name'] === ele) {
            if (v['set_default_value'] && v['set_default_value'] === 'OPTION') {
              this.quoteCreateForm.controls[v['name']].setValue(value);
            } else {
              this.quoteCreateForm.controls[v['name']].setValue('');
            }
            if (value !== '' && (v['visibility_depends_on']).indexOf(value) > -1) {
              this.formData[key]['extra_fields'][k]['visibility'] = true;
            } else {
              this.formData[key]['extra_fields'][k]['visibility'] = false;
            }
          }
          if (v['name'] === ele && this.formData[key]['extra_fields'][k]['extra_fields']
            && this.formData[key]['extra_fields'][k]['extra_fields'].length > 0) {
            this.formData[key]['extra_fields'][k]['extra_fields'].map((iv, ik) => {
              if (iv['set_default_value'] && iv['set_default_value'] === 'OPTION') {
                this.quoteCreateForm.controls[iv['name']].setValue(value);
              } else {
                this.quoteCreateForm.controls[iv['name']].setValue('');
              }
              if (value !== '' && (iv['visibility_depends_on']).indexOf(value) > -1) {
                this.formData[key]['extra_fields'][k]['extra_fields'][ik]['visibility'] = true;
              } else {
                this.formData[key]['extra_fields'][k]['extra_fields'][ik]['visibility'] = false;
              }
            });
          }
        });
      }
    });
  }

  showCustomField = () => {
    const values = this.quoteCreateForm.value;
    if (values['pockets_1'] === 'Custom' || values['pockets_2'] === 'Custom') {
      this.show_custom_field = true;
    } else {
      this.quoteCreateForm.controls['pockets_details'].setValue('');
      this.show_custom_field = false;
    }
  }

  onSubmit = (postData = {}) => {
    // if (this.quoteCreateForm.valid) {
    let data = {};
    if (Object.keys(postData).length > 0) {
      // on submit calendar and booklets
      data = postData;
      // console.log(postData);
    } else {
      data = this.quoteCreateForm.value;
      // on submit json form
    }
    this.loading = true;
    this._quoteService.saveQuote(data).subscribe(
      (res) => {
        this.loading = false;
      },
      (error) => {
        this.loading = false;
      }
    );
    // }
  }

  onSubmitOtherForm = (formData) => {
    this.bookletOrCalendar.emit(formData);
    // const data = formData;
    // data['product_type'] = this.productType;
    // // data['quote_name'] = this.quoteCreateForm.get('quote_name').value;
    // this.onSubmit(data);
  }

  initBookletForm = () => {
    this.quoteCreateForm = this._fb.group({
      test: ['']
    });
  }

  back = () => {
    this.router.navigateByUrl(this.previousUrl || '/'+this.franchiseId+'/project');
  }

  onSubmitProject = (type) => {
    if (type === 1) {
      this.bookletComponent.onProjectFormSubmit();
    } else {
      this.calendarComponent.onProjectFormSubmit();
    }
  }
}
