import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ConditionalValidator } from '../../../validators/conditional.validator';
import { AsapQuoteService } from '../../../service/asap-quote.service';

@Component({
  selector: 'app-booklet',
  templateUrl: 'booklet.component.html',
  styleUrls: ['booklet.component.scss']
})
export class BookletComponent implements OnInit {
  bookletForm: FormGroup;
  bookletType: any;
  saddleBinding = false;
  subscription: any;
  formSubmitted = false;
  @Output() submitForm = new EventEmitter();
  @Output() checkValidBooklet = new EventEmitter();
  @Input() bookletEditData: any = undefined;


  constructor(private _fb: FormBuilder, private _quoteService: AsapQuoteService) {
  }

  ngOnInit() {
    if(this.bookletEditData) {
      this.bookletForm = this._fb.group({
        booklet_type: [this.bookletEditData['quote_details']['booklet_type'], Validators.required],
        binding: [this.bookletEditData['quote_details']['binding'], Validators.required],
        flat_width: [this.bookletEditData['quote_details']['flat_width']],
        flat_height: [this.bookletEditData['quote_details']['flat_height']],
        finish_width: [this.bookletEditData['quote_details']['finish_width'], Validators.required],
        finish_height: [this.bookletEditData['quote_details']['finish_height'], Validators.required],
        // self cover controls
        stock: [this.bookletEditData['quote_details']['stock']], // required
        other_stock: [this.bookletEditData['quote_details']['other_stock']], // conditionally required
        coating: [this.bookletEditData['quote_details']['coating']],
        sides: [this.bookletEditData['quote_details']['sides']], // required
        // end
        // hard cover controls
        cover_stock: [this.bookletEditData['quote_details']['cover_stock']], // required
        other_cover_stock: [this.bookletEditData['quote_details']['other_cover_stock']], // conditionally required
        cover_sides: [this.bookletEditData['quote_details']['cover_sides']],
        cover_coating: [this.bookletEditData['quote_details']['cover_coating']],
        cover_coating_notes: [this.bookletEditData['quote_details']['cover_coating_notes']],
        text_stock: [this.bookletEditData['quote_details']['text_stock']], // required
        other_text_stock: [this.bookletEditData['quote_details']['other_text_stock']], // conditionally required
        text_coating: [this.bookletEditData['quote_details']['text_coating']],
        text_sides: [this.bookletEditData['quote_details']['text_sides']], // required
        // end
        // general for both
        coat_on: [this.bookletEditData['quote_details']['coat_on']], // conditionally required
        notes: [this.bookletEditData['quote_details']['notes']],
        // end
        number_of_pages: [this.bookletEditData['quote_details']['number_of_pages'], Validators.required],
        flat_size_sheets: [this.bookletEditData['quote_details']['flat_size_sheets'], Validators.required],
        versions: [this.bookletEditData['quote_details']['versions']],
        quantitiy: [this.bookletEditData['quote_details']['quantitiy'], Validators.required],
        // add_qty1: [''],
        // add_qty2: [''],
        // add_qty3: [''],
        // add_qty4: [''],
        // add_qty5: [''],
        additional_quntities: this._fb.group({
          qty1: [this.bookletEditData['quote_details']['additional_quntities']['qty1']],
          qty2: [this.bookletEditData['quote_details']['additional_quntities']['qty2']],
          qty3: [this.bookletEditData['quote_details']['additional_quntities']['qty3']],
          qty4: [this.bookletEditData['quote_details']['additional_quntities']['qty4']],
          qty5: [this.bookletEditData['quote_details']['additional_quntities']['qty5']],
        }),
        additional_comments: [this.bookletEditData['quote_details']['additional_comments']],
        pockets: [this.bookletEditData['quote_details']['pockets']],
        pocket_details: [this.bookletEditData['quote_details']['pocket_details']],
        drilling: [this.bookletEditData['quote_details']['drilling']],
        drilling_notes: [this.bookletEditData['quote_details']['drilling_notes']],
        perforation: [this.bookletEditData['quote_details']['perforation']],
        perforation_notes: [this.bookletEditData['quote_details']['perforation_notes']]
      }, {
          validators: [ConditionalValidator.validatorBooklet]
        });
      
        const bookletType = this.bookletForm.get('booklet_type').value;
        const binding = this.bookletForm.get('binding').value;
        switch (bookletType) {
          case 'self_cover':
            this.bookletType = 1;
            break;
          case 'hard_cover':
            this.bookletType = 2;
            break;
        }
    
        if (binding === 'saddle_stitch') {
          this.saddleBinding = true;
        } else {
          this.onChangeSelect('saddle_stitch');
          this.saddleBinding = false;
        }
        // this.onSubmit();
        this.submitForm.emit(this.bookletForm.value);
    }
    else {
      this.initBookletForm();
      this.getPages();
    }
  }

  onProjectFormSubmit = () => {
    this.formSubmitted = true;
    if (this.bookletForm.valid) {
      this.submitForm.emit(this.bookletForm.value);
      this.checkValidBooklet.emit(true)
    }
    else {
      this.checkValidBooklet.emit(false)
    }
  }

  checkBooklet(data: any) {
    this.formSubmitted = true;
    if (data === true) {
      if (this.bookletForm.valid) {
        this.checkValidBooklet.emit(true)
      }
      else {
        this.checkValidBooklet.emit(false)
      }
    }
  }

  initBookletForm(bookletType = '') {
    this.bookletForm = this._fb.group({
      booklet_type: ['', Validators.required],
      binding: ['', Validators.required],
      flat_width: [''],
      flat_height: [''],
      finish_width: ['', Validators.required],
      finish_height: ['', Validators.required],
      // self cover controls
      stock: [''], // required
      other_stock: [''], // conditionally required
      coating: [''],
      sides: [''], // required
      // end
      // hard cover controls
      cover_stock: [''], // required
      other_cover_stock: [''], // conditionally required
      cover_sides: [''],
      cover_coating: [''],
      cover_coating_notes: [''],
      text_stock: [''], // required
      other_text_stock: [''], // conditionally required
      text_coating: [''],
      text_sides: [''], // required
      // end
      // general for both
      coat_on: [''], // conditionally required
      notes: [''],
      // end
      number_of_pages: ['', Validators.required],
      flat_size_sheets: ['', Validators.required],
      versions: [''],
      quantitiy: ['', Validators.required],
      // add_qty1: [''],
      // add_qty2: [''],
      // add_qty3: [''],
      // add_qty4: [''],
      // add_qty5: [''],
      additional_quntities: this._fb.group({
        qty1: [null],
        qty2: [null],
        qty3: [null],
        qty4: [null],
        qty5: [null],
      }),
      additional_comments: [''],
      pockets: ['No'],
      pocket_details: [''],
      drilling: [''],
      drilling_notes: [''],
      perforation: ['No'],
      perforation_notes: ['']
    }, {
        validators: [ConditionalValidator.validatorBooklet]
      });
  }

  getPages = (multiply = 1) => {
    let i = 0;
    const pages = Array.apply(null, { length: 25 }).map(() => {
      i++;
      return i * multiply;
    });
    return pages;
  }

  onBookletTypeAndBinding = () => {
    const bookletType = this.bookletForm.get('booklet_type').value;
    const binding = this.bookletForm.get('binding').value;
    switch (bookletType) {
      case 'self_cover':
        this.bookletType = 1;
        break;
      case 'hard_cover':
        this.bookletType = 2;
        break;
    }

    if (binding === 'saddle_stitch') {
      this.saddleBinding = true;
    } else {
      this.onChangeSelect('saddle_stitch');
      this.saddleBinding = false;
    }
  }

  onChangeSelect = (type = '') => {
    if (type) {
      switch (type) {
        case 'saddle_stitch':
          this.resetByControlName('flat_width');
          this.resetByControlName('flat_height');
          break;

        case 'stock':
          this.resetByControlName('other_stock');
          break;

        case 'coating':
        case 'text_coating':
          this.resetByControlName('coat_on');
          this.resetByControlName('notes');
          break;

        case 'cover_stock':
          this.resetByControlName('other_cover_stock');
          break;

        case 'cover_coating':
          this.resetByControlName('cover_coating_notes');
          break;

        case 'text_stock':
          this.resetByControlName('other_text_stock');
          break;

        case 'pockets':
          this.resetByControlName('pocket_details');
          break;

        case 'drilling':
          this.resetByControlName('drilling_notes');
          break;

        case 'perforation':
          this.resetByControlName('perforation_notes');
          break;
      }
    }
  }

  resetByControlName = (controlName, initialValue = '') => {
    this.bookletForm.get(controlName).setValue(initialValue);
  }

  onSelectCoatOn = (value) => {
    this.bookletForm.get('notes').setValue(value);
  }

  onSelectPage = (value) => {
    if (value) {
      this.bookletForm.get('flat_size_sheets').setValue((value / 4));
    } else {
      this.bookletForm.get('flat_size_sheets').setValue('');
    }

  }

  onSubmit = () => {
    this.submitForm.emit(this.bookletForm.value);
  }

}
