import { AfterViewInit, Component, EventEmitter, Input, NgModule, OnDestroy, OnInit, Output, ViewChild ,} from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject, Subscription } from 'rxjs';
import { DataTableService } from '../../service/datatable.service';
import { UserService } from '../../service/user.service';
import Swal from 'sweetalert2';
import { ToasterService } from 'angular2-toaster';


@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss']
})
export class DatatableComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  data: any = [];
  subscription: Subscription;
  @Input() path: string;
  @Input() columns: any = [];
  @Input() headers: any = [];
  @Input() custom_data: any = [];
  @Output() editData = new EventEmitter();
  @Output() resetPassword = new EventEmitter();
  @Output() contactsTransfer = new EventEmitter();
  @Output() deleteData = new EventEmitter();
  message :any = ''


  constructor(private _dtService: DataTableService, private userService:UserService, private toasterService: ToasterService) {
  }

  ngOnInit() {
    this.getTableData();
    this.subscription = this._dtService.rerenderDatatable.subscribe(res => {
      this.rerender();
    });
  }

  getTableData = () => {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      lengthMenu: [5, 10, 25, 50],
      serverSide: true,
      processing: true,
      language: {
        searchPlaceholder: 'Search...',
        search: ''
      },
      ajax: (dataTablesParameters: any, callback) => {
        this._dtService.getTableData(dataTablesParameters, this.path, this.custom_data).subscribe(res => {
          this.data = res.data.original.data;
          callback({
            recordsTotal: res.data.original.recordsTotal,
            recordsFiltered: res.data.original.recordsFiltered,
            data: []
          });
        });
      },
      order: [],
      columns: this.columns
    };
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  Edit = (editData) => {
    this.editData.emit(editData);
  }

  resetUserPassword = (editData) => {
    this.resetPassword.emit(editData);
  }
  
  Delete = (user) => {
    if(user['role'] == 'Sales Rep' || user['role'] == 'Estimator HOD') {
      this.contactsTransfer.emit({
        user_id: user.id,
        role: user.role
      });
      return false;
    }
    
    this.message = ''
      var data = { 'id' : user.id}
      Swal.fire({
        title: "Are you sure?",
        text : "You want to delete this user!",
        type: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        confirmButtonText: 'Yes, Delete it!',
        preConfirm: (value1) => {
            return this.userService.deleteUserRoleWise(data).toPromise().then((res) => {
              if (res['data']) {
                this.rerender();
                return true;
              }
            })
              .catch((error) => {
                if(error['error']['message']){
                  this.message = error['error']['message'];
                  this.toasterService.pop('error', this.message, '');
                }
                return true;
              });
        }
      }).then((res) => {
        if (res['value']) {
          this.rerender();
        }
      });
    }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
    this.subscription.unsubscribe();

  }

  rerender = (): void => {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.clear().destroy();
      this.dtTrigger.next();
    });
  }
  getVal = (project, myPath) => {
    // return 
    var result = myPath.split('.').reduce((res, prop) => res[prop], project);
    if(myPath == 'roles.0.original_name'){
      if(result == 'Estimator HOD'){
        return result = 'Account Manager';
      }else if(result == 'Sales Rep') {
        return result = 'Distributor';
      }else{
        return result
      }
    }else{
      return result
    }
  }
}
