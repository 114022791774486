import { Component, OnInit, Output,Input, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ConditionalValidator } from '../../../validators/conditional.validator';
import { AsapQuoteService } from '../../../service/asap-quote.service';

@Component({
  selector: 'app-calendar',
  templateUrl: 'calendar.component.html',
  styleUrls: ['calendar.component.scss']
})
export class CalendarComponent implements OnInit {
  quoteCreateFormNew: FormGroup;
  coating_selected: boolean;
  no_selection: boolean;
  stock_selected: boolean;
  text_stock_selected: boolean;
  text_coating_selected: boolean;
  pocket_selected: boolean;
  drilling_selected: boolean;
  perforation_selected: boolean;
  formSubmitted = false;
  subscription: any;
  @Output() submitForm = new EventEmitter();
  @Output() checkValidCalender = new EventEmitter();
  @Input() calenderEditData: any = undefined;
  // @Output() projectSubmitted = new EventEmitter();


  constructor(private _fb: FormBuilder,
    private _httpclient: HttpClient,
    private _quoteService: AsapQuoteService) { }

  ngOnInit() {
    if(this.calenderEditData) {
      this.quoteCreateFormNew = this._fb.group({
        Binding: [this.calenderEditData['quote_details']['Binding'], Validators.required],
        flat_width: [this.calenderEditData['quote_details']['flat_width']],
        flat_height: [this.calenderEditData['quote_details']['flat_height']],
        finish_width: [this.calenderEditData['quote_details']['finish_width'], Validators.required],
        finish_height: [this.calenderEditData['quote_details']['finish_height'], Validators.required],
        cover_flat_width: [this.calenderEditData['quote_details']['cover_flat_width']],
        cover_flat_height: [this.calenderEditData['quote_details']['cover_flat_height']],
        cover_stock: [this.calenderEditData['quote_details']['cover_stock'], Validators.required],
        Other_cover_Stock_Name: [this.calenderEditData['quote_details']['Other_cover_Stock_Name']], // conditionally
        Cover_Sides: [this.calenderEditData['quote_details']['Cover_Sides']],
        Coating: [this.calenderEditData['quote_details']['Coating']],
        Text_Coating: [this.calenderEditData['quote_details']['Text_Coating']],
        notes: [this.calenderEditData['quote_details']['notes']],
        text_coaton: [this.calenderEditData['quote_details']['text_coaton']], // conditionally
        text_notes: [this.calenderEditData['quote_details']['text_notes']],
        text_stock: [this.calenderEditData['quote_details']['text_stock'], Validators.required],
        Text_Sides: [this.calenderEditData['quote_details']['Text_Sides'], Validators.required],
        Other_Text_Stock_Name: [this.calenderEditData['quote_details']['Other_Text_Stock_Name']], // conditionally
        // Number_of_pages: ['', Validators.required],
        number_of_months: [this.calenderEditData['quote_details']['number_of_months'], Validators.required],
        // num_of_pages: [''],
        number_of_pages: [this.calenderEditData['quote_details']['number_of_pages']],
        Flat_size: [this.calenderEditData['quote_details']['Flat_size'], Validators.required],
        Versions: [this.calenderEditData['quote_details']['Versions']],
        Quantity: [this.calenderEditData['quote_details']['Quantity'], Validators.required],
        Pockets: [this.calenderEditData['quote_details']['Pockets']],
        details_pocket: [this.calenderEditData['quote_details']['details_pocket']],
        Drilling: [this.calenderEditData['quote_details']['Drilling'], Validators.required],
        drilling_notes: [this.calenderEditData['quote_details']['drilling_notes']],
        Perforation: [this.calenderEditData['quote_details']['Perforation']],
        perforation_notes: [this.calenderEditData['quote_details']['perforation_notes']],
        additional_quntities: this._fb.group({
          qty1: [this.calenderEditData['quote_details']['additional_quntities']['qty1']],
          qty2: [this.calenderEditData['quote_details']['additional_quntities']['qty2']],
          qty3: [this.calenderEditData['quote_details']['additional_quntities']['qty3']],
          qty4: [this.calenderEditData['quote_details']['additional_quntities']['qty4']],
          qty5: [this.calenderEditData['quote_details']['additional_quntities']['qty5']],
        }),
        additional_comments: [this.calenderEditData['quote_details']['additional_comments']]
      }, {
          // validators: this.quoteCreateFormNew.get('Other_cover_Stock_Name').setValidators(ConditionalValidator.conditionallyRequired());
          validators: [ConditionalValidator.conditionallyRequired]
        });
      
      const selected = this.quoteCreateFormNew.controls.Binding.value;
      if (selected === 'coil' || selected === 'perfect' || selected === 'collate_only') {
        this.no_selection = true;
      } else {
        this.no_selection = false;
      }

      const coverstokselected =  this.quoteCreateFormNew.controls.cover_stock.value;
      if (coverstokselected === 'Other') {
        this.stock_selected = true;
      } else {
        this.stock_selected = false;
      }

      const selectedcoating = this.quoteCreateFormNew.controls.Coating.value;
      if (selectedcoating === 'Gloss_aqueous_both_sides' || selectedcoating === 'Gloss_aqueous_one_side' || selectedcoating === 'UV_Coating') {
        this.coating_selected = true;
      } else {
        this.coating_selected = false;
      }

      const textStokselected = this.quoteCreateFormNew.controls.text_stock.value;
      if (textStokselected === 'Other') {
        this.text_stock_selected = true;
      } else {
        this.text_stock_selected = false;
      }

      const selectednumpages = this.quoteCreateFormNew.controls.number_of_months.value;
      if (selectednumpages === '10' || selectednumpages === '11') {
        this.quoteCreateFormNew.get('Flat_size').setValue('6');
      } else if (selectednumpages === '12' || selectednumpages === '13') {
        this.quoteCreateFormNew.get('Flat_size').setValue('7');
      } else if (selectednumpages === '14' || selectednumpages === '15') {
        this.quoteCreateFormNew.get('Flat_size').setValue('8');
      } else if (selectednumpages === '16' || selectednumpages === '17') {
        this.quoteCreateFormNew.get('Flat_size').setValue('9');
      } else if (selectednumpages === '18' || selectednumpages === '19') {
        this.quoteCreateFormNew.get('Flat_size').setValue('10');
      } else if (selectednumpages === '20' || selectednumpages === '21') {
        this.quoteCreateFormNew.get('Flat_size').setValue('11');
      } else if (selectednumpages === '22' || selectednumpages === '23') {
        this.quoteCreateFormNew.get('Flat_size').setValue('12');
      } else if (selectednumpages === '24' || selectednumpages === '25') {
        this.quoteCreateFormNew.get('Flat_size').setValue('13');
      } else {
        this.quoteCreateFormNew.get('Flat_size').setValue('');
      }

      const selectedtextcoaton = this.quoteCreateFormNew.controls.text_coaton.value;
      if (selectedtextcoaton === 'sides') {
        this.quoteCreateFormNew.get('text_notes').setValue('both sides');
      } else if (selectedtextcoaton === 'front') {
        this.quoteCreateFormNew.get('text_notes').setValue('only on front');
      } else {
        this.quoteCreateFormNew.get('text_notes').setValue('');
      }

    
      const selectedTextCoating = this.quoteCreateFormNew.controls.Text_Coating.value;
      if (selectedTextCoating === 'gloss_aqueous_both_sides' || selectedTextCoating === 'gloss_aqueous_one_side' || selectedTextCoating === 'uV_Coating') {
        this.text_coating_selected = true;
      } else {
        this.text_coating_selected = false;
      }

      const selectedPockets = this.quoteCreateFormNew.controls.Pockets.value;
      if (selectedPockets === 'Yes') {
        this.pocket_selected = true;
      } else {
        this.pocket_selected = false;
      }

      const selectedDrilling = this.quoteCreateFormNew.controls.Drilling.value;
      if (selectedDrilling === 'Yes' || selectedDrilling === 'No') {
        this.drilling_selected = true;
      } else {
        this.drilling_selected = false;
      }

      const selectedPerforation = this.quoteCreateFormNew.controls.Perforation.value;
      if (selectedPerforation === 'yes_straight' || selected === 'yes_angled') {
        this.perforation_selected = true;
      } else {
        this.perforation_selected = false;
      }

      this.submitForm.emit(this.quoteCreateFormNew.value);
    }
    else {
      this.initQuoteForm();
    }
  }

  onProjectFormSubmit = () => {
    this.formSubmitted = true;
    if (this.quoteCreateFormNew.valid) {
      this.checkValidCalender.emit(true)
      this.submitForm.emit(this.quoteCreateFormNew.value);
    }
    else {
      this.checkValidCalender.emit(false)
    }
  }

  checkCalender(data) {
    this.formSubmitted = true;
    if (data === true) {
      if (this.quoteCreateFormNew.valid) {
        this.checkValidCalender.emit(true)
      }
      else {
        this.checkValidCalender.emit(false)
      }
    }
  }

  initQuoteForm = () => {
    this.quoteCreateFormNew = this._fb.group({
      Binding: ['', Validators.required],
      flat_width: [''],
      flat_height: [''],
      finish_width: ['', Validators.required],
      finish_height: ['', Validators.required],
      cover_flat_width: [''],
      cover_flat_height: [''],
      cover_stock: ['', Validators.required],
      Other_cover_Stock_Name: [''], // conditionally
      Cover_Sides: [''],
      Coating: [''],
      Text_Coating: [''],
      notes: [''],
      text_coaton: [''], // conditionally
      text_notes: [''],
      text_stock: ['', Validators.required],
      Text_Sides: ['', Validators.required],
      Other_Text_Stock_Name: [''], // conditionally
      // Number_of_pages: ['', Validators.required],
      number_of_months: ['', Validators.required],
      // num_of_pages: [''],
      number_of_pages: [''],
      Flat_size: ['', Validators.required],
      Versions: [''],
      Quantity: ['', Validators.required],
      Pockets: ['No'],
      details_pocket: [''],
      Drilling: ['', Validators.required],
      drilling_notes: [''],
      Perforation: [''],
      perforation_notes: [''],
      additional_quntities: this._fb.group({
        qty1: [null],
        qty2: [null],
        qty3: [null],
        qty4: [null],
        qty5: [null],
      }),
      additional_comments: ['']
    }, {
        // validators: this.quoteCreateFormNew.get('Other_cover_Stock_Name').setValidators(ConditionalValidator.conditionallyRequired());
        validators: [ConditionalValidator.conditionallyRequired]
      });
  };
  binding_selection(event) {
    const selected = event.target.value;
    if (selected === 'coil' || selected === 'perfect' || selected === 'collate_only') {
      this.no_selection = true;
    } else {
      this.no_selection = false;
    }
  }
  selectInput(event) {
    const selected = event.target.value;
    if (selected === 'Other') {
      this.stock_selected = true;
    } else {
      this.stock_selected = false;
    }
  }
  selectedInput(event) {
    const selected = event.target.value;
    if (selected === 'Gloss_aqueous_both_sides' || selected === 'Gloss_aqueous_one_side' || selected === 'UV_Coating') {
      this.coating_selected = true;
    } else {
      this.coating_selected = false;
    }
  }
  selInput(event) {
    const selected = event.target.value;
    if (selected === 'Other') {
      this.text_stock_selected = true;
    } else {
      this.text_stock_selected = false;
    }
  }
  num_pages(event) {
    const selected = event.target.value;
    if (selected === '10' || selected === '11') {
      this.quoteCreateFormNew.get('Flat_size').setValue('6');
    } else if (selected === '12' || selected === '13') {
      this.quoteCreateFormNew.get('Flat_size').setValue('7');
    } else if (selected === '14' || selected === '15') {
      this.quoteCreateFormNew.get('Flat_size').setValue('8');
    } else if (selected === '16' || selected === '17') {
      this.quoteCreateFormNew.get('Flat_size').setValue('9');
    } else if (selected === '18' || selected === '19') {
      this.quoteCreateFormNew.get('Flat_size').setValue('10');
    } else if (selected === '20' || selected === '21') {
      this.quoteCreateFormNew.get('Flat_size').setValue('11');
    } else if (selected === '22' || selected === '23') {
      this.quoteCreateFormNew.get('Flat_size').setValue('12');
    } else if (selected === '24' || selected === '25') {
      this.quoteCreateFormNew.get('Flat_size').setValue('13');
    } else {
      this.quoteCreateFormNew.get('Flat_size').setValue('');
    }
  }

  text_coat(event) {
    const selected = event.target.value;
    if (selected === 'sides') {
      this.quoteCreateFormNew.get('text_notes').setValue('both sides');
    } else if (selected === 'front') {
      this.quoteCreateFormNew.get('text_notes').setValue('only on front');
    } else {
      this.quoteCreateFormNew.get('text_notes').setValue('');
    }
  }

  selctInput(event) {
    const selected = event.target.value;
    if (selected === 'gloss_aqueous_both_sides' || selected === 'gloss_aqueous_one_side' || selected === 'uV_Coating') {
      this.text_coating_selected = true;
    } else {
      this.text_coating_selected = false;
    }
  }
  extra_pocket(event) {
    const selected = event.target.value;
    if (selected === 'Yes') {
      this.pocket_selected = true;
    } else {
      this.pocket_selected = false;
    }
  }
  Drilling(event) {
    const selected = event.target.value;
    if (selected === 'Yes' || selected === 'No') {
      this.drilling_selected = true;
    } else {
      this.drilling_selected = false;
    }
  }
  perforation(event) {
    const selected = event.target.value;
    if (selected === 'yes_straight' || selected === 'yes_angled') {
      this.perforation_selected = true;
    } else {
      this.perforation_selected = false;
    }
  }

  onSubmit = () => {
    // this.submitForm.emit(this.quoteCreateFormNew.value);
    // console.log(this.bookconstForm.value);
  }
}
