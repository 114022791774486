import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { DataTableService } from '../../../service/datatable.service';
import { CommonService } from '../../../service/common.service';
import { ModalDirective } from 'ngx-bootstrap';
import { Utils } from '../../../utils';
import { PurchaseOrderService } from '../../../service/purchase-order.service';
import { FranchiseService } from '../../../service/franchise.service';
import Swal from 'sweetalert2';
import {AuthenticationService} from '../../../service/authentication.service';

@Component({
  templateUrl: './all-bills.component.html',
  styleUrls: ['./all-bills.component.scss']
})

export class AllBillsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  @ViewChild('billModal') public modal: ModalDirective;
  franchiseData: any = [];
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  currentUser: any = null;
  allOrders: any = [];
  roleUser = '';
  singleBill: any = [];
  status: any = {
    loading: false,
    success: false,
    error: false
  };
  showBillEdit: boolean = false;
  isSalesRep = false;

  constructor(
    private _dtService: DataTableService,
    private authService: AuthenticationService,
    public franchiseService: FranchiseService,
    public csService: CommonService,
    private utils: Utils,
    private _poService: PurchaseOrderService) {
  }

  ngOnInit() {
    this.authService.userData.subscribe((res) => {
      this.currentUser = res['id'];
    });

    this.csService.introType.next('bills_general');
    this.franchiseData = this.franchiseService.franchiseData.getValue();
    this.roleUser = this.franchiseData['role']['original_name'];
  
    if (this.roleUser == 'Accountant' || this.roleUser == 'Admin' || this.roleUser == 'Estimator HOD' || this.roleUser == 'Estimator') {
      this.showBillEdit = true;
    } else if (this.roleUser == 'Sales Rep') {
      this.isSalesRep = true;
    }
    this.getAllOrders();
  }

  getAllOrders = () => {
    this.dtOptions = {
      dom: "<'row'<'col-sm-12 col-md-4'l><'col-sm-12 col-md-4 top'i><'col-sm-12 col-md-4 top'f>>" +
      "<'row'<'col-sm-12'tr>>" +
      "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
      pagingType: 'full_numbers',
      pageLength: 10,
      lengthMenu: [5, 10, 25, 50],
      serverSide: true,
      processing: true,
      language: {
        searchPlaceholder: 'Search...',
        search: ''
      },
      ajax: (dataTablesParameters: any, callback) => {
        this._dtService.getTableData(dataTablesParameters, 'supplier/get-bills', '').subscribe(res => {
          this.allOrders = res['data']['original']['data'];
          if (this.allOrders.length > 0) {
            this.csService.introType.next('bills_general');
          } else {
            this.csService.introType.next('');
          }
          callback({
            recordsTotal: res['data']['original']['recordsTotal'],
            recordsFiltered: res['data']['original']['recordsFiltered'],
            data: []
          });
        });
      },
      order: [],
      columns: [
        { name: 'bill_uid' },
        { name: 'po_uid' },
        { name: 'sales_order_uid' },
        { name: 'due_date' },
        { name: 'supplier_name' },
        { name: 'sales_rep_name' },
        { name: 'amount' },
        { name: 'paid_amount' },
        { name: 'id' },
        { name: 'po_id' },
        { data: null }
      ]
    };
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }

  rerender = (): void => {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  onOpen = (id) => {
    this.singleBill = [];
    this.modal.show();
    this.status = this.utils.showContainers(this.status, 'loading');
    this._poService.getBillDetails(id).subscribe(res => {
      this.singleBill = res['data'];
      this.status = this.utils.showContainers(this.status, 'success');
    });
  }
  
  getCommaSeparetedItems = (items1) => {
    const nameArray = [];
    items1.map(product => {
      nameArray.push(product['item_spec']);
    });
    return nameArray.join(', ');
  }

  getDueAmount = (total, paid) => {
    const due = +total - +paid;
    return due > 0 ? due : 0;
  }

  deleteBill = (id) => {
    Swal.fire({
      title: "Are you sure to delete this bill?",
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      preConfirm: (value) => {
        return this._poService.deleteBill(id).toPromise().then((res) => {
          if (res['data']) {
            this.rerender();
            return true;
          }
        }).catch(() => {
          return true;
        });
      }
    }).then((res) => {
      if (res['value']) {
        this.rerender();
      }
    });
  }
}
