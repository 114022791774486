import { OnInit, OnDestroy, Component, ElementRef, ViewChild, NgZone, Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { AuthenticationService } from '../../service/authentication.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FranchiseService } from '../../service/franchise.service';
import { CommonService } from '../../service/common.service';
import { ChatService } from '../../service/chat.service';
import { RolepermissionService } from '../../service/rolepermission.service';
import { PusherService } from '../../service/pusher.service';
import { Utils } from '../../utils';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationService } from '../../service/notification.service';
import { navItems } from '../../_nav';
import { environment } from '../../../environments/environment';
import { BootController } from '../../../boot-controller';
import { SupplierService } from '../../service/supplier.service';
import * as introJs from 'intro.js/intro.js';
import { introSteps } from '../../../assets/intro-json/intro-steps';
@Component({
  selector: 'app-dashboard',
  templateUrl: './supplier-layout.component.html',
  styleUrls: ['./supplier-layout.component.scss']
})

export class SupplierLayoutComponent implements OnInit, OnDestroy {
  public navItems: any;
  public chatForm: FormGroup;
  public sidebarMinimized = true;
  public isNotSalesRep = false;
  public isChat = false;
  public outSide = false;
  private changes: MutationObserver;
  public element: HTMLElement = document.body;
  public sub = new Subscription();
  public userData = {};
  public searchUser = '';
  public searchProject = '';
  public salesrep = false;
  public franchiseData = {};
  public myNavItems: any = [];
  public userList: any = [];
  public projectList = [];
  public tempProjectList = [];
  public selectedUser = {};
  public logo = '';
  private roleService = null;
  public loaded = false;
  public profileImage: any = '';
  public defaultImage = '';
  public messages: any = [];
  public franchiseId = '';
  public unreadCount: any;
  public selectedProject: any = [];
  public projectUnreadCount = 0;
  public userUnreadCount = 0;
  public franchiseOptions: any = [];
  public selectedFranchise = '';
  canViewProjectChat = false;
  notificationData: any = [];
  loading = false;
  franchiseUrl = '';
  origin = window.location.origin;
  status: any = {
    loading: false,
    success: false,
    error: false
  };
  public imagePath = '';
  public currentIntroSteps = [];
  @ViewChild('users') users: ElementRef;
  @ViewChild('projects') projects: ElementRef;
  @ViewChild('msgContainer') msgContainer: ElementRef;
  @ViewChild('msgScrollBar') msgScrollBar: PerfectScrollbarComponent;
  @ViewChild('chatDiv') chatDiv: ElementRef;
  @ViewChild('chatIcon') chatIcon: ElementRef;

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private _fb: FormBuilder,
    public sanitizer: DomSanitizer,
    private franchiseService: FranchiseService,
    public csService: CommonService,
    private chatService: ChatService,
    private rolePermissionService: RolepermissionService,
    private ngZone: NgZone,
    private pusherService: PusherService,
    private utils: Utils,
    private renderer: Renderer2,
    private activatedRoute: ActivatedRoute,
    private _notifService: NotificationService,
    private _el: ElementRef,
    private supplierService: SupplierService
  ) {
    this.changes = new MutationObserver(mutations => {
      this.sidebarMinimized = document.body.classList.contains(
        'sidebar-minimized'
      );
    });
    this.roleService = this.rolePermissionService;
    this.navItems = JSON.parse(JSON.stringify(navItems));

    this.changes.observe(<Element>this.element, {
      attributes: true
    });
  }

  close = () => {
    this.selectedProject = [];
    this.selectedUser = {};
    this.isChat = false;
  }

  ngOnInit() {
    this.subscribeToIntroType();
    this.initChatForm();
    this.defaultImage = '../../../assets/img/profile_card.png';
    this.imagePath = environment.AWS_PROFILE_PATH;

    this.profileImage = '../../../assets/img/profile.jpg';
    const userDataSubscription = this.authService.userData.subscribe(resData => {
      if (Object.keys(resData).length > 0) {
        this.userData = resData;
        if (this.userData['profile_image']) {
          this.profileImage = environment.AWS_PROFILE_PATH + this.userData['profile_image'];
        } else {
          this.profileImage = '../../../assets/img/profile_card.png';
        }
        this.logo = this.csService.UPLOAD_FILE_AWS('franchise_images', true);
        const elementRef = this.element.getElementsByClassName(
          'navbar-brand-full'
        );
        if (elementRef.length > 0) {
          elementRef.item(0).setAttribute('src', this.logo);
        }
      }
    });
    this.sub.add(userDataSubscription);
    const tempUserData = this.authService.userData.getValue();
    if (Object.keys(tempUserData).length > 0) {

      if (tempUserData['roles'][0]['id'] !== 1) {
        this.pusherNotification();
      }

      this.myNavItems = [];
      this.navItems.forEach((item, key) => {
        const navItem = JSON.parse(JSON.stringify(item));
        if (navItem['data']) {
          if (navItem['data']['onlySupplier']) {
            this.myNavItems.push(navItem);
            if (navItem['children'] && navItem['children'].length > 0) {
              navItem['children'] = [];
              for (let x = 0; x < item['children'].length; x++) {
                if (item['children'][x]['data'] && item['children'][x]['data']['onlySupplier']) {
                  // item['children'][x]['url'] = '/' + this.franchiseId + item['children'][x]['url'];
                  navItem['children'].push(item['children'][x]);
                }
              }
            }
          }
        }
      });
      this.myNavItems = [...this.myNavItems];
    }
    this.franchiseOptions = this.franchiseService.franchiseOptions.getValue();
    this.selectedFranchise = this.franchiseOptions[0]['id'];
    this.getNotifications();
  }

  getNotifications = () => {
    this.supplierService.getNotificationsSupplier().subscribe(
      (res) => {
        this.notificationData = res['data'];
      }
    );
  }

  viewAllNotification = () => {
    this.router.navigate(['/supplier/notification']);
  }

  pusherNotification = () => {

    // for individual chat channel

    // this.pusherService.chatChannel.bind('App\\Events\\PusherChat', res => {
    //   const data = res['data'];

    //   if (Object.keys(this.selectedUser).length === 0) {
    //     this.unreadCount += 1;
    //     if (this.userList.length > 0) {
    //       for (const user of this.userList) {
    //         if (+data['sender_id'] === +user['id']) {
    //           user['unread'] = +user['unread'] + 1;
    //         }
    //       }
    //     }
    //   } else {
    //     if (this.selectedUser['id'] === +data['sender_id']) {
    //       this.messages.push(data);
    //     } else {
    //       if (this.userList.length > 0) {
    //         this.unreadCount += 1;
    //         for (const user of this.userList) {
    //           if (+data['sender_id'] === +user['id']) {
    //             user['unread'] = +user['unread'] + 1;

    //           }
    //         }
    //       }
    //     }
    //     this.scrollToLatestMessage(true);
    //   }
    //   this.sortBy('unread_count');
    // });

    this.pusherService.notificationChannelSupplier.forEach(singleChannel => {
      singleChannel.bind('App\\Events\\NotifyEvent', res => {
        if (res['data']) {
          this.notificationData['data'].unshift(res['data']);
          this.notificationData['unread'] = +this.notificationData['unread'] + 1;

          // if (!('Notification' in window)) {
          //   alert('Web Notification is not supported');
          //   return;
          // }
          // Notification
          //   .requestPermission()
          //   .then(() => {
          //     const _notify = new Notification('AWI Notification',
          //       { body: res['message'], icon: '../../../assets/img/awi_logo.png' }
          //     );
          //     setTimeout(() => {
          //       _notify.close();
          //     }, 5000);
          //   });
        }
      });
    });

    // end

  }

  updateMessageReadProject = (project_id) => {
    this.chatService.updateMessageReadProject(project_id).subscribe(
      (res) => {

      }
    );
  }

  testNotif = () => {
    if (!('Notification' in window)) {
      alert('Web Notification is not supported');
      return;
    }
    Notification
      .requestPermission()
      .then(() => {
        const _notify = new Notification('AWI Notification',
          { body: 'test message', icon: '../../../assets/img/optahub.svg' }
        );
        setTimeout(() => {
          _notify.close();
        }, 5000);
      });
  }

  sortBy(field: string) {
    const users = [...this.userList];
    users.sort((a: any, b: any) => {
      if (a['unread'] && b['unread']) {
        if (a['unread'] > b['unread']) {
          return 1;
        }
        return 0;
      }
      return 0;
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    // this.pusherService.chatChannel.unbind('App\\Events\\PusherChat');
    // this.pusherService.notificationChannelSupplier.unbind('App\\Events\\NotifyEvent');
    this.pusherService.unbindSupplierNotifChannels();
  }

  scrollToLatestMessage(scrollToEndOfConversation = false) {
    if (scrollToEndOfConversation) {
      setTimeout(() => {
        this.msgScrollBar.directiveRef.scrollToBottom(0, 500);
      }, 100);
    }
  }

  chatOpen = (user, index) => {
    this.selectedProject = [];
    if (user['id']) {
      this.chatForm.get('project_id').setValue('');
      if (this.unreadCount > 0) {
        this.unreadCount = this.unreadCount - user['unread'];
      }

      setTimeout(() => {
        user['unread'] = 0;
      }, 200);
      this.chatForm.get('receiver_id').setValue(user['id']);
      this.userList.forEach(key => {
        if (key['id'] === +user['id']) {
          this.selectedUser = key;
        }
      });
      this.status = this.utils.showContainers(this.status, 'loading');
      this.chatService.getMessageOfUser(user['id'], this.selectedFranchise).subscribe(res => {
        this.messages = res['data'];
        this.status = this.utils.showContainers(this.status, 'success');
        this.scrollToLatestMessage(true);
      });
    }
    Object.keys(this.users['nativeElement']['children']).forEach(key => {
      if (index === +key) {
        this.renderer.addClass(this.users['nativeElement']['children'][key], 'active');
      } else {
        this.renderer.removeClass(this.users['nativeElement']['children'][key], 'active');
      }
    });
  }

  openProjectChat = (project, index) => {
    this.selectedUser = {};
    this.selectedProject = [];
    this.selectedProject.push(project);
    this.status = this.utils.showContainers(this.status, 'loading');

    const unread = this.unreadCount - project['unread'];
    this.unreadCount = unread > 0 ? unread : 0;

    const projectUnread = this.projectUnreadCount - project['unread'];
    this.projectUnreadCount = projectUnread > 0 ? projectUnread : 0;

    setTimeout(() => {
      project['unread'] = 0;
    }, 200);

    this.chatService.getMessagesOfProject(project['id'], this.selectedFranchise).subscribe(
      (res) => {
        this.messages = res['data'];
        this.status = this.utils.showContainers(this.status, 'success');
        this.scrollToLatestMessage(true);
        this.chatForm.get('project_id').setValue(project['id']);
      }
    );
    Object.keys(this.projects['nativeElement']['children']).forEach(key => {
      if (index === +key) {
        this.renderer.addClass(this.projects['nativeElement']['children'][key], 'active');
      } else {
        this.renderer.removeClass(this.projects['nativeElement']['children'][key], 'active');
      }
    });
  }

  selectedUserLength = () => {
    return Object.keys(this.selectedUser).length;
  }

  initChatForm = () => {
    this.chatForm = this._fb.group({
      receiver_id: [''],
      message: [''],
      project_id: ['']
    });
  }

  openChat = () => {
    this.searchUser = '';
    this.selectedUser = {};
    this.selectedProject = [];
    this.isChat = !this.isChat;
    // this.getUsersAndProjectsForChat(); hide user chat call
  }

/* hide user chat call
  getUsersAndProjectsForChat = () => {
    this.chatService.getAllUserForChat(this.selectedFranchise).subscribe(res => {
      this.userList = res['data'];
    });
  }
*/

  sendMessage = () => {
    const message = '';
    if (this.chatForm.get('message').value !== null) {
      let postData: any = {};
      const tempMessageString = this.chatForm.get('message').value.trim();
      if (tempMessageString !== '') {
        postData = { ...this.chatForm.value };
      } else {
        return;
      }
      this.chatForm.get('message').setValue('');
      if (postData['project_id']) {
        this.chatService.sendProjectChatMessage(postData, this.selectedFranchise).subscribe(
          (res) => {
            this.messages.push(res['data']);
            this.scrollToLatestMessage(true);
          }
        );
      } else {
        this.chatService.sendMessages(postData, this.selectedFranchise).subscribe(res => {
          this.messages.push(res['data']);
          this.scrollToLatestMessage(true);
        });
      }
    }
  }

  sendMessageOnEnter(event) {
    this.sendMessage();
  }

  logout = () => {
    this.loading = true;
    this.authService.logout().subscribe(res => {
      if (res) {
        this.loading = false;
        localStorage.clear();
        this.ngZone.runOutsideAngular(() => BootController.getbootControl().restart());
        this.router.navigate(['login']);
      }
    }, error => {
      this.loading = false;
      localStorage.clear();
      this.ngZone.runOutsideAngular(() => BootController.getbootControl().restart());
    });
  }

  profile = () => {
    this.router.navigate(['supplier/user-profile']);
  }

  changePassword = () => {
    this.router.navigate(['supplier/change-password']);
  }

  onNotifClick = () => {
    if (+this.notificationData['unread'] > 0) {
      setTimeout(() => {
        this._notifService.updateNotificationSupplier().subscribe(
          (res) => {
            this.notificationData['unread'] = 0;
            this.notificationData['data'].forEach(item => {
              item['is_read'] = 1;
            });
          }
        );
      }, 1000);
    }
  }

  showMessage = (message, user, createdAt) => {
    const createdUser = user['first_name'] + ' ' + user['last_name'];
    return message.replace(createdUser, '<strong>' + createdUser + ' <span>' + createdAt + '</span></strong>');
  }

  redirectToLink = (path) => {
    this.router.navigate([path]);
  }

  onSearchProject = (value: string) => {
    if (value.trim()) {
      let tempProjects = [];
      tempProjects = this.projectList.filter(project => {
        return project['project_name'].toLowerCase().indexOf(value.toLowerCase()) > -1
          || project['project_uid'].toLowerCase().indexOf(value.toLowerCase()) > -1;
      });
      this.tempProjectList = tempProjects;
    } else {
      this.tempProjectList = this.projectList;
    }
  }
  onComponentDestroy = (event) => {
    this.currentIntroSteps = [];
  }
  subscribeToIntroType = () => {
    this.csService.introType.subscribe(
      (type) => {
        this.currentIntroSteps = [];
        if (type && introSteps[type]) {
          this.currentIntroSteps = introSteps[type];
        }
      }
    );
  }
  startIntro() {
    const intro = introJs();
    intro.setOptions({ steps: this.currentIntroSteps });
    intro.start();
  }
}
