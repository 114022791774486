import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../service/authentication.service';
import { RolepermissionService } from '../service/rolepermission.service';
import { DevRolepermissionService } from '../service/DevAdmin/rolepemission.service';
import { FranchiseService } from '../service/franchise.service';
/*
 * @usage Auth Guard check if authenticated or not. If authenticated then
 *        continue otherwise redirect to login.
 */

@Injectable()
export class RolePermissionGuard implements CanActivateChild {
  rolePermisionService: any = null;
  constructor(private _router: Router,
    private authService: AuthenticationService,
    private userRolePermission: RolepermissionService,
    private devRolePermission: DevRolepermissionService,
    private franchiseService: FranchiseService) {
    if (localStorage.getItem('designation') && localStorage.getItem('designation') === 'da') {
      this.rolePermisionService = this.devRolePermission;
    } else {
      this.rolePermisionService = this.userRolePermission;
    }
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
    // console.log('in can activate child');
    if (localStorage.getItem('token')) {
      let userData = this.authService.userData.getValue();
      if (Object.keys(userData).length === 0) {
        return new Promise((resolve, reject) => {
          this.authService.refreshUserdata().then(res => {
            userData = this.authService.userData.getValue();
            if (userData['is_supplier']) {
              this.franchiseService.franchiseOptions.next(userData['franchises']);
              this._router.navigate(['supplier/dashboard']);
              resolve(true);
            } else {
              if (childRoute['data']) {
                if (childRoute['data']['permissions']) {
                  if (this.rolePermisionService.permissions.getValue().length === 0) {
                    return this.rolePermisionService.refreshPermissions().then(perm => {
                      if (this.rolePermisionService.hasAnyPermissions(childRoute['data']['permissions'])) {
                        resolve(true);
                      } else {
                        this._router.navigate(['/', 'dashboard']);
                        reject(true);
                      }
                    });
                  }
                  if (this.rolePermisionService.hasAnyPermissions(childRoute['data']['permissions'])) {
                    resolve(true);
                  } else {
                    this._router.navigate(['/', 'dashboard']);
                    reject(true);
                  }
                } else if (childRoute['data']['onlyAdmin']) {
                  if (localStorage.getItem('designation') !== 'da') {
                    this._router.navigate(['/', 'dashboard']);
                    reject(true);
                  } else {
                    resolve(true);
                  }
                } else {
                  resolve(true);
                }
              } else {
                resolve(true);
              }
            }
          });
        });
      } else {
        return new Promise((resolve, reject) => {
          userData = this.authService.userData.getValue();
          if (userData['is_supplier']) {
            this.franchiseService.franchiseOptions.next(userData['franchises']);
            this._router.navigate(['supplier/dashboard']);
            resolve(true);
          } else {
            if (childRoute['data']) {
              if (childRoute['data']['permissions']) {
                if (this.rolePermisionService.hasAnyPermissions(childRoute['data']['permissions'])) {
                  resolve(true);
                } else {
                  this._router.navigate(['/', 'dashboard']);
                  reject(true);
                }
              } else if (childRoute['data']['onlyAdmin']) {
                if (localStorage.getItem('designation') !== 'da') {
                  this._router.navigate(['/', 'dashboard']);
                  reject(true);
                } else {
                  resolve(true);
                }
              } else {
                resolve(true);
              }
            } else {
              resolve(true);
            }
          }
        });
      }
    } else {
      return new Promise((resolve, reject) => {
        this._router.navigate(['login']);
        resolve(true);
      });
    }
  }
}
