import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable, fromEventPattern } from 'rxjs';
import { CommonService } from '../common.service';

@Injectable({ providedIn: 'root' })
export class PrintProductService {

  categories = new BehaviorSubject([]);
  product = new BehaviorSubject({});

  constructor(private _http: HttpClient, private _cs: CommonService) { }

  getProductCategories = () => {
    if (this.categories.getValue.length > 0) {
      this.categories.next(this.categories.getValue());
    } else {
      this.getProductCategoriesAPI().subscribe((response) => {
        this.categories.next(response['data']);
      });
    }
  }

  getProductCategoriesAPI = (): Observable<any> => {
    return this._http.get(environment.API_PRODUCT_API + 'fetch-all-categories');
  }

  getAllProductsByCategory = (uuid) => {
    return this._http.post(environment.API_PRODUCT_API + 'fetch-products-by-category', { 'cat': uuid });
  }

  getAllOptionsByCategory = (uuid, selectedOptions) => {
    if (selectedOptions && selectedOptions.length > 0) {
      var options = Object.values(selectedOptions)
      return this._http.post(environment.API_PRODUCT_API + 'fetch-options-by-options', { 'cat': uuid, 'option': options });
    }
    return this._http.post(environment.API_PRODUCT_API + 'fetch-options-by-category', { 'cat': uuid, 'option': [] });
  }


  filterProducts = (option_uuid, cat_uuid, search_text) => {
    return this._http.post(environment.API_PRODUCT_API + 'filter-products',
      { 'option': option_uuid, 'cat': cat_uuid, 'search_text': search_text });
  }

  getProductWithOptins = (cat_uuid, product_uuid) => {
    return this._http.post(environment.API_PRODUCT_API + 'fetch-product-with-options', { 'product': product_uuid, 'cat': cat_uuid });
  }

  getProductPrice = (product, cat) => {
    return this._http.post(environment.API_URL + 'print-products/fetch-product-price', { 'product_data': product, 'cat_uuid': cat }, this._cs.setHeader());
  }

  calculateShippingCost = (product_data, address_data) => {
    return this._http.post(environment.API_URL + 'print-products/calculate-shipping-cost', 
    { 'product_data': product_data, 'address_data': address_data }, this._cs.setHeader());
  }
 
  createQuote = (data,estimation) => {
    return this._http.post(environment.API_URL + 'print-products/create-quote', { data: data,estimation }, this._cs.setHeader());
  }
  deleteCompany = (client_id) => {
    return this._http.delete(environment.API_URL + 'contacts/' + client_id, this._cs.setHeader())
  }

}