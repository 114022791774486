import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CommonService } from './common.service';

@Injectable({ providedIn: 'root' })
export class UserService {

  constructor(private _http: HttpClient, private _cs: CommonService) {}

  getUserById = (user_id) => {
    return this._http.post<any>(environment.API_URL + 'users/user', { user_id }, this._cs.setHeader());
  };

  getFranchiseUsersByRole = (role_name, franchise_id = null) => {
    let api = 'users/get-franchise-users-by-role/' + role_name;
    if (franchise_id) {
      api += '/' + franchise_id;
    }
    return this._http.get(environment.API_URL + api, this._cs.setHeader());
  }

  getSupplierById = (id) => {
    return this._http.get(environment.API_URL + 'users/supplier-id/' + id, this._cs.setHeader());
  }

  getCommissionType = () => {
    return this._http.get(environment.API_URL + 'get-commission-types');
  };

  getUserData = (email_username, isSupplier = false) => {
    return this._http.post<any>(environment.API_URL + 'users/by-email-or-username',
      { email_username: email_username, isSupplier: isSupplier }, this._cs.setHeader());
  };

  createSupplier = (supplierData) => {
    return this._http.post<any>(environment.API_URL + 'users/create-supplier', supplierData, this._cs.setHeader());
  }

  createUser = (userData) => {
    return this._http.post<any>(environment.API_URL + 'users/create', userData, this._cs.setHeader());
  };

  updateUser = (userData) => {
    return this._http.post<any>(environment.API_URL + 'users/update', userData, this._cs.setHeader());
  };

  changePassword = (data) => {
    return this._http.post<any>(environment.API_URL + 'users/change-password', data, this._cs.setHeader());
  }

  getAuthUserDetail = () => {
    return this._http.get(environment.API_URL + 'users/auth-user', this._cs.setHeader());
  };

  saveProfileDetail = (profileData) => {
    return this._http.post<any>(environment.API_URL + 'users/auth-user-update', profileData, this._cs.setHeader());
  };

  saveUserContactDetail = (userContactData) => {
    return this._http.post<any>(environment.API_URL + 'contacts/save', userContactData, this._cs.setHeader());
  };

  getContactOptions = () => {
    return this._http.get(environment.API_URL + 'contacts', this._cs.setHeader());
  };

  getUserContactDetailById = (id) => {
    return this._http.get(environment.API_URL + 'contacts/' + id, this._cs.setHeader());
  };

  deleteContactDetail = (id) => {
    return this._http.delete(environment.API_URL + 'contacts/' + id, this._cs.setHeader());
  };

  getUsersOfAllFranchiseByRole = (data) => {
    return this._http.post(environment.API_URL + 'get-users-of-all-franchises-by-role', data, this._cs.setHeader());
  };

  getEstimatorHodList = () => {
    return this._http.get(environment.API_URL + 'users/get-estimator-hod', this._cs.setHeader());
  };

  getSerachSupplierList = (search) => {
    return this._http.post<any>(environment.API_URL + 'users/search-suppliers', { search: search }, this._cs.setHeader());
  };

  getAllUsers = (franchise_id = null) => {
    if (franchise_id) {
      return this._http.get(environment.API_URL + 'users/get-users-hierarchy/'+franchise_id , this._cs.setHeader());
    } else {
      return this._http.get(environment.API_URL + 'users/get-users-hierarchy', this._cs.setHeader());
    }
  };

  getAllNotifications = () => {
    return this._http.get(environment.API_URL + 'notification/get-all', this._cs.setHeader());
  };

  importContactCsv = (filedata) => {
    return this._http.post<any>(environment.API_URL + 'contacts/import', filedata, this._cs.setHeader());
  };

  importStakesMfgCsv = (filedata) => {
    return this._http.post<any>(environment.API_URL + 'apparel-products/import', filedata, this._cs.setHeader());
  };

  createGlobalSupplier = (supplierData) => {
    return this._http.post<any>(environment.API_URL + 'users/create-global-supplier', supplierData, this._cs.setHeader());
  };

  importglobalSupplierCsv = (filedata) => {
    return this._http.post<any>(environment.API_URL + 'globalsupplier/import', filedata, this._cs.setHeader());
  };

  importSupplierCsv = (filedata) => {
    return this._http.post<any>(environment.API_URL + 'supplier/import', filedata, this._cs.setHeader());
  };

  getAllSalesRep = () => {
    return this._http.get(environment.API_URL + 'users/get-franchise-salesrep-users', this._cs.setHeader());
  };

  deleteUserRoleWise = (data) => {
    return this._http.post<any>(environment.API_URL + 'users/delete-user', data, this._cs.setHeader());
  };

  customerBasedOnSalesRep = (sales_rep_id) => {
    return this._http.get<any>(environment.API_URL + 'contacts/sales_rep/'+sales_rep_id, this._cs.setHeader());
  };

  transferUserData = (data) => {
    return this._http.post<any>(environment.API_URL + 'users/transfer-user-data', data, this._cs.setHeader());
  };

  updateUserAsGlobalSupplierByEmail = (data) => {
    return this._http.post(environment.API_URL + 'users/make-supplier-to-global-supplier', data, this._cs.setHeader());
  };
  
  saveContactCompany = (data) => {
    return this._http.post(environment.API_URL + 'contact-company/save', data, this._cs.setHeader());
  }

  getContactCompany = (contact_company_id) => {
    return this._http.get(environment.API_URL + 'contact-company/' + contact_company_id, this._cs.setHeader());
  }

  getContactCompaniesBySalesRep = (sales_rep_id) => {
    return this._http.post(environment.API_URL + 'contact-company', { "sales_rep_id": sales_rep_id }, this._cs.setHeader());
  }
}