import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { DataTableService } from '../../../service/datatable.service';
import { CommonService } from '../../../service/common.service';
import { FranchiseService } from '../../../service/franchise.service';
import Swal from 'sweetalert2';
import { PurchaseOrderService } from '../../../service/purchase-order.service';
import { ToasterService } from 'angular2-toaster';
import {AuthenticationService} from '../../../service/authentication.service';
import * as XLSX from 'xlsx';


@Component({
  templateUrl: './purchase-orders.component.html',
  styleUrls: ['./purchase-orders.component.scss']
})

export class PurchaseOrdersComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  // dtOptions: DataTables.Settings = {};
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject();

  currentUser: any = null;
  allPO: any = [];
  editlink = '../purchase-order/edit-po/';
  roleUser = '';
  isAccountant = false;
  showBillCreate:boolean = false;
  selectedTab = 'Open'; // It can be Open, Completed or All
  franchiseData: any = {};
  isSalesRep = false;
  
  constructor(private _dtService: DataTableService,
    private commonService: CommonService,
    private franchiseService: FranchiseService,
    private authService: AuthenticationService,
    private _poService: PurchaseOrderService,
    private toasterService: ToasterService  
  ) {}

  ngOnInit() {
    this.authService.userData.subscribe((res) => {
      this.currentUser = res['id'];
    });

    this.commonService.introType.next('purchase_orders_general');
    this.franchiseData = this.franchiseService.franchiseData.getValue();
    this.editlink = '/'+this.franchiseData['id']+'/purchase-order/edit-po/';
    
    this.roleUser = this.franchiseData['role']['original_name'];
    if (this.roleUser == 'Accountant' || this.roleUser == 'Admin' || this.roleUser == 'Estimator HOD' || this.roleUser == 'Estimator') {
      this.showBillCreate = true;
    } else if (this.roleUser == 'Sales Rep') {
      this.isSalesRep = true;
    }
    if (this.roleUser === 'Accountant') {
      this.isAccountant = true;
    }
    this.getAllPO();
  }

  getAllPO = () => {
    let that = this;
    this.dtOptions = {
      dom: "<'row'<'col-sm-12 col-md-4'l><'col-sm-12 col-md-4 top'i><'col-sm-12 col-md-4 top'f>>" +
      "<'row'<'col-sm-12'tr>>" +
      "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
      pagingType: 'full_numbers',
      pageLength: 25,
      lengthMenu: [10, 25, 50,100],
      serverSide: true,
      processing: true,
      language: {
        searchPlaceholder: 'Search...',
        search: ''
      },
      ajax: (dataTablesParameters: any, callback) => {
        let type = that.selectedTab;
        let data = {type: type};
        this._dtService.getTableData(dataTablesParameters, 'purchase-order/get-po-users', data).subscribe(res => {
            this.allPO = res['data']['original']['data'];
            callback({
              recordsTotal: res['data']['original']['recordsTotal'],
              recordsFiltered: res['data']['original']['recordsFiltered'],
              data: []
            });
          });
      },
      order: [],
      columns: [
        { name: 'sales_order.sales_order_uid' },
{ name: 'po_created_date' },
        { name: 'po_uid' },
{ name: 'status' },
        { name: 'supplier_name' },
        { name: 'sales_order.project_of_order.sales_rep.first_name' },
        { name: 'linked_bills' },
        { name: 'accountant_status', orderable: false },
        { name: 'status', orderable: false },
        { data: null, searchable: false, orderable: false}
      ]
    };
  }

  changedTab(tabName) {
    this.selectedTab = tabName;
    this.rerender();
  }

  ngAfterViewInit() {
    const configStatus = this.commonService.configStatus.getValue();
    if (Object.keys(configStatus).length === 0) {
      this.commonService.getConfigStatusData().subscribe(res => {
        this.commonService.configStatus.next(res);
        this.dtTrigger.next();
      });
    } else {
      this.dtTrigger.next();
    }
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }

  changePoStatus = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Approve It!',
      showLoaderOnConfirm: true,
      preConfirm: (value) => {
        return this._poService.approvePoAccountant(id).toPromise()
          .then((res) => {
            if (res['message']) {
              this.rerender();
              return true;
            }
          })
          .catch(() => {
            return true;
          });
      },
    }).then((res) => {
    });
  }

  exportToExcel = (exportType) => {
    let type = this.selectedTab;
    this._poService.getPurchaseOrderReport({
      columns: [
        { name: 'sales_order.sales_order_uid' },
        { name: 'po_created_date' },
        { name: 'po_uid' },
        { name: 'status' },
        { name: 'supplier_name' },
        { name: 'sales_order.project_of_order.sales_rep.first_name' },
        { name: 'linked_bills' },
        { name: 'accountant_status', orderable: false },
        { name: 'status', orderable: false },
        { data: null, searchable: false, orderable: false }
      ],
      order: [],
      type: type,
      search: { value: "", regex: false },
      start: 0,
    }).subscribe((res) => {
      let Newdata = (res.data.original.data || [])
      let newJson = [];
      for (let z = 0; z < Newdata.length; z++) {
        newJson[z] = {};
        newJson[z]["Order Number"] = Newdata[z]["sales_order"]["sales_order_uid"];
        newJson[z]["PO Created On"] = Newdata[z]["po_created_date"];
        newJson[z]["PO Number"] = Newdata[z]["po_uid"];
        newJson[z]["Order Status"] = this.commonService.getStatusName(Newdata[z]["status"], 'project_order');
        newJson[z]["Supplier Name"] = Newdata[z]["assigned_supplier"] ? Newdata[z]["assigned_supplier"]["display_name"] : '';
        newJson[z]["Distributor Name"] = Newdata[z]["sales_order"]["project_of_order"] && Newdata[z]["sales_order"]["project_of_order"]["sales_rep"] ? Newdata[z]["sales_order"]["project_of_order"]["sales_rep"]["full_name"] : '';
        newJson[z]["Linked Bills"] = Newdata[z]["linked_bills"] ? Newdata[z]["linked_bills"] : "No Bills Created";
        newJson[z]["Accountant Status"] = this.commonService.getStatusName(Newdata[z]["accountant_approved"], 'purchase_order_status');
        newJson[z]["PO Status"] = this.commonService.getStatusName(Newdata[z]["po_approved"], 'purchase_order_status')
      }
      const worksheet1: XLSX.WorkSheet = XLSX.utils.json_to_sheet(newJson);
      const csvOutput: string = XLSX.utils.sheet_to_csv(worksheet1);
      const workbook: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet1, 'Sheet1');
      if (exportType == 'CSV') {
        XLSX.writeFile(workbook, 'Purchase Order Report ' + type + '.csv')
      } else {
        XLSX.writeFile(workbook, 'Purchase Order Report ' + type + '.xlsx', { bookType: 'xlsx', type: 'buffer' });
      }
    });

  }
      

  rerender = (): void => {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  sendPurchaseOrder = (id) => {
    Swal.fire({
      input: 'textarea',
      inputPlaceholder: 'Type your message here...',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Send it!',
      showLoaderOnConfirm: true,
      preConfirm: (value) => {
        const data = {};
        data['id'] = id;
        data['message'] = value;
        return this._poService.sendPo(data).toPromise()
          .then((res) => {
            if (res['data']) {
              return true;
            }
          })
          .catch(() => {
            return true;
          });
      },
    }).then((res) => {
      if (res['value']) {
        this.rerender();
      }
    });
  }

  deletePO = (id) => {
    Swal.fire({
      title: "Are you sure to delete this purchase order?",
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      preConfirm: (value) => {
        return this._poService.deletePO(id).toPromise().then((res) => {
          if (res['data']) {
            this.rerender();
            return true;
          }
        }).catch((error) => {
          this.toasterService.pop('error', error['error']['message']);
        });
      }
    });
  }
}