
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl, FormArray } from '@angular/forms';
import { CommonService } from '../../../../service/common.service';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { SupplierService } from '../../../../service/supplier.service';
import Swal from 'sweetalert2';
import { SupplierCreditService } from '../../../../service/supplier-credit.service';
import { debug } from 'util';

@Component({
  selector: 'app-pay-multiple-bills',
  templateUrl: 'pay-multiple-bills.component.html',
  styleUrls: ['pay-multiple-bills.component.scss']
})

export class PayMultipleBillsComponent implements OnChanges {
  billsForm: FormGroup;
  api_error = {};
  bills = [];
  credits = [];
  applyAmount = 0;
  creditAmount = 0;
  loading = false;
  creditsDisabled = true;
  @Input() paymentTypes = [];
  @Input() paymentAccount = [];
  @Input() supplier = [];
  @Output() formSaved = new EventEmitter();
  billControls = [];
  creditControls = [];
  requiredCredit = 0; // when bills are being paid using credits only

  constructor(private _fb: FormBuilder,
    public _cs: CommonService,
    private _dp: DatePipe,
    private _supplierService: SupplierService,
    private _supplierCreditService: SupplierCreditService) {
  }

  ngOnChanges(changes: { [property: string]: SimpleChange }) {
    this.getSupplierBills();
  }

  getSupplierBills = () => {
    this.initForm();
    this.loading = true;
    this._supplierService.getAllBillsOfSupplier(this.supplier[0]['id']).subscribe(
      (res) => {
        this.loading = false;
        this.bills = res['data']['bills'];
        this.credits = res['data']['credits'];

        const billArr = <FormArray>this.billsForm.get('all_bills');
        const creditsArr = <FormArray>this.billsForm.get('all_credits');

        this.bills.map(bill => {
          if (bill['bill_payments'].length > 0) {
            if ((+bill['amount'] - +bill['bill_payments'][0]['total_received']) > 0) {
              bill['open_balance'] = (+bill['amount'] - +bill['bill_payments'][0]['total_received']).toFixed(2);
            } else {
              bill['open_balance'] = 0;
            }
          } else {
            bill['open_balance'] = bill['amount'];
          }
          billArr.push(this.initBill(bill));
        });
        this.billControls = this.billsForm.get('all_bills')['controls'];

        this.credits.map(credit => {
          credit['open_balance'] = credit['open_amount'];
          delete credit['open_amount'];
          creditsArr.push(this.initCredit(credit));
        });

        this.creditControls = this.billsForm.get('all_credits')['controls'];
        this.changeCreditsCheckboxStatus();
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  initForm = () => {
    this.billsForm = this._fb.group({
      supplier_id: [this.supplier[0]['id']],
      amount: [''],
      payment_type: [''],
      paid_date: [moment()],
      account_from: [''],
      ref_number: [''],
      comment: [''],
      all_bills: this._fb.array([]),
      all_credits: this._fb.array([])
    });
  }

  initBill = (data = []) => {
    return this._fb.group({
      status: [false],
      bill_id: [data['id']],
      open_balance: [data['open_balance']],
      payment: ['']
    }, {
        validator: this.checkPayment
      });
  }

  initCredit = (data = []) => {
    return this._fb.group({
      status: [false],
      id: [data['id']],
      open_balance: [data['open_balance']],
      payment: ['']
    });
  }

  checkPayment = (control: AbstractControl) => {
    if (control.get('status').value) {
      if (+control.get('payment').value <= 0) {
        control.get('payment').setErrors({ 'min': 'payment should not be less than 1.' });
      } else {
        control.get('payment').setErrors(null);
      }
    } else {
      control.get('payment').setErrors(null);
    }
  }

  onEnterAmount = (value) => {
  }

  onAddPayment = (isChecked, controlGroup) => {
    const payment = +controlGroup.get('payment').value;
    if (payment > controlGroup.get('open_balance').value) {
      controlGroup.get('payment').setValue('');
      controlGroup.get('status').setValue(false);
    } else {
      if (!this.checkIfCreditIsSelected()) {
        if (+payment === 0) {
          controlGroup.get('status').setValue(false);
          // controlGroup.get('payment').setValue('');
          this.calculateCredit();
        } else {
          if (!isChecked) {
            controlGroup.get('status').setValue(true);
          }
          this.calculateBillPayment(controlGroup);
        }
      } else {
        this.evaluateBillAndCreditPayment();
      }
    }
    this.changeCreditsCheckboxStatus();
  }







  // for single invoice under selecting invoice or entering payment for that invoice
  calculateBillPayment = (controlGroup) => {
    const payment = +controlGroup.get('payment').value;
    if (payment === 0) {
      if (+controlGroup.get('open_balance').value > this.creditAmount) {
        controlGroup.get('payment').setValue(this.creditAmount);
      } else {
        controlGroup.get('payment').setValue(+controlGroup.get('open_balance').value);
      }
    } else {
      this.deriveMainAmountFromPayments();
    }
    this.calculateCredit();
  }

  deriveMainAmountFromPayments = () => {
    let totalPayment = 0;
    const amountEntered = +this.billsForm.get('amount').value;
    this.billControls.map((control) => {
      totalPayment += +control.get('payment').value;
    });
    if (totalPayment > amountEntered) {
      this.billsForm.get('amount').setValue(totalPayment);
      this.applyAmount = totalPayment;
    }
  }

  calculateCredit = () => {
    let amount = this.billsForm.get('amount').value;
    if (amount > 0) {
      this.billControls.map(
        (control) => {
          amount -= +control.get('payment').value;
        }
      );

      this.creditAmount = amount;

    } else {
      this.creditAmount = 0;
    }
  }

  // for customer credits

  onCheckCredit = (isChecked, controlGroup) => {
    if (isChecked) {
      controlGroup.get('payment').setValue(controlGroup.get('open_balance').value);
      // this.creditAmount += +controlGroup.get('payment').value
    }
    else {
      // this.creditAmount = this.creditAmount - (+controlGroup.get('payment').value)
      controlGroup.get('payment').setValue('');
    }
    this.commonFunc();
  }

  onChangeAmount() {
    this.commonFunc();
  }

  onChangeBillPayment(status, controlgroup) {
    if (status) {
      if (+controlgroup.get('payment').value > +controlgroup.get('open_balance').value) {
        controlgroup.get('payment').setErrors({ 'min': 'payment should be less than open balance' });
      }
    }
    this.commonFunc();
  }

  commonFunc() {
    this.creditAmount = 0;
    const amountEntered = +this.billsForm.get('amount').value;
    this.creditAmount = this.creditAmount + amountEntered

    let totalPayment = 0;
    this.creditControls.map((control) => {
      if (control.get('status').value) {
        totalPayment += +control.get('payment').value;
      }
    });
    this.creditAmount = this.creditAmount + totalPayment

    let billPayment = 0;

    this.billControls.map((billcontrol) => {
      if (billcontrol.get('status').value) {
        billPayment += +billcontrol.get('payment').value;
      }
    });


    if (this.creditAmount > billPayment) {
      this.creditAmount = this.creditAmount - billPayment
    }
    else if (this.creditAmount <= billPayment) {
      this.creditAmount = 0
    }

    this.applyAmount = billPayment
  }

  onChangeCreditPayment(status, controlgroup) {
    //check credit amount
    if (status) {
      if (+controlgroup.get('payment').value > +controlgroup.get('open_balance').value) {
        controlgroup.get('payment').setErrors({ 'min': 'Credit payment should be less than open balance' });
      }
    }
    this.commonFunc();
  }

  onCheckBill = (isChecked, controlGroup) => {
    const amountEntered = +this.billsForm.get('amount').value;
    if (isChecked) {
      let totalPayment = 0;

      this.creditControls.map((control) => {
        if (control.get('status').value) {
          totalPayment += +control.get('payment').value;
        }
      });

      let displayBillPayment = totalPayment + amountEntered;

      this.billControls.map(
        (control) => {
          if (control.get('status').value) {
            if (displayBillPayment > 0) {
              if (control.get('open_balance').value <= displayBillPayment) {
                let decimalValue = parseFloat(control.get('open_balance').value).toFixed(2)
                control.get('payment').setValue(decimalValue);
                displayBillPayment -= +control.get('open_balance').value
              }
              else if (control.get('open_balance').value > displayBillPayment) {
                control.get('payment').setValue(displayBillPayment.toFixed(2));
                displayBillPayment -= displayBillPayment
              }
            }
          }
        }
      );
      // if(displayBillPayment>0) {
      //   if (controlGroup.get('open_balance').value <= displayBillPayment) {
      //     controlGroup.get('payment').setValue(+controlGroup.get('open_balance').value);
      //     displayBillPayment -= +controlGroup.get('open_balance').value
      //   }
      //   else if (controlGroup.get('open_balance').value > displayBillPayment) {
      //     controlGroup.get('payment').setValue(displayBillPayment);
      //     displayBillPayment -= displayBillPayment
      //   }
      // }


    }
    else {
      controlGroup.get('payment').setValue('');
    }
    this.commonFunc();
  }


  getTotalCreditPaymentAmount = () => {
    let totalCreditPayment = 0;
    this.creditControls.map(
      (control) => {
        if (control.get('status').value) {
          totalCreditPayment += +control.get('payment').value;
        }
      }
    );
    return totalCreditPayment;
  }

  evaluateBillAndCreditPayment = (addVal = null, notAdd = false) => {
    let selectedBillOpenBalance = 0;
    this.billControls.map((control) => {
      if (control.get('status').value) {
        selectedBillOpenBalance += +control.get('open_balance').value;
      }
    });

    let selectedCreditOpenBalance = 0;
    if (addVal && addVal != null) {
      selectedCreditOpenBalance = addVal;
    } else {
      this.creditControls.map((control) => {


        if (control.get('status').value) {
          selectedCreditOpenBalance += +control.get('open_balance').value;
        }
      });
    }





    if (this.billsForm.get('amount')) {

      let newVal;
      if (notAdd) {
        newVal = +this.billsForm.get('amount').value
      }
      else {
        newVal = (+this.billsForm.get('amount').value) + (+selectedCreditOpenBalance)
      }


      this.billsForm.get('amount').setValue(newVal)
      if (newVal > 0) {
        this.applyAmount = newVal;
        this.billControls.map(
          (control) => {
            if (newVal > 0) {
              if (control.get('status').value) {
                if (control.get('payment').value !== ''
                  && +control.get('payment').value > 0
                  && +control.get('payment').value <= newVal) {

                  newVal -= +control.get('payment').value;
                } else {

                  const payment = newVal - +control.get('open_balance').value;
                  if (payment < 0) {
                    control.get('payment').setValue(newVal);
                    newVal = 0;
                  } else {
                    control.get('payment').setValue(+control.get('open_balance').value);
                    newVal -= control.get('open_balance').value;
                  }
                }
              }
            } else {
              control.get('status').setValue(false);
              this.onCheckBill(false, control);
            }
          }
        );
      } else {
        this.billControls.map(
          (control) => {
            // control.get('payment').setValue('');
            control.get('status').setValue(false);
          }
        );
        this.applyAmount = 0;
      }
      this.calculateCredit();
    }
    else {
      this.billsForm.get('amount').setValue(selectedCreditOpenBalance)
    }

    const originalCreditOpenBalance = JSON.parse(JSON.stringify(selectedCreditOpenBalance));

    // this.billsForm.get('amount').setValue(originalCreditOpenBalance)

    let totalBillPaymentAmount = 0;
    this.billControls.map(
      (control) => {
        if (control.get('status').value) {
          if (selectedCreditOpenBalance > 0) {
            if (+control.get('payment').value === 0) {
              if (+control.get('open_balance').value < selectedCreditOpenBalance) {
                control.get('payment').setValue(+control.get('open_balance').value);
                selectedCreditOpenBalance = selectedCreditOpenBalance - +control.get('open_balance').value;
              } else {
                control.get('payment').setValue(selectedCreditOpenBalance);
                selectedCreditOpenBalance = 0;
              }
            } else {
              selectedCreditOpenBalance = selectedCreditOpenBalance - +control.get('payment').value;
            }
          } else {

          }
          totalBillPaymentAmount += +control.get('payment').value;
        }
      }
    );

    this.applyAmount = totalBillPaymentAmount;

    let usedCreditPayment = +originalCreditOpenBalance - selectedCreditOpenBalance;

    this.creditControls.map((control) => {
      if (control.get('status').value) {
        if (usedCreditPayment > 0) {
          if (+control.get('open_balance').value < usedCreditPayment) {
            control.get('payment').setValue(control.get('open_balance').value);
          } else {
            control.get('payment').setValue(usedCreditPayment);
          }
          usedCreditPayment -= +control.get('payment').value;
        } else {


        }
      }
    });
    this.changeCreditsCheckboxStatus();
  }

  applyAmountWhenCreditIsSelected = () => {
    let totalPayment = 0;
    this.creditControls.map((control) => {
      totalPayment += +control.get('payment').value;
    });
    this.applyAmount = totalPayment;
  }


  changeCreditsCheckboxStatus = () => {
    this.creditsDisabled = false;
    this.billControls.map(control => {
      if (control.get('status').value) {
        this.creditsDisabled = control.get('status').value;
      }
    });
    const creditControls = this.billsForm.get('all_credits')['controls'];
    if (!this.creditsDisabled || +this.billsForm.get('amount').value > 0) {
      this.billsForm.get('amount').enable();
      creditControls.map(
        (control) => {

        }
      );
    } else {
      if (this.checkIfCreditIsSelected()) {
        this.billsForm.get('amount').setValue('');
        this.billsForm.get('amount').disable();
      }
      creditControls.map(
        (control) => {
          control.enable();
        }
      );
    }
  }

  checkIfCreditIsSelected = () => {
    let flag = false;
    this.creditControls.forEach(
      (control) => {
        if (control.get('status').value) {
          flag = true;
        }
      }
    );
    return flag;
  }

  onSubmit = () => {
    if (this.billsForm.valid) {
      if (+this.billsForm.get('amount').value === 0 && this.checkIfCreditIsSelected()) {
        this.requiredCredit = 0;
        if (this.applyAmount > +this.getTotalCreditPaymentAmount()) {
          this.requiredCredit = this.applyAmount - +this.getTotalCreditPaymentAmount();
          this.showConfirmPayment(this.requiredCredit);
        } else {
          this.saveFormData();
        }
      }
      else if (+this.billsForm.get('amount').value !== 0 || this.checkIfCreditIsSelected()) {
        this.requiredCredit = 0
        if ((this.checkIfCreditIsSelected() && this.applyAmount > this.creditAmount) || (!this.checkIfCreditIsSelected() && (this.applyAmount > +this.billsForm.get('amount').value))) {
          this.requiredCredit = this.applyAmount - ((+this.getTotalCreditPaymentAmount()) + (+this.billsForm.get('amount').value));
          if (this.requiredCredit > 0) {
            this.showConfirmBillPayment(this.requiredCredit.toFixed(2));
          }
          else {
            this.saveFormData();
          }
        } else {
          this.saveFormData();
        }
      }
      else {
        this.requiredCredit = 0;
        if (+this.applyAmount === +this.creditAmount && +this.applyAmount) {
          this.showConfirmForCredit();
        } else {
          this.saveFormData();
        }
      }
    }
  }

  showConfirmForCredit = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Saving whole amount to supplier\'s credit',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Save To Credits!',
    }).then((res) => {
      if (res['value']) {
        this.saveWholeCredit();
      }
    });
  }

  showConfirmBillPayment = (amount) => {
    Swal.fire({
      //title: 'Are you sure?',
      // text: 'Pay remaining $ ' + amount + ' as invoice amount',
      text: 'You need more $' + amount + ' credit to complete bill payment',
      // type: 'question',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok',
    }).then((res) => {
      if (res['value']) {
        //this.saveDataWithCreditAndAmount();
        // this.saveWholeCredit();
      }
    });
  }

  showConfirmPayment = (amount) => {
    Swal.fire({
      title: 'Are you sure?',
      // text: 'Pay remaining $ ' + amount + ' as invoice amount',
      text: 'Pay remaining $ ' + amount + ' as bill amount ? ',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!',
    }).then((res) => {
      if (res['value']) {
        this.saveDataWithCreditAndAmount();
        // this.saveWholeCredit();
      }
    });
  }


  saveFormData = () => {
    this.loading = true;
    const postData = this.preparePostdata();
    postData['credit_amount'] = this.creditAmount;
    postData['all_bills'] = postData['all_bills'].filter(
      invoice => invoice['status']
    );
    this._supplierService.saveMultipleBillPayment(postData).subscribe(
      (res) => {
        this.loading = false;
        this.formSaved.emit('');
      }, (error) => {
        this.loading = false;
        if (error['error']) {
          this.api_error = error['error']['data'];
        }
      }
    );
  }

  saveWholeCredit = () => {
    this.loading = true;
    // const postData = { ...this.invoicesForm.value };
    const postData = this.preparePostdata();
    postData['total_amount'] = this.creditAmount;
    postData['open_amount'] = this.creditAmount;
    this._supplierCreditService.saveSupplierCredit(postData).subscribe(
      (res) => {
        this.formSaved.emit('');
        this.loading = false;
      }, (error) => {
        if (error['error']) {
          this.api_error = error['error']['data'];
          this.loading = false;
        }
      }
    );
  }

  saveDataWithCreditAndAmount = () => {
    const postData = this.preparePostdata();
    postData['all_bills'] = postData['all_bills'].filter(item => item['status']);
    postData['all_credits'] = postData['all_credits'].filter(item => item['status']);
    if (this.requiredCredit > 0) {
      postData['extra_amount'] = this.requiredCredit;
    }
    this.loading = true;

    this._supplierService.saveBillPaymentAndCredits(postData).subscribe(
      (res) => {
        this.formSaved.emit('');
        this.loading = false;
      }, (error) => {
        if (error['error']) {
          this.api_error = error['error']['data'];
          this.loading = false;
        }
      }
    );
  }

  preparePostdata = () => {
    const postData = { ...this.billsForm.value };
    if (postData['paid_date']) {
      postData['paid_date'] = this._dp.transform(postData['paid_date'], 'y-MM-d');
    }
    return postData;
  }

  // onSubmit1 = () => {
  //   if (this.billsForm.valid) {
  //     let finalAmount = 0;
  //     this.billControls.map(control => {
  //       finalAmount += +control.get('payment').value;
  //     });
  //     this.applyAmount = finalAmount;
  //     this.billsForm.get('amount').setValue(finalAmount);

  //     const postData = { ...this.billsForm.value };
  //     if (postData['paid_date']) {
  //       postData['paid_date'] = this._dp.transform(postData['paid_date'], 'y-MM-d');
  //     }
  //     postData['all_bills'] = postData['all_bills'].filter(
  //       bill => bill['status']
  //     );
  //     this.loading = true;
  //     this._supplierService.saveMultipleBillPayment(postData).subscribe(
  //       (res) => {
  //         this.loading = false;
  //         this.formSaved.emit('');
  //       }, (error) => {
  //         this.loading = false;
  //         if (error['error']) {
  //           this.api_error = error['error']['data'];
  //         }
  //       }
  //     );
  //   }
  // }
}