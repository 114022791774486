import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Subject } from 'rxjs';
import { CommonService } from './common.service';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  public userData = new BehaviorSubject({});
  public userInfo: any = [];

  constructor(private _http: HttpClient, private _cs: CommonService) {
    this.userData.subscribe((res) => {
      this.userInfo = res;
    });
  }

  login = (credential) => {
    return this._http.post<any>(environment.API_URL + 'login', credential);
  }
  changePassword = (email) => {
    return this._http.post<any>(environment.API_URL + 'forgot_password', email);
  }
  getUserdata = () => {
    return this._http.get<any>(environment.API_URL + 'user?token=' + localStorage.getItem('token'));
  }
  getUserFranchiseRole = (franchiseId) => {
    return this._http.get<any>(environment.API_URL + franchiseId + '/user?token' + localStorage.getItem('token'));
  }
  logout = () => {
    return this._http.post<any>(environment.API_URL + 'logout', '');
  }
  refreshUserdata = () => {
    return new Promise((resolve, reject) => {
      this.getUserdata().subscribe(res => {
        if (res) {
          this.userData.next(res.data);
          resolve(true);
        } else {
          resolve(true);
        }
      });
    });
  }
  getUserToken = (remember_token) => {
    return this._http.post<any>(environment.API_URL + 'user_by_token', { remember_token });
  }
  resetPassword = (resetPassswordData) => {
    return this._http.post<any>(environment.API_URL + 'reset_password', resetPassswordData);
  }
  callFunction = () => {
    const data = [];
    data['userEmail'] = '';
    data['userPassword'] = '';
    return this._http.post<any>('https://awi-beta.anythingwithink.com/login', data);
  }
  // getParentUserData = (id) => {
  //   return this._http.post<any>(environment.API_URL + 'users/parent-user', { user_id: id });
  // }


}

