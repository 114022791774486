import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectService } from '../../../../../service/project.service';
import { FranchiseService } from '../../../../../service/franchise.service';
import { CommonService } from '../../../../../service/common.service';
import { DatePipe } from '@angular/common';
import { SalesOrderService } from '../../../../../service/sales-order.service';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap';
import { AppConstants } from '../../../../../constants/app.constants';
import { ToasterService } from 'angular2-toaster';

@Component({
  templateUrl: './invoice-order.component.html',
  styleUrls: ['../../../../../../assets/css/invoice.css',
    './invoice-order.component.scss']
})

export class InvoiceOrderComponent implements OnInit {
  @ViewChild('modal') public modal: ModalDirective;
  orderId = '';
  orderData: any = [];
  // invoiceData: any = { subTotal: 0, tax: 0, shipping: 0, total: 0 };
  invoiceData = {};
  franchiseData: any = [];
  api_error: any = [];
  franchiseLogo = '';
  franchiseId = '';
  invoiceId = '';
  isNotPrintable = false;
  isShipplingDisable = false;
  istaxDisable = false;
  editDueDate = false;
  dueDateForm: FormGroup;
  productForm: FormGroup;
  currentItem = {};
  apiErrors = {};
  productErrors = {};
  numbers: Array<any> = [];
  loading = false;
  invoiceSubtotal = 0;
  totalData = {
    subtotal: '0',
    discount_amount: '0',
    taxable_subtotal: '0',
    tax_amount: '0',
    shipping_charge: '0',
    total_amount: '0'
  };
  isSalesRep = false;
  project_type :any = [];
  due_date :any = ''
  zeroToClientCharge = 0;
  previousUrl: any = null;

  constructor(private activatedRoute: ActivatedRoute,
    private router: Router,
    public commonService: CommonService,
    private projectService: ProjectService,
    private franchiseService: FranchiseService,
    private _orderService: SalesOrderService,
    private _fb: FormBuilder,
    private datePipe: DatePipe,
    private _franchiseService: FranchiseService,
    private toasterService: ToasterService) {
    this.numbers = Array.from({ length: 31 }, (v, k) => k + 1);
  }

  ngOnInit() {
    this.commonService.previousUrl$
    .subscribe((previousUrl: string) => {
        this.previousUrl = previousUrl
    });

    this.initialiseInvoiceData();
    this.commonService.introType.next('sales_order_print');
    this.franchiseId = this.commonService.currentFranchise();
    const franchiseRes = this.franchiseService.franchiseData.getValue();
    this.franchiseData = franchiseRes;
    const logo = this.commonService.UPLOAD_FILE_AWS('franchise_images');
    this.franchiseLogo = logo + (this.franchiseData['print_logo'] ? this.franchiseData['print_logo'] : this.franchiseData['logo']);
    this.activatedRoute.params.subscribe(resId => {
      this.orderId = resId['id'];
      this.getInvoiceData();
    });

    if (franchiseRes) {
      if (franchiseRes['role']['original_name'] === AppConstants['roles']['sales_rep']) {
        this.isSalesRep = true;
      }
    }

    this.dueDateForm = this._fb.group({
      id: [''],
      due_date: [''],
      comment: ['']
    });

    this.productForm = this._fb.group({
      id: [''],
      item_spec: [''],
      item_description: [''],
      turnaround_time: [''],
      shipping_included: [''],
      quantity: [''],
      total_cost: [''],
      markup: [''],
      markup_amount: [''],
      shipping_cost: [''],
      shipping_markup: [''],
      shipping_markup_amount: [''],
      supplier: [''],
      supplier_name: [''],
      supplier_id: [''],
      price: [''],
      price_per_piece: [''],
      tax_applicable: [''],
      tax: [''],
      comment: [''],
      discount_allowed: ['']
    }, {
        validator: this.validateQuantity
      });
  }
  returnStringWithBold = (description) => {
    if(+this.project_type == 8){
    let string =description
    let final = []
    var c = string.split(", ");
    c.map((item) => {
      var char = item.split(":");
      if (char[0] && char[1]) {
        final.push(char[0].bold() +' : '+ char[1] +'<br>')
      }
    })
    return final.join(' ')
  }else{
    return description
  }
}

  initialiseInvoiceData = () => {
    this.invoiceData = { subTotal: 0, tax: 0, shipping: 0, total: 0 };
  }

  validateQuantity = (productForm: FormGroup) => {
    if (this.isSalesRep) {
      if (+productForm.get('quantity').value < +this.currentItem['quantity']) {
        productForm.get('quantity').setErrors({ 'min': 'Quantity should not be lesser than ' + this.currentItem['quantity'] });
      } else {
        productForm.get('quantity').setErrors(null);
      }
    }
  }

  getInvoiceData = () => {
    this.loading = true;
    this._orderService.getOrderInvoice(this.orderId).subscribe(
      (res) => {
        this.loading = false;
        this.initialiseInvoiceData();
        this.orderData = res['data'];
        this.due_date = this.orderData['order_invoice']['due_date'];
        let i =  this.orderData['order_invoice']['due_date'];
        let dueDateArr = i.split('-');
        this.orderData['order_invoice']['due_date'] = new Date(parseInt(dueDateArr[2]), parseInt(dueDateArr[0]) - 1, parseInt(dueDateArr[1]));
        this.invoiceId = this.orderData['order_invoice']['id'];
        this.project_type = res['data']['project_of_order'] ? res['data']['project_of_order']['project_type'] : ''
        const tempOrderData = JSON.parse(JSON.stringify(this.orderData['order_items']));
        const invoiceitems = JSON.parse(JSON.stringify(this.orderData['order_invoice_items']));
        for (const item of invoiceitems){
          if (item.zero_to_client == 1) {
            this.zeroToClientCharge += item.price;
          }
          this.invoiceSubtotal += +item['price'];
        }
        for (const data of tempOrderData) {
          data['price'] = Math.round(data['price']);
          data['tax'] = Math.round((data['tax'] * data['price']) / 100);
          data['shipping_cost'] = Math.round(data['shipping_cost']);
          data['shipping_markup_amount'] = Math.round(data['shipping_markup_amount']);
          data['total'] = Math.round((data['price'] + data['tax'] + data['shipping_cost'] + data['shipping_markup_amount']));
          this.invoiceData['subTotal'] += data['price'];
          this.invoiceData['tax'] += data['tax'];
          this.invoiceData['shipping'] += (data['shipping_cost'] + data['shipping_markup_amount']);
          this.invoiceData['total'] += data['total'];
        }
        Object.keys(this.totalData).map(
          (key) => {
            if (this.orderData[key]) {
              this.totalData[key] = this.orderData[key];
            }
          }
        );
      },
      (error) => {
        this.loading = false;
        if(error['error']['data'] == 'invalid_request') {
          this.toasterService.pop('error', error['error']['message']);
          this.router.navigate(['../../../dashboard'], { relativeTo: this.activatedRoute });
        }
      }
    );
  }

  disabledFileds = () => {
    if (this.productForm.get('shipping_included').value === '1') {
      this.productForm.get('shipping_cost').setValue(0);
      this.isShipplingDisable = true;

    } else {
      this.isShipplingDisable = false;
      this.productForm.get('shipping_cost').setValue('');
    }
  };

  disableTaxFileds = () => {
    if (!+this.productForm.get('tax_applicable').value) {
      this.productForm.get('tax').setValue(0);
      this.istaxDisable = true;
    } else {
      this.istaxDisable = false;
    }
  }

  onPriceChanges = (control: FormGroup, changePerPiece = true) => {
    if (Number(control.get('price').value) > 0) {
      this.validatePriceTotalCost(control);
      const totalCost = (Number(control.get('total_cost').value) > 0 ? Number(control.get('total_cost').value) : 0);
      const markUpAmount = Number(control.get('price').value) - totalCost;
      if (markUpAmount > 0) {
        const markupPer = (markUpAmount * 100) / (totalCost > 0 ? totalCost : 1);
        control.get('markup').setValue(markupPer.toFixed(2));
        control.get('markup_amount').setValue(markUpAmount.toFixed(2));
      } else {
        control.get('markup').setValue(0);
        control.get('markup_amount').setValue(0);
      }
      if (changePerPiece && +control.get('quantity').value > 0) {
        // const totalCost = (+control.get('total_cost').value > 0 ? control.get('total_cost').value : 1);
        const pricePerPiece = Number(control.get('price').value) / +control.get('quantity').value;
        control.get('price_per_piece').setValue(pricePerPiece.toFixed(5));
      }

      // this.onTotalPriceChange(control);
    } else {
      control.get('price_per_piece').setValue('');
      control.get('markup').setValue(0);
      control.get('markup_amount').setValue(0);
    }
  }

  validatePriceTotalCost = (control: FormGroup) => {
    if (Number(control.get('price').value) < Number(control.get('total_cost').value) && !control.get('discount_allowed').value) {
      control.get('price').setErrors({ min: 'price must be greater than or equal to total cost' });
    } else {
      control.get('price').setErrors(null);
    }
  }

  onPricePerPieceChange = (control: FormGroup) => {
    if (+control.get('quantity').value > 0) {
      const price = Number(control.get('price_per_piece').value) * Number(control.get('quantity').value);
      control.get('price').setValue(price.toFixed(2));
      this.onPriceChanges(control, false);
    } else {
      control.get('price').setValue(0);
      control.get('total_cost').setValue(0);
      control.get('markup_amount').setValue(0);
    }
  }

  onMarkupChange = (control: FormGroup, markupPercentChange = true) => {
    if (!markupPercentChange || Number(control.get('markup').value) > 0) {
      if (markupPercentChange) {
        const markupAmount = (Number(control.get('markup').value) * Number(control.get('total_cost').value) / 100);
        control.get('markup_amount').setValue(markupAmount.toFixed(2));
      } else {
        const markupPer = ((Number(control.get('markup_amount').value) * 100) / Number(control.get('total_cost').value));
        control.get('markup').setValue(markupPer.toFixed(2));
      }

      const finalPrice = ((control.get('markup').value) *
        (Number(control.get('total_cost').value)) / 100) + (Number(control.get('total_cost').value));
      control.get('price').setValue(finalPrice.toFixed(2));

    } else {
      control.get('markup_amount').setValue('');
      control.get('price').setValue(Number(control.get('total_cost').value));
    }

    if (+control.get('quantity').value !== 0) {
      const price_per_piece = Number(control.get('price').value) / Number(control.get('quantity').value);
      control.get('price_per_piece').setValue(price_per_piece.toFixed(5));
    } else {
      control.get('price_per_piece').setValue('');
    }
  }

  onTotalPriceChange = (control: FormGroup) => {
    if (control.get('markup').value > 0) {
      const markupAmount = ((+control.get('markup').value) * (+control.get('total_cost').value) / 100);
      control.get('markup_amount').setValue(markupAmount.toFixed(2));
      const finalPrice = (markupAmount + (+control.get('total_cost').value));
      control.get('price').setValue(finalPrice.toFixed(2));
      if (+control.get('quantity').value > 0) {
        // const totalCost = (+control.get('total_cost').value > 0 ? control.get('total_cost').value : 1);
        const pricePerPiece = +control.get('price').value / +control.get('quantity').value;
        control.get('price_per_piece').setValue(pricePerPiece.toFixed(5));
      }
    } else {
      const price_per_piece = control.get('total_cost').value / +control.get('quantity').value;
      control.get('price').setValue(Number((+control.get('total_cost').value)).toFixed(2));
      if (+control.get('quantity').value > 0) {
        control.get('price_per_piece').setValue(Number(+price_per_piece).toFixed(5));
      }
    }

    if (+control.get('quantity').value === 0) {
      if (+control.get('markup').value > 1) {
        const markup = ((+control.get('markup').value) *
          (+control.get('total_cost').value) / 100) + (+control.get('total_cost').value);
        control.get('price').setValue(Number(+markup).toFixed(2));
      } else {
        control.get('price').setValue(Number(+control.get('total_cost').value).toFixed(2));
        control.get('price_per_piece').setValue('');
      }
    }

    if (+control.get('total_cost').value === 0) {
      control.get('price_per_piece').setValue('');
      control.get('price').setValue('');
    }
  }

  onQuantityChanges = (control: FormGroup) => {
    if ((Number)(control.get('price').value) > 0 && (Number)(control.get('quantity').value) > 0) {
      const price_per_piece = +control.get('price').value / +control.get('quantity').value;
      control.get('price_per_piece').setValue(Number(price_per_piece).toFixed(5));
    } else {
      control.get('price_per_piece').setValue('');
    }
  }

  onShippingMarkup = (control: FormGroup) => {
    if (control.get('shipping_markup').value > 0 && control.get('shipping_cost').value > 0) {
      const amount = (Number(control.get('shipping_cost').value) * Number(control.get('shipping_markup').value)) / 100;
      control.get('shipping_markup_amount').setValue(amount.toFixed(2));
    } else {
      control.get('shipping_markup_amount').setValue(0);
    }
  }

  onShippingAmount = (control: FormGroup) => {
    if (control.get('shipping_markup_amount').value > 0 && control.get('shipping_cost').value > 0) {
      const newMarkup = ((Number(control.get('shipping_markup_amount').value) * 100) / Number(control.get('shipping_cost').value));
      control.get('shipping_markup').setValue(newMarkup.toFixed(2));
    } else {
      control.get('shipping_markup').setValue(0);
    }
  }

  onShippingCost = (control: FormGroup) => {
    if ((Number)(control.get('shipping_markup').value) > 0 && control.get('shipping_cost').value > 0) {
      const amount = (Number(control.get('shipping_cost').value) * Number(control.get('shipping_markup').value)) / 100;
      control.get('shipping_markup_amount').setValue(amount.toFixed(2));
    } else {
      control.get('shipping_markup').setValue(0);
      control.get('shipping_markup_amount').setValue(0);
    }
  }

  deriveTotalAmounts = () => {
    let subTotal = 0;
    let totalOfTaxItems = 0;
    let discountAmount = 0;
    let taxableSubtotal = 0;
    let taxAmount = 0;
    let shippingCharge = 0;

    let products = [];
    if (this.productForm.get('id').value) {
      products.push(this.productForm.value);
      this.orderData['order_items'].map(product => {
        if (+product['id'] !== this.productForm.get('id').value) {
          products.push(product);
        }
      });
    } else {
      products = JSON.parse(JSON.stringify(this.orderData['order_items']));
    }


    if (products.length > 0) {
      // calculate subtotal, shipping charge and subtotal of tax allowed items
      products.map((item) => {
        if (item['tax_applicable']) {
          totalOfTaxItems += +item['price'];
        }
        subTotal += +item['price'];
        if (+item['shipping_included'] === 0) {
          shippingCharge += (+item['shipping_cost'] + +item['shipping_markup_amount']);
        }
      });
      // end

      // calculate discount and taxable amounts
      this.totalData['subtotal'] = subTotal.toFixed(2);
      this.totalData['shipping_charge'] = shippingCharge.toFixed(2);
      const discountValue = +this.orderData['discount_value'];
      if (discountValue > 0) {
        if (+this.orderData['discount_type'] === 1) {
          // calculate by percent
          discountAmount = (discountValue * subTotal) / 100;
        } else {
          // calculate by value
          discountAmount = discountValue;
        }
      }

      if (totalOfTaxItems > 0) {
        // taxableSubtotal = totalOfTaxItems - discountAmount;
        const taxable = (totalOfTaxItems * discountAmount) / subTotal;
        taxableSubtotal = totalOfTaxItems - taxable;
      }

      this.totalData['discount_amount'] = discountAmount.toFixed(2);
      this.totalData['taxable_subtotal'] = taxableSubtotal > 0 ? taxableSubtotal.toFixed(2) : '0.00';
      // end

      // calculate tax amount by rate
      if (this.orderData['tax_type_id'] && this.orderData['tax_type']) {
        taxAmount = (taxableSubtotal * +this.orderData['tax_type']['rate']) / 100;
      }
      this.totalData['tax_amount'] = taxAmount.toFixed(2);
      // end

      // calculate total amount
      const totalAmount = subTotal - discountAmount + taxAmount + shippingCharge;
      this.totalData['total_amount'] = totalAmount.toFixed(2);
      // end
    }
  }

  print(): void {
    this.isNotPrintable = true;
    let printContents, popupWin;
    printContents = document.getElementById('invoice').innerHTML;
    popupWin = window.open('', 'self', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Invoice</title>
          <link rel="stylesheet" href="'../../../../../../assets/css/invoice-new.css">
        </head>
    <body onload="window.print();window.close()" style="font-family: 'Karla', sans-serif;">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }
  validateAndDownload = () => {
    this.orderData['order_invoice']['due_date'] = this.due_date
    const postData = this.orderData;
    postData['invoice_data'] = this.invoiceData;
    postData['franchise_logo'] = this.franchiseLogo;
    postData['total_data'] = this.totalData;
    this.loading = true;
    this.projectService.downloadInvoicePdf(postData).subscribe(
      (res: any) => {
        this.loading = false;
        this.api_error = {};
        const blobData = new Blob([res], { type: 'application/octet-stream' });
        const a = document.createElement('a');
        a.href = URL.createObjectURL(blobData);
        a.download = postData['order_invoice']['invoice_uid'] + '_Invoice.pdf';
        document.body.appendChild(a);
        a.click();
      },
      (error) => {
        this.loading = false;
        if (error['error']['data']) {
          this.api_error = error['error']['data'];
        }
      }
    );
  }

  back = () => {
    this.router.navigateByUrl(this.previousUrl || '/'+this.franchiseId+'/sales-order');
  }

  saveDueDate = () => {
    const postData = { ...this.dueDateForm.value };
    if (postData['due_date']) {
      postData['due_date'] = this.datePipe.transform(postData['due_date'], 'y-MM-d');
    }
    this.loading = true;
    this._orderService.updateInvoiceData(postData).subscribe(
      (res) => {
        this.loading = false;
        this.apiErrors = {};
        this.editDueDate = !this.editDueDate;
        this.dueDateForm.reset();
        this.getInvoiceData();
      },
      (error) => {
        this.loading = false;
        if (error['error']) {
          this.apiErrors = error['error']['data'];
        }
      }
    );
  }

  onEditDate = () => {
    this.editDueDate = !this.editDueDate;
    if (this.editDueDate) {
      this.apiErrors = {};
      this.dueDateForm.get('id').setValue(this.orderData['order_invoice']['id']);
      const dateArr = this.orderData['order_invoice']['due_date'].split('-');
      this.dueDateForm.get('due_date').setValue(new Date(dateArr[2], dateArr[1] - 1, dateArr[0]));
    }
  }

  updateProduct = () => {
    if (this.productForm.valid) {
      this.deriveTotalAmounts();
      this.productForm.get('shipping_included').enable();
      const updateProductData = { ...this.productForm.value };
      if (this.isSalesRep) {
        Object.keys(updateProductData).map(key => {
          if (['comment', 'price_per_piece', 'quantity', 'supplier', 'supplier_id', 'supplier_name'].indexOf(key) < 0) {
            updateProductData[key] = this.currentItem[key];
          }
        });
      }
      updateProductData['invoice_id'] = this.invoiceId;
      updateProductData['project_order_id'] = this.orderId;
      updateProductData['total_data'] = this.totalData;

      this._orderService.updateOrderInvoice(updateProductData).subscribe(res => {
        if (res['data']) {
          this.getInvoiceData();
          this.modal.hide();
        }
      }, error => {
        if (error['error']) {
          if (this.isSalesRep) {
            this.productForm.get('shipping_included').disable();
          }
          this.api_error = error['error']['data'];
        }
      });
    }
  }

  onOpenModel = (itemData) => {
    this.currentItem = JSON.parse(JSON.stringify(itemData));
    Object.keys(this.productForm.value).forEach((element) => {
      if (element === 'supplier') {
        if (this.currentItem['supplier_name']) {
          this.productForm.get(element).setValue(this.currentItem['supplier_name']);
        } else {
          this.productForm.get(element).setValue(this.currentItem['estimation_supplier']['first_name']
            + ' ' + this.currentItem['estimation_supplier']['last_name']);
        }

      } else {
        this.productForm.get(element).setValue(this.currentItem[element]);
      }
    });
    this.productForm.get('comment').setValue('');
    if (this.isSalesRep) {
      this.productForm.get('shipping_included').disable();
    }
    this.modal.show();
  }

  onCloseModel = () => {
    this.productForm.reset();
    this.productErrors = {};
  }
}