import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';
import { CommonService } from './common.service';


@Injectable({ providedIn: 'root' })
export class ChatService {

  constructor(private _http: HttpClient, private _cs: CommonService) {

  }
  getAllUserForChat = (franchiseId = '') => {
    return this._http.get(environment.API_URL + 'chat/get-users', this._cs.setHeader(franchiseId));
  }
  getAllProjectList = (salesrep, franchiseId = '') => {
    return this._http.post(environment.API_URL + 'chat/get-projects', { salesRep: salesrep }, this._cs.setHeader(franchiseId));
  }
  getMessageOfUser = (id, franchiseId = '') => {
    return this._http.get(environment.API_URL + 'chat/user-messages/' + id, this._cs.setHeader(franchiseId));
  }

  getMessagesOfProject = (projectId, franchiseId = '') => {
    return this._http.get(environment.API_URL + 'chat/get-project-messages/' + projectId, this._cs.setHeader(franchiseId));
  }

  sendProjectChatMessage = (data, franchiseId = '') => {
    return this._http.post(environment.API_URL + 'chat/send-project-message', data, this._cs.setHeader(franchiseId));
  }

  updateMessageReadProject = (project_id) => {
    return this._http.get(environment.API_URL + 'chat/read-project-messages/' + project_id, this._cs.setHeader());
  }

  sendMessages = (data, franchiseId = '') => {
    return this._http.post(environment.API_URL + 'chat/send-messages', data, this._cs.setHeader(franchiseId));
  }
}
