import { OnInit, Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../service/authentication.service';
import { AppConstants } from '../../constants/app.constants';
import { CommonService } from '../../service/common.service';

@Component({
  // template: '<p>email redirect</p>'
  templateUrl: './email-redirect.component.html'
})

export class EmailRedirectComponent implements OnInit {
  userRole = '';
  actionId = '';
  actionId2 = '';
  franchiseId = '';
  loading = false;
  message = '';
  constructor(private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private authService: AuthenticationService,
    public commonService: CommonService) {

  }

  ngOnInit() {
    this.message = 'loading ...';
    this.loading = true;
    this._activatedRoute.queryParams.subscribe(
      (res) => {
        this.actionId = res['action_id'];
        this.franchiseId = res['franchise_id'];
        if (res['action_id2']) {
          this.actionId2 = res['action_id2'];
        }
        this.authService.getUserFranchiseRole(res['franchise_id']).subscribe(
          (userData) => {
            if (userData['data']['original_name']) {
              // this.userRole = userData['data']['original_name'];
              this.userRole = userData['data']['original_name'].toLowerCase().replace(' ', '_');
              this.checkEventType(res['event_type']);
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
    );
  }

  checkEventType = (eventType) => {
    const links = [];
    switch (eventType) {
      // case 'project_create':
      // case 'project_update':
      case 'project':
        links['admin'] = '/project/view/' + this.actionId;
        links['estimator'] = '/project/view/' + this.actionId;
        links['estimator_hod'] = '/project/view/' + this.actionId;
        links['sales_rep'] = '/project/view/' + this.actionId;
        this.checkUserRole(links);
        break;

      // case 'estimation_create':
      // case 'estimation_update':
      // case 'estimation_approve':
      case 'estimation':
        links['admin'] = '/project/estimate-view/' + this.actionId;
        links['estimator'] = '/project/estimate-view/' + this.actionId;
        links['estimator_hod'] = '/project/estimate-view/' + this.actionId;
        links['sales_rep'] = '/project/estimate-view/' + this.actionId;
        this.checkUserRole(links);
        break;

      // case 'direct_est_create':
      // case 'direct_est_update':
      case 'direct_est':
        links['admin'] = '/direct-estimation/view/' + this.actionId;
        links['estimator'] = '/direct-estimation/view/' + this.actionId;
        links['estimator_hod'] = '/direct-estimation/view/' + this.actionId;
        links['sales_rep'] = '/direct-estimation/view/' + this.actionId;
        this.checkUserRole(links);
        break;


      // case 'so_create':
      // case 'so_update':
      // case 'so_status':
      case 'sales_order':
        links['admin'] = '/sales-order/view/' + this.actionId;
        links['estimator'] = '/sales-order/view/' + this.actionId;
        links['estimator_hod'] = '/sales-order/view/' + this.actionId;
        links['sales_rep'] = '/sales-order/view/' + this.actionId;
        links['accountant'] = '/sales-order/view/' + this.actionId;
        this.checkUserRole(links);
        break;

      case 'invoice_sent':
        links['admin'] = '/sales-order/print-invoice/' + this.actionId;
        links['estimator'] = '/sales-order/print-invoice/' + this.actionId;
        links['estimator_hod'] = '/sales-order/print-invoice/' + this.actionId;
        links['sales_rep'] = '/sales-order/print-invoice/' + this.actionId;
        this.checkUserRole(links);
        break;

      case 'bid_request':
        links['admin'] = '/project/project-bid/' + this.actionId;
        links['estimator'] = '/project/project-bid/' + this.actionId;
        links['estimator_hod'] = '/project/project-bid/' + this.actionId;
        links['supplier'] = '/supplier/' + this.franchiseId + '/supplier-project/supplier-bid/' + this.actionId2;
        this.checkUserRole(links);
        break;

      // case 'bid_placed':
      // case 'bid_approve':

      // case 'bid_activity':
      case 'bid_placed':
        links['admin'] = '/project/bid-view/' + this.actionId;
        links['estimator'] = '/project/bid-view/' + this.actionId;
        links['estimator_hod'] = '/project/bid-view/' + this.actionId;
        this.checkUserRole(links);
        break;

      case 'bid_approve':
        links['admin'] = '/project/bid-view/' + this.actionId;
        links['estimator'] = '/project/bid-view/' + this.actionId;
        links['estimator_hod'] = '/project/bid-view/' + this.actionId;
        links['supplier'] = '/supplier/' + this.franchiseId + '/supplier-project/view/' + this.actionId2;
        this.checkUserRole(links);
        break;

      case 'po_create':
      case 'bill_create':
        links['admin'] = '/purchase-order/view/' + this.actionId;
        links['estimator'] = '/purchase-order/view/' + this.actionId;
        links['estimator_hod'] = '/purchase-order/view/' + this.actionId;
        links['supplier'] = '/supplier/purchase-order/' + this.franchiseId + '/view/' + this.actionId;
        links['accountant'] = '/purchase-order/view/' + this.actionId;
        this.checkUserRole(links);
        break;
        
        case 'contact':
          links['admin'] = '/contacts/view/' + this.actionId;
          links['sales_rep'] = '/contacts/view/' + this.actionId;
          links['estimator_hod'] = '/contacts/view/' + this.actionId;
          links['accountant'] = '/contacts/view/' + this.actionId;
          this.checkUserRole(links);
        break;
    }
  }

  checkUserRole = (links) => {
    if (links[this.userRole]) {
      this.loading = false;
      this.message = 'Redirecting ...';
      if (this.userRole === 'supplier') {
        this._router.navigate([links[this.userRole]]);
      } else {
        this._router.navigate(['/' + this.franchiseId + links[this.userRole]]);
      }
    } else {
      this.loading = false;
      this.message = 'You are not allowed to access this resource';
    }
    // switch (this.userRole) {
    //   case AppConstants['roles']['sales_rep']:
    //     this._router.navigate(['/' + this.franchiseId + links['sales_rep']]);
    //     break;
    //   case AppConstants['roles']['estimator']:
    //     this._router.navigate(['/' + this.franchiseId + links['estimator']]);
    //     break;
    //   case AppConstants['roles']['admin']:
    //     this._router.navigate(['/' + this.franchiseId + links['admin']]);
    //     break;
    //   case AppConstants['roles']['estimator_hod']:
    //     this._router.navigate(['/' + this.franchiseId + links['estimator_hod']]);
    //     break;
    //   case AppConstants['roles']['supplier']:
    //     this._router.navigate([links['supplier']]);
    //     break;
    //   case AppConstants['roles']['accountant']:
    //     this._router.navigate(['/' + this.franchiseId + links['accountant']]);
    //     break;
    // }
  }

}
