import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DevRolepermissionService {
  permissionCategory = new BehaviorSubject({});
  permissions = new BehaviorSubject([]);
  unread = new BehaviorSubject({});
  notificationData = new BehaviorSubject({});
  projectsData = new BehaviorSubject({});

  permissionWithCategory = new BehaviorSubject([]);

  constructor(private _http: HttpClient) {
  }

  hasAllPermissions = (checkPermissions) => {
    checkPermissions = checkPermissions.split(',');
    const permissions = this.permissions.getValue();
    const result = checkPermissions.filter(function (checkPermission) {
      return permissions.indexOf(checkPermission) > -1;
    });
    return result.length === checkPermissions.length;
  };

  hasAnyPermissions = (checkPermissions) => {
    checkPermissions = checkPermissions.split(',');
    const permissions = this.permissions.getValue();
    let result = checkPermissions.filter(function (checkPermission) {
      return permissions.indexOf(checkPermission) > -1;
    });
    return result.length > 0;
  };

  hasPermission = (checkPermission: string) => {
    const permissions = this.permissions.getValue();
    return permissions.indexOf(checkPermission) > -1;
  };

  getPermissionCategory = () => {
    return this._http.get<any>(environment.API_URL + 'developer-admin/permission-categories/all');
  };

  savePermissionCategory = permissionCategory => {
    return this._http.post<any>(
      environment.API_URL + 'developer-admin/permission-categories/save',
      permissionCategory
    );
  };

  savePermission = permission => {
    return this._http.post<any>(
      environment.API_URL + 'developer-admin/permissions/save',
      permission
    );
  };

  getAllPermissionWithCategories = (franchise_id) => {
    return this._http.post<any>(environment.API_URL + 'developer-admin/permission-with-categories', { 'franchise_id': franchise_id });
  };

  saveRolePermissionData = (rolePermissiondata) => {
    return this._http.post<any>(
      environment.API_URL + 'developer-admin/roles/permissions/save',
      { rolePermissiondata: rolePermissiondata, franchise_id: rolePermissiondata['franchise_id'] }
    );
  };

  saveRoleData = roleName => {
    return this._http.post<any>(environment.API_URL + 'developer-admin/role/save', {
      name: roleName['name'],
      franchise_id: roleName['franchise_id']
    });
  };

  getPermissions = () => {
    return this._http.get<any>(environment.API_URL + 'franchise/permissions');
  };
  getDeveloperAdminPermission = () => {
    return this._http.get<any>(
      environment.API_URL + 'developer-admin/user-permissions'
    );
  };

  refreshPermissions = () => {
    return new Promise((resolve, reject) => {
      this.getDeveloperAdminPermission().subscribe(res => {
        if (res) {
          this.permissions.next(res.data);
          resolve(true);
        }
      });
    });
  };
}
