import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { CommonService } from './common.service';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  constructor(private _http: HttpClient, private _cs: CommonService) { }

  updateNotifications = () => {
    return this._http.get(environment.API_URL + 'notification/update-notifications', this._cs.setHeader());
  }

  updateNotificationSupplier = () => {
    return this._http.get(environment.API_URL + 'supplier-global/update-notifications');
  }
}
