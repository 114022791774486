import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { LocationStrategy, PathLocationStrategy, DatePipe, DecimalPipe } from "@angular/common";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { PERFECT_SCROLLBAR_CONFIG } from "ngx-perfect-scrollbar";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = { suppressScrollX: true };
import { AppComponent } from "./app.component";

// Import containers
import { DefaultLayoutComponent, SupplierLayoutComponent } from "./containers";

import * as $ from "jquery";

const APP_CONTAINERS = [DefaultLayoutComponent, SupplierLayoutComponent];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from "@coreui/angular";

// Import routing module
import { AppRoutingModule } from "./app.routing";

// Import 3rd party components
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
// import { CurrencyMaskModule } from "ng2-currency-mask";
import { TabsModule } from "ngx-bootstrap/tabs";
import { ChartsModule } from "ng2-charts/ng2-charts";
import { SharedModule } from "./shared.module";
// import { TextMaskModule } from 'angular2-text-mask'; // test
import { ToasterModule } from "angular2-toaster";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { InterceptorService } from "./interceptor/interceptor.service";
import { AuthGuard } from "./guard/auth.guard";
import { RolePermissionGuard } from "./guard/rolepermission.guard";
import { AuthenticationService } from "./service/authentication.service";
import { AuthenticationModule } from "./views/authentication/authentication.module";
import { RolepermissionService } from "./service/rolepermission.service";
import { DevRolepermissionService as DevRolePermission } from "./service/DevAdmin/rolepemission.service";
import { DevFranchiseService } from "./service/DevAdmin/franchise.service";
import { DataTableService } from "./service/datatable.service";
import { FranchiseService } from "./service/franchise.service";
import { FranchiseSelectionComponent } from "./views/franchise-selection/franchise-selection.component";
import { DevUserService } from "./service/DevAdmin/user.service";
import { UserService } from "./service/user.service";
import { ProjectService } from "./service/project.service";
import { ApparealService } from "./service/appareal.service";
import { SupplierService } from "./service/supplier.service";
import { PrintProductService } from "./service/print-products/print-products.service";
import { CommonService } from "./service/common.service";
import { HttpCancelService } from "./service/httpCancle.service";
import { HTTPCancleInterceptor } from "./interceptor/http-cancle-interceptor.service";
import { SalesOrderService } from "./service/sales-order.service";
import { PurchaseOrderService } from "./service/purchase-order.service";
import { AccountingService } from "./service/accounting.service";
import { ReportService } from "./service/reports.service";
import { ChatService } from "./service/chat.service";
import { searchFilterPipe } from "./pipes/searchfilter.pipe";
import { PusherService } from "./service/pusher.service";
import { NotificationService } from "./service/notification.service";
import { BsDatepickerModule } from "ngx-bootstrap";
import { ExportService } from "./service/export.service";
import { UserChangePasswordComponent } from "./views/user-change-password/user-change-password.cmponent";
// import { DateFilterPipe } from "./pipes/datefilter.pipe";
// import { AutocompleteLibModule } from "angular-ng-autocomplete";
import { SupplierAuthGuard } from "./guard/supplier-auth.guard";
import { SupplierChildGuard } from "./guard/supplier-child.guard";
import { NotFoundComponent } from "./views/404/not-found.component";
import { DashboardService } from "./service/dashboard.service";
import { EmailRedirectComponent } from "./views/email-redirect/email-redirect.component";
import { EmailRedirectGuard } from "./guard/email-redirect.guard";
import { AsapQuoteService } from "./service/asap-quote.service";
import { PublicQuoteComponent } from "./views/sage/public-quote/public-quote.component";
// import { QuoteFormsComponent } from "./views/quote-forms/quote-forms.component";
// import { FormFieldsComponent } from "./views/quote-forms/form-fields/form-fields.component";
// import { CalendarComponent } from "./views/quote-forms/calendar/calendar.component";
// import { BookletComponent } from "./views/quote-forms/booklet/booklet.component";
import { TaxRatesService } from "./service/tax-rates.service";
import { ContactCreditService } from "./service/contact-credit.service";
import { SupplierCreditService } from "./service/supplier-credit.service";
import { PublicEstimationComponent } from "./views/custom-project/custom-project-estimates/public-estimation/public-estimation.component";
import { ProofPublicComponent } from "./views/custom-project/custom-project-estimates/proof-public/proof-public.component";
import { SupplierPublicEstimationComponent } from "./views/custom-project/custom-project-estimates/supplier-public-estimates/supplier-public-estimation.component";
import { DigitalProposalReviewComponent } from "./views/custom-project/custom-project-estimates/digital-review/digital-proposal-review.component";
import { SiteAuditPublicComponent } from "./views/custom-project/custom-project-estimates/site-public/site-audit-public.component";
import { DigitalProposalPublicComponent } from "./views/custom-project/custom-project-estimates/digital-proposal/digital-proposal-public.component";
import { SupplierDigitalPublicEstimatesComponent } from "./views/custom-project/custom-project-estimates/supplier-digital-public-estimates/supplier-digital-public-estimates.component";
import { PublicOrderPaymentComponent } from "./views/custom-project/custom-project-estimates/public-order-payment/public-order-payment.component";
// import { SupplierLayoutComponent } from './containers/supplier-layout/supplier-layout.component';
import { GlobalSupplierComponent } from "./views/global-supplier/global-supplier.component";
import { GlobalProjectReportComponent } from "./views/global-project-report/global-project-report.component";
import { GlobalInvoiceReportComponent } from "./views/global-invoice-report/global-invoice-report.component";
import { GlobalMarginReportComponent } from "./views/global-margin-report/global-margin-report.component";
import { OrderShippingChargeComponent } from "./views/order-shipping-charge/order-shipping-charge.component";

// import { CurrencyPipe } from '@angular/common';
import { NgxCurrencyInputModule } from "ngx-currency-input";
import { NgCircleProgressModule } from "ng-circle-progress";
import { CustomRouteReuseStrategy } from "./CustomRouteReuseStrategy";
import { RouteReuseStrategy } from "@angular/router";
import { CompanyService } from "./service/company.service";
import { PrintProductContactService } from "./service/print-products/contact.service";
import { PriceListComponent } from './views/price-list/price-list.component';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    NgxCurrencyInputModule,
    NgCircleProgressModule.forRoot({
      // set defaults here
      // radius: 100,
      // outerStrokeWidth: 16,
      // innerStrokeWidth: 8,
      // outerStrokeColor: "#78C000",
      // innerStrokeColor: "#C7E596",
      // animationDuration: 300,
      radius: 100,
      outerStrokeWidth: 9,
      innerStrokeWidth: 8,
      outerStrokeColor: "#2b78d7",
      innerStrokeColor: "#2b78d7",
      animation: true,
      titleFontSize: "32",
      unitsFontSize: "32",
      showInnerStroke: false,
      subtitleFontSize: "30",
      animationDuration: 300,
      backgroundOpacity: 1,
      backgroundColor: "#ffffff",
      renderOnClick: false,
      space: 9,
    }),
    AppBreadcrumbModule.forRoot(),
    BsDatepickerModule.forRoot(),
    // TextMaskModule,
    // CurrencyMaskModule,
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ToasterModule.forRoot(),
    ChartsModule,
    SharedModule,
    AuthenticationModule,
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    FranchiseSelectionComponent,
    UserChangePasswordComponent,
    NotFoundComponent,
    EmailRedirectComponent,
    PublicQuoteComponent,
    PublicEstimationComponent,
    ProofPublicComponent,
    SupplierPublicEstimationComponent,
    SiteAuditPublicComponent,
    DigitalProposalReviewComponent,
    DigitalProposalPublicComponent,
    SupplierDigitalPublicEstimatesComponent,
    PublicOrderPaymentComponent,
    GlobalSupplierComponent,
    GlobalProjectReportComponent,
    GlobalInvoiceReportComponent,
    GlobalMarginReportComponent,
    OrderShippingChargeComponent,
    // QuoteFormsComponent,
    // FormFieldsComponent,
    // CalendarComponent,
    // BookletComponent,
    searchFilterPipe,
    PriceListComponent,
  ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStrategy,
    },
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HTTPCancleInterceptor,
      multi: true,
    },
    AuthGuard,
    SupplierAuthGuard,
    SupplierChildGuard,
    // CurrencyPipe,
    RolePermissionGuard,
    EmailRedirectGuard,
    AuthenticationService,
    RolepermissionService,
    DataTableService,
    SupplierService,
    PusherService,
    FranchiseService,
    ReportService,
    ChatService,
    ExportService,
    DevRolePermission,
    DevFranchiseService,
    DevUserService,
    UserService,
    ProjectService,
    ApparealService,
    PurchaseOrderService,
    PrintProductService,
    CommonService,
    SalesOrderService,
    AccountingService,
    DatePipe,
    DecimalPipe,
    HttpCancelService,
    NotificationService,
    DashboardService,
    AsapQuoteService,
    TaxRatesService,
    ContactCreditService,
    SupplierCreditService,
    CompanyService,
    PrintProductContactService
  ],
  exports: [BsDatepickerModule],
  bootstrap: [AppComponent],
})
export class AppModule {}