import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { Overlay } from '@angular/cdk/overlay';
import { DatePipe } from '@angular/common';
@Injectable({ providedIn: 'root' })
export class CommonService {
  // Global variable to use accross the application
  INSTANT_QUOTE_CLIENT_ID_FROM_PROJECT_CREATION = null;
  INSTANT_QUOTE_SALES_REP_ID_FROM_PROJECT_CREATION = null;
  INSTANT_QUOTE_ESTIMATOR_ID_FROM_PROJECT_CREATION = null;

  configStatus = new BehaviorSubject({});
  urlArray: any = [];
  imagePath = '';
  imagePreview: any = '';
  public config = {
    animationType: ngxLoadingAnimationTypes.chasingDots,
    primaryColour: '#007AFF',
    secondaryColour: '#74798c',
    tertiaryColour: '#007AFF', backdropBorderRadius: '3px'
  };
  introType = new BehaviorSubject('');

  constructor(private _http: HttpClient,
    private _router: Router,
    private overlay: Overlay,
    private datePipe: DatePipe) {
  }

  checkImageTypeAws = (art, folder_name, is_default = false, isPrintProduct = false, franchiseId = '') => {
    if (isPrintProduct) {
      //  this.imagePath = this.UPLOAD_PRINTPRODUCT_ARTWORK();
    } else {
      this.imagePath = this.UPLOAD_FILE_AWS(folder_name, false, franchiseId);
    }
    if (art.split('.').pop() === 'jpg' || art.split('.').pop() === 'JPEG' || art.split('.').pop() === 'jpeg'
      || art.split('.').pop() === 'png' || art.split('.').pop() === 'PNG') {
      this.imagePreview = this.imagePath + art;
      return this.imagePreview;
    } else if (art.split('.').pop() === 'pdf') {
      this.imagePreview = '../../../../assets/img/pdf.jpg';
      return this.imagePreview;
    } else if (art.split('.').pop() === 'doc' || art.split('.').pop() === 'docx') {
      this.imagePreview = '../../../../assets/img/doc.jpg';
      return this.imagePreview;
    } else {
      this.imagePreview = '../../../../assets/img/file.png';
      return this.imagePreview;
    }
  }

  getConfigStatusData = () => {
    return this._http.get(environment.API_URL + 'get-status', this.setHeader());
  }

  removeProuctIds(){
    localStorage.removeItem('products');
  }

  setHeader = (franchiseId = '') => {
    if (franchiseId) {
      return { headers: { 'awi-franchise-id': franchiseId.toString() } };
    } else {
      const url = window.location.pathname;
      this.urlArray = url.split('/');
      const franchises = JSON.parse(localStorage.getItem('franchise_ids'));
      if (franchises) {
        if (franchises.length > 1) {
          if (!isNaN(+this.urlArray[1]) && franchises.includes(+this.urlArray[1])) {
            return { headers: { 'awi-franchise-id': this.urlArray[1].toString() } };
          }
        } else if (franchises.length === 1) {
          return { headers: { 'awi-franchise-id': franchises[0].toString() } };
        } else {
          this._router.navigate(['account-selection']);
        }
      }
    }
  }

  setexportHeader = (franchiseId = '') => {
    const url = window.location.pathname;
    this.urlArray = url.split('/');

    if (franchiseId) {
      return {
        headers: { 'awi-franchise-id': franchiseId },
        responseType: 'blob' as 'blob'
      };
    } else {
      const franchises = JSON.parse(localStorage.getItem('franchise_ids'));
      if (franchises) {
        if (franchises.length > 1) {
          if (!isNaN(+this.urlArray[1]) && franchises.includes(+this.urlArray[1])) {
            return { headers: { 'awi-franchise-id': this.urlArray[1].toString() }, responseType: 'blob' as 'blob' };
          }
        } else if (franchises.length === 1) {
          return {
            headers: { 'awi-franchise-id': franchises[0].toString() },
            responseType: 'blob' as 'blob'
          };
        } else {
          this._router.navigate(['account-selection']);
        }
      }
    }
  }


  getUrlTillFranchise = () => {
    const mainUrl = window.location.origin;
    const url = window.location.pathname;
    this.urlArray = url.split('/');
    const franchises = JSON.parse(localStorage.getItem('franchise_ids'));
    if (franchises) {
      if (franchises.length > 1) {
        if (!isNaN(+this.urlArray[1]) && franchises.includes(+this.urlArray[1])) {
          return mainUrl + '/' + this.urlArray[1] + '/';
        }
      } else if (franchises.length === 1) {
        return mainUrl + '/' + franchises[0] + '/';
      } else {
        this._router.navigate(['account-selection']);
      }
    }
  }

  currentFranchise = () => {
    const url = window.location.pathname;
    this.urlArray = url.split('/');
    if (!isNaN(+this.urlArray[1])) {
      return this.urlArray[1];
    }
    return '';
  }

  getStatusClass = (number = 1, type = 'default') => {
    const statusData = this.configStatus.getValue();
    if (statusData[type] === 'default') {
      return 'default';
    } else {
      if (statusData && statusData[type] && statusData[type][number]) {
        return statusData[type][number]['cssClass'];
      }
    }
    return 'default';
  }

  getStatusName = (number = 1, type = '') => {
    const statusData = this.configStatus.getValue();
    if (type) {
      if (statusData[type]) {
        if (statusData[type][number]) {
          return statusData[type][number]['name'];
        } else {
          return '';
        }
      } else {
        return '';
      }
    }
    return '';
  }

  getByType = (type) => {
    const statusData = this.configStatus.getValue();
    return statusData[type];
  }

  getOrderStatus = (type) => {
    const status = {};
    const statusData = this.configStatus.getValue();
    const orderStatus = statusData[type];
    Object.keys(orderStatus).forEach(key => {
      status[key] = orderStatus[key].name;
    });
    return status;
  }

  UPLOAD_FILE_AWS(folder_name, isDefault = false, franchiseId = '') {
    if (franchiseId) {
      return environment.AWS_FILE_PATH + franchiseId + '/' + folder_name + '/';
    } else {
      const currentPath = window.location.pathname;
      const urlArr = currentPath.split('/');
      if (localStorage.getItem('franchise_ids') && !isDefault && !isNaN(+urlArr[1])) {
        return environment.AWS_FILE_PATH + urlArr[1] + '/' + folder_name + '/';
      } else {
        return environment.STATIC_IMAGE;
      }
    }
  }

  getLogoUrl = (ext) => {
    if (ext === 'jpg' || ext === 'JPEG' || ext === 'jpeg' || ext === 'png' || ext === 'PNG') {
      return false;
    } else if (ext === 'pdf') {
      return '../../../../assets/img/pdf.jpg';
    } else if (ext === 'doc' || ext === 'docx') {
      return '../../../../assets/img/doc.jpg';
    } else {
      return '../../../../assets/img/file.png';
    }
  }

  getUniqueId = (id: any, prefix) => {
    const pre = prefix.toUpperCase();
    if (id <= 9) {
      return pre + '00' + id;
    } else if (id <= 99) {
      return pre + '0' + id;
    } else {
      return pre + id;
    }
  }

  getFormatedDate = (date) => {
    let newDate: any = new Date(date);
    if (isNaN(newDate.getTime())) {
      const dateArr = date.split('-');
      newDate = new Date(dateArr[2], dateArr[1] - 1, dateArr[0]);
    }
    return this.datePipe.transform(newDate, 'MM-dd-yyyy');
  }
  /** Conevrt value in decimal point */
  getDecimalPointValue = (amount) => {
    var regex = /,/g;
    let convertedValue = null;
    if (amount != null) {
      amount = amount.toString();
      const amountValue = parseFloat(amount.replace(regex, ''));
      convertedValue = (amountValue).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }
    return convertedValue;
  }
  showProgress = (p) => {
    if (p.loaded && p.total) {
      var progress = 1
      progress = Math.round(p.loaded / p.total * 100);
      return progress;
      // this.percentageBarShow();
    }
  }
  percentageBarShow = () => {
    $(function () {
      $(".progress").each(function () {
        var value: any = $(this).attr('id');
        var left: any = $(this).find('.progress-left .progress-bar');
        var right: any = $(this).find('.progress-right .progress-bar');
        if (+value > 0) {
          if (+value <= 50) {
            right.css('transform', 'rotate(' + percentageToDegrees(+value) + 'deg)')
          } else {
            right.css('transform', 'rotate(180deg)')
            left.css('transform', 'rotate(' + percentageToDegrees(+value - 50) + 'deg)')
          }
        }
      })
      function percentageToDegrees(percentage) {
        return +percentage / 100 * 360
      }
    });
  }

  toFindDuplicatesOfArray = (arra1) => {
    var object = {};
    var result = [];

    arra1.forEach(function (item) {
      if (!object[item])
        object[item] = 0;
      object[item] += 1;
    })

    for (var prop in object) {
      if (object[prop] >= 2) {
        result.push(prop);
      }
    }

    return result;
  }

  // checkImageType = (art, isPrintProduct = false) => {
  //   if (isPrintProduct) {
  //     this.imagePath = this.UPLOAD_PRINTPRODUCT_ARTWORK();
  //   } else {
  //     this.imagePath = this.UPLOAD_FILE();
  //   }
  //   if (art.split('.').pop() === 'jpg' || art.split('.').pop() === 'JPEG' || art.split('.').pop() === 'jpeg'
  //     || art.split('.').pop() === 'png' || art.split('.').pop() === 'PNG') {
  //     this.imagePreview = this.imagePath + art;
  //     return this.imagePreview;
  //   } else if (art.split('.').pop() === 'pdf') {
  //     this.imagePreview = '../../../../assets/img/pdf.jpg';
  //     return this.imagePreview;
  //   } else if (art.split('.').pop() === 'doc' || art.split('.').pop() === 'docx') {
  //     this.imagePreview = '../../../../assets/img/doc.jpg';
  //     return this.imagePreview;
  //   } else {
  //     this.imagePreview = '../../../../assets/img/file.png';
  //     return this.imagePreview;
  //   }
  // }


  // UPLOAD_PRINTPRODUCT_ARTWORK(isDefault = false) {
  //   const currentPath = window.location.pathname;
  //   const urlArr = currentPath.split('/');
  //   if (localStorage.getItem('franchise_ids') && !isDefault && !isNaN(+urlArr[1])) {
  //     return environment.API_URL + urlArr[1] + '/print_product_order_artwork/';
  //   }
  // }


  // UPLOAD_FILE(isDefault = false) {
  //   const currentPath = window.location.pathname;
  //   const urlArr = currentPath.split('/');
  //   if (localStorage.getItem('franchise_ids') && !isDefault && !isNaN(+urlArr[1])) {
  //     return environment.API_URL + urlArr[1] + '/images/';
  //   } else {
  //     return environment.STATIC_IMAGE;
  //   }
  // }

  private previousUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public previousUrl$: Observable<string> = this.previousUrl.asObservable();
  setPreviousUrl(previousUrl: string) {
    console.log(previousUrl);
    this.previousUrl.next(previousUrl);
    if (previousUrl
      && !previousUrl.includes('/login')
      && !previousUrl.includes('/dashboard')
      && !previousUrl.includes('/promotional/product-details')
      && !previousUrl.includes('/promotional/products')) {
      console.log("removing selected products as navigation is out of product.")
      localStorage.removeItem('products');
    }

  }

  partially_shipped_status = (project) => {
    var partialShipped = false;
    try {
      let project_status = project.order_of_project['status']
      let order_items = project.order_of_project['order_items']
      if (project_status == 7 || project_status == 15) {
        let with_tracking_number = 0;
        for (var i = 0; i < order_items.length; i++) {
          if (order_items[i].tracking_number) {
            with_tracking_number = with_tracking_number + 1;
          }
        }
        if(with_tracking_number  < order_items.length){
          return "(Partial(" + with_tracking_number + "/" + order_items.length + "))";
        }
      }
    } catch (error) {
      console.log("Error in checking isPartialShipped");
    }
    return '';
  }


}
