import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { CommonService } from './common.service';

@Injectable({ providedIn: 'root' })
export class TaxRatesService {
  constructor(private _http: HttpClient, private _cs: CommonService) {
  }

  saveTax = (data) => {
    return this._http.post(environment.API_URL + 'tax/save', data, this._cs.setHeader());
  }

  getTax = (id) => {
    return this._http.get(environment.API_URL + 'tax/get-tax/' + id, this._cs.setHeader());
  }

  changeTaxStatus = (data) => {
    return this._http.post(environment.API_URL + 'tax/change-tax-status', data, this._cs.setHeader());
  }

  getTaxOptions = () => {
    return this._http.get(environment.API_URL + 'tax/get-tax-options', this._cs.setHeader());
  }

}
