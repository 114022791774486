import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ValidationService } from '../../service/validation.service';

@Component({
  selector: 'app-validation-error',
  templateUrl: './validator.component.html',
  styleUrls: ['./validator.component.scss']
})
export class ValidatorComponent {
  @Input('control') control: FormControl;
  @Input('controlName') controlName: string;
  @Input('formSelector') formSelector: any;

  constructor() {

  }

  getValidatoinMessage = () => {
    for (const property in this.control.errors) {
      if (this.control.errors.hasOwnProperty(property) && this.formSelector.submitted) {
        return ValidationService.getValidatorErrorMessage(property, this.control.value, this.controlName);
      }
    }
    return false;
  }
}
