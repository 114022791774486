import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../../../service/authentication.service';

@Component({
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
    resetpasswordForm: FormGroup;
    token: any;
    api_error = {};
    successMessage = false;

    constructor(private _fb: FormBuilder, private activatedRoute: ActivatedRoute,
                private router: Router,
                private authService: AuthenticationService) {
    }

    ngOnInit() {
        this.activatedRoute.params.subscribe((res) => {
            if (res['token']) {
                this.authService.getUserToken(res['token']).subscribe(resData => {
                    if (resData['data']) {
                        this.token = res['token'];
                    }
                }, error => {
                    if (error['error']) {
                        this.router.navigate(['/login']);
                    }

                });
            }
        });
        this.resetpasswordForm = this._fb.group({
            password: ['', [Validators.required]],
            confirm_password: ['', [Validators.required]]
        });
    }

    resetPassword = () => {
        if (this.token && this.resetpasswordForm.valid) {
            const resetPassworddata = {};
            resetPassworddata['password'] = this.resetpasswordForm.value['password'];
            resetPassworddata['password_confirmation'] = this.resetpasswordForm.value['confirm_password'];
            resetPassworddata['remember_token'] = this.token;
            this.authService.resetPassword(resetPassworddata).subscribe(res => {
                this.successMessage = true;
            }, error => {
                if (error['error']) {
                    this.api_error = error['error']['data'];
                }
            });
        }
    }
}
