import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';
import { AuthGuard } from './guard/auth.guard';
import { RolePermissionGuard } from './guard/rolepermission.guard';
import { LoginComponent } from './views/authentication/login/login.component';
import { ForgotPasswordComponent } from './views/authentication/forgot-password/forgot-password.component';
import { FranchiseSelectionComponent } from './views/franchise-selection/franchise-selection.component';
import { ResetPasswordComponent } from './views/authentication/reset-password/reset-password.component';
import { PurchaseOrdersComponent } from './views/accounting/purchase-orders/purchase-orders.component';
import { CreateReceivableComponent } from './views/accounting/receivables/create-receivable/create-receivable.component';
import { AllBillsComponent } from './views/accounting/all-bills/all-bills.component';
import { CreatePayableComponent } from './views/accounting/payables/create-payable/create-payable.component';
import { UserChangePasswordComponent } from './views/user-change-password/user-change-password.cmponent';
import { SupplierAuthGuard } from './guard/supplier-auth.guard';
import { SupplierLayoutComponent } from './containers/supplier-layout/supplier-layout.component';
import { SupplierChildGuard } from './guard/supplier-child.guard';
import { EmailRedirectComponent } from './views/email-redirect/email-redirect.component';
import { EmailRedirectGuard } from './guard/email-redirect.guard';
import { PublicQuoteComponent } from './views/sage/public-quote/public-quote.component';
import { PublicEstimationComponent } from './views/custom-project/custom-project-estimates/public-estimation/public-estimation.component';
import { ProofPublicComponent } from './views/custom-project/custom-project-estimates/proof-public/proof-public.component';
import { PublicOrderPaymentComponent } from './views/custom-project/custom-project-estimates/public-order-payment/public-order-payment.component';
import { SupplierPublicEstimationComponent } from './views/custom-project/custom-project-estimates/supplier-public-estimates/supplier-public-estimation.component';
import {SiteAuditPublicComponent} from './views/custom-project/custom-project-estimates/site-public/site-audit-public.component';
import {DigitalProposalPublicComponent} from './views/custom-project/custom-project-estimates/digital-proposal/digital-proposal-public.component';
import {SupplierDigitalPublicEstimatesComponent} from './views/custom-project/custom-project-estimates/supplier-digital-public-estimates/supplier-digital-public-estimates.component';
import {DigitalProposalReviewComponent} from './views/custom-project/custom-project-estimates/digital-review/digital-proposal-review.component';

import { SupplierBillComponent } from './views/supplier/supplier-bill/supplier-bill.component';
import { GlobalSupplierComponent } from './views/global-supplier/global-supplier.component';
import { GlobalProjectReportComponent } from './views/global-project-report/global-project-report.component';
import { GlobalInvoiceReportComponent } from './views/global-invoice-report/global-invoice-report.component';
import { GlobalMarginReportComponent } from './views/global-margin-report/global-margin-report.component';

import { ProjectHistoryComponent } from './views/project-history/project-history.component';
import { OrderShippingChargeComponent } from './views/order-shipping-charge/order-shipping-charge.component';
import { PriceListComponent } from './views/price-list/price-list.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'email-redirect',
    component: EmailRedirectComponent,
    canActivate: [EmailRedirectGuard]
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'account-selection', //franchise-selection
    component: FranchiseSelectionComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'franchise Selection',
    }
  },
  {
    path: 'global-supplier',
    component: GlobalSupplierComponent,
  },
  {
    path: 'global-project-report',
    component: GlobalProjectReportComponent,
  },
  {
    path: 'global-invoice-report',
    component: GlobalInvoiceReportComponent,
  },
  {
    path: 'global-margin-report',
    component: GlobalMarginReportComponent,
  },
  {
    path: 'reset/:token',
    component: ResetPasswordComponent,
  },
  {
    path: ':franchise_id/:quote_id/custom-quote',
    component: PublicQuoteComponent,
    data: {
      title: 'franchise Selection',
    }
  },
  {
    path: ':franchise_id/:estimation_id/custom-estimation',
    component: PublicEstimationComponent,
    data: {
      title: 'franchise Selection',
    }
  },
  {
    path: ':franchise_id/:order_id/:item_id/proof',
    component: ProofPublicComponent,
    data: {
      title: 'franchise Selection',
    }
  },
  {
    path: ':franchise_id/:order_id/payment',     // public order payment
    component: PublicOrderPaymentComponent,
    data: {
      title: 'franchise Selection',
    }
  },
  {
    path: ':franchise_id/:project_id/:supplier_id/supplier-estimation/:project_supplier_id',
    component: SupplierPublicEstimationComponent,
    data: {
      title: 'franchise Selection',
    }
  },
  {
    path: ':franchise_id/:id/:sales_rep_id/site-audit',
    component: SiteAuditPublicComponent,
    data: {
      title: 'franchise Selection',
    }
  },
  {
    path: ':frenchiseId/:proposalid/:salesrep/proposal-request',
    component : DigitalProposalPublicComponent,
    data: {
      title:'franchise Selection'
    }
  },
  {
    path: ':frenchiseId/:proposal/review-proposal',
    component : DigitalProposalReviewComponent,
    data: {
      title:'franchise Selection'
    }
  },
  {
    path: ':franchise_id/:project_id/:supplier_id/supplier-digital-estimation',
    component: SupplierDigitalPublicEstimatesComponent,
    data: {
      title: 'franchise Selection',
    }
  },
  // only for supplier
  {
    path: 'supplier',
    redirectTo: 'supplier/dashboard',
    pathMatch: 'full'
  },
  {
    path: 'supplier',
    component: SupplierLayoutComponent,
    canActivate: [SupplierAuthGuard],

    data: {
      title: 'Home',
    },
    children: [
      {
        path: 'dashboard',
        canActivateChild: [SupplierChildGuard],
        loadChildren: './views/dashboard/dashboard.module#DashboardModule',
        data: {
          onlySupplier: true
        }
      },
      {
        path: 'purchase-order',
        canActivateChild: [SupplierChildGuard],
        // canActivate: [SupplierAuthGuard],
        // data: {
        //   onlySupplier: true
        // },
        loadChildren: './views/purchase-order/purchase-order.module#PurchaseOrderModule',
      },
      {
        path: '',
        canActivateChild: [SupplierChildGuard],
        // canActivate: [SupplierAuthGuard],
        // data: {
        //   onlySupplier: true
        // },
        loadChildren: './views/supplier/supplier.module#SupplierModule',
      },
      {
        path: 'user-profile',
        canActivateChild: [SupplierChildGuard],
        // data: {
        //   onlySupplier: true
        // },
        loadChildren: './views/user-profile/user-profile.module#UserProfileModule',
      },
      {
        path: 'change-password',
        canActivateChild: [SupplierChildGuard],
        // data: {
        //   onlySupplier: true
        // },
        component: UserChangePasswordComponent
      },
      {
        path: 'notification',
        canActivateChild: [SupplierChildGuard],
        loadChildren: './views/notification/notification.module#NotificationModule',
      },
    ]
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    canActivateChild: [RolePermissionGuard],
    data: {
      title: 'Home',
    },
    children: [
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        loadChildren: './views/dashboard/dashboard.module#DashboardModule',
      },
      {
        path: '',
        canActivate: [AuthGuard],
        loadChildren: './views/devAdmin/user/user.module#UserModule',
        data: {
          onlyAdmin: true
        }
      },
      {
        path: 'settings',
        canActivate: [AuthGuard],
        loadChildren: './views/devAdmin/setting/setting.module#SettingModule',
        data: {
          onlyAdmin: true
        }
      },
      {
        path: 'user-profile',
        canActivate: [AuthGuard],
        loadChildren: './views/user-profile/user-profile.module#UserProfileModule',
      },
      {
        path: 'activity',
        canActivate: [AuthGuard],
        loadChildren: './views/activity-list/activity-list.module#ActivityListModule',
      }
    ]
  },
  {
    path: ':id',
    redirectTo: ':id/dashboard',
    pathMatch: 'full'
  },
  {
    path: ':id',
    component: DefaultLayoutComponent,
    canActivateChild: [RolePermissionGuard],
    data: {
      title: 'Home',
    },
    children: [
      {
        path: 'notification',
        canActivate: [AuthGuard],
        loadChildren: './views/notification/notification.module#NotificationModule',
      },
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        loadChildren: './views/dashboard/dashboard.module#DashboardModule',
      },
      {
        path: 'change-password',
        canActivate: [AuthGuard],
        component: UserChangePasswordComponent
      },
      {
        path: 'supplier',
        canActivate: [AuthGuard],
        loadChildren: './views/supplier/supplier.module#SupplierModule',
      },
      {
        path: 'print-products',
        canActivate: [AuthGuard],
        loadChildren: './views/4over/print-products.module#PrintProductsModule',
      },
      {
        path: 'pay-invoice',
        canActivate: [AuthGuard],
        component: CreateReceivableComponent,
        data: {
          permissions: 'view_pay_invoice'
        }
      },
      {
        path: 'custom-quote-form',
        canActivate: [AuthGuard],
        loadChildren: './views/quote-forms/quote-forms.module#QuoteFormsModule',
      },
      {
        path: 'direct-estimation',
        canActivate: [AuthGuard],
        loadChildren: './views/direct-estimation/direct-estimation.module#DirectEstimationModule',
      },
      {
        path: 'page-intro',
        canActivate: [AuthGuard],
        loadChildren: './views/page-intro-video/page-intro.module#PageIntroModule',
      },
      {
        path: 'admin-content',
        canActivate: [AuthGuard],
        loadChildren: './views/content-generator-admin/content-generator-admin.module#ContentGeneratorAdminModule',
      },
      {
        path: 'pressero-order',
        canActivate: [AuthGuard],
        loadChildren: './views/pressero-order/pressero-order.module#PresseroOrderModule',
      },
      {
        path: 'order',
        canActivate: [AuthGuard],
        loadChildren: './views/order/order.module#OrderModule',
      },
      {
        path: 'order-shipping-charge',
        canActivate: [AuthGuard],
        component: OrderShippingChargeComponent
      },
      {
        path: 'project-history',
        canActivate: [AuthGuard],
        component: ProjectHistoryComponent,
        data: {
          reuseRoute: true
        }
      },
      {
        path: 'pay-bill',
        canActivate: [AuthGuard],
        component: CreatePayableComponent,
        data: {
          permissions: 'view_pay_bill'
        }
      },
      {
        path: 'user-profile',
        canActivate: [AuthGuard],
        loadChildren: './views/user-profile/user-profile.module#UserProfileModule',
      },
      {
        path: 'sales-rep-list',
        canActivate: [AuthGuard],
        loadChildren: './views/sales-representative/sales-representative.module#SalesRepresentativeModule',
      },
      {
        path: 'activity',
        canActivate: [AuthGuard],
        loadChildren: './views/activity-list/activity-list.module#ActivityListModule',
      },
      {
        path: 'contacts',
        canActivate: [AuthGuard],
        loadChildren: './views/user-contacts/user-contacts.module#UserContactsModule',
      },
      {
        path: 'stakes-mfg', // stakes MFG
        canActivate: [AuthGuard],
        loadChildren: './views/stakes_mfg/stakes_mfg.module#StakesMfgModule',
      },
      {
        path: 'project',
        canActivate: [AuthGuard],
        loadChildren: './views/custom-project/custom-project.module#CustomProjectModule',
      },
      {
        path: 'archive-project',
        canActivate: [AuthGuard],
        loadChildren: './views/custom-project/custom-project.module#CustomProjectModule',
      },
      {
        path: 'digital-products',
        canActivate: [AuthGuard],
        loadChildren: './views/digital-products/digital-products.module#DigitalProductsModule',
      },
      // 
      {
        path: 'campaign-request',
        canActivate: [AuthGuard],
        loadChildren: './views/campaign-request/campaign-request.module#CampaignRequestModule',
      },
      // 
      {
        path: 'campaign-request-title',
        canActivate: [AuthGuard],
        loadChildren: './views/campaign-request-super/campaign-request-super.module#CampeignRequestSuperModule',
      },
      {
        path: 'campaign-request-industry',
        canActivate: [AuthGuard],
        loadChildren: './views/campaign-request-super/campeign-industry.module#CampeignIndustrySuperModule',
      },
      {
        path: 'campaign-source',
        canActivate: [AuthGuard],
        loadChildren: './views/campaign-request-super/source-dropdown.module#SourceDropdownModule',
      },
      {
        path: 'creative-resource-category',
        canActivate: [AuthGuard],
        loadChildren: './views/campaign-request-super/creative-resource-category.module#CreativeResourceCategoryModule',
      },
      {
        path: 'creative-resource-document',
        canActivate: [AuthGuard],
        loadChildren: './views/campaign-request-super/creative-resource-document.module#CreativeResourceDocumentModule',
      },
      {
        path: 'content-generator',
        canActivate: [AuthGuard],
        loadChildren: './views/content-generator/content-generator.module#ContentGeneratorModule',
      },
      //
      // 
      {
        path: 'scorecard',
        canActivate: [AuthGuard],
        loadChildren: './views/scorecard/score-card.module#ScorecardModule',
      },
      // 
      {
        path: 'hubspot-import-contact',
        canActivate: [AuthGuard],
        loadChildren: './views/hubspot-import-contact/hubspot-import-contact.module#HubspotImportContactModule',
      },
      {
        path: 'sales-user-relation',
        canActivate: [AuthGuard],
        loadChildren: './views/user-hierarchy/user-hierarchy.module#UserHierarchyModule',
      },
      {
        path: 'sales-order',
        canActivate: [AuthGuard],
        loadChildren: './views/sales-order/sales-order.module#SalesOrderModule',
      },
      {
        path: 'bill',
        canActivate: [AuthGuard],
        loadChildren: './views/bill/bill.module#BillModule',
      },
      {
        path: 'bills', // for est,est hod,admin
        canActivate: [AuthGuard],
        component: AllBillsComponent,
        data: {
          permissions: 'list_view_order_bills'
        }
      },
      {
        path: 'purchase-order-detail', // for est,est hod,admin
        canActivate: [AuthGuard],
        // canActivateChild: [RolePermissionGuard],
        component: PurchaseOrdersComponent,
        data: {
          permissions: 'list_view_purchase_order'
        }
      },
      {
        path: 'purchase-order',
        canActivate: [AuthGuard],
        loadChildren: './views/purchase-order/purchase-order.module#PurchaseOrderModule',
      },
      {
        path: 'setting',
        canActivate: [AuthGuard],
        loadChildren: './views/admin/admin.module#AdminModule'
      },
      {
        path: 'setting/pricing/list',
        canActivate: [AuthGuard],
        component: PriceListComponent
      },
      {
        path: 'reports',
        canActivate: [AuthGuard],
        loadChildren: './views/reports/reports.module#ReportsModule'
      },
      {
        path:'storefronts',
        canActivate:[AuthGuard],
        loadChildren:'./views/storefronts_artwork/storefronts-artwork.module#StorefrontsArtworkModule'
      },
      {
        path:'storefront-artwork-request',
        canActivate:[AuthGuard],
        loadChildren:'./views/storefront-artwork-request/storefronts-artwork-request.module#StorefrontsArtworkRequestModule'
      }, 
      {
        path:'site-audit',
        canActivate:[AuthGuard],
        loadChildren:'./views/site-audit/site-audit.module#SiteAuditModule'
      },
      {
        path:'digital-request',
        canActivate:[AuthGuard],
        loadChildren:'./views/digital-request/digital-request.module#DigitalRequestModule'
      },
      {
        path: 'kpi-report',
        canActivate: [AuthGuard],
        loadChildren: './views/kpi-report/kpi-report.module#KPIReportsModule'
      },
      {
        path: 'accounting',
        canActivate: [AuthGuard],
        loadChildren: './views/accounting/accounting.module#AccountingModule'
      },
      {
        path: 'accounts',
        canActivate: [AuthGuard],
        loadChildren: './views/accounts/accounts.module#AccountsModule'
      },
      {
        path: 'payments',
        canActivate: [AuthGuard],
        loadChildren: './views/payments/payments.module#PaymentsModule'
      },
      {
        path: 'promotional',
        canActivate: [AuthGuard],
        loadChildren: './views/sage/sage.module#SageModule'
      },
      {
        path: 'apparel-new',
        canActivate: [AuthGuard],
        loadChildren: './views/sage/sage.module#SageModule'
      },
      {
        path: 'apparel',
        canActivate: [AuthGuard],
        loadChildren: './views/stakes-mfg-front/stakes-mfg-front.module#StakesMFGFrontModule'
      },
      {
        path: 'order-invoices',
        canActivate: [AuthGuard],
        loadChildren: './views/order-invoices/order-invoices.module#OrderInvoicesModule'
      },
      {
        path: 'purchase-order-detail/link-bill/:poId',  // for supplier only
        component: SupplierBillComponent,
        data: {
          title: 'Purchase Order Bill',
          onlySupplier: true,
          permissions: 'create_order_bills'
        }
      },
      {
        path: 'purchase-order-detail/edit-bill/:billId',  // for supplier only
        component: SupplierBillComponent,
        data: {
          title: 'Purchase Order Bill',
          onlySupplier: true,
          permissions: 'create_order_bills'
        }
      },
      {
        path: 'category',
        canActivate: [AuthGuard],
        loadChildren: './views/category/category.module#CategoryModule'
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}