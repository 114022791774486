import { Component, OnInit, Input, OnChanges, SimpleChange, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, AbstractControl } from '@angular/forms';
import { CommonService } from '../../../../service/common.service';
import { SalesOrderService } from '../../../../service/sales-order.service';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { ContactCreditService } from '../../../../service/contact-credit.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-pay-multiple',
  styleUrls: ['pay-multiple.component.scss'],
  templateUrl: 'pay-multiple.component.html'
})

export class PayMultipleComponent implements OnInit, OnChanges {
  invoicesForm: FormGroup;
  customerData = {};
  api_error = {};
  invoices = [];
  credits = [];
  applyAmount = 0;
  creditAmount = 0;
  loading = false;
  creditsDisabled = true;
  @Input() paymentTypes = [];
  @Input() paymentAccount = [];
  @Input() customer = [];
  @Output() formSaved = new EventEmitter();
  invoiceControls = [];
  creditControls = [];
  requiredCredit = 0; // when invoices are being paid using credits only
  constructor(private _fb: FormBuilder,
    public _cs: CommonService,
    public _salesOrderService: SalesOrderService,
    private _creditsService: ContactCreditService,
    private _dp: DatePipe) {

  }

  ngOnInit() {
  }

  getCustomerInvoices = () => {
    this.initForm();
    this.loading = true;
    this._salesOrderService.getInvoicesByCustomer(this.customer[0]['id']).subscribe(
      (res) => {
        this.loading = false;
        this.invoices = res['data']['invoices'];
        this.credits = res['data']['credits'];
        const invoiceArr = <FormArray>this.invoicesForm.get('all_invoices');
        const creditsArr = <FormArray>this.invoicesForm.get('all_credits');
        this.invoices.map(invoice => {
          if (invoice['received_payments'].length > 0) {
            if ((+invoice['amount'] - +invoice['received_payments'][0]['total_received']) > 0) {
              invoice['open_balance'] = (+invoice['amount'] - +invoice['received_payments'][0]['total_received']).toFixed(2);
            } else {
              invoice['open_balance'] = 0;
            }
          } else {
            invoice['open_balance'] = invoice['amount'];
          }
          invoiceArr.push(this.initInvoice(invoice));
        });
        this.invoiceControls = this.invoicesForm.get('all_invoices')['controls'];

        this.credits.map(credit => {
          credit['open_balance'] = credit['open_amount'];
          delete credit['open_amount'];
          creditsArr.push(this.initCredit(credit));
        });

        this.creditControls = this.invoicesForm.get('all_credits')['controls'];
        this.changeCreditsCheckboxStatus();
      }
    );
  }

  initForm = () => {
    this.invoicesForm = this._fb.group({
      client_id: [this.customer[0]['id']],
      amount: [''],
      payment_type: [''],
      received_date: [moment()],
      deposited_to: [''],
      ref_number: [''],
      comment: [''],
      due_amount: [],
      all_invoices: this._fb.array([]),
      all_credits: this._fb.array([])
    });
  }

  initInvoice = (data = []) => {
    return this._fb.group({
      status: [false],
      invoice_id: [data['id']],
      order_id: [data['order_id']],
      open_balance: [data['open_balance']],
      payment: ['']
    }, {
      validator: this.checkPayment
    });
  }

  initCredit = (data = []) => {
    return this._fb.group({
      status: [false],
      id: [data['id']],
      open_balance: [data['open_balance']],
      payment: ['']
    });
  }

  checkPayment = (control: AbstractControl) => {
    if (control.get('status').value) {
      if (+control.get('payment').value <= 0) {
        control.get('payment').setErrors({ 'min': 'payment should not be 0.' });
      } else {
        control.get('payment').setErrors(null);
      }
    } else {
      control.get('payment').setErrors(null);
    }
  }

  onEnterAmount = (value) => {
    let amount = value.slice();
    if (amount > 0) {
      this.applyAmount = value;
      this.invoiceControls.map(
        (control) => {
          if (amount > 0) {
            if (control.get('status').value) {
              if (control.get('payment').value !== ''
                && +control.get('payment').value > 0
                && +control.get('payment').value <= amount) {
                amount -= +control.get('payment').value;
              } else {
                const payment = amount - +control.get('open_balance').value;
                if (payment < 0) {
                  control.get('payment').setValue(amount);
                  amount = 0;
                } else {
                  control.get('payment').setValue(+control.get('open_balance').value);
                  amount -= control.get('open_balance').value;
                }
              }
            }
          } else {
            control.get('status').setValue(false);
            this.onCheckInvoice(false, control);
          }
        }
      );
    } else {
      this.invoiceControls.map(
        (control) => {
          control.get('payment').setValue('');
          control.get('status').setValue(false);
        }
      );
      this.applyAmount = 0;
    }
    this.calculateCredit();
  }

  onAddPayment = (isChecked, controlGroup) => {
    const payment = +controlGroup.get('payment').value;
    if (payment > controlGroup.get('open_balance').value) {
      controlGroup.get('payment').setValue('');
      controlGroup.get('status').setValue(false);
    } else {
      if (!this.checkIfCreditIsSelected()) {
        if (+payment === 0) {
          controlGroup.get('status').setValue(false);
          // controlGroup.get('payment').setValue('');
          this.calculateCredit();
        } else {
          if (!isChecked) {
            controlGroup.get('status').setValue(true);
          }
          this.calculateInvoicePayment(controlGroup);
        }
      } else {
        this.evaluateInvoiceAndCreditPayment();
      }
    }
    this.changeCreditsCheckboxStatus();
  }

  onCheckInvoice = (isChecked, controlGroup) => {
    if (!isChecked) {
      controlGroup.get('payment').setValue('');
    }
    if (!this.checkIfCreditIsSelected()) {
      if (isChecked) {
        this.calculateInvoicePayment(controlGroup);
      } else {
        controlGroup.get('payment').setValue('');
        this.calculateCredit();
      }
      this.changeCreditsCheckboxStatus();
    } else {
      this.evaluateInvoiceAndCreditPayment();
    }
  }

  // for single invoice under selecting invoice or entering payment for that invoice
  calculateInvoicePayment = (controlGroup) => {
    const payment = +controlGroup.get('payment').value;
    if (payment === 0) {
      if (+controlGroup.get('open_balance').value > this.creditAmount) {
        controlGroup.get('payment').setValue(this.creditAmount);
      } else {
        controlGroup.get('payment').setValue(+controlGroup.get('open_balance').value);
      }
    } else {
      this.deriveMainAmountFromPayments();
    }
    this.calculateCredit();
  }

  deriveMainAmountFromPayments = () => {
    let totalPayment = 0;
    const amountEntered = +this.invoicesForm.get('amount').value;
    this.invoiceControls.map((control) => {
      totalPayment += +control.get('payment').value;
    });
    if (totalPayment > amountEntered) {
      this.invoicesForm.get('amount').setValue(totalPayment);
      this.applyAmount = totalPayment;
    }
  }

  calculateCredit = () => {
    let amount = this.invoicesForm.get('amount').value;
    if (amount > 0) {
      this.invoiceControls.map(
        (control) => {
          amount -= +control.get('payment').value;
        }
      );
      this.creditAmount = amount;
    } else {
      this.creditAmount = 0;
    }
  }

  // for customer credits

  onCheckCredit = (isChecked, controlGroup) => {
    if (isChecked) {
      this.evaluateInvoiceAndCreditPayment();
    } else {
      if (this.checkIfCreditIsSelected()) {
        this.applyAmountWhenCreditIsSelected();
      } else {
        this.invoicesForm.get('amount').enable();
        this.deriveMainAmountFromPayments();
      }
      controlGroup.get('payment').setValue('');
    }
  }

  getTotalCreditPaymentAmount = () => {
    let totalCreditPayment = 0;
    this.creditControls.map(
      (control) => {
        if (control.get('status').value) {
          totalCreditPayment += +control.get('payment').value;
        }
      }
    );
    return totalCreditPayment;
  }

  checkIfCreditIsSelected = () => {
    // console.log(this.creditControls);
    let flag = false;
    this.creditControls.forEach(
      (control) => {
        if (control.get('status').value) {
          flag = true;
        }
      }
    );
    return flag;
  }

  evaluateInvoiceAndCreditPayment = () => {
    let selectedInvoiceOpenBalance = 0;
    this.invoiceControls.map((control) => {
      if (control.get('status').value) {
        selectedInvoiceOpenBalance += +control.get('open_balance').value;
      }
    });

    let selectedCreditOpenBalance = 0;
    this.creditControls.map((control) => {
      if (control.get('status').value) {
        selectedCreditOpenBalance += +control.get('open_balance').value;
      }
    });

    const originalCreditOpenBalance = JSON.parse(JSON.stringify(selectedCreditOpenBalance));

    let totalInvoicePaymentAmount = 0;
    this.invoiceControls.map(
      (control) => {
        if (control.get('status').value) {
          if (selectedCreditOpenBalance > 0) {
            if (+control.get('payment').value === 0) {
              if (+control.get('open_balance').value < selectedCreditOpenBalance) {
                control.get('payment').setValue(+control.get('open_balance').value);
                selectedCreditOpenBalance = selectedCreditOpenBalance - +control.get('open_balance').value;
              } else {
                control.get('payment').setValue(selectedCreditOpenBalance);
                selectedCreditOpenBalance = 0;
              }
            } else {
              selectedCreditOpenBalance = selectedCreditOpenBalance - +control.get('payment').value;
            }
          } else {
            control.get('payment').setValue(0);
          }
          totalInvoicePaymentAmount += +control.get('payment').value;
        }
      }
    );

    this.applyAmount = totalInvoicePaymentAmount;

    let usedCreditPayment = +originalCreditOpenBalance - selectedCreditOpenBalance;

    this.creditControls.map((control) => {
      if (control.get('status').value) {
        if (usedCreditPayment > 0) {
          if (+control.get('open_balance').value < usedCreditPayment) {
            control.get('payment').setValue(control.get('open_balance').value);
          } else {
            control.get('payment').setValue(usedCreditPayment);
          }
          usedCreditPayment -= +control.get('payment').value;
        } else {
          control.get('status').setValue(false);
          control.get('payment').setValue('');
        }
      }
    });
    this.changeCreditsCheckboxStatus();
  }

  applyAmountWhenCreditIsSelected = () => {
    let totalPayment = 0;
    this.invoiceControls.map((control) => {
      totalPayment += +control.get('payment').value;
    });
    this.applyAmount = totalPayment;
  }

  changeCreditsCheckboxStatus = () => {
    this.creditsDisabled = false;
    this.invoiceControls.map(control => {
      if (control.get('status').value) {
        this.creditsDisabled = control.get('status').value;
      }
    });
    const creditControls = this.invoicesForm.get('all_credits')['controls'];
    if (!this.creditsDisabled || +this.invoicesForm.get('amount').value > 0) {
      this.invoicesForm.get('amount').enable();
      creditControls.map(
        (control) => {
          // control.reset();
          control.get('payment').setValue('');
          control.get('status').setValue(false);
          control.disable();
        }
      );
    } else {
      if (this.checkIfCreditIsSelected()) {
        this.invoicesForm.get('amount').setValue('');
        this.invoicesForm.get('amount').disable();
      }
      creditControls.map(
        (control) => {
          control.enable();
        }
      );
    }
  }

  // end

  ngOnChanges(changes: { [property: string]: SimpleChange }) {
    // const change: SimpleChange = changes['orderNo'];
    this.getCustomerInvoices();
  }

  onSubmit = () => {
    if (this.invoicesForm.valid) {
      if (+this.invoicesForm.get('amount').value === 0 && this.checkIfCreditIsSelected()) {
        this.requiredCredit = 0;
        if (this.applyAmount > +this.getTotalCreditPaymentAmount()) {
          this.requiredCredit = this.applyAmount - +this.getTotalCreditPaymentAmount();
          this.showConfirmPayment(this.requiredCredit);
        } else {
          this.saveDataWithCreditAndAmount();
        }
      } else {
        this.requiredCredit = 0;
        if (+this.applyAmount === +this.creditAmount && +this.applyAmount) {
          this.showConfirmForCredit();
        } else {
          this.saveFormData();
        }
      }
    }
  }

  showConfirmForCredit = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Saving whole amount to customer\'s credit',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Save To Credits!',
    }).then((res) => {
      if (res['value']) {
        this.saveWholeCredit();
      }
    });
  }

  showConfirmPayment = (amount) => {
    Swal.fire({
      title: 'Are you sure?',
      // text: 'Pay remaining $ ' + amount + ' as invoice amount',
      text: 'Pay remaining $ ' + amount + ' as received amount ? ',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!',
    }).then((res) => {
      if (res['value']) {
        this.saveDataWithCreditAndAmount();
        // this.saveWholeCredit();
      }
    });
  }

  saveFormData = () => {
    this.loading = true;
    const postData = this.preparePostdata();
    postData['credit_amount'] = this.creditAmount;
    postData['all_invoices'] = postData['all_invoices'].filter(
      invoice => invoice['status']
    );
    this._salesOrderService.payMultipleInvoice(postData).subscribe(
      (res) => {
        this.formSaved.emit('');
        this.loading = false;
      }, (error) => {
        if (error['error']) {
          this.api_error = error['error']['data'];
          this.loading = false;
        }
      }
    );
  }

  saveWholeCredit = () => {
    this.loading = true;
    // const postData = { ...this.invoicesForm.value };
    const postData = this.preparePostdata();
    postData['contact_id'] = postData['client_id'];
    postData['total_amount'] = this.creditAmount;
    postData['open_amount'] = this.creditAmount;
    this._creditsService.saveContactCredit(postData).subscribe(
      (res) => {
        this.formSaved.emit('');
        this.loading = false;
      }, (error) => {
        if (error['error']) {
          this.api_error = error['error']['data'];
          this.loading = false;
        }
      }
    );
  }

  saveDataWithCreditAndAmount = () => {
    const postData = this.preparePostdata();
    postData['all_invoices'] = postData['all_invoices'].filter(item => item['status']);
    postData['all_credits'] = postData['all_credits'].filter(item => item['status']);
    if (this.requiredCredit > 0) {
      postData['extra_amount'] = this.requiredCredit;
    }
    this.loading = true;
    this._salesOrderService.payInvoicesUsingCredit(postData).subscribe(
      (res) => {
        this.formSaved.emit('');
        this.loading = false;
      }, (error) => {
        if (error['error']) {
          this.api_error = error['error']['data'];
          this.loading = false;
        }
      }
    );
  }

  preparePostdata = () => {
    const postData = { ...this.invoicesForm.value };
    if (postData['received_date']) {
      postData['received_date'] = this._dp.transform(postData['received_date'], 'y-MM-d');
    }
    return postData;
  }
}
