import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CommonService } from './common.service';

@Injectable({ providedIn: 'root' })

export class AccountingService {
  constructor(private _http: HttpClient,
    private _cs: CommonService) {
  }

  saveReceivedPayment = (data) => {
    return this._http.post<any>(environment.API_URL + 'accounting/save-receivable', data, this._cs.setHeader());
  }

  savePayable = (data) => {
    return this._http.post<any>(environment.API_URL + 'accounting/save-payable', data, this._cs.setHeader());
  }

  getOrderReceivedPayments = (id) => {
    return this._http.get(environment.API_URL + 'accounting/order-received-payments/' + id, this._cs.setHeader());
  }

  getOrderBillPayments = (id) => {
    return this._http.get(environment.API_URL + 'accounting/order-bill-payment/' + id, this._cs.setHeader());
  }

  getAllReceived = (id) => {
    return this._http.get(environment.API_URL + 'accounting/get-all-received/' + id, this._cs.setHeader());
  }

  getReceivedPayment = (id) => {
    return this._http.get(environment.API_URL + 'accounting/get-received-payment/' + id, this._cs.setHeader());
  }
  
  deleteReceivedPayment = (id) => {
    return this._http.get(environment.API_URL + 'accounting/delete-received-payment/' + id, this._cs.setHeader());
  }

  getPaidBillPayment = (id) => {
    return this._http.get(environment.API_URL + 'accounting/get-paid-bill-payment/' + id, this._cs.setHeader());
  }

  deletePaidBillPayment = (id) => {
    return this._http.get(environment.API_URL + 'accounting/delete-paid-bill-payment/' + id, this._cs.setHeader());
  }

  getAllPaid = (id) => {
    return this._http.get(environment.API_URL + 'accounting/get-all-paid/' + id, this._cs.setHeader());
  }

  paySalesRepPayment = (data) => {
    let header:any = this._cs.setHeader();
    header['reportProgress'] = true;
    header['observe'] = "events";
    return this._http.post<any>(environment.API_URL + 'accounting/save-salesrep-payment', data, header);
  }

  getCommissionPayment = (id) => {
    return this._http.get<any>(environment.API_URL + 'reports/commission-payment-report/' + id, this._cs.setHeader());
  }

  getPaymentHistoryData = (data) => {
  	return this._http.post<any>(environment.API_URL + 'accounting/get-salesrep-payment', data, this._cs.setHeader());
  }

  getSalesRepPaymentById = (id) => {
  	return this._http.get<any>(environment.API_URL + 'accounting/get-salesrep-payment-by-id/'+id, this._cs.setHeader());
  }

  getAccountReceivablesData = (data) => {
  	return this._http.post<any>(environment.API_URL + 'accounting/get-account-receivables', data,this._cs.setHeader());
  }

  getAccountPayablesData = (data) => {
  	return this._http.post<any>(environment.API_URL + 'accounting/get-account-payables', data,this._cs.setHeader());
  }

  getAllSalesOrderData = (data) => {
    return this._http.post<any>(environment.API_URL + 'order/color-filter', data, this._cs.setHeader());
  }
  
}