import {Component, EventEmitter, Input, OnChanges, Output, ViewChild} from "@angular/core";
import {DataTableDirective} from "angular-datatables";
import {BehaviorSubject, Subject} from "rxjs";
import {AccountingService} from "../../../service/accounting.service";
import {CommonService} from "../../../service/common.service";
import * as XLSX from "xlsx";
import * as moment from 'moment';

@Component({
  selector: 'app-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.scss']
})
export class PaymentHistoryComponent implements OnChanges {
  @Input() filters = {
    sales_rep_or_csr_id: null,
    from: null,
    to: null,
    showActions: false
  };

  @Output() editPayment = new EventEmitter();

  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  newJson: any = undefined;
  newPassedJson = new BehaviorSubject({});
  
  paymentHistoryData: any = [];

  constructor(
    public csService: CommonService,
    public _accountingService: AccountingService
  ) {}

  ngOnChanges() {
    let columns: any = [
      { name: null, searchable: false },
      { name: "amount" },
      { name: "payment_type", searchable: false },
      { name: "payment_date", searchable: false },
      { name: "notes" },
      { name: "due_amount" },
      { name: null, searchable: false }
    ];

    if (this.filters.showActions) {
      columns.push({ name: null, searchable: false, orderable: false });
    }

    this.dtOptions = {
      dom: "<'row'<'col-sm-12 col-md-4'l><'col-sm-12 col-md-4 top'i><'col-sm-12 col-md-4 top'f>>" +
      "<'row'<'col-sm-12'tr>>" +
      "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
      pagingType: "full_numbers",
      pageLength: 10,
      lengthMenu: [5, 10, 25, 50],
      processing: true,
      language: {
        searchPlaceholder: "Search..",
        search: "",
        emptyTable: 'No record found!'
      },
      columnDefs: [
        {targets: '_all', orderable: false}
      ],
      columns: columns
    };

    this.getPaymentHistoryList();
  }

  getPaymentHistoryList = () => {
    if (this.filters.sales_rep_or_csr_id) {
      if (this.dtElement.dtInstance) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
        });
      }
      this._accountingService.getPaymentHistoryData(this.filters).subscribe((res) => {
        this.paymentHistoryData = res.data || [];
        this.newJson = (res.data).map((item, index) => {
          return {
            Index: index+1,
            Amount: item.amount || 0,
            'Payment Type': (item.payment_type == 1) ? 'Payment' : 'Draw',
            'Payment Date': moment(item.payment_date).format('MM/DD/YYYY'),
            Notes: item.notes || '',
            'Due Amount': item.due_amount || 0
          };
        });
        this.newPassedJson.next(this.newJson);

        setTimeout(() => {
          this.dtTrigger.next();
        }, 0);
      }, (error) => {
        console.error(error);
      });
    }
  }

  editPaymentHistory(id) {
    this.editPayment.emit(id);
  }

  exportToCsv = () => {
    this.newJson = this.newPassedJson.getValue();
    const worksheet1: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.newJson);
    const csvOutput: string = XLSX.utils.sheet_to_csv(worksheet1);
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet1, "Sheet1");
    XLSX.writeFile(workbook, "Commission Payment.csv");
  };
}