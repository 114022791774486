import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { UserService } from '../../service/user.service';
import { CommonService } from '../../service/common.service';
import { AuthenticationService } from '../../service/authentication.service';

@Component({
  templateUrl: './user-change-password.component.html',
  styleUrls: ['./user-change-password.component.scss']
})
export class UserChangePasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  api_error: any = [];

  constructor(private _fb: FormBuilder, private userService: UserService,
    private csService: CommonService,
    private authService: AuthenticationService) {
  }

  ngOnInit() {
    this.initChangePassword();
  }

  initChangePassword = () => {
    this.changePasswordForm = this._fb.group({
      password: [''],
      password_confirmation: [''],
    });
  }

  changePassword = () => {
    const data = this.changePasswordForm.value;
    this.userService.changePassword(data).subscribe(res => {
      // console.log(res);
    }, error => {
      if (error['error']) {
        this.api_error = error['error']['data'];
      }
    });
  }

}
