import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

import * as moment from 'moment';

@Pipe({
  name: 'mdyTodmyDatefilter'
})

export class MdyToDmyDateFilterPipe implements PipeTransform {

  constructor(private datePipe: DatePipe) { }

  transform(date: any): any {
    if (date) {
      const dateArr = date.split('-');
      return dateArr[1] + '-' + dateArr[0]  +'-'+ dateArr[2];
    }else{
      return date;
    }
  }
}
