import {Injectable, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ToasterService} from 'angular2-toaster';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {StoragemanagerService} from '../service/storagemanager.service';
import {tap} from 'rxjs/operators';
import {AuthenticationService} from '../service/authentication.service';

@Injectable()
export class InterceptorService implements HttpInterceptor {
    constructor(private router: Router,
        private activatedRoute: ActivatedRoute,
        private toaster: ToasterService,
    ) {
    }


    intercept = (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> => {
        const request = req.clone({
            setHeaders: {
                Authorization: `Bearer ${StoragemanagerService.getToken()}`,
            },
            //  params: req.params.set('awi_franchise_id', localStorage.getItem('franchise_id'))
        });
        return next.handle(request).pipe(
            tap(event => {
                if (event instanceof HttpResponse) {
                    if (event.body.message) {
                        if(typeof event.body.message != 'object'){
                        this.toaster.pop('success', event.body.message, '');
                        }
                    }
                }
            },
                error => {
                    if (error.status === 401) {
                        localStorage.clear();
                        this.toaster.pop('error', error.error.message, '');
                        this.router.navigate(['/', 'login']);
                    }
                    if (error.status === 403) {
                        localStorage.clear();
                        this.toaster.pop('error', error.error.message, '');
                        this.router.navigate(['/', 'login']);
                    }
                    if (error.error.message && error.status !== 401 && error.status !== 403) {
                      //  this.toaster.pop('error', 'Something Went Wrong!', '') //remove something went wrong message;
                    }
                })
        );
    };
}
