import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { DataTableService } from '../../service/datatable.service';
import { CommonService } from '../../service/common.service';
import * as XLSX from 'xlsx';
import { FranchiseService } from '../../service/franchise.service';
import { AuthenticationService } from '../../service/authentication.service';
import { UserService } from '../../service/user.service';
import { RolepermissionService } from '../../service/rolepermission.service';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  templateUrl: './global-invoice-report.component.html',
  styleUrls: ['./global-invoice-report.component.scss'],
  selector: 'app-global-invoice-report'
})
export class GlobalInvoiceReportComponent implements OnInit, OnDestroy {
  constructor(
    private _dtService: DataTableService,
    private userService: UserService,
    public commonService: CommonService,
    private franchiseService: FranchiseService,
    private authService: AuthenticationService,
    private rolePermissionService: RolepermissionService,
    private router: Router
  ){}

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  loading = false;  

  currentUser: any = null;
  invoices = [];
  grand_total :any ='';
  franchiseList:any = [];
  franchise_id:any = null;
  salesRepList:any = [];
  sales_rep_id:any = null;
  estimatorList:any = [];
  estimator_id: any = null;

  userData:any = {};
  isSalesRep = false;
  isCSR = false;
  isEstimator = false;

  options: any = {
    locale: { format: 'MM/DD/YYYY' },
    alwaysShowCalendars: false,
    startDate: moment("2019-01-01"),
    endDate: moment(),
    ranges: {
      'Today': [moment(), moment()],
      'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
      'All' : [moment("2019-01-01"), moment()] //moment().subtract(3, 'year').startOf('year')
    }
  };

  date_filter:any = {
    from: moment("2019-01-01").format(),
    to: moment().format(),
    filter_by: 'created'
  };

  async ngOnInit() {
    this.userData = this.franchiseService.franchiseData.getValue();
    await new Promise(resolve => {
      this.authService.userData.subscribe((res) => {
        this.currentUser = res['id'];
        resolve(res);
      });
    });

    this.franchiseService.getAllFranchise().subscribe((res) => {
      this.franchiseList = res['data'];
    });

    this.userService.getUsersOfAllFranchiseByRole({role: 'Sales Rep'}).subscribe((res) => {
      this.salesRepList = res['data'];
    });

    this.userService.getUsersOfAllFranchiseByRole({role: 'Estimator'}).subscribe((res) => {
      this.estimatorList = res['data'];
    });

    let configStatus:any = this.commonService.configStatus.getValue();
    if (Object.keys(configStatus).length === 0) {
      await new Promise(resolve => {
        this.commonService.getConfigStatusData().subscribe(res => {
          configStatus = res;
          this.commonService.configStatus.next(res);
          resolve(res);
        });
      });
    }

    this.getInvoices();
    setTimeout(()=> {
      this.dtTrigger.next();
    }, 0);
  }

  getInvoices() {
    this.dtOptions = {
      dom: "<'row'<'col-sm-12 col-md-4'l><'col-sm-12 col-md-4 top'i><'col-sm-12 col-md-4 top'f>>" +
      "<'row'<'col-sm-12'tr>>" +
      "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
      pagingType: 'full_numbers',
      pageLength: 10,
      // lengthMenu: [5, 10, 25, 50],
      lengthMenu: [ [10, 25, 50, 100, -1], [10, 25, 50, 100, "All"] ],
      searchDelay: 1000,
      serverSide: true,
      processing: true,
      language: {
        searchPlaceholder: 'Search...',
        search: ''
      },
      ajax: (dataTablesParameters: any, callback) => {
        let data:any = {
          franchise_id: (this.franchise_id || []).map(item => item.id),
          sales_rep_id: this.sales_rep_id,
          estimator_id: this.estimator_id,
          date_filter: this.date_filter
        };

        this._dtService.getTableData(dataTablesParameters, 'global-invoice-report', data).subscribe((response: any = {})=>{
          this.grand_total = (response.data.original.grand_total || 0).toFixed(2);
          this.invoices = (response.data.original.data || []).map((item,index) => {
            if (item['invoice_date']) item['display_invoice_date'] = moment(item['invoice_date']).format("MM-DD-YYYY");
            if (item['due_date']) item['display_due_date'] = moment(item['due_date']).format("MM-DD-YYYY");
            item['due_amount'] = Math.max(+(item['amount'] || 0) - +(item['received_amount'] || 0), 0);
            item['base_sale_amount'] = (parseFloat(item.amount) - parseFloat(item.shipping_amount) - parseFloat(item.tax_value)).toFixed(2);
            return item;
          });

          callback({
            recordsTotal: response['data']['original']['recordsTotal'],
            recordsFiltered: response['data']['original']['recordsFiltered'],
            data: []
          });
        },(error) => {
          callback({
            recordsTotal: 0,
            recordsFiltered: 0,
            data: []
          });
        });
      },
      order: [],
      columns: [
        { data: null, orderable: false, searchable: false },
        { name: 'invoice_uid', orderable: false },
        { name: 'invoice_order.sales_order_uid', orderable: false },
        { name: 'client_name', orderable: false },
        { data: 'company_name', orderable: false },
        { name: 'invoice_order.project_of_order.sales_rep.first_name', orderable: false },
        { name: 'invoice_date' },
        { name: 'due_date' },
        { data: null, orderable: false, searchable: false },
        { data: null, orderable: false, searchable: false },
        { data: null, orderable: false, searchable: false },
        { name: 'amount', orderable: false },
        { name: 'received_amount', orderable: false, searchable: false },
        { data: null, orderable: false, searchable: false },
        { data: null, orderable: false, searchable: false }
      ]
    };
  }

  dropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    placeholder: 'Distributor Network',
    allowSearchFilter: true,
    closeDropDownOnSelection: true
  };

  rerender = (): void => {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    }, (error) => {
      console.error(error);
    });
  }

  selectedDate (value: any) {
    this.date_filter.from = value.start.format();
    this.date_filter.to = value.end.format();
  }

  exportToExcel(type = 'XLSX') {
    this.loading = true;
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      let dataTablesParameters:any = dtInstance.ajax.params();
      delete dataTablesParameters.length;
      delete dataTablesParameters.start;

      let data:any = {
        franchise_id: (this.franchise_id || []).map(item => item.id),
        sales_rep_id: this.sales_rep_id,
        estimator_id: this.estimator_id,
        date_filter: this.date_filter,
      };

      this._dtService.getTableData(dataTablesParameters, 'global-invoice-report', data).subscribe((response: any = {})=>{
        let xlsJSON = [];
        
        (response.data.original.data || []).forEach((item) => {  
          if (item['invoice_date']) item['display_invoice_date'] = moment(item['invoice_date']).format("MM-DD-YYYY");
          if (item['due_date']) item['display_due_date'] = moment(item['due_date']).format("MM-DD-YYYY");
          item['due_amount'] = Math.max(+(item['amount'] || 0) - +(item['received_amount'] || 0), 0);
                  
          let obj = [];
          obj['Distributor Network'] = (item.franchise && item.franchise.name) ? item.franchise.name : '';
          obj['Invoice No'] = item.invoice_uid;
          obj['Sales Order No'] = (item.invoice_order) ? item.invoice_order.sales_order_uid : '';
          obj['Client'] = item.client_name;
          obj['Organization'] = item.company_name || '';
          obj['Distributor'] = (item.invoice_order) ? (item.invoice_order.project_of_order ? (item.invoice_order.project_of_order.sales_rep ? item.invoice_order.project_of_order.sales_rep.full_name : ''): ''): '';
          obj['Invoice Date'] = item.display_invoice_date || '';
          obj['Due Date'] = item.display_due_date || '';
          obj['Base Sales Amount'] = +((parseFloat(item.amount) - parseFloat(item.shipping_amount) - parseFloat(item.tax_value)).toFixed(2));
          obj['Shipping Value'] = item.shipping_amount || 0;
          obj['Tax Value'] = item.tax_value;
          obj['Total Amount'] = +item.amount || 0;
          obj['Balance Amount'] = +item.received_amount || 0;
          obj['Due Amount'] = +item.due_amount || 0;
          obj['Status'] = this.commonService.getStatusName(item.payment_status ,'payment_status_invoice');
          xlsJSON.push(obj);
        });

        xlsJSON.push({});
        xlsJSON.push({
          'Distributor Network': 'Grand Total',
          'Invoice No': response.data.original.grand_total || 0
        });

        const worksheet1: XLSX.WorkSheet = XLSX.utils.json_to_sheet(xlsJSON);
        const csvOutput: string = XLSX.utils.sheet_to_csv(worksheet1);
        const workbook: XLSX.WorkBook = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(workbook, worksheet1, 'Sheet1');
     
        if (type == 'CSV') {
          XLSX.writeFile(workbook,'Invoice Report.csv')
        } else {
          XLSX.writeFile(workbook,'Invoice Report.xlsx', { bookType: 'xlsx', type: 'buffer' } );
        }

        this.loading = false;
      }, (error) => {
        console.error(error);
        this.loading = false;
      });
    }, (error) => {
      console.error(error);
      this.loading = false;
    });
  }

  navigateTo(franchiseId, salesOrderId, page = 'Sales Order') {
    this.franchiseService.refreshFranchiseData(franchiseId).then(() => {});
    if (franchiseId) {
      this.loading = true;
      this.rolePermissionService.getPermissions(franchiseId).subscribe(res => {
        if (res) {
          this.rolePermissionService.permissions.next(res['data']['permissions']);
          this.rolePermissionService.unread.next(res['data']['unread']);
          this.rolePermissionService.notificationData.next(res['data']['notification']);
          this.rolePermissionService.projectsData.next(res['data']['projects']);
        }
        this.loading = false;
        if (page == 'Sales Order') {
          this.router.navigateByUrl('/'+franchiseId+'/sales-order/view/'+salesOrderId);
        } else if (page == 'Invoice') {
          this.router.navigateByUrl('/'+franchiseId+'/sales-order/print-invoice/'+salesOrderId);
        }
      });
    }
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }
}