import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ProjectService } from "../../../../service/project.service";
import { CommonService } from "../../../../service/common.service";
import { ToasterService } from "angular2-toaster";
import { Utils } from "../../../../utils";
import { FranchiseService } from "../../../../service/franchise.service";
import { AppConstants } from "../../../../constants/app.constants";
import Swal from "sweetalert2";
import { ModalDirective } from "ngx-bootstrap";
import { DatePipe } from "@angular/common";
import {AuthenticationService} from "../../../../service/authentication.service";

@Component({
  templateUrl: "./custom-project-estimate-view.component.html",
  styleUrls: ["./custom-project-estimate-view.component.scss"],
})
export class CustomProjectEstimateViewComponent implements OnInit {
  franchiseId = '';
  @ViewChild("billModal") public modal: ModalDirective;
  franchiseData: any = [];
  franchiseLogo = "";
  api_error = {};
  loading = false;

  currentUser: any = null;
  estimateId = "";
  estimateData: any = [];
  estInfo = {};
  productData: any = [];
  projectStatus: any = [];
  supplierDetail: any = [];
  comment_history: any = [];
  status: any = {
    loading: false,
    success: false,
    error: false,
  };
  
  projectId: any = undefined;
  isSalesRep = false;
  isEstimatorHod = false;
  isDigitalProject = false;
  approveAllowed = false;
  hideAction = false;
  previousUrl: any = null;

  constructor(
    private _route: Router,
    private projectService: ProjectService,
    private activatedRoute: ActivatedRoute,
    private franchiseService: FranchiseService,
    private utils: Utils,
    private toasterService: ToasterService,
    public commonService: CommonService,
    private datepipe: DatePipe,
    private authService: AuthenticationService,
  ) {}

  ngOnInit() {
    this.commonService.previousUrl$
    .subscribe((previousUrl: string) => {
        this.previousUrl = previousUrl
    });

    this.commonService.introType.next("direct_estimation_details");
    this.franchiseData = this.franchiseService.franchiseData.getValue();
    this.franchiseId = this.commonService.currentFranchise();
    this.authService.userData.subscribe((res) => {
      this.currentUser = res['id'];
    });

    if (this.franchiseData["role"]["original_name"] === AppConstants["roles"]["sales_rep"]) {
      this.isSalesRep = true;
    } else if (this.franchiseData["role"]["original_name"] === AppConstants["roles"]["estimator_hod"]) {
      this.isEstimatorHod = true;
      this.approveAllowed = true;
    } else if (this.franchiseData["role"]["original_name"] === AppConstants["roles"]["admin"]) {
      this.approveAllowed = true;
    }

    const logo = this.commonService.UPLOAD_FILE_AWS("franchise_images");
    this.franchiseLogo = logo + (this.franchiseData["print_logo"] || this.franchiseData["logo"]);

    const configStatus = this.commonService.configStatus.getValue();
    if (Object.keys(configStatus).length > 0) {
      this.projectStatus = configStatus;
    } else {
      this.commonService.getConfigStatusData().subscribe((res) => {
        this.commonService.configStatus.next(res);
        this.projectStatus = res["project_status"];
      });
    }

    this.activatedRoute.params.subscribe((resId) => {
      this.estimateId = resId["id"];
      this.status = this.utils.showContainers(this.status, "loading");
      this.projectService.getEstimateData(resId["id"]).subscribe((res) => {
        this.estimateData = res["data"];
        if (this.estimateData.contact_billing_details) {
          const billingDetails = JSON.parse(this.estimateData.contact_billing_details);
          this.estimateData["contact_of_estimation"] = {
            ...this.estimateData["contact_of_estimation"],
            full_name: billingDetails.full_name || "",
            email: billingDetails.email || "",
            address_line_1: billingDetails.address_line_1 || "",
            address_line_2: billingDetails.address_line_2 || "",
            city: billingDetails.city || "",
            state_code: billingDetails.state_code || "",
            zip_code: billingDetails.zip_code || "",
            country: billingDetails.country || "",
        }
      }
        if (this.isSalesRep && res["data"]["project_of_estimation"]["sales_rep_id"] != this.currentUser) {
          this.hideAction = true;
        } else if (this.isEstimatorHod && res["data"]["project_of_estimation"]["csr_user_id"] != this.currentUser) {
          this.hideAction = true;
        }

        this.projectId = this.estimateData["project_id"];
        this.estInfo = res["data"]["estimation_info"][0];
        if (res["data"]["project_of_estimation"]["project_type"] == 4) {
          this.isDigitalProject = true;
        }

        this.status = this.utils.showContainers(this.status, "success");
        this.getSupplierDetail(this.estimateData);
      },
      (error) => {
        if (error["error"]["data"] == "invalid_request") {
          this.toasterService.pop("error", error["error"]["message"]);
          this._route.navigate(["../../../dashboard"], {
            relativeTo: this.activatedRoute,
          });
        }
      });
    });
  }

  returnStringWithBold = (description) => {
    if (
      this.estimateData["project_of_estimation"] &&
      this.estimateData["project_of_estimation"]["project_type"] == 8
    ) {
      let string = description;
      let final = [];
      var c = string.split(", ");
      c.map((item) => {
        var char = item.split(":");
        if (char[0] && char[1]) {
          final.push(char[0].bold() +' : '+ char[1] +'<br>')
        }
      });
      return final.join(" ");
    } else {
      return description;
    }
  };

  openModal = (estimationId) => {
    let data = {
      estimation_id: estimationId,
    };
    if (estimationId) {
      this.loading = true;
      this.modal.show();
      this.projectService.getCommentsFromHistory(data).subscribe((res) => {
        this.loading = false;
        this.comment_history = res;
        this.comment_history.map((com, index) => {
          let today = new Date();
          let updatedDate = this.datepipe.transform(
            this.comment_history[index]["created_at"],
            "MM/dd/yyyy HH:mm:ss"
          );
          let currentDate = this.datepipe.transform(
            today,
            "MM/dd/yyyy HH:mm:ss"
          );
          let diff =
            Math.abs(+new Date(updatedDate) - +new Date(currentDate)) / 1000;
          const days = Math.floor(+diff / 86400);
          diff -= +days * 86400;
          // const diffTime  = Math.abs(+(new Date(currentDate)).getTime() - +(new Date(updatedDate)).getTime());
          // let diffDays = Math.ceil(+diffTime / (1000 * 60 * 60 * 24));
          // this.projectData[i]['days'] = diffDays;
          let displaydate = this.datepipe.transform(
            this.comment_history[index]["updated_at"],
            "MM-dd-yyyy HH:mm:ss"
          );
          this.comment_history[index]["display_update_date"] = displaydate;
        });
      });
    }
  };

  getSupplierDetail = (estimateData) => {
    let finalSupplier = [];
    let supplier = [];
    estimateData["estimation_specs"].map((item, index) => {
      if (
        !supplier.includes(estimateData["estimation_specs"][index].supplier_id)
      ) {
        finalSupplier.push(estimateData["estimation_specs"][index]);
        supplier.push(estimateData["estimation_specs"][index].supplier_id);
      }
    });
    this.supplierDetail = finalSupplier;
  };
  
  back = () => {
    this._route.navigateByUrl(this.previousUrl || this.franchiseId+'/project');
  };

  print = () => {
    let printContents, popupWin;
    printContents = document.getElementById("invoice").innerHTML;
    popupWin = window.open("", "_blank", "top=0,left=0,height=100%,width=auto");
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Estimate</title>
          <link rel="stylesheet" href="'../../../../../../assets/css/invoice.css">
        </head>
        <body onload="window.print();window.close()" style="font-family: 'Karla', sans-serif;">${printContents}</body>
      </html>`);
    popupWin.document.close();
  };

  validateAndDownload = () => {
    this.estimateData["franchise_logo"] = this.franchiseLogo;
    this.estimateData["project_type"] = this.estimateData["project_of_estimation"] && this.estimateData["project_of_estimation"]["project_type"] ? this.estimateData["project_of_estimation"]["project_type"] : "";
    this.loading = true;

    //this.projectService.sendEstimationToCustomer(this.estimateData.id, null, true).subscribe((res) => {
      // Project status is get updated
    //});

    this.projectService.downloadAllPdf(this.estimateData).subscribe(
      (res: any) => {
        this.api_error = {};
        this.loading = false;
        const blobData = new Blob([res], { type: "application/octet-stream" });
        const a = document.createElement("a");
        a.href = URL.createObjectURL(blobData);
        a.download = this.estimateData["estimation_uid"] + "_Estimate.pdf";
        document.body.appendChild(a);
        a.click();
      },
      (error) => {
        this.loading = false;
        if (error["error"]["data"]) {
          this.api_error = error["error"]["data"];
        }
      }
    );
  };

  confirmApprove = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Approve it!",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.projectService
          .approveEstimation(this.estimateId)
          .toPromise()
          .then((res) => {
            if (res["data"]) {
              return true;
            }
          })
          .catch(() => {
            return true;
          });
      },
    }).then((res) => {
      if (res["value"]) {
        this.estimateData["is_approved"] = 1;
        this.estimateData["status"] = 2;
      }
    });
  };

  getAmountString = (string) => {
    return string.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  onChangeRequest = () => {
    Swal.fire({
      title: "Requirement Change Request",
      // text: 'You won\'t be able to revert this!',
      input: "textarea",
      inputPlaceholder: "Type request message here...",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Submit",
      showLoaderOnConfirm: true,
      preConfirm: (value) => {
        if (!value.trim()) {
          Swal.showValidationMessage("Request should not be blank");
        } else {
          const data = {
            id: this.estimateId,
            request_message: value,
            projectid: this.projectId,
          };
          if (this.isSalesRep) {
            data["isSaleRep"] = "true";
          }
          return this.projectService
            .estimationChangeRequest(data)
            .toPromise()
            .then((res) => {
              if (res["data"]) {
                this.estimateData["request_message"] = value;
                this.ngOnInit();
                return true;
              }
            })
            .catch(() => {
              return true;
            });
        }
      },
    }).then((res) => {
      if (res["value"]) {
        this.estimateData["has_request"] = 1;
        this.ngOnInit();
      }
    });
  };

  confirmReject = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Change it!",
      showLoaderOnConfirm: true,
      input: "textarea",
      inputPlaceholder: "Request For Change Reason",
      preConfirm: (value) => {
        if (!value.trim()) {
          Swal.showValidationMessage("Request for change reason is required");
        } else {
          const data = {};
          data["reject_reason"] = value;
          data["id"] = id;
          return this.projectService
            .rejectEstimation(data)
            .toPromise()
            .then((res) => {
              if (res["data"]) {
                return true;
              }
            })
            .catch(() => {
              return true;
            });
        }
      },
    }).then((res) => {
      if (res["value"]) {
        this.ngOnInit();
        // this.rerender();
      }
    });
  };

  sendEstimate = (estimationId) => {
    let data = {
      project_id: this.projectId,
      estimation_id: estimationId,
    };
    this.projectService.getProjectComment(data).subscribe((res) => {
      if (res["data"]["project_status"] == 14) {
        Swal.fire({
          title: "This was already sent to customer, would you like to resend?",
          html: "<textarea id='text' placeholder='Comment' class='form-control'></textarea>",
          // text: 'Alreday send to cutomer, do you want to send again?',
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Send again!",
          showLoaderOnConfirm: true,
          onBeforeOpen: () => {
            $("#text").val(
              res["data"]["comment"] ? res["data"]["comment"] : ""
            );
          },
          preConfirm: (value1) => {
            var val = document.getElementById("text")["value"] ? document.getElementById("text")["value"] : null;
            return this.projectService
              .sendEstimationToCustomer(estimationId, val)
              .toPromise()
              .then((res) => {
                if (res["data"]) {
                  return true;
                }
              })
              .catch(() => {
                return false;
              });
          },
        }).then((res) => {});
      } else {
        let title = "Are you sure this is ready to send? Please add comments to send to your customer with estimate. Thank you!";
        if (res['data']['is_order_created']) {
          title = "Order is already been created of the project. Are you sure to send estimate to the customer?";
        }
        Swal.fire({
          title: title,
          html: "<textarea id='text' placeholder='Comment' class='form-control'></textarea>",
          // text: 'You want to send this to customer?',
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Send it!",
          showLoaderOnConfirm: true,
          onBeforeOpen: () => {
            $("#text").val(
              res["data"]["comment"] ? res["data"]["comment"] : ""
            );
          },
          preConfirm: (value2) => {
            var val = document.getElementById("text")["value"] ? document.getElementById("text")["value"] : null;
            return this.projectService
              .sendEstimationToCustomer(estimationId, val)
              .toPromise()
              .then((res) => {
                if (res["data"]) {
                  this.ngOnInit();
                  return true;
                }
              })
              .catch(() => {
                return false;
              });
          },
        }).then((res) => {
          this.ngOnInit();
        });
      }
    });
  };
}
