import { Injectable } from '@angular/core';
import { CanActivateChild, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../service/authentication.service';

@Injectable()
export class SupplierChildGuard implements CanActivateChild {
  constructor(private _router: Router, private authService: AuthenticationService) {
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
    return new Promise((resolve, reject) => {
      const userData = this.authService.userData.getValue();
      if (userData['is_supplier']) {
        if (childRoute['data'] && childRoute['data']['onlySupplier']) {
          resolve(true);
        } else {
          this._router.navigate(['supplier/dashboard']);
          reject(true);
        }
      } else {
        this._router.navigate(['account-selection']); //franchise-selection
        resolve(true);
      }
    });
  }
}
