import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { CommonService } from './common.service';

@Injectable({ providedIn: 'root' })
export class CategoryService {
  constructor(private _http: HttpClient, private _cs: CommonService) {
  }

  saveCategory = (data) => {
    return this._http.post(environment.API_URL + 'category/save-category', data, this._cs.setHeader());
  }

  getAllCategory = () => {
    return this._http.get(environment.API_URL + 'category/get-all-category', this._cs.setHeader());
  }

  getCategory = () => {
    return this._http.get(environment.API_URL + 'category/getCategory', this._cs.setHeader());
  }
  getGlobalCategory = () => {
    return this._http.get(environment.API_URL + 'category/get-category', this._cs.setHeader());
  }
  //   changeTaxStatus = (data) => {
  //     return this._http.post(environment.API_URL + 'tax/change-tax-status', data, this._cs.setHeader());
  //   }

  //   getTaxOptions = () => {
  //     return this._http.get(environment.API_URL + 'tax/get-tax-options', this._cs.setHeader());
  //   }

  saveCampeignRequestTitle = (data) => {
    return this._http.post(environment.API_URL + 'campaign-request/save-title', data, this._cs.setHeader());
  }

  removeCampeignRequestTitle = (id) => {
    return this._http.get(environment.API_URL + 'campaign-request/delete-title/' + id, this._cs.setHeader());
  }

  saveCampeignRequestIndustry = (data) => {
    return this._http.post(environment.API_URL + 'campaign-request/save-industry', data, this._cs.setHeader());
  }

  removeCampeignRequestIndustry = (id) => {
    return this._http.get(environment.API_URL + 'campaign-request/delete-industry/' + id, this._cs.setHeader());
  }
}
