
import { Component, OnInit } from '@angular/core';
import { Utils } from '../../../../utils';
import { CommonService } from '../../../../service/common.service';
import { ActivatedRoute } from '@angular/router';
import { DigitalService } from '../../../../service/digital.service';
import { FormBuilder, FormGroup } from '@angular/forms';

declare const $: any;

@Component({
  templateUrl: 'digital-proposal-review.component.html',
  styleUrls: ['./digital-proposal-review.component.scss']
})

export class DigitalProposalReviewComponent implements OnInit {
  products = [];
  status: any = {
    loading: false,
    success: false,
    error: false
  };

  // for view and edit quote
  projectData = {};
  franchiseLogo = '';
  loading = false;
  item: any = 'item0'
  api_error = {};
  total: any = 0;
  frenchiseId: any = undefined;
  printQuote = {};
  iisa_quote = {};
  additionalQuantities = [];
  iisa_additionalQuantities = [];

  projectId: any = undefined
  supplierBidForm: FormGroup;
  filesArr: any = [];
  docArr: any = [];
  oldDocArr: any = [];
  checkExist: boolean = false;
  supplierId: any = undefined
  supplierEditData: any = undefined
  edit: boolean = false;
  supplierBidEditId: any = undefined
  project_supplier_id :any = undefined
  isvalue :boolean = false;
  id:any = ''
  category : any = [];
  category_name:any = []


  // end
  constructor(
    private utils: Utils,
    public commonService: CommonService,
    private _activatedRoute: ActivatedRoute,
    private _fb: FormBuilder,
    private digitalService:DigitalService) {}

  ngOnInit() {
    this.digitalService.getDigitalServices().subscribe((res) => {
      if (res['data']) {
        this.category = res['data']['original']['data']
      }
        if(this.category){
        this._activatedRoute.params.subscribe(
          (params) => {
            if (params['frenchiseId'] && params['proposal']) {
              try { this.frenchiseId = atob(params["frenchiseId"]); }
              catch (e) { this.frenchiseId = atob( decodeURIComponent(params["frenchiseId"]) ); }

              try { this.id = atob(params["proposal"]); }
              catch (e) { this.id = atob( decodeURIComponent(params["proposal"]) ); }

              this.filesArr = []
              this.docArr = []
              this.oldDocArr = []
              this.supplierBidForm = this._fb.group({
                customer_document: [''],
                sales_rep_id:[''],
                id:[''],
                customer_comment:[''],
                approve_reject:['']
              });
              this.getProjectDetails(this.id, this.frenchiseId);
            }
          }
        );
      }
    })
  }

  //added for print product specification display in capital
  getCapitalisedString = (str) => {
    const result = ((str.toString()).split('_')).join(' ');
    return result.charAt(0).toUpperCase() + result.slice(1);
  }

  //added for print product specification get additional quantity
  getAdditionalQuantities = (quantities) => {
    const qtys = Object.entries(quantities);
    const final = qtys.filter(row => {
      return row[1] !== null && row[1] !== '';
    });
    return final;
  }

  //added to display additional quantity
  getQtyHeading = (str) => {
    const name = str.substring(0, 3);
    return name.charAt(0).toUpperCase() + 'ty ' + str.charAt((str.length - 1));
  }

  getFormatedDate = (date) => {
    if (date) {
      const dateArr = date.split('-');
      return dateArr[1] + '-' + dateArr[0] + '-' + dateArr[2];
    } else {
      return date;
    }
  }

  getProjectDetails = (projectId, franchiseId) => {
    this.status = this.utils.showContainers(this.status, 'loading');
    this.digitalService.getRequestSiteUrl(projectId, franchiseId).subscribe(res => {
      this.loading = true
      this.projectData = res['data']['data'];
      let category = this.projectData['category'].split(',')
      this.supplierBidForm.get('customer_comment').setValue(this.projectData['customer_comment']? this.projectData['customer_comment']:'')
      // this.supplierBidForm.get('approve_reject').setValue(this.projectData['approve_reject']? this.projectData['approve_reject']:'')
      if(category.length > 0){
      category.map((cat) => {
          this.category.map((ctg)=> {
              if(cat == ctg['id']){
                  this.category_name.push(ctg['name'])
              }
          })
        })
      }
      
      if(this.projectData['customer_document'] != null){
      this.docArr = this.projectData['customer_document'] ? this.projectData['customer_document'] : [];
          this.oldDocArr = this.projectData['customer_document'] ? [...this.projectData['customer_document']] : [];

          this.docArr.forEach((value, keyDoc) => {
            this.filesArr[keyDoc] = [];
            const pathValue = this.commonService.checkImageTypeAws(value, 'digital_proposal', false, false, this.frenchiseId);
            if (!pathValue) {
              this.filesArr[keyDoc]['url'] = pathValue + value;
            } else {
              this.filesArr[keyDoc]['url'] = pathValue;
            }
          });
        }
      this.projectData['frenchise_data'] = res['data']['frenchise_data']
      this.projectData['franchise_logo'] = res['data']['franchise_logo']
      //ended
      if (this.projectData) {
        if(this.projectData['status'] ==  5 || this.projectData['status'] ==  6){
          this.checkExist = true;
        }
      }
      const logo = this.commonService.UPLOAD_FILE_AWS('franchise_images', false, franchiseId);
      this.franchiseLogo = logo + this.projectData['franchise_logo'];
      this.status = this.utils.showContainers(this.status, 'success');
      this.loading = false
    }, (error) => {
      this.status = this.utils.showContainers(this.status, 'success');
    })
  }

  uploadDoc = (event: any) => {
    const files = event.target.files;
    let i = this.filesArr.length;
    for (const file of files) {
      const ext = file['name'].substring(file['name'].lastIndexOf('.') + 1).toLowerCase();
      let flag = true;
      this.docArr.forEach((value) => {
        if (value === file.name) {
          flag = false;
        }
      });
      if (flag) {
        this.docArr.push(file.name);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event1: Event) => {
          this.filesArr[i] = {};
          this.filesArr[i]['file'] = file;
          const pathValue = this.commonService.getLogoUrl(ext);
          if (!pathValue) {
            this.filesArr[i]['url'] = event1.target['result'];
          } else {
            this.filesArr[i]['url'] = pathValue;
          }
          i++;
        };
      }
    }
  }

  removeDoc = (doc, i) => {
    this.filesArr.splice(i, 1);
    this.docArr.forEach((value, key) => {
      if (key === +i) {
        this.docArr.splice(key, 1);
      }
    });
    this.oldDocArr.forEach((value, key) => {

      if (key === +i) {
        this.oldDocArr.splice(key, 1);
      }
    });
  }

  setChec = (isSelect) => {
    if(isSelect){
      this.supplierBidForm.get('approve_reject').setValue(1)
    }else{
      this.supplierBidForm.get('approve_reject').setValue(0)
    }
  }

  saveSupplierBid() {
    this.status = this.utils.showContainers(this.status, 'loading');
    const postData = this.supplierBidForm.value;
    postData['customer_document'] = this.oldDocArr;
    postData['id'] = this.projectData['id'];
    postData['edit'] = this.edit;
    if (this.edit) {
      postData['editbid'] = this.supplierBidEditId;
    }
    postData['old_document'] = (this.oldDocArr != null) ? this.oldDocArr : [];
    const fData: FormData = new FormData;
    if (this.filesArr.length > 0) {
      for (const file of this.filesArr) {
        if (file['file']) {
          fData.append('customer_document[]', file['file']);
        }
      }
    }
    
    fData.append('id',postData['id'])
    fData.append('customer_comment',postData['customer_comment'])
    fData.append('approve_reject',postData['approve_reject'])
    fData.append('old_document',postData['old_document'])
    this.digitalService.saveDigitalReview(fData, this.frenchiseId).subscribe(res => {
      this.loading = true
      if (res['data']) {
          this.api_error =[]
        this.status = this.utils.showContainers(this.status, 'success');
        this.loading = false;
        this.ngOnInit();
      }
    }, error => {
      this.status = this.utils.showContainers(this.status, 'success');
      this.loading = false;
      if (error['error']) {
        this.api_error = error['error']['data'];
      }
    });
  }
}
