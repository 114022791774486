import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { CommonService } from './common.service';

@Injectable({ providedIn: 'root' })
export class SupplierService {
  constructor(private _http: HttpClient, private _cs: CommonService) {
  }

  supplierData = new BehaviorSubject({});
  getSupplierList = () => {
    return this._http.get(environment.API_URL + 'users/get-suppliers', this._cs.setHeader());
  }
  sendToSupplier = (supplierData) => {
    return this._http.post<any>(environment.API_URL + 'supplier/save', supplierData, this._cs.setHeader());
  }
  saveSupplierBid = (postData, franchiseId = '') => {
    return this._http.post<any>(environment.API_URL + 'supplier/save-bid', postData, this._cs.setHeader(franchiseId));
  }
  saveSupplierPublicBid = (postData, franchiseId = '') => {
    return this._http.post<any>(environment.API_URL + 'save-bid-link', postData, this._cs.setHeader(franchiseId));
  }
  saveEstimation = (postData, franchiseId = '') => {
    return this._http.post<any>(environment.API_URL + 'digital-services/submit-digital-estimate/'+ franchiseId, postData, this._cs.setHeader(franchiseId));
  }

  getPlacedBidData = (id, franchiseId = '') => {
    return this._http.post<any>(environment.API_URL + 'supplier/get-bids', { id }, this._cs.setHeader(franchiseId));
  }

  saveBill = (data, isDirect, franchiseId = '') => {
    let header:any = this._cs.setHeader();
    header['reportProgress'] = true;
    header['observe'] = "events";
    if (isDirect) {
      return this._http.post<any>(environment.API_URL + 'supplier/save-bill-direct', data, header);
    } else {
      return this._http.post<any>(environment.API_URL + 'supplier/save-bill', data, header);
    }
  }

  getBillOptions = () => {
    return this._http.get(environment.API_URL + 'supplier/get-bill-options', this._cs.setHeader());
  }

  getBillAmounts = (id) => {
    return this._http.get(environment.API_URL + 'supplier/get-bill-amounts/' + id, this._cs.setHeader());
  }

  getBillOptionsOfOrder = (orderId) => {
    return this._http.get(environment.API_URL + 'supplier/get-order-bill-options/' + orderId, this._cs.setHeader());
  }

  getNotificationsSupplier = () => {
    return this._http.get(environment.API_URL + 'supplier-global/notifications');
  }

  getAllNotificationsSupplier = () => {
    return this._http.get(environment.API_URL + 'supplier-global/all-notifications');
  }

  getAllBillsOfSupplier = (id) => {
    return this._http.get(environment.API_URL + 'supplier/supplier-bills/' + id, this._cs.setHeader());
  }

  saveMultipleBillPayment = (data) => {
    return this._http.post(environment.API_URL + 'supplier/save-multiple-payments', data, this._cs.setHeader());
  }

  saveBillPaymentAndCredits = (data) => {
    return this._http.post(environment.API_URL + 'supplier/save-payments-with-credits', data, this._cs.setHeader());
  }
  getSupplierForService = () => {
    return this._http.post(environment.API_URL + 'getAllSupplier',this._cs.setHeader());
  }
  getSupplierForDigital = () => {
    return this._http.get(environment.API_URL + 'digital-services/get-suppliers',this._cs.setHeader());
  }

  addSageSupplierToGlobal = (data) => {
    return this._http.post(environment.API_URL + 'users/add-sage-supplier-to-global', data, this._cs.setHeader());
  }
}
