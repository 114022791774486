import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class DevFranchiseService {
    franchiseData = new BehaviorSubject({});

    constructor(private _http: HttpClient) {
    }

    saveFranchiseDetail = (fData) => {
        return this._http.post<any>(environment.API_URL + 'developer-admin/save-franchise', fData);
    }

    getAllFranchise = () => {
        return this._http.get(environment.API_URL + 'developer-admin/all-franchise');
    }
    getAllCompany = () => {
        return this._http.get(environment.API_URL + 'developer-admin/all-companies');
    }
    getFranchiseDetailById = (editId) => {
        return this._http.get(environment.API_URL + 'developer-admin/franchise/' + editId);

    }
    getRoleByFranchiseId = (franchise_id) => {
        return this._http.post<any>(environment.API_URL + 'developer-admin/roles-by-franchise', {franchise_id});
    }
    refreshFranchiseData = () => {
        this.getAllFranchise().subscribe((response) => {
            this.franchiseData.next(response['data']);
        });
    }
}
