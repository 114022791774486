import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { DataTableService } from '../../service/datatable.service';
import { CommonService } from '../../service/common.service';
import { FormGroup, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap';
import { UserService } from '../../service/user.service';
import { states } from '../../../assets/json-options/usa-states';
import { countryCodes } from '../../../assets/json-options/country-codes';
import { ExportService } from '../../service/export.service';
import { CategoryService } from '../../service/category.service';
import { environment } from '../../../environments/environment';
import Swal from 'sweetalert2';

declare var $;

@Component({
  templateUrl: './global-supplier.component.html',
  styleUrls: ['./global-supplier.component.scss'],
})
export class GlobalSupplierComponent implements OnInit, OnDestroy {
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  data: any = [];
  dtTrigger: Subject<any> = new Subject();
  @ViewChild('suppliermodal') suppliermodal: ModalDirective;
  suppplierCreateForm: FormGroup;
  showModel: boolean = false;
  sup_api_error: any = [];
  isReadOnly = false;
  isShipping = false;
  stateList = states;
  countryList = countryCodes;
  loading = false;
  api_error: any = [];
  docArr: any = [];
  oldDocArr: any = [];
  filesArr: any = [];
  showImport = true;
  edit: boolean = false;
  editId: any =undefined;
  categoryList : any = [];
  filesArray: any = {};
  file: any = [];
  fileName = '';
  imageUrl = '';
  dropdownSettings: any = [];
  selectedItems:any =[];
  isPromoStandardSupplier = false;

  constructor(private _dtService: DataTableService, private _cs: CommonService, private userService: UserService, private exportService: ExportService,
    private _fb: FormBuilder,private category : CategoryService) { }

  ngOnInit() {
    this.getCategory();
    this.getAllSupplierList();
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'category_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  getCategory = () => {
    this.category.getGlobalCategory().subscribe((res) => {
      if(res){
       const supplierData = res['data'];
        const arr = [];
        Object.keys(supplierData).forEach(key => {
          arr.push({
            id: supplierData[key]['id'],
            category_name: supplierData[key]['category_name']
          });
        });

        this.categoryList = arr;
      }
    })
  }

  getAllSupplierList = () => {
    this.dtOptions = {
      dom: "<'row'<'col-sm-12 col-md-4'l><'col-sm-12 col-md-4 top'i><'col-sm-12 col-md-4 top'f>>" +
      "<'row'<'col-sm-12'tr>>" +
      "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
      pagingType: 'full_numbers',
      pageLength: 10,
      lengthMenu: [5, 10, 25, 50],
      serverSide: true,
      processing: true,
      language: {
        searchPlaceholder: 'Search...',
        search: ''
      },
      ajax: (dataTablesParameters: any, callback) => {
        this._dtService.getTableData(dataTablesParameters, 'getAllSupplier', '', '',true).subscribe(res => {
          this.data = res.data.original.data;
          if (this.data.length > 0) {
            this._cs.introType.next('supplier');
          } else {
            this._cs.introType.next('');
          }
          callback({
            recordsTotal: res.data.original.recordsTotal,
            recordsFiltered: res.data.original.recordsFiltered,
            data: []
          });
        });
      },
      order: [],
      columns: [
        { name: 'first_name' },
        { name: 'last_name' },
        { name: 'email' },
        { name: 'display_name' },
        { name: 'mobile_no' },
        { name: 'global_supplier' },
        { data: null }
      ]
    };
  }

  initShippingDetails = (shippingData=null) => {
    if(shippingData) {
      return {
        is_same: [shippingData['is_same']],
        s_address1: [shippingData['s_address1']],
        s_address2: [shippingData['s_address2']],
        s_city: [shippingData['s_city']],
        s_state: [shippingData['s_state']],
        s_zip_code: [shippingData['s_zip_code']],
        s_country: [shippingData['s_country']],
      };
    }
    else {
      return {
        is_same: [''],
        s_address1: [''],
        s_address2: [''],
        s_city: [''],
        s_state: [''],
        s_zip_code: [''],
        s_country: ['US'],
      };
    }
  }

  setShippingInfoSupplier = (isChecked) => {
    this.isReadOnly = false;
    this.isShipping = isChecked;
    const shippingControls = this.suppplierCreateForm.get('shipping_address');
    shippingControls.get('s_state').enable();
    shippingControls.get('s_country').enable();
    if (isChecked) {
      shippingControls.get('is_same').setValue(true);
      this.suppplierCreateForm.get('same_address').setValue(true);
      shippingControls.get('s_address1').setValue(this.suppplierCreateForm.get('address_line_1').value);
      shippingControls.get('s_address2').setValue(this.suppplierCreateForm.get('address_line_2').value);
      shippingControls.get('s_city').setValue(this.suppplierCreateForm.get('city').value);
      shippingControls.get('s_state').setValue(this.suppplierCreateForm.get('state_code').value);
      shippingControls.get('s_country').setValue(this.suppplierCreateForm.get('country').value);
      shippingControls.get('s_zip_code').setValue(this.suppplierCreateForm.get('zip_code').value);
      shippingControls.get('s_state').disable();
      shippingControls.get('s_country').disable();
      this.isReadOnly = true;
    } else {
      shippingControls.get('s_address1').setValue('');
      shippingControls.get('s_address2').setValue('');
      shippingControls.get('s_city').setValue('');
      shippingControls.get('s_state').setValue('');
      shippingControls.get('s_zip_code').setValue('');
      shippingControls.get('is_same').setValue(false);
      this.suppplierCreateForm.get('same_address').setValue(false);
    }
  }

  addNewSupplierOpen = () => {
    this.edit = false;
    this.editId = undefined;
    this.sup_api_error = {}
    this.suppliermodal.show();
    this.showModel = true;
    this.imageUrl = '';
    this.isPromoStandardSupplier = false;
    $('#promo-standard-switch').prop('checked', true);
    this.suppplierCreateForm = this._fb.group({
      first_name: [''],
      last_name: [''],
      display_name:[''],
      company_name:[''],
      email: [''],
      accounting_email: [''],
      additional_emails: this._fb.array([['']]),
      mobile_no: [''],
      website_detail: [''],
      business_id_number: [''],
      was_approved: [false],
      address_line_1: [''],
      address_line_2: [''],
      city: [''],
      notes: [''],
      state_code: [''],
      zip_code: [''],
      country: ['US'],
      same_address: [false],
      product_type:[''],
      image:[''],
      global_supplier:[1],
      shipping_address: this._fb.group(this.initShippingDetails()),
      promo_standard_supplier_code: [''],
      promo_standard_username: [''],
      promo_standard_password	: [''],
      promo_standard_supplier_services: this._fb.array([
        this._fb.group({id: null, service_name: 'Product Data', service_code: 'Product', service_version: '', end_point: '', is_live_url: true}),
        this._fb.group({id: null, service_name: 'Media Content', service_code: 'MED', service_version: '', end_point: '', is_live_url: true}),
        this._fb.group({id: null, service_name: 'Product Pricing and Configuration', service_code: 'PPC', service_version: '', end_point: '', is_live_url: true}),
        this._fb.group({id: null, service_name: 'Inventory', service_code: 'INV', service_version: '', end_point: '', is_live_url: true})
      ])
    })

    let globalSupplierInterval = setInterval(() => {
      if($('#globalSupplierOption').length != 0) {
        $('#globalSupplierOption').prop('checked', true);
        $('#globalSupplierOption').attr("disabled", true);
        clearInterval(globalSupplierInterval);
      }
    }, 1);
  }

  rerender = (): void => {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  addAdditionalEmail() {
    const control = <FormArray>this.suppplierCreateForm.get('additional_emails');
    control.push(this._fb.control(''));
  }
  
  removeAdditionalEmail(index) {
    const control = <FormArray>this.suppplierCreateForm.get('additional_emails');
    control.removeAt(index);
  }
  
  saveSupplierDetails = () => {
    this.loading = true;
    let additionalEmails = this.suppplierCreateForm.get('additional_emails').value.filter(item => item != null);
    this.suppplierCreateForm.get('shipping_address').get('s_state').enable();
    this.suppplierCreateForm.get('shipping_address').get('s_country').enable();
    if (this.suppplierCreateForm.get('shipping_address').get('is_same').value) {
      this.suppplierCreateForm.get('shipping_address').get('s_state').disable();
      this.suppplierCreateForm.get('shipping_address').get('s_country').disable();
    }

    const postSupplierData = { ...this.suppplierCreateForm.value };
    postSupplierData['id'] = this.editId;
    const arr = [];
    if (postSupplierData['product_type'].length > 0) {
      for (const row of postSupplierData['product_type']) {
        arr.push(row['id']);
      }
    }
    postSupplierData['product_type'] = arr;
    postSupplierData['old_image'] = this.fileName;
    postSupplierData.promo_standard_supplier_services = postSupplierData.promo_standard_supplier_services.filter(item => item.service_version && item.end_point);
    
    const fData: FormData = new FormData;
    delete postSupplierData['additional_emails'];
    additionalEmails.forEach((element, index) => {
      let key = 'additional_emails['+index+']';
      if (element) fData.append(key, element);
    });
    
    postSupplierData.promo_standard_supplier_services.forEach((object, index) => {
      for(let property in object) {
        let key = 'promo_standard_supplier_services['+index+']['+property+']';
        fData.append(key, object[property] || '');
      }
    });
    delete postSupplierData.promo_standard_supplier_services;

    if (this.file) {
      fData.append('image', this.file);
    }
    postSupplierData['shipping_address'] = JSON.stringify(postSupplierData['shipping_address']);
    Object.keys(postSupplierData).forEach(field => {
      fData.append(field, postSupplierData[field]);
    });
    
    this.userService.createGlobalSupplier(fData).subscribe((res) => { //postSupplierData
      this.loading = false;
      this.suppliermodal.hide();
      this.sup_api_error = {}
      this.showModel = false;
      this.rerender();
    }, error => {
      this.loading = false;
      if (error['error']) {
        this.sup_api_error = error['error']['data'];
        if(this.sup_api_error['email']) {
          if(this.sup_api_error['email'][0] == 'The email has already been taken.') {
            this.sup_api_error['email'][0] = ''; // For Hiding this error at bottom of email field
            this.updateAsGlobleSupplier(postSupplierData['email'], 'The email has already been taken.');
          }
        }
      }
    })
  }

  uploadListener(event: any): void {
    this.filesArr = [];
    const files = event.target.files;
    let i = this.filesArr.length;
    if (files.length > 0) {
      this.showImport = false;
    } else {
      this.showImport = true;
    }
    for (const file of files) {
      const ext = file['name'].substring(file['name'].lastIndexOf('.') + 1).toLowerCase();
      let flag = true;
      this.docArr.forEach((value) => {
        if (value === file.name) {
          flag = false;
        }
      });
      if (flag) {
        this.docArr.push(file.name);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event1: Event) => {
          this.filesArr[i] = {};
          this.filesArr[i]['file'] = file;
          const pathValue = this._cs.getLogoUrl(ext);
          if (!pathValue) {
            this.filesArr[i]['url'] = event1.target['result'];
          } else {
            this.filesArr[i]['url'] = pathValue;
          }
          i++;
        };
      }
    }
  }

  submitFile = () => {
    const fData: FormData = new FormData;
    if (this.filesArr.length > 0) {
      for (const file of this.filesArr) {
        if (file['file']) {
          fData.append('files[]', file['file']);
        }
      }
      this.loading = true;
      this.userService.importglobalSupplierCsv(fData).subscribe(
        (res) => {
          this.api_error = [];
          this.loading = false;
          $("input[type='file']").val(null)
          this.showImport = false;
          this.rerender();
          // this._dtService.rerenderDatatable.next();
        }, error => {
          this.loading = false;
          if (error['error']) {
            this.api_error = error['error']['data'];
          }
        }
      );
    }
  }

  exportCsv = () => {
    this.loading = true;
    this.exportService.getSupplierCsv().subscribe((res: any) => {
      this.loading = false;
      const blobData = new Blob([res], { type: 'application/octet-stream' });
      const a = document.createElement('a');
      a.href = URL.createObjectURL(blobData);
      a.download = 'global_supplier.csv';
      document.body.appendChild(a);
      a.click();
    }, (error) => {
      this.loading = false;
    });
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  openEdit = (itemVal) => {
    this.filesArray = {};
    this.file = [];
    this.fileName = '';
    this.imageUrl = '';
    let checkedVal;
    if(itemVal['address_details']) {
      if(itemVal['address_details']['shipping_address']['is_same'] == "true" || itemVal['address_details']['shipping_address']['is_same'] == "1") {
        checkedVal = true;
      }
    }else {
      checkedVal = false;
    }
    this.edit = true;
    this.editId = itemVal['id']
    this.sup_api_error = {}
    this.suppliermodal.show();
    this.showModel = true;
    var splitArray = itemVal['product_type'].split(',');
    var selected = [];
    if(splitArray.length){
      splitArray.forEach(element => {
        this.categoryList.map((category)=> {
          if(category['id'] == element){
            selected.push(category);
          }
        });
        this.selectedItems = selected;
      });
    }
    this.suppplierCreateForm = this._fb.group({
      first_name: [itemVal['first_name'] || ''],
      last_name: [itemVal['last_name'] || ''],
      company_name:[itemVal['company_name'] || ''],
      display_name:[itemVal['display_name'] || ''],
      email: [itemVal['email'] || ''],
      accounting_email: [itemVal['accounting_email'] || ''],
      additional_emails: this._fb.array([['']]),
      mobile_no: [itemVal['mobile_no'] || ''],
      business_id_number: [itemVal['business_id_number'] || ''],
      website_detail: [itemVal['website_detail'] || ''],
      was_approved: [false],
      address_line_1: [itemVal['address_details'] ? itemVal['address_details']['address_line_1'] : ''],
      address_line_2: [itemVal['address_details'] ? itemVal['address_details']['address_line_2'] : ''],
      city: [itemVal['address_details'] ? itemVal['address_details']['city'] : ''],
      notes: [itemVal['notes'] || ''],
      state_code: [itemVal['address_details'] ? itemVal['address_details']['state_code'] : ''],
      zip_code: [itemVal['address_details'] ? itemVal['address_details']['zip_code'] : ''],
      country: [itemVal['address_details'] ? itemVal['address_details']['country'] : ''],
      same_address: [checkedVal],
      product_type:[this.selectedItems],
      global_supplier:[itemVal['global_supplier'] || false],
      image:[''],
      shipping_address: this._fb.group(this.initShippingDetails(itemVal['address_details'] ? itemVal['address_details']['shipping_address'] : null)),
      promo_standard_supplier_code: [itemVal['promo_standard_supplier_code'] || ''],
      promo_standard_username: [itemVal['promo_standard_username'] || ''],
      promo_standard_password: [itemVal['promo_standard_password'] || ''],
      promo_standard_supplier_services: this._fb.array([
        this._fb.group({id: '', service_name: 'Product Data', service_code: 'Product', service_version: '', end_point: '', is_live_url: true}),
        this._fb.group({id: '', service_name: 'Media Content', service_code: 'MED', service_version: '', end_point: '', is_live_url: true}),
        this._fb.group({id: '', service_name: 'Product Pricing and Configuration', service_code: 'PPC', service_version: '', end_point: '', is_live_url: true}),
        this._fb.group({id: '', service_name: 'Inventory', service_code: 'INV', service_version: '', end_point: '', is_live_url: true})
      ])
    });
        
    if (itemVal['promo_standard_supplier_code']) {
      this.isPromoStandardSupplier = true;
      setTimeout(() => $('#promo-standard-switch').prop('checked', true), 0);
      this.suppplierCreateForm.get('promo_standard_supplier_services')['controls'].forEach(control => {
        let editedSupplierService = itemVal['promo_standard_supplier_services'].find(item => item.service_code == control.value.service_code);
        if (editedSupplierService) control.patchValue(editedSupplierService);
      });
    } else {
      this.isPromoStandardSupplier = false;
      setTimeout(() => $('#promo-standard-switch').prop('checked', false), 0);
    }

    if (itemVal['additional_emails']) {
      let additional_emails = (itemVal['additional_emails'] || []);
      const control = <FormArray>this.suppplierCreateForm.get('additional_emails');
      while(control.length) {
        control.removeAt(0);
      }
      additional_emails.forEach((item) => {
        control.push(this._fb.control(item));
      });
    }
    if (itemVal['image']) {
      this.filesArray['image'] = [];
      this.fileName = itemVal['image'];
      this.imageUrl = environment.AWS_SUPPLIER_PATH + itemVal['image'];
      this.filesArray['image']['url'] = itemVal['image'];
    }
    let globalSupplierInterval = setInterval(() => {
      if($('#globalSupplierOption').length != 0) {
        if(itemVal['global_supplier'] == 0) {
          $('#globalSupplierOption').prop('checked', false);
        }else {
          $('#globalSupplierOption').prop('checked', true);
        }
        $('#globalSupplierOption').removeAttr("disabled");
        clearInterval(globalSupplierInterval);
      }
    }, 1);    
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }

  removeImage = (index) => {
    this.file = '';
    this.imageUrl = '';
    this.fileName = '';
    delete this.filesArray[index];
    this.suppplierCreateForm.get(index).reset();
  }

  logoImages = (event, index) => {
    if (event.target.files && event.target.files[0]) {
      this.file = event.target.files[0];
      this.filesArray[index] = [];
      this.filesArray[index]['file'] = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event1: Event) => {
        this.imageUrl = event1.target['result'];
        this.filesArray[index]['url'] = event1.target['result'];
      };
    }
  }

  updateAsGlobleSupplier = (email, error_msg) => {
    let postData = {};
    postData['email'] = email;
    this.userService.updateUserAsGlobalSupplierByEmail(postData).toPromise()
    .then((res) => {
      if (res['data']) {
        if(res['data']['global_supplier_created']) {
          this.showCreatedGlobleSupplierMsg(res['message']);
          console.log("res['data']['global_supplier_created'] : ", res['data']['global_supplier_created']);
        }
      }
    },(error) => {
      if(error['error']['data']) {
        if(error['error']['data']['cant_create_globle_supplier']) {
          console.log("error['error']['data']['cant_create_globle_supplier'] : ", error['error']['data']['cant_create_globle_supplier']);
          // this.showCantCreateGlobleSupplierMsg();
          console.log('ASSSSSSS');
          this.sup_api_error['custom_error'] = 'The given email is already registered with another role so using this email cannot create a global supplier.';
          console.log("this.sup_api_error : ", this.sup_api_error);
        }
      }
    }).catch(() => {
      
    });
  }

  showCantCreateGlobleSupplierMsg = () => {
    Swal.fire({
      title: 'Oops...',
      text: 'The given email is already registered with another role so using this email cannot create a global supplier.',
      type: 'error',
      preConfirm: (value) => {
        this.suppliermodal.hide();
      }
    })
  }

  showCreatedGlobleSupplierMsg = (msg) => {
    if(msg == 'User Created as Globle supplier') {
      msg = "The given email is already exists in system, so it's Created as Globle supplier";
    } else if(msg == 'User is already a Globle supplier') {
      msg = 'User is already a Globle supplier of your given email.';
    }

    Swal.fire({
      title: msg,
      type: 'success',
      preConfirm: (value) => {
        this.suppliermodal.hide();
        this.rerender();
      }
    })
  }

  clearState = (event) => {
    if (event.target.id == 's_country') {
      if (event.target.value != 'US') {
        this.suppplierCreateForm.get('shipping_address').get('s_state').setValue('');
      } else {
        this.suppplierCreateForm.get('shipping_address').get('s_state').setValue('');
      }
    }

    if (event.target.id == 'country') {
      if (event.target.value != 'US') {
        this.suppplierCreateForm.get('state_code').setValue('');
      } else {
        this.suppplierCreateForm.get('state_code').setValue('');
      }
    }
  }

  isCreateGlobal = (createGlobal) => {
    this.suppplierCreateForm.get('global_supplier').setValue(createGlobal ? 1 : 0);
  }

  togglePromoStandardForm(checked) {
    this.isPromoStandardSupplier = checked;
  }
}
