import { OnInit, AfterViewInit, OnDestroy,OnChanges, Component, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { AuthenticationService } from '../../service/authentication.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'sticky-notes-board',
  templateUrl: './sticky-notes-board.component.html',
  styleUrls: ['./sticky-notes-board.component.scss']
})
export class StickyNotesBoardComponent implements OnInit {
  @Input() stickyNotesData: any;
  @Output() deletedNote = new EventEmitter();
  @Output() editNote = new EventEmitter();
  currentUserId:any = '';
  constructor(private authService: AuthenticationService) { }

  ngOnInit() {
    this.authService.userData.subscribe(
      (res) => {
        this.currentUserId = res['id'];
      }
    );
  }

  ngOnChanges(){}


  formatCreatedDate = (date) => {
    if(date) {
      let formattedDate = moment(date).format("LL");
      return formattedDate;
    }
  }

  displayCustomRoleName = (role) => {
    switch(role) {
      case 'Estimator HOD': 
        return 'Account Manager';
      case 'Sales Rep' :
        return 'Distributor';
      default:
        return role;
    }
  }

  deleteStickyNote = (noteData) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((confirmed) => {
      if (confirmed.value) {
        this.deletedNote.emit(noteData);
      }
    })
  }

  editStickyNote = (noteData) => {
    this.editNote.emit(noteData);
  }
}
