import { Pipe } from "@angular/core";

@Pipe({
  name: "phonePipe"
})
export class PhonePipe {
  transform(rawNum:string) {
    if(rawNum) {
      var rawNum = rawNum.replace(/[^\w\s]/gi, '').replace(/\s/g, "");
      rawNum = "+1"+ rawNum;

      // const countryCodeStr = rawNum.slice(0,2);
      const areaCodeStr = rawNum.slice(2,5);
      const midSectionStr = rawNum.slice(5,8);
      const lastSectionStr = rawNum.slice(8);

      return `(${areaCodeStr}) ${midSectionStr}-${lastSectionStr}`;
    }else {
      return '';
    }
  }
}
