import { Component, OnInit,ViewChild,ElementRef,ChangeDetectorRef ,AfterViewInit,OnDestroy} from '@angular/core';
import { CommonService } from '../../../../service/common.service';
import { ActivatedRoute } from '@angular/router';
import { ProjectService } from '../../../../service/project.service';
import { SalesOrderService } from '../../../../service/sales-order.service';
import { DecimalPipe } from '@angular/common';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import * as moment from 'moment';

declare const $: any;

@Component({
  templateUrl: 'public-order-payment.component.html',
  styleUrls: ['./public-order-payment.component.scss']
})

export class PublicOrderPaymentComponent implements OnInit,AfterViewInit, OnDestroy {
  @ViewChild('cardInfo') cardInfo: ElementRef;
  error: any = '';
  onChange = ( error ) => {
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    this.cd.detectChanges();
  }
  card: any;
  eps:any;
  cardHandler = this.onChange.bind(this);
  products = [];
  status: any = {
    loading: false,
    success: false,
    error: false
  };
  // for view and edit quote
  estimationData = {};
  franchiseLogo = '';
  loading = false;
  item: any = 'item0'
  api_error :any = '';
  total: any = 0;
  frenchiseId: any = undefined;
  approveRejectReason: any = '';
  showErrorMsg: any = false
  showItemErrorMsg: any = false
  approvedOrRejectedEstimation: any = {}
  estId: any = undefined
  approvalCheck: any = 1;
  approvalRejectComment: any = undefined;
  approvalText: any = undefined
  newDisplaySelectedTotal: any = 0
  project_type = ''
  checkedElements:any=[]
  message  = ''
Path :any = ''
imagePreviewSource:any= ''
item_id:any = ''
proof_status:any = ''
handler:any = null;
transferMsg:any = ''
orderDataInfo:any = []
paymentForm: FormGroup;
displayMessage: string;
paymentToken = {};
amount:any;
showCard:boolean = true
reciept:any = ''
card_errors:any = {}
errorAmountCard:any = ''
errorAmountBankTransfer:any = ''
paymentMethod:any = 'card';
bankTransferPaymentForm: FormGroup;
cardPaymentForm: FormGroup;
invoiceData = {};
orderData: any = [];
invoiceAmountData:any = [];
totalData = {
  subtotal: '0',
  discount_amount: '0',
  taxable_subtotal: '0',
  tax_amount: '0',
  shipping_charge: '0',
  total_amount: '0'
}
paymentOption:any = 3;
paymentCompleted:boolean = false;

monthsArray:any = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
yearsArray:any = [];
showCardPaymentErrors:boolean = false;
showBankPaymentErrors:boolean = false;
quickbookPaymentError:any = [];

  // end
  constructor(
    public commonService: CommonService,
    private _activatedRoute: ActivatedRoute,
    private projectService: ProjectService,
    private orderService : SalesOrderService,
    private cd: ChangeDetectorRef,
    private decimalPipe: DecimalPipe,
    private _fb: FormBuilder) {

  }
  //start
  ngAfterViewInit() {
    const style = {
      base: {
        lineHeight: '24px',
        fontFamily: 'monospace',
        fontSmoothing: 'antialiased',
        fontSize: '19px',
        '::placeholder': {
          color: 'purple'
        },
        width:'500px',
        heigh:'500px',
      }
    };
    
    this.card = elements.create('card');
    
    var iframe = document.getElementsByTagName('iframe')
    iframe[0].style.width = "430px";

    this.card.addEventListener('change', this.cardHandler);
  }

  ngOnDestroy() {
    this.card.removeEventListener('change', this.cardHandler);
    this.card.destroy();
  } 
    
  async onSubmit(form: NgForm) {
    // Stripe Card Payment
    // if(typeof(this.amount) == 'string') {
    //   this.amount = Number(this.amount.replace(/,/g, ''));
    // }
    // this.loading = true;
    
    // const { token, error } = await stripe.createToken(this.card);
    // if (error) {
    //     this.loading = false;
    // this.card_errors = error
    // } else {
    //   this.card_errors = {}
    //     if(token && +this.amount > 0){
    //         let data = token
    //         data['sourceID']=token['id']
    //         data['order_id'] = this.estId
    //         data['frenchise_id'] = this.frenchiseId
    //         data['payment_type'] = token['type']
    //         data['amount'] = +this.amount;
    //         this.orderService.makeOrderPayment(this.frenchiseId,data).subscribe((res)=>{
    //             this.loading = false;
    //             this.amount = null;
    //             this.card.clear();
    //             $('#card_payment_amount').val('');
    //             if(res['data']){
    //              this.reciept =   res['data']['receipt_url']
    //             }
    //             this.ngOnInit()
    //             this.loading = false
    //         },(error)=>{
    //             this.loading = false
    //             if (error['error']) {
    //                 this.api_error = error['error']['message'];
    //             }

    //         })
    //     }else {
    //       this.loading = false
    //       this.errorAmountCard = "Please Enter Amount!"
    //     }
    //     if(token && !this.amount){
    //       this.errorAmountCard = "Please Enter Amount!"
    //     }
    // }
    // End Stripe Card Payment


    // Quickbook Card Payment
    if((+this.amount < 0) || !this.amount){
      this.errorAmountCard = "Please Enter Amount!"
    }

    if(this.cardPaymentForm.valid) {
      let cardForm = this.cardPaymentForm.value;

      if(typeof(this.amount) == 'string') {
        this.amount = Number(this.amount.replace(/,/g, ''));
      }
      this.card_errors = {}
      if(+this.amount > 0) {
          let data = cardForm;
          data['payment_type'] = 'card';
          data['order_id'] = this.estId
          data['frenchise_id'] = this.frenchiseId
          data['amount'] = (+this.amount).toFixed(2);
          this.loading = true;
          this.quickbookPaymentError = [];
          this.orderService.makeOrderPayment(this.frenchiseId,data).subscribe((res)=>{
              this.loading = false;
              this.amount = null;
              // this.card.clear();
              $('#card_payment_amount').val('');
              if(res['data']){
                this.reciept =   res['data']['receipt_url']
              }
              this.ngOnInit()
              this.loading = false
          },(error)=>{
              this.loading = false
              if (error['error']) {
                  this.api_error = error['error']['message'];
                  if(error['error']['data']) {
                    this.quickbookPaymentError = error['error']['data'];
                  }
              }
          })
      }else {
        this.loading = false
        this.errorAmountCard = "Please Enter Amount!"
      }
      if(!this.amount){
        this.errorAmountCard = "Please Enter Amount!"
      }
    }else {
      this.showCardPaymentErrors = true;
    }
    // End Quickbook Card Payment
  }

  ngOnInit() {
    let currentYear = moment().year();
    for(let i = 0; i <= 30; i++)  {
      this.yearsArray.push(currentYear + i);
    }

    this._activatedRoute.params.subscribe((params) => {
      if (params['franchise_id'] && params['order_id']) {
        try { this.frenchiseId = atob(params["franchise_id"]); }
        catch (e) { this.frenchiseId = atob( decodeURIComponent(params["franchise_id"]) ); }

        try { this.estId = atob(params["order_id"]); }
        catch (e) { this.estId = atob( decodeURIComponent(params["order_id"]) ); }
        
        this.getInvoiceData();
      }
    });

    this.bankTransferPaymentForm = this._fb.group({
      payment_amount: ['', Validators.required],
      routing_number: ['', [Validators.required]], //, Validators.minLength(9), Validators.maxLength(9)
      account_number: ['', [Validators.required]], //, Validators.minLength(16), Validators.maxLength(17)
      phone_number: ['', [Validators.required]], //, Validators.minLength(10), Validators.maxLength(10)
      account_holder: ['', [Validators.required]]
    });

    this.cardPaymentForm = this._fb.group({
      card_number: ['', [Validators.required]], //,Validators.minLength(16),Validators.min(1111111111111111),Validators.max(9999999999999999)
      exp_month: ['', [Validators.required]], //,Validators.minLength(1),Validators.maxLength(2),Validators.min(1),Validators.max(12)
      exp_year: ['', [Validators.required]], //,Validators.minLength(4),Validators.maxLength(4),Validators.min(1111),Validators.max(9999)
      postal_code: ['', [Validators.required]], //, Validators.minLength(5), Validators.maxLength(5)
      cvc: ['', [Validators.required]] //,Validators.minLength(3),Validators.maxLength(4)
    });

    this.initialiseInvoiceData();
    this.getOrderData();
    // this.loadStripe();

    // this.buildForm();
  }

  getOrderData = () => {
      this.loading = true
      this.orderService.getOrderPublicData(this.frenchiseId,this.estId).subscribe((res)=> {
        if(res['data']){
          this.orderDataInfo = res['data']
            this.loading = false
            if(!this.invoiceAmountData['received']){
              if(res['data']['bill_payment_term']){
                this.amount =(res['data']['bill_payment_term'] == 1? Number(+res['data']['total_amount']/2).toFixed(2) : Number(+res['data']['total_amount']).toFixed(2))// res['data']['total_amount']
              }else{
                this.amount = Number(+res['data']['total_amount']).toFixed(2)// res['data']['total_amount']
              }
           
            $('#card_payment_amount').val(Number(+this.amount).toFixed(2))
            this.bankTransferPaymentForm.get('payment_amount').setValue(Number(+this.amount).toFixed(2))
            }else{
              this.amount = Number(+this.invoiceAmountData['due']).toFixed(2)
              this.bankTransferPaymentForm.get('payment_amount').setValue(Number(+this.amount).toFixed(2))
              $('#card_payment_amount').val(Number(+this.amount).toFixed(2))
            }
            this.estimationData = res['data']
            this.showCard =  res['data']['ask_for_payment'] ? true:false
            const logo = this.commonService.UPLOAD_FILE_AWS('franchise_images', false, this.frenchiseId);
            this.franchiseLogo = logo + this.estimationData['franchise_logo'];
        }
      },(error)=> {

      })
  }

  getInvoiceData = () => {
    this.loading = true;
    this.orderService.getPublicOrderInvoice(this.frenchiseId, this.estId).subscribe(
    // this.orderService.getOrderInvoice(this.estId).subscribe(
      (res) => {
        this.loading = false;
        this.initialiseInvoiceData();
        this.orderData = res['data'];
        this.paymentOption = this.orderData['payment_option'];
        this.invoiceAmountData = this.orderData['received'];
        if(!this.invoiceAmountData['received'] && this.orderDataInfo){
          if(this.orderDataInfo['bill_payment_term']){
            this.amount =(this.orderDataInfo['bill_payment_term'] == 1? Number(+this.orderDataInfo['total_amount']/2).toFixed(2) : Number(+this.orderDataInfo['total_amount']).toFixed(2))// res['data']['total_amount']
          }else{
            this.amount = Number(+this.orderDataInfo['total_amount']).toFixed(2)// res['data']['total_amount']
          }
          // this.amount =(this.orderDataInfo['data']['bill_payment_term'] == 1? (+this.orderDataInfo['data']['total_amount']/2) : this.orderDataInfo['data']['total_amount'])// res['data']['total_amount']
          $('#card_payment_amount').val(Number(+this.amount).toFixed(2))
          this.bankTransferPaymentForm.get('payment_amount').setValue(Number(+this.amount).toFixed(2))
          }else{
            this.amount = Number(+this.invoiceAmountData['due']).toFixed(2)
            this.bankTransferPaymentForm.get('payment_amount').setValue(Number(+this.amount).toFixed(2))
            $('#card_payment_amount').val(Number(+this.amount).toFixed(2))
          }
        if(this.invoiceAmountData['due'] == 0) {
          this.paymentCompleted = true;
        }
        if(this.orderData['order_invoice']) {
          this.invoiceAmountData['invoice_uid'] = this.orderData['order_invoice']['invoice_uid']; 
          // this.getInvoiceAmounts(this.orderData['order_invoice']['id']);
        }
        // this.invoiceId = this.orderData['order_invoice']['id'];
        this.project_type = res['data']['project_of_order'] ? res['data']['project_of_order']['project_type'] : ''
        const tempOrderData = JSON.parse(JSON.stringify(this.orderData['order_items']));
        // const invoiceitems = JSON.parse(JSON.stringify(this.orderData['order_invoice_items']));
        // for (const item of invoiceitems){
        //   this.invoiceSubtotal += +item['price'];
        // }
        for (const data of tempOrderData) {
          data['price'] = Math.round(data['price']);
          data['tax'] = Math.round((data['tax'] * data['price']) / 100);
          data['shipping_cost'] = Math.round(data['shipping_cost']);
          data['shipping_markup_amount'] = Math.round(data['shipping_markup_amount']);
          data['total'] = Math.round((data['price'] + data['tax'] + data['shipping_cost'] + data['shipping_markup_amount']));
          this.invoiceData['subTotal'] += data['price'];
          this.invoiceData['tax'] += data['tax'];
          this.invoiceData['shipping'] += (data['shipping_cost'] + data['shipping_markup_amount']);
          this.invoiceData['total'] += data['total'];
        }
        Object.keys(this.totalData).map(
          (key) => {
            if (this.orderData[key]) {
              this.totalData[key] = this.orderData[key];
            }
          }
        );
      },
      (error) => {
        this.loading = false;
      }
    );
  }
  
onAmountUpdate = (event) => {
  let unformattedvalue = event.target.value;
  unformattedvalue = Number(unformattedvalue.replace(/,/g, ''));
  if(event.target.value){
    this.api_error = '';
    if(unformattedvalue > 0) {
      if(this.paymentMethod == 'card') {
        this.errorAmountCard = '';
        this.card_errors = {};
      }else {
        this.errorAmountBankTransfer = '';
      }
    }
    this.amount = event.target.value
  }else{
    this.amount = 0;
  }
}

formatAmount = (event) => {
  let unformattedvalue = event.target.value;
  unformattedvalue = Number(unformattedvalue.replace(/,/g, ''));
  event.target.value = this.decimalPipe.transform(unformattedvalue, '1.2-2');
  this.amount = event.target.value;
} 

  submitForm() {
    /* Change the display message on button click / submit form */
  // stop here if form is invalid
  if (this.paymentForm.invalid) {
    this.displayMessage = "Payment Failed!";
      return;
  }
    this.displayMessage = "Payment Successful!";
  }

  setPaymentMethod = (event) => {
    let payment_method = event.target.innerText;
    if(payment_method == 'Bank Transfer') {
      this.paymentMethod = 'bank_transfer';
    }else if(payment_method == 'Card'){
      this.paymentMethod = 'card';
    }
  }

  // Stripe Bank Payment
  // async payThroughBankTransfer(formData)  {
  //   let data = this.bankTransferPaymentForm.value;
  //   if(!data['routing_amount']){
  //     this.transferMsg = 'Please enter router number!'
  //   }else if(!data['account_number']){
  //     this.transferMsg = 'Please enter account number!'
  //   }else if(!data['account_holder']){
  //     this.transferMsg = 'Please enter account holder name!'
  //   }else if(!data['confirm_account']){
  //     this.transferMsg = 'Please enter confirm account number!'
  //   }else if(!data['payment_amount']){
  //     this.transferMsg = 'Please enter amount!'
  //   }else{
  //     this.transferMsg = ''
  //   if(typeof(this.amount) == 'string') {
  //     this.amount = Number(this.amount.replace(/,/g, ''));
  //   }
   
  //   data['order_id'] = this.estId
  //   data['frenchise_id'] = this.frenchiseId
  //   //added for create token
  //   this.loading = true;
  //   const { token, error } = await stripe.createToken('bank_account',{
  //     country: 'US',
  //     currency: 'usd',
  //     routing_number: data['routing_amount'],
  //     account_number: data['account_number'],
  //     account_holder_name:data['account_holder'] ,
  //     account_holder_type: 'individual',
  //   })
  //   if (error) {
  //     this.loading = false;
  //     this.api_error = error['message'];
  //     // this.card_errors = error
  //   } else {
  //     // this.card_errors = {}
  //       if(token && +this.amount > 0){
  //           let data = token
  //           data['sourceID']=token['bank_account']['id']
  //           data['order_id'] = this.estId
  //           data['frenchise_id'] = this.frenchiseId
  //           data['payment_type'] = token['type']
  //           data['amount'] = +this.amount;
  //           data['type'] = "bank_transfer"
  //           this.orderService.makeOrderPayment(this.frenchiseId,data).subscribe((res)=>{
  //             this.amount = null;
  //               if(res['data']){
  //                this.reciept =   res['data']['receipt_url']
  //               }
  //               this.ngOnInit()
  //               this.loading = false
  //           },(error)=>{
  //               this.loading = false
  //               if (error['error']) {
  //                   this.api_error = error['error']['message'];
  //               }

  //           })
  //       }else {
  //         this.loading = false
  //         this.errorAmountBankTransfer = "Please Enter Amount!"
  //       }
  //       if(token && !this.amount){
  //         this.errorAmountBankTransfer = "Please Enter Amount!"
  //       }
  //   }
  // }
  // } 



   // Quickbook Bank/E-Check Payment
   async payThroughBankTransfer(formData)  {
    if((+this.amount < 0) || !this.amount){
      this.errorAmountBankTransfer = "Please Enter Amount!"
    }
    if(this.bankTransferPaymentForm.valid) {
      if(typeof(this.amount) == 'string') {
        this.amount = Number(this.amount.replace(/,/g, ''));
      }
      let data = this.bankTransferPaymentForm.value;
    
      if(+this.amount > 0){
          data['order_id'] = this.estId;
          data['frenchise_id'] = this.frenchiseId
          data['amount'] = (+this.amount).toFixed(2);
          data['payment_type'] = "bank_account"
          this.loading = true;
          this.quickbookPaymentError = [];
          this.orderService.makeOrderPayment(this.frenchiseId,data).subscribe((res)=>{
            this.amount = null;
              if(res['data']){
                this.reciept =   res['data']['receipt_url']
              }
              this.ngOnInit()
              this.loading = false
              // this.receivedPayment.emit();
              // this.closeModel.emit();
          },(error)=>{
              this.loading = false
              if (error['error']) {
                  this.api_error = error['error']['message'];
                  if(error['error']['data']) {
                    this.quickbookPaymentError = error['error']['data'];
                  }
              }
  
          })
      }else {
        this.loading = false
        this.errorAmountBankTransfer = "Please Enter Amount!"
      }
      if(!this.amount){
        this.errorAmountBankTransfer = "Please Enter Amount!"
      }
    }else {
      this.showBankPaymentErrors = true;
    }
  } 

  // end card ui

  // download invoice
  initialiseInvoiceData = () => {
    this.invoiceData = { subTotal: 0, tax: 0, shipping: 0, total: 0 };
  }

  validateAndDownload = () => {
    const postData = this.orderData;
    postData['invoice_data'] = this.invoiceData;
    postData['franchise_logo'] = this.franchiseLogo;
    postData['franchise_id'] = this.frenchiseId;
    postData['total_data'] = this.totalData;
    this.loading = true;
    this.projectService.publicDownloadInvoicePdf(postData).subscribe(
      (res) => {
        this.loading = false;
        this.api_error = '';
        const blobData = new Blob([res], { type: 'application/octet-stream' });
        const a = document.createElement('a');
        a.href = URL.createObjectURL(blobData);
        // if(postData['order_invoice']) {
        //   a.download = postData['order_invoice']['invoice_uid'] + '_Invoice.pdf';
        // }else
        //  {
          a.download = postData['sales_order_uid'] + '_Invoice.pdf';
        // }
        document.body.appendChild(a);
        a.click();
      },
      (error) => {
        this.loading = false;

        if (error['error']['data']) {
          this.api_error = error['error']['data'];
        }
      }
    );
  }
  // end download invoice

  get f() { return this.cardPaymentForm.controls; }

  get b() { return this.bankTransferPaymentForm.controls; }
}
